export const API_LIST = {
  recentStudies: 'dashboard/dashboard-studies',
  dashboardDetails: 'dashboard/dashboard-details',
  myStudies: 'studies',
  launchBar: 'launch-bar',
  logs: 'logs',
  errorLog: 'error-log',
  checkStudyTitle: '/study/check-study-title/',
  dataSourceURL: 'cohort/validate-data-sources',
  getCohortURL: 'cohort/canvas/exported_publish_or_my_cohort?dataset_id=',
  getCohortVisualizationURL:
    'cohort/canvas/cohort_export_criteria_details?cohortId=',
  idmUsers: '/users/idm-users/',
  validateS3Location: '/cohort/validate-tables/',
  createStudy: 'study/',
  validateTable: 'cohort/validate-tables/',
  businessRules: 'business-rule',
  userSession: 'auth/user-session',
  dataFilterAPI: '/parameter/<<study_id>>/dos-imputation/execute/',
  projectDetails:'projects/',
  projectUsers: '/users_in_project?project_id=<<project_id>>&application=PATIENT_JOURNEY&user_id=<<user_id>>'
};
