import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { API_LIST } from '../constants/api-list';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private httpOptions: any;
  constructor(
    private transactionManager: TransactionManagerService,
    private http: HttpClient,
    private notification: NzNotificationService,
    private router: Router
  ) {}

  fetchLaunchBar(): Observable<any> {
    return this.transactionManager.get(API_LIST.launchBar).pipe(
      map((response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          return response;
        } else {
          return this.errorHandler();
        }
      })
    );
  }

  getBusinessRules(): Observable<any> {
    return this.transactionManager.get(API_LIST.businessRules).pipe(
      map((response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          return response;
        } else {
          return this.errorHandler();
        }
      })
    );
  }

  getUserSession() {
    return this.transactionManager.get(API_LIST.userSession).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getUserRole(studyId: any) {
    return this.transactionManager.get('study/' + studyId + '/user-role').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getProjectName(projectId:any): Observable<any> {
    // @ts-ignore
    const url = environment.pacHomeURL + API_LIST.projectDetails + projectId;

    return this.http.get(url, this.httpOptions).pipe(
      map(
        (response: any) => {
         return response.data.project_name
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  createNotification(type: string, message: any): void {
    this.notification.create(type, '', message, {
      nzClass: 'notification-' + type,
    });
  }

  getJobStatus(studyId: any) {
    return this.transactionManager
      .get(
        'study/' +
          studyId +
          '/job-status?job_type=mapping&job_category=mapping&job_status=running,pending,completed'
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getStudyProgress(studyId: any) {
    return this.transactionManager.get('study/' + studyId + '/progress/').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  errorHandler(e: any = ''): any {
    if (e.error == 'Study access issue') {
      this.router.navigate(['studies/error/access_issue']);
    }
    return { status: API_FAILURE_KEY };
  }

  getRegion() {
    return this.transactionManager.get('users/fetch_region').pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
