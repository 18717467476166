import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sentenceCase' })
export class SentenceCasePipe implements PipeTransform {
  transform(value: string) {
    if (value) {
      const sentences = value
        .split('. ')
        .map((s) => s[0].toUpperCase() + s.substring(1));
      return sentences.join('. ');
    }
    return value;
  }
}
