<rd-modal
  [isVisible]="show"
  [isCloseable]="true"
  [isMaskClosable]="false"
  modalWidth="65%"
  (modalCloseEvent)="handleClose()"
>
  <ng-container rdModalTitle>Add Rules</ng-container>
  <div
    class="stage-identification__modal-content"
    rdModalContent
    [ngSwitch]="ruleListFetchStatus"
  >
    <rd-alert
      *ngIf="errorMessage"
      [showIcon]="true"
      [closable]="true"
      type="error"
      [message]="errorMessage"
    ></rd-alert>
    <ng-container
      *ngSwitchCase="'loading'"
      [ngTemplateOutlet]="loadingSpinner"
    ></ng-container>
    <ng-container
      *ngSwitchCase="'error'"
      [ngTemplateOutlet]="errorMessageContainer"
    ></ng-container>
    <ng-container *ngSwitchCase="'available'">
      <div class="steps-container">
        <rd-steps
          [options]="stepsOption"
          [index]="currentStep"
          (currentIndex)="handleStepChange($event)"
        ></rd-steps>
      </div>
      <div class="steps-content">
        <div class="rule-content__step" [ngSwitch]="currentStep">
          <ng-container
            *ngSwitchCase="0"
            [ngTemplateOutlet]="templateRules"
          ></ng-container>
          <ng-container
            *ngSwitchCase="1"
            [ngTemplateOutlet]="customRules"
          ></ng-container>
          <ng-container
            *ngSwitchCase="2"
            [ngTemplateOutlet]="reviewRules"
          ></ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="stage-identification__modal-footer" rdModalFooter>
    <rd-button
      *ngIf="buttonLabel('back')"
      btnType="default"
      [btnText]="buttonLabel('back')"
      [btnStyle]="buttonStyle"
      (btnClick)="handleBackButtonClick(currentStep)"
    ></rd-button>
    <rd-button
      *ngIf="buttonLabel('next')"
      btnType="primary"
      [btnText]="buttonLabel('next')"
      [btnStyle]="buttonStyle"
      [isDisabled]="isNextButtonDisabled(currentStep)"
      (btnClick)="handleNextButtonClick(currentStep)"
    ></rd-button>
  </div>
</rd-modal>

<ng-template #templateRules>
  <div
    class="rule-category"
    *ngFor="let category of templateRulesList; let categoryIndex = index"
  >
    <nz-table
      nzSize="middle"
      [nzData]="category.rules"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
    >
      <thead>
        <tr>
          <th>{{ category.name }}:</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rule of category.rules">
          <td>
            <div class="rule rule-template">
              <label
                nz-checkbox
                [(ngModel)]="rule.selected"
                (ngModelChange)="
                  handleTemplateRuleCheck($event, categoryIndex, rule)
                "
              ></label>
              <span>{{ rule.text | sentenceCase }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>

<ng-template #customRules>
  <div
    class="rule-category rule-category-custom"
    *ngFor="let category of rulesList; let categoryIndex = index"
  >
    <div class="rule__category-header">
      <p class="rule__category-title">{{ category.name }}:</p>
      <nz-select
        class="rule__category-stage"
        nzPlaceHolder="Select"
        [style]="{ width: '120px' }"
        [(ngModel)]="category.stage"
      >
        <nz-option
          *ngFor="let option of stageOptions"
          [nzLabel]="option | titlecase"
          [nzValue]="option"
        ></nz-option>
      </nz-select>
    </div>
    <div *ngIf="category.rules.length; else selectTemplateOrAddCustomMessage">
      <div
        class="rule rule-custom"
        [ngClass]="{ 'rule--error': rule.error }"
        *ngFor="let rule of category.rules; let ruleIndex = index"
      >
        <div class="rule__definition">
          <ng-container
            *ngFor="let ruleComponent of rule.rule; let componentIndex = index"
          >
            <div
              *ngIf="rule.type === 'custom' || ruleComponent.exists"
              [ngSwitch]="ruleComponent.element"
            >
              <div
                *ngSwitchCase="'selectable'"
                class="rule__component--selectable"
                [ngClass]="'rule__component--' + ruleComponent.key"
              >
                <nz-select
                  nzPlaceHolder="Select"
                  [style]="{ width: '120px' }"
                  [nzAllowClear]="ruleComponent.key === 'condition'"
                  [(ngModel)]="ruleComponent.value"
                  (ngModelChange)="
                    handleRuleComponentInputChange(
                      $event,
                      rule,
                      ruleComponent,
                      componentIndex
                    )
                  "
                >
                  <nz-option
                    *ngFor="let option of ruleComponent.options"
                    [nzLabel]="option | titlecase"
                    [nzValue]="option"
                  ></nz-option>
                </nz-select>
                <div
                  *ngIf="ruleComponent.value === 'nth'"
                  class="n-value-input"
                >
                  <span>(</span>
                  <nz-input-number
                    [(ngModel)]="ruleComponent.data"
                    (ngModelChange)="handleRuleComponentMetadataChange(rule)"
                    [nzMin]="2"
                    [nzStep]="1"
                  ></nz-input-number>
                  <span>)</span>
                </div>
                <div
                  *ngIf="
                    (ruleComponent.key === 'event1' ||
                      ruleComponent.key === 'event2') &&
                    ruleComponent.value
                  "
                >
                  <rd-button
                    btnType="text"
                    [isIconNeeded]="true"
                    [iconStyle]="buttonIconStyle"
                    [btnStyle]="iconOnlyButtonStyle"
                    iconType="plus-circle"
                    (btnClick)="handleAddSubclassButtonClick(ruleComponent)"
                    title="Add events"
                  ></rd-button>
                </div>
              </div>
              <div *ngSwitchCase="'input'">
                <nz-input-number
                  [(ngModel)]="ruleComponent.value"
                  (ngModelChange)="
                    handleRuleComponentInputChange(
                      $event,
                      rule,
                      ruleComponent,
                      componentIndex
                    )
                  "
                  [nzMin]="0"
                  [nzStep]="1"
                ></nz-input-number>
              </div>
              <p *ngSwitchDefault>{{ ruleComponent.value }}</p>
            </div>
          </ng-container>
        </div>
        <div class="rule__actions">
          <rd-button
            btnType="text"
            [isIconNeeded]="true"
            [iconStyle]="{ color: 'rgba(0,0,0,.85)' }"
            [btnStyle]="iconOnlyButtonStyle"
            iconType="custom:copy-icon"
            (btnClick)="handleCloneRuleButtonClick(categoryIndex, ruleIndex)"
          ></rd-button>
          <rd-button
            btnType="text"
            [isIconNeeded]="true"
            [iconStyle]="{ color: 'rgba(0,0,0,.85)' }"
            [btnStyle]="iconOnlyButtonStyle"
            iconType="custom:delete-icon"
            (btnClick)="handleRemoveRuleButtonClick(categoryIndex, ruleIndex)"
          ></rd-button>
        </div>
      </div>
    </div>

    <div class="rule__category-actions">
      <rd-button
        btnText="Add Custom Rule"
        btnType="text"
        [isIconNeeded]="true"
        [iconStyle]="{ color: 'rgba(0,0,0,.85)' }"
        iconType="plus"
        (btnClick)="handleAddCustomRuleButtonClick(categoryIndex)"
      ></rd-button>
    </div>
  </div>
</ng-template>

<ng-template #reviewRules>
  <ng-container
    *ngIf="isPriorityRuleListNonEmpty(); else addRulesToSaveMessage"
  >
    <ng-container
      *ngFor="let category of priorityRulesList; let categoryIndex = index"
    >
      <div class="rule-category" *ngIf="category.rules.length">
        <div class="rule__category-header">
          <p class="rule__category-title">{{ category.name }}:</p>
          <p class="rule__category-title rule__category-stage">
            {{ category.stage | titlecase }}
          </p>
        </div>
        <nz-table
          nzSize="middle"
          [nzData]="category.rules"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
        >
          <thead>
            <tr>
              <th nzWidth="85px">Reorder</th>
              <th>Rule</th>
              <th nzWidth="85px" nzAlign="center">Actions</th>
            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="drop($event, categoryIndex)">
            <tr
              *ngFor="let rule of category.rules; let ruleIndex = index"
              cdkDrag
            >
              <td>
                <div class="drag-handle" cdkDragHandle>
                  <i nz-icon nzType="custom:drag-handle"></i>
                </div>
              </td>
              <td>
                <ng-container
                  *ngFor="let item of rule.textPlus; let first = first"
                >
                  <span
                    *ngIf="item.metadata?.length; else justText"
                    class="rule__popover-text"
                    nz-popover
                    nzType="primary"
                    nzPopoverOverlayClassName="subclass-popover"
                    [nzPopoverContent]="subclassPopoverContent"
                  >
                    {{ first ? ('' + item.text | titlecase) : item.text }}
                  </span>
                  <ng-template #subclassPopoverContent>
                    <p class="subclass-popover-content">
                      {{ item.metadata }}
                    </p>
                  </ng-template>
                  <ng-template #justText>
                    <span>
                      {{ first ? ('' + item.text | titlecase) : item.text }}
                    </span>
                  </ng-template>
                </ng-container>
                <rd-tag *ngIf="rule.type !== 'existing'">
                  Newly Configured
                </rd-tag>
              </td>
              <td nzAlign="center">
                <rd-button
                  btnType="text"
                  [isIconNeeded]="true"
                  [iconStyle]="{ color: 'rgba(0,0,0,.85)' }"
                  [btnStyle]="iconOnlyButtonStyle"
                  [ngStyle]="{
                    display: 'block',
                    width: 'fit-content',
                    margin: 'auto'
                  }"
                  iconType="custom:delete-icon"
                  (btnClick)="
                    handleRemovePriorityRuleButtonClick(
                      categoryIndex,
                      ruleIndex
                    )
                  "
                ></rd-button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #selectTemplateOrAddCustomMessage>
  <p class="message-container">Select a template or add a custom rule.</p>
</ng-template>

<ng-template #addRulesToSaveMessage>
  <p class="message-container">
    No rules have been configured. Add rules to save.
  </p>
</ng-template>

<ng-template #errorMessageContainer>
  <rd-alert
    class="alert-center"
    [showIcon]="true"
    type="error"
    message="Something went wrong. Please try again later."
  ></rd-alert>
</ng-template>

<ng-template #loadingSpinner>
  <div class="message-container">
    <rd-spinner></rd-spinner>
  </div>
</ng-template>

<app-select-subclass-drawer
  *ngIf="subclassDrawerData.show"
  [show]="subclassDrawerData.show"
  [studyId]="subclassDrawerData.studyId"
  [ruleType]="subclassDrawerData.ruleType"
  [selectedListOfValues]="subclassDrawerData.selectedValues"
  [regimenDays]="subclassDrawerData.regimenDays"
  (drawerClose)="handleSubclassDrawerClose($event)"
></app-select-subclass-drawer>
