import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { consolidatedBaseUrl } from './baseUrls';

@Injectable()
export class TransactionManagerService {
  private endpoint: string;

  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    this.endpoint = environment.serverUrl;
  }

  /**
   * Function to call an API with DELETE method.
   */
  public delete(url: string): Observable<any> {
    const apiEndPoint = this.createEndPoint(url);
    return this.http.delete(apiEndPoint, this.httpOptions);
  }

  /**
   * Function to call an API with GET method.
   */
  public get(url: string): Observable<any> {
    const apiEndPoint = this.createEndPoint(url);
    return this.http.get(apiEndPoint, this.httpOptions);
  }

  /**
   * Function to call an API with POST method.
   */
  public post(url: string, body?: any): Observable<any> {
    const bodyChanged = this.addProperties(body);
    const apiEndPoint = this.createEndPoint(url);
    return this.http.post(apiEndPoint, bodyChanged, this.httpOptions);
  }

  /**
   * Function to call an API with PUT method.
   */
  public put(url: string, body: any): Observable<any> {
    const bodyChanged = this.addProperties(body);
    const apiEndPoint = this.createEndPoint(url);
    return this.http.put(apiEndPoint, bodyChanged, this.httpOptions);
  }

  /**
   * Function to call an API with PATCH method.
   */
  public patch(url: string, body: any): Observable<any> {
    const bodyChanged = this.addProperties(body);
    const apiEndPoint = this.createEndPoint(url);
    return this.http.patch(apiEndPoint, bodyChanged, this.httpOptions);
  }

  private createEndPoint(url: string): string {
    return `${this.endpoint}/${url}`;
  }

  private addProperties(body: any): any {
    return body;
  }
}
