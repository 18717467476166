import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-export-action-popup',
  templateUrl: './export-action-popup.component.html',
  styleUrls: ['./export-action-popup.component.scss'],
})
export class ExportActionPopupComponent implements OnInit {
  @Input() exportBody: any;

  @Output() closeExportPopup: EventEmitter<boolean> = new EventEmitter();

  isVisible: boolean = false;

  exportData: any[] = [];

  constructor(private clipboardService: ClipboardService) {}

  ngOnInit() {
    this.showModal();
    if (this.exportBody?.data?.length > 0) {
      this.exportData = this.exportBody.data;
    } else {
      this.exportData = [];
    }
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closeExportPopup.emit(false);
  }

  handleOk() {
    this.isVisible = false;
    this.closeExportPopup.emit(true);
  }

  copyDynamicText(index: number) {
    this.exportData[index].isActive = true;
    setTimeout(() => {
      this.exportData[index].isActive = false;
    }, 100);
    this.clipboardService.copyFromContent(this.exportData[index].filepath[0]);
  }
}
