<div *ngIf="!isStudyAccessIssue || !studyId" class="main-class">
  <div [style.pointer-events]="!isCohortDataLoaded ? 'none' : 'auto'">
    <rd-breadcrumb
      *ngIf="!studyId"
      [breadcrumbData]="breadcrumbData"
      [separator]="'>'"
    ></rd-breadcrumb>
    <nz-breadcrumb
      class="breadcrumb endClickable"
      [nzSeparator]="'>'"
      *ngIf="studyId"
    >
      <nz-breadcrumb-item
        class="breadcrumb endClickable"
        *ngFor="let item of viewerBreadCrumbData"
      >
        <a
          class="breadcrumb endClickable"
          *ngIf="item.navigationPath.length > 0"
          (click)="collaborator(item.displayName, item.navigationPath)"
        >
          {{ item.displayName }}
        </a>
        <span
          [ngClass]="
            item.navigationPath.length === 0 ? 'nonClickableBreadCrumb' : ''
          "
          *ngIf="item.navigationPath.length === 0"
        >
          {{ item.displayName }}
        </span>
      </nz-breadcrumb-item>
    </nz-breadcrumb>
  </div>

  <div class="page-heading title">Basic Information</div>

  <form [formGroup]="studyDetailForm">
    <div
      class="main-class-section parameter-section section-border d-flex d-block-sm textboxsize"
    >
      <div class="input-section w-50 pr-sm-10 w-sm-100">
        <div class="label-heading">
          Study Title<span class="required-star"> *</span>
        </div>
        <div class="d-flex">
          <input
            nz-input
            placeholder=""
            [ngClass]="
              studyDetailForm.get('studyTitle')?.invalid &&
              studyDetailForm.get('studyTitle')?.touched
                ? 'red-outline'
                : ''
            "
            (keypress)="allowAlphaNumeric($event, '^[0-9a-zA-Z ]+$')"
            (change)="validateStudyTitle($event)"
            (paste)="allowAlphaNumeric($event, '^[0-9a-zA-Z ]+$')"
            maxlength="50"
            formControlName="studyTitle"
          />
          <div
            class="error d-flex align-items-center px-2 red-icon"
            *ngIf="
              !studyDetailForm.get('studyTitle')?.valid &&
              studyDetailForm.get('studyTitle')?.touched
            "
          >
            <i
              nz-icon
              nzType="exclamation-circle"
              nzTheme="fill"
              nz-popover
              [nzPopoverContent]="contentTemplate"
            ></i>
            <ng-template #contentTemplate>
              <div *ngIf="studyDetailForm.get('studyTitle')?.errors?.required">
                This field is required
              </div>
              <div
                *ngIf="
                  studyDetailForm.get('studyTitle')?.errors?.minlength ||
                  studyDetailForm.get('studyTitle')?.errors?.pattern
                "
              >
                Study Title should start with an alphabet in upper case, can be
                alphanumeric and have minimum 8 characters
              </div>
              <div
                *ngIf="
                  studyDetailForm.get('studyTitle')?.errors?.validStudyTitle
                "
              >
                Study Title already in use
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="input-section w-50 pr-sm-10 w-sm-100">
        <div class="label-heading">Study Description</div>
        <input
          nz-input
          placeholder=""
          formControlName="studyDescription"
          (keypress)="allowAlphaNumeric($event, '^[a-z0-9A-Z ]+$')"
          maxlength="100"
          (paste)="allowAlphaNumeric($event, '^[0-9a-zA-Z ]+$')"
        />
      </div>

      <div class="input-section w-50 pr-sm-10 w-sm-100">
        <div class="label-heading">
          Therapy Area<span class="required-star">&nbsp;*</span>
        </div>
        <div class="d-flex">
          <input
            nz-input
            placeholder=""
            formControlName="therapyArea"
            [ngClass]="
              studyDetailForm.get('therapyArea')?.invalid &&
              studyDetailForm.get('therapyArea')?.touched
                ? 'red-outline'
                : ''
            "
            (keypress)="allowAlphaNumeric($event, '^[0-9a-zA-Z-_ ]+$')"
            maxlength="50"
            (paste)="allowAlphaNumeric($event, '^[0-9a-zA-Z-_ ]+$')"
          />
          <div
            class="error d-flex align-items-center px-2 red-icon"
            *ngIf="
              !studyDetailForm.get('therapyArea')?.valid &&
              studyDetailForm.get('therapyArea')?.touched
            "
          >
            <i
              nz-icon
              nzType="exclamation-circle"
              nzTheme="fill"
              nz-popover
              [nzPopoverContent]="contentTemplate"
            ></i>
            <ng-template #contentTemplate>
              <div *ngIf="studyDetailForm.get('therapyArea')?.errors?.required">
                This field is required
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="main-class-section parameter-section section-border">
    <div nz-row class="d-flex-start-space-between">
      <div>
        <div class="cohort-details-title">Cohort Information</div>
        <div class="cohort-details-sub-title">
          Select cohort or Import cohort
        </div>
      </div>
      <div *ngIf="showCohortTable" class="rd-btn-styl">
        <rd-button
          [btnType]="'default'"
          [btnStyle]="importBtnStyle"
          btnClass="override-antcss-focus"
          btnText="Import"
          [isDisabled]="disableInput"
          [isIconNeeded]="false"
          (btnClick)="onImportClick()"
        ></rd-button>
      </div>
      <div *ngIf="showBox" class="rd-btn-styl">
        <rd-button
          [btnType]="'primary'"
          [btnStyle]="selectCohortBtnStyle"
          btnText="Select Cohort"
          [isIconNeeded]="false"
          [btnClass]="'custom-primaryfill-greenbtn'"
          (btnClick)="onSelectCohortClick()"
        ></rd-button>
      </div>
    </div>

    <div
      *ngIf="showCohortTable"
      [ngClass]="{ disabledTable: viewEditClicked }"
      nz-row
      class="cohort-details-body"
    >
      <div nz-col [nzSpan]="7" class="border-box">
        <rd-table
          [isLoading]="!isDataSourceLoaded"
          [tableColumns]="dataSourceTableColumns"
          [tableData]="dataSourceList"
          [errorMessage]="dataSourceTableErrorMessage"
          [errorType]="dataSourceTableErrorType"
          [showPagination]="false"
          [frontEndPagination]="false"
          [tableScrollDetails]="dataSourceTableScrollDetails"
          (tableRowClick)="onDataSourceTableRowClick($event)"
        ></rd-table>
      </div>
      <div nz-col [nzSpan]="1"></div>

      <div nz-col [nzSpan]="16" class="border-box">
        <rd-table
          [tableScrollDetails]="dataSourceTableScrollDetails"
          [isLoading]="!isCohortDataLoaded"
          [tableColumns]="cohortTableColumns"
          [tableData]="cohortDetails"
          [errorMessage]="cohortTableErrorMessage"
          [errorType]="cohortTableErrorType"
          [showPagination]="false"
          [frontEndPagination]="false"
          (tableRowClick)="onCohortViewButtonClick($event)"
        ></rd-table>
      </div>
    </div>
    <div class="spinner-style" *ngIf="spinnerOnCohortViewClick">
      <rd-spinner [isSpinning]="true"></rd-spinner>
    </div>

    <div *ngIf="showBox" class="box-container">
      <div nz-row>
        <div nz-col [nzSpan]="24" class="box-title">
          Imported cohort information
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8" class="ant-col-style">
          <p class="drawer-box-header">Data Source</p>
          <p class="drawer-box-content">
            {{ cohortDetailsImportSelection?.dataSourceName }}
          </p>
        </div>
        <div nz-col nzSpan="8" class="ant-col-style">
          <p class="drawer-box-header">Cohort Name</p>
          <p class="drawer-box-content">
            {{ cohortDetailsImportSelection?.cohortName }}
          </p>
        </div>

        <div nz-col nzSpan="8" class="ant-col-style">
          <p class="drawer-box-header">Description</p>
          <p class="drawer-box-content">
            {{ cohortDetailsImportSelection?.importedCohortDescription }}
          </p>
        </div>
        <div nz-col nzSpan="24" class="ant-col-style">
          <p class="drawer-box-header">S3 Location</p>
          <p class="drawer-box-content">
            {{ cohortDetailsImportSelection?.importedS3Location }}
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="showBoxNew" class="box-container">
      <div nz-row>
        <div nz-col [nzSpan]="24" class="box-title">
          Imported cohort information
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8" class="ant-col-style">
          <p class="drawer-box-header">Data Source</p>
          <p class="drawer-box-content">
            {{ editCohort.dataSourceName }}
          </p>
        </div>
        <div nz-col nzSpan="8" class="ant-col-style">
          <p class="drawer-box-header">Cohort Name</p>
          <p class="drawer-box-content">
            {{ editCohort.cohortName }}
          </p>
        </div>

        <div nz-col nzSpan="8" class="ant-col-style">
          <p class="drawer-box-header">Description</p>
          <p class="drawer-box-content">
            {{ editCohort.importedCohortDescription }}
          </p>
        </div>
        <div nz-col nzSpan="24" class="ant-col-style">
          <p class="drawer-box-header">S3 Location</p>
          <p class="drawer-box-content">
            {{ editCohort.importedS3Location }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="main-class-section selectors-section">
    <div class="title">Add Users to the Study</div>
    <div class="selectors-section-inner d-flex d-block-sm">
      <div class="input-section w-50 pr-sm-10 w-sm-100">
        <div class="label-heading">Add Collaborators</div>
        <nz-select
          (ngModelChange)="onSelectColloboratorsViewers($event, true)"
          nzMode="multiple"
          nzPlaceHolder="Please select"
          [nzLoading]="!collaboratorViewerLoaded"
          [(ngModel)]="listOfSelectedColloborators"
          [disabled]="viewModeClicked"
          [nzShowArrow]="true"
          [nzMaxTagCount]="3"
        >
          <nz-option
            *ngFor="let item of idmUsersListCollaborators"
            [nzLabel]="item.name"
            [nzValue]="item"
            [nzDisabled]="item.isViewer"
          ></nz-option>
        </nz-select>
      </div>
      <div></div>
      <div class="input-section w-50 pl-sm-10 w-sm-100">
        <div class="label-heading">Add Viewers</div>
        <nz-select
          nzMode="multiple"
          nzPlaceHolder="Please select"
          [nzLoading]="!collaboratorViewerLoaded"
          [(ngModel)]="listOfSelectedViewers"
          (ngModelChange)="onSelectColloboratorsViewers($event, false)"
          [disabled]="viewModeClicked"
          [nzShowArrow]="true"
          [nzMaxTagCount]="3"
        >
          <nz-option
            *ngFor="let item of idmUsersListViewers"
            [nzLabel]="item.name"
            [nzValue]="item"
            [nzDisabled]="item.isCollobotator"
          ></nz-option>
        </nz-select>
      </div>
    </div>
  </div>

  <div class="footer-section d-flex justify-content-end">
    <div class="footer-section-wrapper">
      <rd-button
        [btnType]="'default'"
        btnText="Back"
        [isIconNeeded]="false"
        [size]="'large'"
        (btnClick)="navigateBack()"
      ></rd-button>
      <rd-button
        [btnType]="'default'"
        btnText="Save"
        [isIconNeeded]="false"
        [isDisabled]="
          studyDetailForm.invalid ||
          !isUserRoleCollaborator ||
          !checkDataNotSaved()
        "
        (btnClick)="onStudyDetailsSubmitClick('SAVE')"
      ></rd-button>
      <rd-button
        [btnType]="'primary'"
        btnText="Next"
        [btnClass]="'custom-primaryfill-greenbtn'"
        [isDisabled]="
          studyDetailForm.invalid ||
          !isUserRoleCollaborator ||
          !isStudyDetailDataLoaded ||
          !isCohortDataLoaded
        "
        (btnClick)="onStudyDetailsSubmitClick('NEXT')"
      ></rd-button>
    </div>
  </div>
</div>
<div class="spinner-style" *ngIf="createStudyLoader">
  <rd-spinner></rd-spinner>
</div>
<app-action-confirmation-popup
  *ngIf="showConfirmationFlag"
  (closePopup)="closeConfirmationPopup($event)"
  (close)="showConfirmationFlag = false"
  [confirmationConfig]="confirmationConfig"
></app-action-confirmation-popup>

<nz-drawer
  [nzTitle]="importdrawertitleTpl"
  [nzClosable]="false"
  [nzVisible]="importDrawerVisible"
  [nzWidth]="500"
  [nzFooter]="importdrawerfooterTpl"
  nzPlacement="right"
  (nzOnClose)="onCloseImportDrawerClick()"
  nzWrapClassName="cohort-import-drawer-style"
>
  <ng-container *nzDrawerContent>
    <div class="table-validating" *ngIf="!isValidateTableDataLoaded">
      <rd-spinner></rd-spinner>
    </div>
    <div class="import-container" *ngIf="isValidateTableDataLoaded">
      <div class="import-input-section">
        <div class="import-label-heading">
          Data Source<span class="required-star">&nbsp;*</span>
        </div>
        <nz-input-group nzSize="large" [nzAddOnAfter]="dataSourceValidation">
          <input
            nz-input
            [(ngModel)]="dataSource"
            #dataSourceCtrl="ngModel"
            required
            maxlength="50"
            (ngModelChange)="validateForm('dataSource', $event)"
            [ngClass]="
              (dataSourceCtrl.dirty || dataSourceCtrl.touched) &&
              dataSourceCtrl.invalid
                ? 'red-outline'
                : ''
            "
          />

          <ng-template #dataSourceValidation>
            <div
              *ngIf="
                dataSourceError ||
                  ((dataSourceCtrl.dirty || dataSourceCtrl.touched) &&
                    dataSourceCtrl.invalid);
                then formError
              "
            ></div>
          </ng-template>
        </nz-input-group>
      </div>

      <div class="import-input-section">
        <div class="import-label-heading">
          Cohort name<span class="required-star">&nbsp;*</span>
        </div>
        <nz-input-group nzSize="large" [nzAddOnAfter]="cohortNameValidation">
          <input
            nz-input
            [(ngModel)]="cohortName"
            maxlength="50"
            (ngModelChange)="validateForm('cohortName', $event)"
            required
            #cohortNameCtrl="ngModel"
            [ngClass]="
              (cohortNameCtrl.dirty || cohortNameCtrl.touched) &&
              cohortNameCtrl.invalid
                ? 'red-outline'
                : ''
            "
          />
        </nz-input-group>
        <ng-template #cohortNameValidation>
          <div
            *ngIf="
              cohortNameError ||
                ((cohortNameCtrl.dirty || cohortNameCtrl.touched) &&
                  cohortNameCtrl.invalid);
              then formError
            "
          ></div>
        </ng-template>
      </div>

      <div class="import-input-section">
        <div class="import-label-heading">
          Cohort Description<span class="required-star">&nbsp;*</span>
        </div>
        <nz-input-group
          nzSize="large"
          [nzAddOnAfter]="cohortDescriptionValidation"
        >
          <textarea
            rows="4"
            nz-input
            [(ngModel)]="cohortDescription"
            maxlength="100"
            (ngModelChange)="validateForm('cohortDescription', $event)"
            required
            #cohortDescriptionCtrl="ngModel"
            [ngClass]="
              (cohortDescriptionCtrl.dirty || cohortDescriptionCtrl.touched) &&
              cohortDescriptionCtrl.invalid
                ? 'red-outline'
                : ''
            "
          ></textarea>
          <ng-template #cohortDescriptionValidation>
            <div
              *ngIf="
                cohortDescriptionError ||
                  ((cohortDescriptionCtrl.dirty ||
                    cohortDescriptionCtrl.touched) &&
                    cohortDescriptionCtrl.invalid);
                then formError
              "
            ></div>
          </ng-template>
        </nz-input-group>
      </div>

      <div class="import-input-section">
        <div class="import-label-heading">
          S3 location<span class="required-star">&nbsp;*</span>
        </div>
        <nz-input-group nzSize="large" [nzAddOnAfter]="s3LocationValidation">
          <input
            nz-input
            [(ngModel)]="s3Location"
            (ngModelChange)="validateForm('s3Location', $event)"
            #s3LocationCtrl="ngModel"
            required
            [ngClass]="
              (s3LocationCtrl.dirty || s3LocationCtrl.touched) &&
              s3LocationCtrl.invalid
                ? 'red-outline'
                : ''
            "
          />
          <ng-template #s3LocationValidation>
            <div
              *ngIf="
                s3LocationError ||
                  ((s3LocationCtrl.dirty || s3LocationCtrl.touched) &&
                    s3LocationCtrl.invalid);
                then formError
              "
            ></div>
          </ng-template>
        </nz-input-group>
      </div>
    </div>
  </ng-container>
</nz-drawer>

<ng-template #importdrawertitleTpl>
  <div nz-row>
    <div nz-col nzSpan="20" class="drawer-header">Import Cohort</div>
    <div nz-col nzSpan="4" class="drawer-cross-button">
      <a nz-button nzType="link" (click)="onCloseImportDrawerClick()">X</a>
    </div>
  </div>
</ng-template>

<ng-template #importdrawerfooterTpl>
  <div class="import-footer">
    <rd-button
      [btnType]="'default'"
      btnText="Cancel"
      [isIconNeeded]="false"
      (btnClick)="onCancelImportClick()"
    ></rd-button>
    <rd-button
      [btnType]="'primary'"
      btnText="Submit"
      [isIconNeeded]="false"
      (btnClick)="onSubmitClick()"
      [btnClass]="'custom-primaryfill-greenbtn'"
      [isDisabled]="cohortImportFormError || disableSubmit"
    ></rd-button>
  </div>
</ng-template>

<ng-template #formError>
  <div class="error">
    <i
      nz-icon
      nzType="exclamation-circle"
      nzTheme="fill"
      nz-popover
      [nzPopoverContent]="fieldError"
    ></i>
  </div>
</ng-template>

<ng-template #fieldError> This field is required. </ng-template>

<app-cohort-visualization-popup
  *ngIf="openViewPopup"
  (closeViewPopup)="closeViewPopup()"
  [cohortId]="eyeIconCohortID"
  [exportVersion]="eyeIconExportVersion"
  [dataSourceId]="eyeIconDataSourceId"
>
</app-cohort-visualization-popup>

<app-information-popup
  *ngIf="informationPopupStatus"
  (closePopup)="informationPopupStatus = false"
>
</app-information-popup>
