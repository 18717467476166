import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzButtonModule } from "ng-zorro-antd/button";
import { ExportActionPopupComponent } from "./export-action-popup.component";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ClipboardModule } from 'ngx-clipboard';



@NgModule({
  declarations: [
    ExportActionPopupComponent
  ],
  exports: [
    ExportActionPopupComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    NzIconModule,
    ClipboardModule
  ]
})
export class ExportActionPopupModule { }
