import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../shared/services/common.service';
import { differenceInCalendarDays } from 'date-fns';
import { SelectInsightsService } from '../../../shared/services/select-insights.service';
import {
  API_SUCCESS_KEY,
  SOMETHING_WENT_WRONG,
} from '../../../shared/constants/app-constants';
// @ts-ignore
import differenceBy from 'lodash/differenceBy';
import { ActionTriggerService } from 'src/app/shared/services/action-trigger.service';
import { CreateStudiesService } from 'src/app/shared/services/create-studies.service';

@Component({
  selector: 'app-select-insights',
  templateUrl: './select-insights.component.html',
  styleUrls: ['./select-insights.component.scss'],
})
export class SelectInsightsComponent implements OnInit {
  breadcrumbData: any = null;

  studyId: any;

  fromDate: any;

  toDate: any;

  disabledToDate: any;

  disabledFromDate: any;

  insightsSelected = false;

  allMetrics: any = [];

  availableMetrics: any = [];

  selectedMetrics: any = [];

  selectedMetricIds: any = [];

  metricsToBeAdded: any = [];

  selectedMetricsToBeRemoved: any = [];

  activeMetrics: any = [];

  activeSelectedMetrics: any = [];

  actionButtonClass = {
    width: '50px',
    'margin-left': '0px',
    'margin-top': '10px',
    'margin-bottom': '10px',
  };

  availableInsightsLoading = false;

  isViewer = true;

  eligibleForSelectInsights = false;

  showConfirmationFlag = false;

  confirmationConfig = {
    header: 'Unsaved Changes',
    message: 'Your study will not be saved.',
    confirmationButtonName: 'Ok',
    cancelButtonName: 'Cancel',
  };

  showSaveError = false;

  saveErrorMessage = SOMETHING_WENT_WRONG;

  submitSuccessful = false;

  saveInProgress = false;

  availableMetricsInsightsError = false;

  insightLoadErrorMessage = SOMETHING_WENT_WRONG;

  isRequiredJobTriggeredStatus = false;

  originalFromDate: any;

  originalToDate: any;

  routeBackPath = '';

  originalAvailableMetrics = [];

  originalSelectedInsights = [];

  isDateLoaded = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private selectInsightsService: SelectInsightsService,
    private actionTriggerService: ActionTriggerService,
    private createStudiesService: CreateStudiesService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      this.studyId = params.get('studyId');
    });

    this.fetchProgressStatus();

    this.breadcrumbData = [
      { displayName: 'All Studies', navigationPath: '/home/' },
      {
        displayName: 'Study Details',
        navigationPath: `/studies/edit/${this.studyId}`,
      },
      {
        displayName: 'Dimension Mapping',
        navigationPath: `/studies/dimension-mapping/${this.studyId}`,
      },
      {
        displayName: 'Episode Creation',
        navigationPath: `/studies/episode-creation/${this.studyId}`,
      },
      {
        displayName: 'Regimen & LoT',
        navigationPath: `/studies/regimen-lot/${this.studyId}`,
      },
      {
        displayName: 'Select Insights',
        navigationPath: '',
      },
    ];
  }

  fetchProgressStatus() {
    this.eligibleForSelectInsights = false;

    this.selectInsightsService
      .getJobStatus(this.studyId)
      .subscribe((response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          if (
            response.data.length > 0 &&
            this.checkCompletedStatus(response.data)
          ) {
            this.eligibleForSelectInsights = true;
            this.fetchUserRole();
            this.fetchDateRange();
            this.fetchInsightsList();
          } else {
            this.isRequiredJobTriggeredStatus = true;
          }
        }
      });
  }

  fetchUserRole() {
    this.selectInsightsService.getUserRole(this.studyId).subscribe(
      (response) => {
        if (response.status === API_SUCCESS_KEY) {
          if (response.data.role === 'viewer') {
            this.isViewer = true;
          } else {
            this.isViewer = false;
          }
        }
      },
      (error) => {
        this.isViewer = false;
      }
    );
  }

  fetchObservationDates() {
    this.actionTriggerService
      .getStudyDetails(this.studyId)
      .subscribe((data: any) => {
        if (data.data.cbCohortId && data.data.cbExportId) {
          this.createStudiesService
            .getCohortVisualizationData(
              data.data.cbCohortId,
              data.data.cbExportId
            )
            .subscribe((item: any) => {
              let datePath =
                JSON.parse(item)[0].chrt_criteria?.chrt_criteria?.obs_period;
              if (
                JSON.parse(item)[0].chrt_criteria?.chrt_criteria?.obs_period
                  ?.observationStartDate &&
                JSON.parse(item)[0].chrt_criteria.chrt_criteria.obs_period
                  .observationEndDate
              ) {
                console.log(
                  new Date(datePath.observationStartDate),
                  new Date(datePath.observationEndDate)
                );
                this.fromDate = new Date(datePath.observationStartDate);
                this.toDate = new Date(datePath.observationEndDate);
                this.setDateRange(true);
              } else {
                this.toDate = new Date();
                this.fromDate = new Date('2000-01-01');
                this.setDateRange(true);
              }
            });
        } else {
          if(!this.toDate || !this.fromDate){
            this.toDate = new Date();
            this.fromDate = new Date('2000-01-01');
            this.setDateRange(true);
          }
        }
      });
  }

  fetchDateRange() {
    this.selectInsightsService
      .getStudyConfig(this.studyId)
      .subscribe((response) => {
        if (response.status === API_SUCCESS_KEY) {
          if (response.kpiTimeframeStart && response.kpiTimeframeEnd) {
            this.toDate = new Date(response.kpiTimeframeEnd * 1000);
            this.fromDate = new Date(response.kpiTimeframeStart * 1000);
            this.setDateRange(true);
          } else {
            if(response.obsStartDate && response.obsEndDate){
              this.fromDate = new Date(response.obsStartDate * 1000);
              this.toDate = new Date(response.obsEndDate * 1000);
              this.setDateRange(true);
            }
            this.fetchObservationDates();
          }
        }
      });
  }

  getMetricsData() {
    const availableMetrics: any = [];
    this.allMetrics.forEach((metricCategory: any) =>
      availableMetrics.push({ ...metricCategory })
    );

    this.availableMetrics = [...availableMetrics];

    this.originalAvailableMetrics = this.availableMetrics;

    this.availableMetrics.forEach((item: any) => {
      item['isSelected'] = false;
    });
  }

  mapAvailableAndSelectedMetrics() {
    this.allMetrics.forEach((category: any) => {
      category.data.forEach((metric: any) => {
        if (this.selectedMetricIds.includes(metric.kpiId)) {
          const selectedCategoryIdx = this.selectedMetrics.findIndex(
            (selectedMetric: any) =>
              selectedMetric.kpiCategoryId === metric.kpiCategoryId
          );
          if (selectedCategoryIdx > -1) {
            this.selectedMetrics[selectedCategoryIdx].data.push(metric);
          } else {
            const categoryData = {
              category: category.category,
              kpiCategoryId: category.kpiCategoryId,
              data: [{ ...metric }],
            };
            this.selectedMetrics.push(categoryData);
          }
        }
      });
    });

    this.availableMetrics.forEach((metric: any) => {
      metric.data = metric.data.filter(
        (ele: any) => !this.selectedMetricIds.includes(ele.kpiId)
      );
    });

    this.availableMetrics = [
      ...this.availableMetrics.filter((insight: any) => insight.data.length),
    ].sort((a, b) => a.kpiCategoryId - b.kpiCategoryId);

    this.originalSelectedInsights = this.selectedMetrics;
  }
  fetchInsightsList() {
    this.availableInsightsLoading = true;

    this.selectInsightsService.getInsightsData(this.studyId).subscribe(
      (response) => {
        if (response.status === API_SUCCESS_KEY) {
          this.allMetrics = response.all;
          this.selectedMetricIds = response.selected;
          this.getMetricsData();
          if (this.selectedMetricIds && this.selectedMetricIds.length) {
            this.mapAvailableAndSelectedMetrics();
          }
          this.availableInsightsLoading = false;
        } else {
          this.availableInsightsLoading = false;
          this.availableMetricsInsightsError = true;
        }
      },
      (error) => {
        this.availableInsightsLoading = false;
        this.availableMetricsInsightsError = true;
      }
    );
  }

  setDateRange(setOriginalDate?: boolean) {
    if (setOriginalDate) {
      this.originalFromDate = this.fromDate;
      this.originalToDate = this.toDate;
    }
    this.disabledFromDate = (current: Date): boolean =>
      differenceInCalendarDays(current, new Date(this.toDate)) > 0 ||
      differenceInCalendarDays(current, new Date(this.originalFromDate)) < 0;

    this.disabledToDate = (current: Date): boolean =>
      differenceInCalendarDays(current, new Date(this.fromDate)) <= -1 ||
      differenceInCalendarDays(current, new Date(this.originalToDate)) > 0;
    this.isDateLoaded = true;
  }

  onFromDateChange(_arg: any) {
    this.setDateRange();
  }

  onToDateChange(_arg: any) {
    this.setDateRange();
  }

  navigateWrtUrl(routerLink: string) {
    this.routeBackPath = routerLink;
    if (this.showWarningPopup()) {
      this.showConfirmationFlag = true;
    } else {
      this.router.navigate([routerLink]);
    }
  }

  onMetricTitleClick(kpiCategoryId: any, category: string) {
    const index = this.availableMetrics.findIndex(
      (item: any) => item.kpiCategoryId === kpiCategoryId
    );

    if (this.availableMetrics[index].isSelected) {
      this.availableMetrics[index].isSelected = false;
    } else {
      this.availableMetrics[index].isSelected = true;
    }

    const selectedInsight = this.availableMetrics.filter(
      (category: any) => category.kpiCategoryId === kpiCategoryId
    );
    if (selectedInsight.length) {
      selectedInsight[0].data.forEach((metric: any) => {
        this.onSelectAvailableMetric(
          kpiCategoryId,
          category,
          metric.kpiId,
          metric.name
        );
      });
    }
  }

  onSelectAvailableMetric(
    categoryId: any,
    categoryName: any,
    metricId: any,
    metricName: any
  ) {
    const idx = this.activeMetrics.indexOf(metricId);
    if (idx > -1) {
      this.activeMetrics.splice(idx, 1);
      const remainingMetricsToBeAdded = this.metricsToBeAdded.filter(
        (metric: any) => metric.metricId !== metricId
      );
      this.metricsToBeAdded = [...remainingMetricsToBeAdded];
    } else {
      this.metricsToBeAdded.push({
        categoryId: categoryId,
        category: categoryName,
        metricId: metricId,
        metricName: metricName,
      });
      this.activeMetrics.push(metricId);
    }
  }

  onSelectSelectedInsight(categoryId: number, categoryName: string) {
    const index = this.selectedMetrics.findIndex(
      (category: any) => category.kpiCategoryId === categoryId
    );

    if (this.selectedMetrics[index]['isSelected']) {
      this.selectedMetrics[index]['isSelected'] = false;
    } else {
      this.selectedMetrics[index]['isSelected'] = true;
    }

    const selectedInsight = this.selectedMetrics.filter(
      (category: any) => category.kpiCategoryId === categoryId
    );
    if (selectedInsight.length) {
      selectedInsight[0].data.forEach((metric: any) => {
        this.onSelectSelectedMetric(
          categoryId,
          categoryName,
          metric.kpiId,
          metric.name
        );
      });
    }
  }

  onSelectSelectedMetric(
    categoryId: any,
    categoryName: any,
    metricId: any,
    metricName: any
  ) {
    const idx = this.activeSelectedMetrics.indexOf(metricId);
    if (idx > -1) {
      this.activeSelectedMetrics.splice(idx, 1);
      const remainingSelectedMetrics = this.selectedMetricsToBeRemoved.filter(
        (metric: any) => metric.metricId !== metricId
      );
      this.selectedMetricsToBeRemoved = [...remainingSelectedMetrics];
    } else {
      this.selectedMetricsToBeRemoved.push({
        categoryId: categoryId,
        category: categoryName,
        metricId: metricId,
        metricName: metricName,
      });
      this.activeSelectedMetrics.push(metricId);
    }
  }

  getMetricsForInsight(categoryName: string) {
    let metricNames = [];
    const currentInsight = this.selectedMetrics.filter(
      (insight: any) => insight.category === categoryName
    );
    if (currentInsight.length) {
      metricNames = currentInsight[0].data.map((metric: any) => metric.name);
    }
    return metricNames.join(', ');
  }
  selectMetrics() {
    const selectedMetrics = [...this.selectedMetrics];
    const metricIdsToBeAdded = this.metricsToBeAdded.map(
      (metric: any) => metric.metricId
    );
    this.metricsToBeAdded.forEach((metric: any) => {
      const foundElementIdx = selectedMetrics.findIndex(
        (ele) => ele.kpiCategoryId === metric.categoryId
      );
      if (foundElementIdx > -1) {
        selectedMetrics[foundElementIdx].data.push({
          kpiId: metric.metricId,
          name: metric.metricName,
        });
      } else {
        selectedMetrics.push({
          category: metric.category,
          kpiCategoryId: metric.categoryId,
          data: [{ kpiId: metric.metricId, name: metric.metricName }],
        });
      }
    });

    this.availableMetrics.forEach((metric: any) => {
      metric.data = metric.data.filter(
        (ele: any) => !metricIdsToBeAdded.includes(ele.kpiId)
      );
    });

    selectedMetrics.forEach((selectedMetric) =>
      selectedMetric.data.sort((a: any, b: any) => a.kpiId - b.kpiId)
    );
    this.selectedMetrics = [...selectedMetrics];
    this.resetMetricsSelection();
  }

  removeSelectedMetrics() {
    const availableMetrics = [...this.availableMetrics];
    const metricIdsToBeRemoved = this.selectedMetricsToBeRemoved.map(
      (metric: any) => metric.metricId
    );
    this.selectedMetricsToBeRemoved.forEach((selectedMetric: any) => {
      const foundElementIdx = availableMetrics.findIndex(
        (ele) => ele.kpiCategoryId === selectedMetric.categoryId
      );
      if (foundElementIdx > -1) {
        availableMetrics[foundElementIdx].data.push({
          kpiId: selectedMetric.metricId,
          name: selectedMetric.metricName,
        });
      } else {
        availableMetrics.push({
          category: selectedMetric.category,
          kpiCategoryId: selectedMetric.categoryId,
          data: [
            {
              kpiId: selectedMetric.metricId,
              name: selectedMetric.metricName,
            },
          ],
        });
      }
    });

    this.selectedMetrics.forEach((metric: any) => {
      metric.data = metric.data.filter(
        (ele: any) => !metricIdsToBeRemoved.includes(ele.kpiId)
      );
    });

    availableMetrics.forEach((availableMetric) =>
      availableMetric.data.sort((a: any, b: any) => a.kpiId - b.kpiId)
    );
    this.availableMetrics = [...availableMetrics];
    this.resetMetricsSelection();
  }

  selectAllMetrics() {
    const selectedCategories = this.selectedMetrics.map(
      (metric: any) => metric.category
    );
    const availableCategories = this.availableMetrics.map(
      (metric: any) => metric.category
    );
    availableCategories.forEach((category: any) => {
      if (selectedCategories.includes(category)) {
        const selectedCategoryIdx = this.selectedMetrics.findIndex(
          (selectedMetric: any) => selectedMetric.category === category
        );
        const availableMetricIdx = this.availableMetrics.findIndex(
          (availableMetric: any) => availableMetric.category === category
        );

        this.selectedMetrics[selectedCategoryIdx].data = [
          ...this.selectedMetrics[selectedCategoryIdx].data,
          ...this.availableMetrics[availableMetricIdx].data,
        ];

        this.availableMetrics.splice(availableMetricIdx, 1);
      }
    });

    this.selectedMetrics = [...this.selectedMetrics, ...this.availableMetrics];
    this.availableMetrics = [];
    this.resetMetricsSelection();
  }

  removeAllSelectedMetrics() {
    const selectedCategories = [
      ...new Set(this.selectedMetrics.map((metric: any) => metric.category)),
    ];
    const availableCategories = [
      ...new Set(this.availableMetrics.map((metric: any) => metric.category)),
    ];

    selectedCategories.forEach((category) => {
      if (availableCategories.includes(category)) {
        const availableCategoryIdx = this.availableMetrics.findIndex(
          (availableMetric: any) => availableMetric.category === category
        );
        const selectedMetricIdx = this.selectedMetrics.findIndex(
          (selectedMetric: any) => selectedMetric.category === category
        );

        this.availableMetrics[availableCategoryIdx].data = [
          ...this.selectedMetrics[selectedMetricIdx].data,
          ...this.availableMetrics[availableCategoryIdx].data,
        ];

        this.selectedMetrics.splice(selectedMetricIdx, 1);
      }
    });

    this.availableMetrics = [...this.selectedMetrics, ...this.availableMetrics];
    this.selectedMetrics = [];
    this.resetMetricsSelection();
  }

  resetMetricsSelection() {
    this.activeMetrics = [];
    this.metricsToBeAdded = [];
    this.activeSelectedMetrics = [];
    this.selectedMetricsToBeRemoved = [];
    this.availableMetrics.forEach((item: any) => {
      item.isSelected = false;
    });
    this.sortInsights();
  }

  private sortInsights() {
    this.availableMetrics = [
      ...this.availableMetrics.filter((insight: any) => insight.data.length),
    ].sort((a, b) => a.kpiCategoryId - b.kpiCategoryId);

    this.selectedMetrics = [
      ...this.selectedMetrics.filter((insight: any) => insight.data.length),
    ].sort((a, b) => a.kpiCategoryId - b.kpiCategoryId);
  }

  closeConfirmationPopup(event: any) {
    if (event == true) {
      this.router.navigate([this.routeBackPath]);
    } else {
      this.showConfirmationFlag = false;
    }
  }

  prepareSelectedKpiIds() {
    let selectedData: any = [];
    this.selectedMetrics.forEach((metric: any) => {
      selectedData = selectedData.concat(
        metric.data.map((ele: any) => ele.kpiId)
      );
    });
    return selectedData;
  }

  saveStudy(submit: boolean) {
    this.saveInProgress = true;

    const timeFramePayload = {
      kpi_timeframe_start: Date.parse(this.fromDate) / 1000,
      kpi_timeframe_end: Date.parse(this.toDate) / 1000,
    };

    const selectedInsightsPayload = {
      kpiId: this.prepareSelectedKpiIds(),
    };

    this.selectInsightsService
      .setTimeFrameDates(this.studyId, timeFramePayload)
      .subscribe(
        (response) => {
          if (response.status === API_SUCCESS_KEY) {
            this.selectInsightsService
              .saveInsightsData(this.studyId, selectedInsightsPayload)
              .subscribe(
                (res) => {
                  if (res.status === API_SUCCESS_KEY) {
                    if (!submit) {
                      this.router.navigate(['/home/']);
                      this.saveInProgress = false;
                    } else {
                      this.triggerJob();
                    }
                  } else {
                    this.showSaveError = true;
                    this.saveInProgress = false;
                  }
                },
                (error) => {
                  this.showSaveError = true;
                  this.saveInProgress = false;
                }
              );
          } else {
            this.showSaveError = true;
            this.saveInProgress = false;
          }
        },
        (error) => {
          this.showSaveError = true;
          this.saveInProgress = false;
        }
      );
  }

  triggerJob() {
    this.selectInsightsService.triggerVisualizationJob(this.studyId).subscribe(
      (response) => {
        if (response.status === API_SUCCESS_KEY) {
          this.submitSuccessful = true;
          this.saveInProgress = false;
        } else {
          this.showSaveError = true;
          this.saveInProgress = false;
        }
      },
      (error) => {
        this.showSaveError = true;
        this.saveInProgress = false;
      }
    );
  }

  closeModal() {
    this.submitSuccessful = false;
  }

  checkCompletedStatus(data: any) {
    data.forEach((item: any) => {
      if (item.status !== 'COMPLETED') {
        return false;
      }
    });
    return true;
  }

  isInsightSelected(categoryId: any) {
    let isInsightSelected = false;
    const selectedInsight = this.selectedMetrics.filter(
      (category: any) => category.kpiCategoryId === categoryId
    );
    if (selectedInsight.length) {
      const selectedMetrics = selectedInsight[0].data.map(
        (metric: any) => metric.kpiId
      );
      isInsightSelected = selectedMetrics.every((metric: any) =>
        this.activeSelectedMetrics.includes(metric)
      );
    }
    return isInsightSelected;
  }

  breadcrumbClick(breadcrumb: any) {
    this.navigateWrtUrl(breadcrumb.navigationPath);
  }

  showWarningPopup(): boolean {
    const metricLength = differenceBy(
      this.availableMetrics,
      this.originalAvailableMetrics,
      'kpiCategoryId'
    );

    const insightLength = differenceBy(
      this.selectedMetrics,
      this.originalSelectedInsights,
      'kpiCategoryId'
    );

    if (
      this.fromDate !== this.originalFromDate ||
      this.toDate !== this.originalToDate ||
      metricLength.length > 0 ||
      insightLength.length > 0
    ) {
      return true;
    }

    return false;
  }
}
