import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Rule,
  ruleCategories,
  RuleCategory,
  RuleTextPlus,
  StageIdentificationRulesApiResponse,
  StageIdentificationSaveRulesApiPayload,
} from 'src/app/main/create-study-detail/regimen-lot/stage-identification/stage-identification.model';
import { OrdinalPipe } from 'src/app/shared/pipes';
import {
  API_SUCCESS_KEY,
  SOMETHING_WENT_WRONG,
  STUDY_ACCESS_ISSUE,
} from '../constants/app-constants';
import { TransactionManagerService } from './transaction-manager.service';

@Injectable({ providedIn: 'root' })
export class StageIdentificationService {
  rulesList: RuleCategory[] = [];
  priorityRulesList: RuleCategory[] = [];
  private titleCasePipe: TitleCasePipe = new TitleCasePipe;
  constructor(
    private transactionManager: TransactionManagerService,
    private router: Router
  ) {}

  readonly stageOptions = ['earliest', 'latest', 'prioritized'];

  prepareTextForRules(rules: Rule[]): Rule[] {
    const ordinal = new OrdinalPipe().transform;

    return rules.map((rule) => {
      rule.textPlus = rule.rule?.reduce((acc, rC) => {
        if (!rC.exists) {
          return acc;
        }

        if (
          (rC.key === 'occurrence1' || rC.key === 'occurrence2') &&
          rC.value === 'nth'
        ) {
          acc.push({ text: ordinal(Number(rC.data)), metadata: '' });
          return acc;
        }

        if (rC.key === 'event1' || rC.key === 'event2') {
          acc.push({
            text: rC.value,
            metadata: rC.metadata?.join(', ') || '',
          });
          if (rC.value === 'treatment' && rC.operator) {
            const regimenDaysText = this.prepareRegimenLengthText(
              rC.operator!,
              rC.regimen_days!
            );
            acc.push({ text: regimenDaysText, metadata: '' });
          }
          return acc;
        }

        if (rC.key === 'gap_days') {
          acc.push({ text: `${Number(rC.value)}`, metadata: '' });
          return acc;
        }

        acc.push({ text: rC.value, metadata: '' });
        return acc;
      }, [] as RuleTextPlus[]);
      return JSON.parse(JSON.stringify(rule));
    });
  }

  private prepareRegimenLengthText(operator: string, days: number): string {
    return (
      {
        '>=': `of length ${days} days or more`,
        '>': `of length more than ${days} days`,
        '<=': `of length ${days} days or less`,
        '<': `of length less than ${days} days`,
        '=': `of length ${days} days`,
      }[operator] || ''
    );
  }

  getStageIdentificationTemplateRules(): Observable<any> {
    // TODO: Add type.
    return this.transactionManager
      .get('stage-identification/get-rule-templates')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response.data;
            } else {
              throw Error(SOMETHING_WENT_WRONG);
            }
          },
          (error: any) => {
            if (error.error === STUDY_ACCESS_ISSUE) {
              this.router.navigate(['studies/error/access_issue']);
            }
            throw Error(SOMETHING_WENT_WRONG);
          }
        )
      );
  }

  populateRuleList(): RuleCategory[] {
    this.rulesList = Object.entries(ruleCategories()).map(([key, name]) => ({
      name,
      key,
      rules: [],
      stage: this.stageOptions[0],
    }));
    return this.rulesList
  }

  combineRulesList(existingRulesList: any): RuleCategory[] {
    this.priorityRulesList = this.rulesList.map((ruleCategory, i) => {
      const textPreparedRulesList =
        this.prepareTextForRules(ruleCategory.rules || []);

      return {
        name: ruleCategory.name,
        key: ruleCategory.key,
        rules: ([] as Rule[]).concat(
          existingRulesList[i].rules.map((r: any) => ({
            ...r,
            type: 'existing',
          })),
          textPreparedRulesList
        ),
        stage: ruleCategory.rules?.length
          ? ruleCategory.stage
          : existingRulesList[i].stage,
      };
    });
    return this.priorityRulesList
  }

  triggerSaveApi(studyId: any): void {
    const payload = this.prepareSaveRulesPayload(studyId);
    this.saveStageIdentificationRules(payload).subscribe();
  }

  prepareSaveRulesPayload(studyId: any, priorityRulesList: RuleCategory[] = this.priorityRulesList): StageIdentificationSaveRulesApiPayload {
    return {
      studyId: +studyId,
      rules: priorityRulesList.reduce(
        (formattedPayload, ruleCategoryValue) => {
          const { key, rules, stage } = ruleCategoryValue;
          formattedPayload[key] = rules.map((r, i) => ({
            template_type: r.template_type,
            id: r.id,
            rule_stage: this.titleCasePipe.transform(stage),
            stage: key,
            text: r.textPlus.map((tP) => tP.text).join(' '),
            priority: i + 1,
            rule: r.rule,
          }));
          return formattedPayload;
        },
        {} as any
      ),
    };
  }

  saveStageIdentficationRules(studyId: any, existingRulesList: RuleCategory[]){
    this.populateRuleList();
    this.combineRulesList(existingRulesList);
    this.triggerSaveApi(studyId);
  }

  getStageIdentificationRules(
    studyId: string
  ): Observable<StageIdentificationRulesApiResponse['data']> {
    return this.transactionManager
      .get(`stage-identification/${studyId}/stage-rules-details`)
      .pipe(
        map(
          (response: StageIdentificationRulesApiResponse) => {
            if (response.status === API_SUCCESS_KEY) {
              return response.data;
            } else {
              throw Error(SOMETHING_WENT_WRONG);
            }
          },
          (error: any) => {
            if (error.error === STUDY_ACCESS_ISSUE) {
              this.router.navigate(['studies/error/access_issue']);
            }
            throw Error(SOMETHING_WENT_WRONG);
          }
        )
      );
  }

  saveStageIdentificationRules(
    payload: StageIdentificationSaveRulesApiPayload
  ): Observable<boolean> {
    return this.transactionManager
      .post('/stage-identification/save-rules', payload)
      .pipe(
        map(
          (response: { status: string; isSaved: boolean }) => {
            if (response.status === API_SUCCESS_KEY) {
              return response.isSaved;
            } else {
              return false;
            }
          },
          (error: any) => {
            if (error.error === STUDY_ACCESS_ISSUE) {
              this.router.navigate(['studies/error/access_issue']);
            }
            return false;
          }
        )
      );
  }
}
