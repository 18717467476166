import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsLinechartComponent } from './highcharts-linechart.component';

@NgModule({
  declarations: [HighchartsLinechartComponent],
  imports: [CommonModule],
  exports: [HighchartsLinechartComponent],
})
export class HighchartsLinechartModule {}
