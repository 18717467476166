<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  nzClassName="export-modal"
  nzCentered
>
  <div *nzModalTitle class="custom-header">
    Data has been exported to the below location
  </div>
  <ng-container *nzModalContent>
    <div class="no-data-style" *ngIf="exportData.length === 0">
      No export files are present for the study.
    </div>
    <div *ngFor="let exportData of exportData; let i = index">
      <div class="content-header-style">{{ exportData?.filename }}</div>
      <div class="d-flex">
        <div
          [title]="exportData?.filepath[0]"
          class="ellipsis-class"
          [ngClass]="{
            'blink-active': exportData?.isActive === true
          }"
        >
          {{ exportData?.filepath[0] }}
        </div>
        <div>
          <button nz-button nzType="default" (click)="copyDynamicText(i)">
            <i nz-icon nzType="copy" nzTheme="outline" class="copy-icon"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>
