import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { HighchartsBarModel } from '../highcharts-barchart/highcharts-barchart.model';
import { CreateStudiesService } from '../../services/create-studies.service';
import { HighchartsLineChartModel } from '../highcharts-linechart/highcharts-linechart.model';
import { SOMETHING_WENT_WRONG } from '../../constants/app-constants';
import * as Highcharts from 'highcharts';
import Funnel from 'highcharts/modules/funnel';
Funnel(Highcharts);

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
  },
});

@Component({
  selector: 'app-cohort-visualization-popup',
  templateUrl: './cohort-visualization-popup.component.html',
  styleUrls: ['./cohort-visualization-popup.component.scss'],
})
export class CohortVisualizationPopupComponent
  implements OnInit, AfterViewInit
{
  @Output() closeViewPopup: EventEmitter<any> = new EventEmitter();
  @Input() cohortId: any;
  @Input() exportVersion: any;
  @Input() dataSourceId: any;

  public funnelFormattedDataValues: any = [];

  private funnelForeignObjectData: any = [];

  public funnelDataValues: any = [];

  top10Drugs: HighchartsBarModel = new HighchartsBarModel();
  drugsDataxAxis: any[] = [];
  drugsDatayAxis: any[] = [];
  isVisible = false;
  cohortVisualizationResponse: any;
  vennData: any = [];
  trimmedArray: any;
  setObject: any[] = [];
  shouldRenderVennChart: boolean = false;
  vennChartData: any[] = [];
  multiLineData: any[] = [];
  xAxisValueForLineChart: any[] = [];
  yAxisValueForLineChart: any[] = [];
  lineChartConfig: HighchartsLineChartModel = new HighchartsLineChartModel();
  isResponseLoaded = false;
  xAxisValueForLineChartFinal: any[] = [];
  setLineChartValue = false;
  spinnerOnCohortPopup = false;

  public patientDemographics1: any = {};
  public showPatientDemographics = false;
  patientDemographics2: any = {
    maleData: {},
    femaleData: {},
  };
  public ageGroup: any = {};
  public multipleBlockColor = [
    '#e0cfdf',
    '#e0b1de',
    '#cf7ccb',
    '#b850b4',
    '#964193',
  ];
  public maleFemaleBlock = ['#366367', '#ec7019'];
  public commonMessage = {
    noDataAvailable: 'No Data Available',
    noRecordsFound: 'No records found',
    somethingWentWrong: 'Something went wrong.',
    selectDrug: 'Please select a drug',
    selectRegimenCombinations:
      'Please select a combination of previous and current regimens',
  };

  showErrorAlert = false;
  alertErrorMessage: any;
  alertType: any;
  observationPeriodStartDate: any;
  observationPeriodEndDate: any;
  containsDate: any;
  observationDate: any;
  formatyAxisValues: any;
  emptyChartData = false;

  constructor(private createStudy: CreateStudiesService) {}

  ngOnInit(): void {
    this.showModal();
    this.getCohortVisualizationData();
  }

  funnelDataView = [
    {
      style: 'min-height: 50px; max-height: 50px',
    },
    {
      style: 'min-height: 50px; max-height: 50px; margin-top: 10px',
    },
    {
      style: 'min-height: 50px; max-height: 50px; margin-top: 10px',
    },
    {
      style: 'min-height: 50px; max-height: 50px; margin-top: 10px',
    },
    {
      style: 'min-height: 50px; max-height: 50px; margin-top: 10px',
    },
  ];

  funnelChartView = [
    {
      fill: 'rgb(0, 98, 155)',
      d: 'M0,0 L360,0 L336,50 L24,50 L0,0',
      style: 'transform: translate(0px, 0px)',
      y: 25,
      transform: 'translate(0px, 0px)',
    },
    {
      fill: 'rgb(50, 112, 159)',
      d: 'M24,50 L336,50 L312,100 L48,100 L24,50',
      style: 'transform: translate(0px, 10px)',
      y: 75,
      transform: 'translate(0px, 10px)',
    },
    {
      fill: 'rgb(72, 125, 164)',
      d: 'M48,100 L312,100 L288,150 L72,150 L48,100',
      style: 'transform: translate(0px, 20px)',
      y: 125,
      transform: 'translate(0px, 20px)',
    },
    {
      fill: 'rgb(89, 136, 168)',
      d: 'M72,150 L288,150 L264,200 L96,200 L72,150',
      style: 'transform: translate(0px, 30px)',
      y: 175,
      transform: 'translate(0px, 30px);',
    },
    {
      fill: 'rgb(103, 147, 172)',
      d: 'M96,200 L264,200 L240,250 L120,250 L96,200',
      style: 'transform: translate(0px, 40px)',
      y: 225,
      transform: 'translate(0px, 40px);',
    },
  ];

  ngAfterViewInit() {
    setTimeout(() => {
      Highcharts.chart('funnelchart', {
        chart: {
          type: 'funnel',
        },
        title: {
          text: '',
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b> ({point.y:,.0f})',
              color: 'black',
            },
          },
          funnel: {
            center: ['40%', '50%'],
            neckWidth: '30%',
            neckHeight: '25%',
            width: '60%',
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            type: 'funnel',
            name: 'Count',
            data: this.funnelFormattedDataValues,
          },
        ],
      });
    }, 1000);
  }

  getCohortVisualizationData() {
    this.createStudy
      .getCohortVisualizationData(this.cohortId, this.exportVersion)
      .subscribe(
        (data) => {
          this.cohortVisualizationResponse = JSON.parse(data)[0];
          this.containsDate = false;
          if (
            this.cohortVisualizationResponse.chrt_criteria == null &&
            this.cohortVisualizationResponse.chrt_criteria_fnl_cnt == null &&
            this.cohortVisualizationResponse.exp_chrt_metadata == null
          ) {
            this.emptyChartData = true;
            this.isResponseLoaded = true;
          } else if (
            this.cohortVisualizationResponse &&
            this.cohortVisualizationResponse.chrt_criteria &&
            this.cohortVisualizationResponse.chrt_criteria.chrt_criteria &&
            this.cohortVisualizationResponse.chrt_criteria.chrt_criteria
              .obs_period &&
            this.cohortVisualizationResponse.chrt_criteria.chrt_criteria
              .obs_period.observationEndDate !== undefined
          ) {
            this.emptyChartData = false;
            this.containsDate = true;
            this.observationPeriodStartDate =
              this.cohortVisualizationResponse.chrt_criteria.chrt_criteria.obs_period.observationStartDate;
            this.observationPeriodEndDate =
              this.cohortVisualizationResponse.chrt_criteria.chrt_criteria.obs_period.observationEndDate;
            this.observationPeriodStartDate =
              this.observationPeriodStartDate.split('T')[0];
            this.observationPeriodEndDate =
              this.observationPeriodEndDate.split('T')[0];
            this.observationPeriodStartDate = this.observationPeriodStartDate
              .split('-')
              .reverse()
              .join('/');
            this.observationPeriodEndDate = this.observationPeriodEndDate
              .split('-')
              .reverse()
              .join('/');
          }

          this.getTopTenDrugs();
          this.getVennData();
          this.getClaimTrendsData();
          this.getPatientDemographics();
          this.getPatientFunnelData();
          this.isResponseLoaded = true;
        },
        (error) => {
          this.alertErrorMessage = SOMETHING_WENT_WRONG;
          this.alertType = 'error';
          this.showErrorAlert = true;
        }
      );
  }

  setLineChart(): void {
    this.setLineChartValue = false;
    this.lineChartConfig.graphId = 'claimsTrendChart';
    this.lineChartConfig.yAxisTitle = 'No. of claims Thousands';
    this.lineChartConfig.height = 300;
    this.lineChartConfig.xAxisTooltipLabel = 'Time-period ';
    this.lineChartConfig.yAxisTooltipLabel = 'No. of claims ';
    this.lineChartConfig.showTooltip = true;
    this.lineChartConfig.xAxis = this.xAxisValueForLineChartFinal;
    this.lineChartConfig.data = this.formatTrendLineData(
      this.yAxisValueForLineChart
    );

    this.setLineChartValue = true;
  }

  formatTrendLineData(trendData: any): any {
    let trendDataObj: any = [];
    const multipleLineChartColor = ['#85c8bb', '#00629b', '#6e2b61'];
    let i = 0;
    trendData.forEach((item: any) => {
      const lineConfig = {
        type: 'scatter',
        name: item.key,
        marker: {
          symbol: 'circle',
          radius: 8,
        },
        id: item.key,
        color: multipleLineChartColor[i],
      };

      const lineData = {
        data: item.yAxisValues,
        linkedTo: item.key,
        marker: {
          symbol: 'circle',
        },
        color: multipleLineChartColor[i],
      };
      i++;
      trendDataObj.push(lineConfig);
      trendDataObj.push(lineData);
    });
    return trendDataObj;
  }

  showModal(): void {
    this.isVisible = true;
  }

  setBarChart(): void {
    this.top10Drugs.graphId = 'top10Drugs';
    if (this.drugsDataxAxis.length == 0) {
      this.top10Drugs.xAxisTitle = ' ';
      this.top10Drugs.yAxisTitle = ' ';
    } else {
      this.top10Drugs.xAxisTitle = 'Drugs';
      this.top10Drugs.yAxisTitle = '#Claims';
    }
    this.top10Drugs.yAxisTooltipLabel = '#Claims ';
    this.top10Drugs.height = 450;
    this.top10Drugs.showTooltip = true;
    this.top10Drugs.showLegends = false;
    this.top10Drugs.xAxis = this.drugsDataxAxis;
    this.top10Drugs.barColor = '#416dab';
    this.top10Drugs.data = this.drugsDatayAxis;
    this.top10Drugs.xAxisTooltipLabel = ' Name ';
  }

  getTopTenDrugs() {
    this.drugsDataxAxis = [];
    this.drugsDatayAxis = [];
    if (
      Object.keys(this.cohortVisualizationResponse.exp_chrt_metadata).length !=
      0
    ) {
      this.cohortVisualizationResponse?.exp_chrt_metadata?.top_ten_drugs?.chart_data.forEach(
        (element: any) => {
          this.drugsDataxAxis.push(element[0]);
          this.drugsDatayAxis.push(element[1]);
        }
      );
    }
    this.setBarChart();
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closeViewPopup.emit();
  }

  getVennData() {
    if (
      Object.keys(this.cohortVisualizationResponse.exp_chrt_metadata).length !=
        0 &&
      this.cohortVisualizationResponse.exp_chrt_metadata
        .fltr_pat_orlp_pxrxdx_cnt
    ) {
      this.vennData =
        this.cohortVisualizationResponse.exp_chrt_metadata.fltr_pat_orlp_pxrxdx_cnt.chart_data;
    }

    const setsArray = [
      {
        sets: ['Px'],
        name: 'Px',
        value: 30.91,
        size: '',
        tooltiptext: 'Procedure',
        color: '#F8C291',
      },
      {
        sets: ['Rx'],
        name: 'Rx',
        value: 34.53,
        size: '',
        tooltiptext: 'Drugs',
        color: '#7E9EC7',
      },
      {
        sets: ['Dx'],
        name: 'Dx',
        value: 40.9,
        size: '',
        tooltiptext: 'Condition',
        color: '#E1B8BE',
      },
      {
        sets: ['Px', 'Rx'],
        name: 'PxRx',
        value: 5.05,
        size: '',
        tooltiptext: 'Procedure and Drugs',
        color: '#7E9EC7',
      },
      {
        sets: ['Dx', 'Px'],
        name: 'DxPx',
        value: 3.65,
        size: '',
        tooltiptext: 'Procedure and Condition',
        color: '#F8C291',
      },
      {
        sets: ['Dx', 'Rx'],
        name: 'DxRx',
        value: 4.08,
        size: '',
        tooltiptext: 'Drugs and Condition',
        color: '#7E9EC7',
      },
      {
        sets: ['Dx', 'Px', 'Rx'],
        name: 'DxPxRx',
        value: 2.8,
        size: '',
        tooltiptext: 'Drugs and Condition and Procedure',
        color: '#7E9EC7',
      },
    ];

    if (
      Object.keys(this.cohortVisualizationResponse.exp_chrt_metadata).length !=
      0
    ) {
      const keyList = Object.keys(this.vennData);

      keyList.map((ele) => {
        const trimmedEle = ele.substring(0, ele.length - 1);
        this.trimmedArray = trimmedEle.split('x');
        this.trimmedArray.splice(this.trimmedArray.length - 1, 1);
        for (let i = 0; i < this.trimmedArray.length; i++) {
          this.trimmedArray[i] = this.trimmedArray[i] + 'x';
        }
        this.trimmedArray.sort();
        this.setObject.push({
          setArray: this.trimmedArray,
          value: this.vennData[ele] as number,
        });
      });
    }
    let venArray: any[] = [];
    setsArray.forEach((set) => {
      this.setObject.forEach((object: any) => {
        const isEqual = this.isArrayEqual(object.setArray, set.sets);
        if (isEqual) {
          set.size = object.value.toString();
          set.name = this.numFormatter(object?.value) as any;
          this.shouldRenderVennChart = true;
          venArray = setsArray as any;
        }
      });
    });
    this.vennChartData = venArray;
  }

  numFormatter(num: number) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num.toString(); // if value < 1000, nothing to do
    }
  }

  isArrayEqual(a: any, b: any) {
    if (a.length != b.length) {
      return false;
    } else {
      for (let i = 0; i < a.length; i++) {
        if (a[i] != b[i]) {
          return false;
        }
        return true;
      }
    }
  }

  alertClosed($event: any) {
    this.showErrorAlert = false;
  }

  // Funnel Chart
  getPatientFunnelData() {
    let data = {
      dataSourceId: this.dataSourceId,
      versionId: this.exportVersion,
      cohortId: this.cohortId,
    };
    this.getDataSources(data);
    this.getConceptData(data);
    this.getInclusionExclusionText('Inclusion');
    this.getInclusionExclusionText('Exclusion');
    this.getInclusionExclusionValues('inclusion');
    this.getInclusionExclusionValues('exclusion');
    this.funnelDataValues = this.funnelDataValues.slice(0, 5);
    this.funnelDataValues.forEach((item: any, i: any) => {
      item.data = this.funnelForeignObjectData[i];
      this.funnelFormattedDataValues.push([item.data, item.value]);
    });
  }

  getDataSources(data: any) {
    this.cohortVisualizationResponse.chrt_criteria_fnl_cnt[0].funl_cnt.forEach(
      (dataSource: any) => {
        if (dataSource.data_src_id === data.dataSourceId) {
          this.funnelForeignObjectData.push(dataSource.data_src_details.name);
          this.funnelDataValues.push({
            value: dataSource.data_src_details.patient_count,
            label: '',
            percent: 100,
          });
        }
      }
    );
  }

  getConceptData(data: any) {
    this.cohortVisualizationResponse.chrt_criteria_fnl_cnt[0].funl_cnt.forEach(
      (dataSource: any) => {
        if (dataSource.data_src_id == data.dataSourceId) {
          this.funnelForeignObjectData.push(
            dataSource.concept_set_details.name
          );
          this.funnelDataValues.push({
            value: dataSource.concept_set_details.patient_count,
            label: '',
            percent: dataSource.concept_set_details.patient_percent,
          });
        }
      }
    );
  }

  getInclusionExclusionValues(type: any) {
    if (this.cohortVisualizationResponse.chrt_criteria_fnl_cnt.length != 0) {
      this.cohortVisualizationResponse.chrt_criteria_fnl_cnt[0].funl_cnt.forEach(
        (funnelBlock: any) => {
          if (funnelBlock.data_src_id == this.dataSourceId) {
            funnelBlock[type].forEach((typeItem: any) => {
              this.funnelDataValues.push({
                value: Number(typeItem.count.replace(/,/g, '')),
                label: '',
                percent: typeItem.pct,
              });
            });
          }
        }
      );
    }
  }

  getInclusionExclusionText(type: any) {
    this.cohortVisualizationResponse.chrt_criteria.chrt_criteria[
      type.toLowerCase()
    ].forEach((block: any) => {
      if (block.criteria_type === 'block') {
        this.funnelForeignObjectData.push(
          block.block_name + '  ( ' + type + ' ) '
        );
      } else {
        this.funnelForeignObjectData.push(block.textsb + ' ( ' + type + ' ) ');
      }
    });
  }

  // patient demographics

  getPatientDemographics() {
    let maleSum = 0;
    let femaleSum = 0;
    if (
      Object.keys(this.cohortVisualizationResponse.exp_chrt_metadata).length !=
        0 &&
      this.cohortVisualizationResponse.exp_chrt_metadata.fltr_pat_age_gndr_cnt
    ) {
      const patientDemographicsAge =
        this.cohortVisualizationResponse.exp_chrt_metadata.fltr_pat_age_gndr_cnt
          .age_range;

      let patientDemographicsDataMale =
        this.cohortVisualizationResponse.exp_chrt_metadata.fltr_pat_age_gndr_cnt
          .chart_data.male;

      patientDemographicsDataMale = this.fillEmptyValues(
        patientDemographicsDataMale
      );

      let patientDemographicsDataFemale =
        this.cohortVisualizationResponse.exp_chrt_metadata.fltr_pat_age_gndr_cnt
          .chart_data.female;

      patientDemographicsDataFemale = this.fillEmptyValues(
        patientDemographicsDataFemale
      );

      femaleSum = this.getTotalCount(patientDemographicsDataFemale);
      maleSum = this.getTotalCount(patientDemographicsDataMale);

      const genderTotal = maleSum + femaleSum;

      const totalMalePercentage = this.getPercentage(maleSum, genderTotal);
      const totalFemalePercentage = this.getPercentage(femaleSum, genderTotal);

      this.patientDemographics1 = {
        male: parseFloat(totalMalePercentage).toFixed(1),
        female: parseFloat(totalFemalePercentage).toFixed(1),
      };

      patientDemographicsAge.map((age: any, index: any) => {
        const maleCount = patientDemographicsDataMale[index] || 0;
        const femaleCount = patientDemographicsDataFemale[index] || 0;

        const currentGenderTotal = this.getPercentage(
          maleCount + femaleCount,
          genderTotal
        );
        this.ageGroup[index] = age;

        if (age.includes('above')) {
          let value = age.split(' ')[0] + '+';
          this.ageGroup[index] = value;
        }

        const malePercentage = this.getPercentage(maleCount, genderTotal);
        const femalePercentage = this.getPercentage(femaleCount, genderTotal);

        this.patientDemographics1[index] = currentGenderTotal.includes('NaN')
          ? 0
          : parseFloat(currentGenderTotal).toFixed(1) || 0;

        this.patientDemographics2.maleData[index] = malePercentage.includes(
          'NaN'
        )
          ? 0
          : parseFloat(malePercentage).toFixed(1) || 0;

        this.patientDemographics2.femaleData[index] = femalePercentage.includes(
          'NaN'
        )
          ? 0
          : parseFloat(femalePercentage).toFixed(1) || 0;
      });
    }

    if (
      Object.keys(this.patientDemographics1).length != 0 &&
      Object.keys(this.patientDemographics1).length != 0
    ) {
      this.showPatientDemographics = true;
    }
  }

  fillEmptyValues(data: any) {
    while (data.length != 5) data.push(0);
    return data;
  }

  getTotalCount(ageArray: any) {
    let sum = 0;
    ageArray.map((element: any) => {
      sum += +element;
    });
    return sum;
  }

  private getPercentage(percentageFor: any, total: any) {
    return ((percentageFor / total) * 100).toFixed(2);
  }

  getClaimTrendsData() {
    if (
      Object.keys(this.cohortVisualizationResponse.exp_chrt_metadata).length !=
      0
    ) {
      this.mapMultiLineData(
        this.cohortVisualizationResponse.exp_chrt_metadata.drug_claim_count
      );
      this.mapMultiLineData(
        this.cohortVisualizationResponse.exp_chrt_metadata.cond_claim_count
      );

      this.mapMultiLineData(
        this.cohortVisualizationResponse.exp_chrt_metadata.proc_claim_count
      );

      this.xAxisValueForLineChartFinal = [];
      this.xAxisValueForLineChartFinal.push(
        ...this.formattedXAxisValue([
          ...this.cohortVisualizationResponse.exp_chrt_metadata.drug_claim_count
            .data,
          ...this.cohortVisualizationResponse.exp_chrt_metadata.cond_claim_count
            .data,
          ...this.cohortVisualizationResponse.exp_chrt_metadata.proc_claim_count
            .data,
        ])
      );
    }
    this.setLineChart();
  }

  mapMultiLineData(lineData: any) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const changeDate = (fullDate: any) => {
      const theDate = new Date(fullDate * 1000);
      const dateString = theDate;
      return dateString;
    };
    const obj = {
      name: lineData.name,
      values: [] as any[],
    };
    lineData.data.map((line: any) => {
      const dateValue = changeDate(line[0]) as Date;
      obj.values.push({
        date: dateValue,
        value: line[1],
      });
    });
    this.multiLineData.push(obj);
    let xAxisValObj: any = {};
    let yAxisValObj: any = {};
    this.multiLineData.forEach((item: any) => {
      xAxisValObj = {
        name: item.name,
        categories: [] as any[],
      };
      yAxisValObj = {
        key: item.name,
        yAxisValues: [] as any[],
      };

      item.values.forEach((item: any) => {
        xAxisValObj.categories.push(item.date);
        yAxisValObj.yAxisValues.push(item.value);
      });
    });

    this.xAxisValueForLineChart.push(xAxisValObj);
    this.yAxisValueForLineChart.push(yAxisValObj);
    let yearsSet = new Set();
    let monthsSet = new Set();

    this.xAxisValueForLineChart.forEach((item: any) => {
      if (item.categories.length > 0) {
        item.categories.forEach((item: any) => {
          yearsSet.add(item.getFullYear());
        });
      }
    });

    let tempArr: any[] = [];
    let temp: any[] = [];
    let yearListArray = [...yearsSet];
    for (let i = 0; i < yearListArray.length; i++) {
      this.xAxisValueForLineChart.forEach((item: any) => {
        if (item.categories.length > 0) {
          item.categories.forEach((item: any) => {
            if (item.getFullYear() === yearListArray[i]) {
              monthsSet.add(months[item.getMonth()]);
            }
          });
        }
      });
      temp.push();
      tempArr[i] = {
        name: yearListArray[i],
        categories: [...monthsSet],
      };
    }
  }

  formattedXAxisValue(lineData: any) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let xAxisList: any = [];
    if (lineData.length > 0) {
      lineData
        .map((line: any) => {
          return new Date(line[0] * 1000) as Date;
        })
        .sort(function (a: any, b: any) {
          return a - b;
        })
        .forEach((data: any) => {
          xAxisList.push(
            months[data.getMonth()] +
              "'" +
              data.getFullYear().toString().slice(2, 4)
          );
        });
    }
    return [...new Set(xAxisList)];
  }
}
