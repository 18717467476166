import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VennDiagramComponent } from './venn-diagram.component';
import { NzGridModule } from 'ng-zorro-antd/grid';

@NgModule({
  declarations: [VennDiagramComponent],
  exports: [VennDiagramComponent],
  imports: [CommonModule, NzGridModule],
})
export class VennDiagramModule {}
