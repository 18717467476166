<div class="main-class">
  <div class="button-section">
    <div class="page-heading">Logs</div>
    <div>
      <rd-button
        [btnType]="'primary'"
        btnText="Refresh"
        [isIconNeeded]="true"
        [iconType]="'sync'"
        [btnClass]="'custom-primaryfill-greenbtn'"
        (btnClick)="onRefreshClick()"
      ></rd-button>
    </div>
  </div>
  <div class="search-section">
    <div class="search-box">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input
          type="text"
          nz-input
          placeholder="Search Logs"
          [(ngModel)]="searchText"
          (ngModelChange)="searchLogs()"
        />
      </nz-input-group>
    </div>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
  <div class="execution-logs-section scrollable-table page-scroll" >
    <rd-table
      [isLoading]="!isExecutionLogsLoaded"
      [tableColumns]="executionLogsColumns"
      [tableData]="executionLogsData"
      [tableScrollDetails]="executionLogsScrollDetails"
      [pageIndex]="pageIndex"
      [pageSize]="defaultPageSize"
      [totalCount]="totalNoOfExecutionLogs"
      [errorMessage]="tableErrorMessage"
      [loadingRows]="defaultPageSize"
      [errorType]="tableErrorType"
      [showPagination]="true"
      [frontEndPagination]="false"
      (paginationChange)="onExecutionLogsPaginationChange($event)"
      (sortApply)="onExecutionLogsTableSort($event)"
      (linkColumnCellClicked)="executionLogsClicked($event)"
    ></rd-table>
  </div>
</div>

<!-- Drawer -->

<nz-drawer
  [nzTitle]="titleTpl"
  [nzClosable]="false"
  [nzVisible]="visible"
  [nzWidth]="800"
  nzPlacement="right"
  (nzOnClose)="close()"
  nzWrapClassName="execution-logs-drawer-style"
>
  <ng-container *nzDrawerContent>
    <div class="box-container">
      <div nz-row>
        <div nz-col nzSpan="10">
          <p class="drawer-box-header">Job ID</p>
          <p class="drawer-box-content">{{ errorFlyoutInfo?.jobId }}</p>
        </div>
        <div nz-col nzSpan="8" nzOffset="4">
          <p class="drawer-box-header">Study Title</p>
          <a
            class="drawer-box-content"
            (click)="navigateToStudyDetail(errorFlyoutInfo?.jobId)"
            >{{ errorFlyoutInfo?.studyTitle }}</a
          >
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10">
          <p class="drawer-box-header">Cluster ID</p>
          <p class="drawer-box-content">{{ errorFlyoutInfo?.clusterId }}</p>
        </div>
        <div nz-col nzSpan="8" nzOffset="4">
          <p class="drawer-box-header">Analyst Name</p>
          <p class="drawer-box-content">
            {{
              errorFlyoutInfo?.analystEmail &&
                emailToNameFormatter(errorFlyoutInfo?.analystEmail)
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <rd-button
        [btnType]="'primary'"
        btnText="Download Logs"
        [isIconNeeded]="true"
        [iconType]="'download'"
        [btnClass]="'custom-primaryfill-greenbtn'"
        (btnClick)="onDownloadLogsClicked()"
        [isDisabled]="!errorLogResponse"
      ></rd-button>
    </div>
    <div
      *ngIf="errorLogLoaded"
      class="response-style"
      [innerHTML]="errorLogResponse"
    ></div>
    <div class="logs-loading" *ngIf="!errorLogLoaded">
      <rd-spinner></rd-spinner>
    </div>
  </ng-container>
</nz-drawer>

<ng-template #titleTpl>
  <div nz-row>
    <div nz-col nzSpan="4" class="drawer-header">Error Logs</div>
    <div nz-col nzSpan="20" class="drawer-cross-button">
      <a nz-button nzType="link" (click)="onCloseButtonClick()">X</a>
    </div>
  </div>
</ng-template>
