import { Injectable } from '@angular/core';
declare var fernet: any;

@Injectable({
  providedIn: 'root',
})
export class SensitiveInfoService {
  key = 'uAAnzVT7bJoz3Epl9GCC1YL4X9IcQJq5agNCYQve6PE=';
  constructor() {}

  getSecret(key: any) {
    return new fernet.Secret(key);
  }

  encrypt(data: any) {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    const token = new fernet.Token({
      secret: this.getSecret(this.key),
      time: new Date().getTime(),
      iv: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    });
    return token.encode(data);
  }

  decrypt(cipherText : any) {
    const secret = this.getSecret(this.key);
    const token = new fernet.Token({
      secret,
      token: cipherText,
      ttl: 0,
    });
    return JSON.parse(token.decode());
  }
}
