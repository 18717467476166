import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { HighchartsLineChartModel } from './highcharts-linechart.model';

@Component({
  selector: 'app-highcharts-linechart',
  templateUrl: './highcharts-linechart.component.html',
  styleUrls: ['./highcharts-linechart.component.scss'],
})
export class HighchartsLinechartComponent implements OnInit {
  chart: any;

  @Input() lineChartConfig: HighchartsLineChartModel =
    new HighchartsLineChartModel();
  @Input()
  myinputMsg!: string;
  constructor(private elementRef: ElementRef) {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    })
  }


  ngOnInit(): void {
    this.createElement();
    this.displayLineGraph();
  }

  /**
   * @description Method sets id attribute to the div in which chart is rendered .
   */
  createElement(): void {
    const elem = this.elementRef.nativeElement.querySelector('.graph');
    elem.setAttribute('id', this.lineChartConfig.graphId);
  }

  displayLineGraph(): void {
    const xAxisTooltipLabel = this.lineChartConfig.xAxisTooltipLabel;
    const yAxisTooltipLabel = this.lineChartConfig.yAxisTooltipLabel;
    var thisclass = this;
  
    this.chart = Highcharts.chart({
      chart: {
        renderTo: this.lineChartConfig.graphId,
        type: 'line',
        alignTicks: true,
        height: this.lineChartConfig.height,
      },
      
      title: {
        text: this.lineChartConfig.graphTitle,
        align: 'left',
        style: {
          fontFamily: 'Open Sans',
          fontSize: '18px',
          color: '#1A1628',
          fontWeight: 'bold',
        },
      },
      legend: {
        align: 'left',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        x: 30,
        y: 0,
        itemStyle: {
          fontFamily: 'Open Sans',
          fontSize: '12px',
          color: '#454250',
          fontWeight: 'normal',
          cursor: 'default',
        },
      },
      tooltip: {
        enabled: this.lineChartConfig.showTooltip,
        useHTML: true,
        style: {
          fontFamily: 'Open Sans',
          fontSize: '12px',
          color: '#454250',
          fontWeight: 'normal',
          cursor: 'default',
        },
        formatter() {
          return (
            yAxisTooltipLabel +
            ' : ' +
            '<b>' +
          Highcharts.numberFormat(this.y!, 0) +
            '</b>' +
            '<br>' +
            xAxisTooltipLabel +
            ' : ' +
            '<b>' +
            this.x +
            '</b>'
          );
        },
      },
      xAxis: {
        labels: {
          style: {
            color: '#454250',
            fontFamily: 'Open Sans',
          },
        },
        categories: this.lineChartConfig.xAxis,
        // tickPositions: [0, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132],
        tickPositions: this.lineChartConfig.tickPositions,
        gridLineWidth: 1,
        type: 'category',
        plotLines: this.lineChartConfig.plotLines,
        title: {
          text: this.lineChartConfig.xAxisTitle,
          style: {
            fontSize: '14px',
            color: '#454250',
            fontFamily: 'Open Sans',
          },
        },
      },
      yAxis: {
        allowDecimals: false,
        gridLineWidth: 0,
        labels: {

          style: {
            color: '#454250',
            fontFamily: 'Open Sans',
          },
          formatter: function () {
            return thisclass.lineChartConfig.percentageFormatter
              ? this.value + '%'
              : this.value + '';
          },
          
        },
        title: {
          text: this.lineChartConfig.yAxisTitle,
          style: {
            fontSize: '14px',
            color: '#454250',
            fontFamily: 'Open Sans',
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: this.lineChartConfig.data,
      plotOptions: {
        series: {
          stickyTracking: false,
          showInLegend: this.lineChartConfig.showLegends,
          events: {
            legendItemClick: () => {
              // Return false to make legend click disable
              return true;
            },
          },
        },
      },
    });

    if (this.lineChartConfig.percentageFormatter) {
      this.chart.yAxis.formatter = function () {
        return this.value + '%';
      };
    }
  }
}
