import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from "./alert.component";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { RDAlertModule } from "@zs-ca/angular-cd-library";

@NgModule({
  declarations: [AlertComponent],
  imports: [
    NzAlertModule,
    CommonModule,
    RDAlertModule
  ],
  exports: [AlertComponent]
})
export class AlertModule { }
