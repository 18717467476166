<nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel('Close popup')" nzClassName="clone-modal" nzCentered>
  <div *nzModalTitle class="custom-header">Clone Study</div>
  <ng-container *nzModalContent>
    <div *ngIf="!isDataLoaded" class="clone-spinner-style">
      <rd-spinner></rd-spinner>
    </div>
    <div *ngIf="isDataLoaded">
      <p class="study-title-style">
        Study title to be cloned: {{ studyDetailsData?.studyTitle }}
      </p>
      <form nz-form [formGroup]="cloneForm" class="login-form" [nzLayout]="'vertical'" [nzNoColon]="true">
        <div class="input-section mb-3">
          <div class="label-heading">
            Study Title
            <span class="mandatory"> *</span>
          </div>
          <div class="d-flex">
            <input nz-input [placeholder]=studyTitlePlaceholder [ngClass]="
                cloneForm.get('studyTitle')?.invalid &&
                cloneForm.get('studyTitle')?.touched
                  ? 'red-outline'
                  : ''
              " (keypress)="allowAlphaNumeric($event, '^[0-9a-zA-Z ]+$')" (change)="validateStudyTitle($event, true)"
              (paste)="allowAlphaNumeric($event, '^[0-9a-zA-Z ]+$')" maxlength="50" formControlName="studyTitle" />
            <div class="error d-flex align-items-center px-2 red-icon" *ngIf="
                !cloneForm.get('studyTitle')?.valid &&
                cloneForm.get('studyTitle')?.touched
              ">
              <i nz-icon nzType="exclamation-circle" nzTheme="fill" nz-popover [nzPopoverContent]="contentTemplate"></i>
              <ng-template #contentTemplate>
                <div *ngIf="cloneForm.get('studyTitle')?.errors?.required">
                  This field is required
                </div>
                <div *ngIf="
                    cloneForm.get('studyTitle')?.errors?.minlength ||
                    cloneForm.get('studyTitle')?.errors?.pattern
                  ">
                  Study Title should start with an alphabet in upper case, can
                  be alphanumeric and have minimum 8 characters
                </div>
                <div *ngIf="cloneForm.get('studyTitle')?.errors?.validStudyTitle">
                  Study Title already in use
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <!-- <div class="toggle-button">
          <rd-button (btnClick)=toggleDisplayDiv() btnText={{toggleName}}> </rd-button>
        </div> -->

        <div class="d-flex-center-space-between">
          <div class="w-calc-50-10px">
            <div (click)="toggleDisplayDiv();" [ngClass]="{
              'section-active': !isShowDiv
            }">
              <div class="subtitle d-flex-center-start">
                <i *ngIf="!isShowDiv" nz-icon nzType="check-circle" nzTheme="fill"></i>
                <div *ngIf="isShowDiv" class="custom-checkmark"></div>
                &nbsp;&nbsp;
                <span> Select Existing Cohort </span>
              </div>
            </div>
          </div>

          <div class="w-calc-50-10px">
            <div (click)="
            toggleDisplayDiv();
            " [ngClass]="{
              'section-active': !isShowDiv
            }">
              <div class="subtitle d-flex-center-start">
                <i *ngIf="isShowDiv" nz-icon nzType="check-circle" nzTheme="fill"></i>
                <div *ngIf="!isShowDiv" class="custom-checkmark"></div>
                &nbsp;&nbsp;
                <span> Import Custom Cohort </span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isShowDiv" class="d-flex-center-space-between">
          <div class="w-calc-50-10px">
            <div class="label-heading">Select Data Source</div>
            <nz-select formControlName="dataSource" [(ngModel)]="dataSourceDefaultValue" [nzSize]="'default'"
              (ngModelChange)="getCohortDetails($event)" class="w-100" nzShowSearch>
              <nz-option *ngFor="let data of dataSource" [nzLabel]="data.dataSourceName"
                [nzValue]="data.dataSourceId * 1"></nz-option>
            </nz-select>
          </div>
          <div class="w-calc-50-10px">
            <div class="label-heading">Select Cohort</div>
            <nz-select [nzLoading]="!cohortLoaded" [nzDisabled]="!cohortLoaded" formControlName="cohort"
              [(ngModel)]="cohortDefaultValue" [nzSize]="'default'" class="w-100" nzShowSearch>
              <nz-option *ngFor="let data of cohortDetails" [nzLabel]="data.cohortName"
                [nzValue]="data.cohortId"></nz-option>
            </nz-select>
          </div>
        </div>

        <div *ngIf="isShowDiv">
          <div class="d-flex-center-space-between">
            <div class="w-calc-50-10px">
              <div class="label-heading">
                Data Source
                <span class="mandatory"> *</span>
              </div>
              <div class="d-flex">
                <input nz-input placeholder="" maxlength="50" formControlName="dataSourceName" />
              </div>
            </div>

            <div class="w-calc-50-10px">
              <div class="label-heading">
                Cohort Name
                <span class="mandatory"> *</span>
              </div>
              <div class="d-flex">
                <input nz-input placeholder="" maxlength="50" formControlName="cohortName" />
              </div>
            </div>
          </div>

          <div class="label-heading">
            Cohort Description
            <span class="mandatory"> *</span>
          </div>
          <div class="d-flex">
            <input nz-input placeholder="" maxlength="50" formControlName="cohortDescription" />
          </div>
          <div class="label-heading">
            S3 Location
            <span class="mandatory"> *</span>
          </div>
          <div class="d-flex">
            <input nz-input placeholder="" formControlName="s3Location" />
          </div>
        </div>

        <div class="button-style">
          <rd-button [btnType]="'default'" btnText="Close" [isIconNeeded]="false"
            (btnClick)="handleCancel('Close popup')"></rd-button>
          <rd-button [btnType]="'primary'" btnText="Clone" [isIconNeeded]="false" (btnClick)="onCloneClicked()"
            [btnClass]="'custom-primaryfill-greenbtn'" [isDisabled]="
              !cloneForm.get('studyTitle')?.valid ||
              validateStudyTitleLoadStatus
            "></rd-button>
        </div>
      </form>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>