import { Injectable } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd/icon';
import { CUSTOM_ICONS } from '../constants/custom-icons';

@Injectable({
  providedIn: 'root',
})
export class CustomIconService {
  constructor(private nzIconService: NzIconService) {}

  /**
   * Method to add local svg icons to Nz-Icons Library, usage - '<i nz-icon nzType='custom:<name>''></i>
   */
  public addCustomIconsToNzLibrary() {
    CUSTOM_ICONS.map((customIcon) => {
      try {
        this.nzIconService.addIconLiteral(
          `custom:${customIcon.name}`,
          customIcon.icon
        );
      } catch (e) {}
    });
  }
}
