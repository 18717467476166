<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleClose()"
  nzClassName="confirm-modal"
  nzCentered
>
  <div *nzModalTitle class="custom-header">
    {{ confirmationConfig.header }}
  </div>
  <ng-container *nzModalContent>
    <p>{{ confirmationConfig.message }}</p>
  </ng-container>
  <div *nzModalFooter class="main">
    <button nz-button nzType="default" (click)="handleCancel()">
      {{ confirmationConfig.cancelButtonName }}
    </button>
    <button nz-button nzType="primary" (click)="handleOk()">
      {{ confirmationConfig.confirmationButtonName }}
    </button>
  </div>
</nz-modal>
