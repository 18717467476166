export class HighchartsBarModel {
  height: any;

  xAxis: any[] = [];

  data: any[] = [];

  barColor: string = '';

  graphId: string = '';

  graphTitle: string = '';

  xAxisTitle: string = '';

  yAxisTitle: string = '';

  showLegends: boolean = false;

  showTooltip: boolean = false;

  xAxisTooltipLabel: string = '';

  yAxisTooltipLabel: string = '';
}
