<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
  nzClassName="cohort-visualization-modal"
>
  <div *nzModalTitle class="custom-header">Cohort Summary</div>
  <div class="spinner-style" *ngIf="spinnerOnCohortPopup">
    <rd-spinner [isSpinning]="true"></rd-spinner>
  </div>
  <ng-container *nzModalContent>
    <div>
      <h2 class="observationDate">
        Observation Period:
        <span *ngIf="containsDate"
          >{{ observationPeriodStartDate }} to
          {{ observationPeriodEndDate }}</span
        >
        <span *ngIf="containsDate === false"> -</span>
      </h2>
    </div>
    <div class="loader-style" *ngIf="!isResponseLoaded || showErrorAlert">
      <div *ngIf="emptyChartData">{{ commonMessage.noDataAvailable }}</div>
      <rd-spinner></rd-spinner>
    </div>
    <div *ngIf="isResponseLoaded">
      <div class="chart-section">
        <div class="chart-header">Patient Funnel</div>
        <h2
          class="noDataAvailable"
          *ngIf="funnelFormattedDataValues.length === 0"
        >
          {{ commonMessage.noDataAvailable }}
        </h2>
        <div class="export-chart" style="opacity: 1">
          <div class="text-center" id="d3-funnel-container" style="opacity: 1">
            <svg width="600" height="300">
              <foreignObject id="foreignObject-block" height="300" width="420">
                <div
                  *ngFor="let funnel of funnelDataValues; let i = index"
                  class="funnel-content-wrapper undefined"
                  id="funnel-content-wrapper-0"
                  [style]="funnelDataView[i].style"
                >
                  <div class="content text-truncate" [title]="funnel.data">
                    {{ funnel.data }}
                  </div>
                </div>
              </foreignObject>
              <g
                *ngFor="let funnel of funnelDataValues; let i = index"
                style="transform: translate(240px, 0px)"
              >
                <g [style]="funnelChartView[i].style">
                  <path
                    [attr.fill]="funnelChartView[i].fill"
                    [attr.d]="funnelChartView[i].d"
                  ></path>

                  <text
                    x="180"
                    [attr.y]="funnelChartView[i].y"
                    fill="#fff"
                    font-size="14px"
                    text-anchor="middle"
                    dominant-baseline="middle"
                    pointer-events="none"
                  >
                    <tspan x="180" dy="0">
                      {{ funnel.value | number }} ({{ funnel.percent }}%)
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="p-2 w-50">
          <div class="chart-header">Patient Overlap</div>
          <div class="chart-view">
            <h2 class="noDataAvailable" *ngIf="vennChartData.length === 0">
              {{ commonMessage.noDataAvailable }}
            </h2>
            <app-venn-diagram
              [vennChartConfig]="vennChartData"
            ></app-venn-diagram>
          </div>
        </div>
        <div class="p-2 w-50">
          <div class="chart-header">Patient Demographics</div>
          <div class="chart-view">
            <div
              class="select-drug-box"
              *ngIf="showPatientDemographics === false"
            ></div>
            <h2 class="noDataAvailable" *ngIf="!showPatientDemographics">
              {{ commonMessage.noDataAvailable }}
            </h2>

            <div
              class="patient-demographics-wrap d-flex flex-column align-items-center"
              *ngIf="showPatientDemographics"
            >
              <div class="main-wrap d-flex flex-column">
                <div class="row-icons d-flex w-100">
                  <div class="left-col d-flex w-35 text-center">
                    <div class="w-50">
                      <img src="assets/images/male.png" />
                      <p>Male</p>
                    </div>
                    <div class="w-50">
                      <img src="assets/images/female.png" />
                      <p>Female</p>
                    </div>
                  </div>
                  <div class="right-col d-flex w-65 text-center">
                    <div class="w-20">
                      <img src="assets/images/0-17yrs.jpg" />
                      <p>{{ ageGroup[4] }}</p>
                    </div>
                    <div class="w-20">
                      <img src="assets/images/18-29-yrs.png" />
                      <p>{{ ageGroup[3] }}</p>
                    </div>
                    <div class="w-20">
                      <img src="assets/images/30-49-yrs.png" />
                      <p>{{ ageGroup[2] }}</p>
                    </div>
                    <div class="w-20">
                      <img src="assets/images/50-64-yrs.png" />
                      <p>{{ ageGroup[1] }}</p>
                    </div>
                    <div class="w-20">
                      <img src="assets/images/65-above-yrs.png" />
                      <p>{{ ageGroup[0] }}</p>
                    </div>
                  </div>
                </div>
                <div class="row-mapping d-flex w-100">
                  <div class="row-mapping-left d-flex w-35 text-center">
                    <div class="mapping-box">
                      <span
                        class="d-block w-100"
                        [style.backgroundColor]="maleFemaleBlock[0]"
                        >{{ patientDemographics1["male"] }}%</span
                      >
                      <span></span>
                      <span></span>
                    </div>
                    <div class="mapping-box">
                      <span
                        class="d-block w-100"
                        [style.backgroundColor]="maleFemaleBlock[1]"
                        >{{ patientDemographics1["female"] }}%</span
                      >
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div class="row-mapping-right d-flex w-65 text-center">
                    <div class="mapping-box">
                      <span
                        style
                        [style.backgroundColor]="multipleBlockColor[0]"
                        class="d-block w-100"
                        >{{ patientDemographics1["4"] }} %</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        style
                        [style.backgroundColor]="multipleBlockColor[1]"
                        class="d-block w-100"
                        >{{ patientDemographics1["3"] }} %</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        style
                        [style.backgroundColor]="multipleBlockColor[2]"
                        class="d-block w-100"
                        >{{ patientDemographics1["2"] }} %</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        style
                        [style.backgroundColor]="multipleBlockColor[3]"
                        class="d-block w-100"
                        >{{ patientDemographics1["1"] }} %</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        style
                        [style.backgroundColor]="multipleBlockColor[4]"
                        class="d-block w-100"
                        >{{ patientDemographics1["0"] }} %</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row-mapping d-flex w-100">
                  <div class="row-mapping-left d-flex w-35 text-center">
                    <div class="mapping-box">
                      <span class="d-block w-100"></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div
                    class="row-mapping-right d-flex w-65 text-center border-bottom"
                  >
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[0]"
                        class="d-block w-100"
                        >{{ patientDemographics2.femaleData["4"] }}%</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[1]"
                        class="d-block w-100"
                        >{{ patientDemographics2.femaleData["3"] }}%</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[2]"
                        class="d-block w-100"
                        >{{ patientDemographics2.femaleData["2"] }}%</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[3]"
                        class="d-block w-100"
                        >{{ patientDemographics2.femaleData["1"] }}%</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[4]"
                        class="d-block w-100"
                        >{{ patientDemographics2.femaleData["0"] }}%</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row-mapping d-flex w-100">
                  <div class="row-mapping-left d-flex w-35 text-center">
                    <div class="mapping-box">
                      <span class="d-block w-100"></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div
                    class="row-mapping-right d-flex w-65 text-center border-top"
                  >
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[0]"
                        class="d-block w-100"
                        >{{ patientDemographics2.maleData["4"] }}%</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[1]"
                        class="d-block w-100"
                        >{{ patientDemographics2.maleData["3"] }}%</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[2]"
                        class="d-block w-100"
                        >{{ patientDemographics2.maleData["2"] }}%</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[3]"
                        class="d-block w-100"
                        >{{ patientDemographics2.maleData["1"] }}%</span
                      >
                    </div>
                    <div class="mapping-box">
                      <span
                        [style.backgroundColor]="multipleBlockColor[4]"
                        class="d-block w-100"
                        >{{ patientDemographics2.maleData["0"] }}%</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="chart-section">
        <div class="chart-header">Claims Trend</div>
        <div class="chart-view">
          <h2 class="noDataAvailable" *ngIf="lineChartConfig.data.length === 0">
            {{ commonMessage.noDataAvailable }}
          </h2>
          <app-highcharts-linechart
            *ngIf="setLineChartValue"
            [lineChartConfig]="lineChartConfig"
          ></app-highcharts-linechart>
        </div>
      </div>
      <div class="chart-section">
        <div class="chart-header">Top 10 drugs</div>
        <div class="chart-view">
          <h2 class="noDataAvailable" *ngIf="top10Drugs.data.length === 0">
            {{ commonMessage.noDataAvailable }}
          </h2>
          <app-highcharts-barchart
            [barChartConfig]="top10Drugs"
          ></app-highcharts-barchart>
        </div>
      </div>
    </div>
    <div class="list-error-box" *ngIf="showErrorAlert">
      <app-alert
        [closable]="false"
        [message]="alertErrorMessage"
        (alertClosed)="alertClosed($event)"
        [type]="alertType"
      ></app-alert>
    </div>
  </ng-container>

  <div *nzModalFooter class="main"></div>
</nz-modal>
