export class Response {
  studyTitle: string;
  username: string;
  jobId:  string;
  clusterId: string;
  startedOn: number;
  completedOn: number;
  status: string;
  email: string;
  stepId: string;
  constructor(studyTitle: string, username: string, jobId:  string, clusterId: string, startedOn: number,
  completedOn: number, status: string, email: string, stepId: string) {
    this.studyTitle = studyTitle;
    this.username = username;
    this.jobId = jobId;
    this.clusterId = clusterId;
    this.startedOn = startedOn;
    this.completedOn = completedOn;
    this.status = status;
    this.email = email;
    this.stepId = stepId;
  }
}

export class ExecutionLog {
  status: string;
  noOfExecutionLogs: number;
  response: Response[];
  constructor(status: string, noOfExecutionLogs: number, response: Response[]) {
    this.status = status;
    this.noOfExecutionLogs = noOfExecutionLogs;
    this.response = response;
  }
}
