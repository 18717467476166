<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  nzClassName="confirm-modal"
  nzCentered
>
  <div *nzModalTitle class="custom-header">Information</div>
  <ng-container *nzModalContent>
    <div class="mb-2 title-14-500">
      Your job has been submitted successfully.
    </div>
    <div (click)="navigateToLogs()" class="title-14-500">
      You can view job status in the
      <span class="cursor-pointer theme-color">My Jobs</span> tab.
    </div>
  </ng-container>
  <div *nzModalFooter class="main"></div>
</nz-modal>


