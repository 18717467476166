<div class="main-class">
  <div>
    <div class="kpi-row" nz-row [nzGutter]="16">
      <ng-container *ngIf="!kpiDataLoaded">
        <div nz-col *ngFor="let _ of [1, 2, 3]">
          <div class="skeletonTile">
            <div class="tile">
              <nz-skeleton-element
                nzType="input"
                [nzActive]="true"
                style="width: 100%; margin-bottom: 10px"
                nzSize="small"
              ></nz-skeleton-element>
              <nz-skeleton-element
                nzType="avatar"
                [nzActive]="true"
                nzSize="small"
              ></nz-skeleton-element>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="kpiDataLoaded">
        <div nz-row class="kpi-card">
          <div
            nz-col
            class="kpi-card mr-2"
            *ngFor="let kpiItem of kpiDataArray"
          >
            <div class="card-inner">
              <rd-tag
                tagClass="tile"
                [mode]="'checkable'"
                [checked]="kpiItem.isSelected"
                [tagStyle]="tileStyle"
                (selectedItemsEvent)="
                  searchText = ''; navigateUrl(kpiItem.routerLink)
                "
              >
                <div class="kpi-card-content">
                  <p class="kpi-card-label" title="{{ kpiItem.text }}">
                    {{ kpiItem.text }}
                  </p>
                  <p class="kpi-card-value">
                    {{ kpiItem.value }}
                  </p>
                </div>
              </rd-tag>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="button-section">
      <div class="search-section">
        <div class="search-box">
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input
              type="text"
              nz-input
              placeholder="Search Study Topic"
              [(ngModel)]="searchText"
              (ngModelChange)="
                ($event.length > 2 || $event.length === 0) &&
                  fetchRecentStudies($event)
              "
            />
          </nz-input-group>
        </div>
        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
      </div>

      <div>
        <rd-button
          [btnType]="'primary'"
          btnText="Create Study"
          [isIconNeeded]="true"
          [iconType]="'plus'"
          [btnClass]="'custom-primaryfill-greenbtn'"
          (btnClick)="navigateCreateStudy()"
        ></rd-button>
      </div>
    </div>

    <div class="recent-studies-section scrollable-table">
      <rd-table
        [isLoading]="!isRecentStudiesLoaded"
        [tableColumns]="recentStudiesColumns"
        [tableData]="recentStudiesData"
        [tableScrollDetails]="recentStudiesScrollDetails"
        [pageIndex]="pageIndex"
        [pageSize]="defaultPageSize"
        [totalCount]="totalNoOfStudies"
        [loadingRows]="defaultPageSize"
        [errorMessage]="tableErrorMessage"
        [errorType]="tableErrorType"
        [showPagination]="true"
        [frontEndPagination]="false"
        (paginationChange)="onRecentStudiesTablePaginationChange($event)"
        (sortApply)="onRecentStudiesTableSort($event)"
        (tableRowClick)="recentStudiesTableActionIconClicked($event)"
      ></rd-table>
    </div>
  </div>

  <app-action-confirmation-popup
    *ngIf="showPopup"
    (closePopup)="closePopup($event)"
    (close)="closePopup($event)"
    [confirmationConfig]="confirmationConfig"
  ></app-action-confirmation-popup>

  <app-export-action-popup
    *ngIf="showExportPopup"
    (closeExportPopup)="closeExportPopup($event)"
    [exportBody]="exportBody"
  ></app-export-action-popup>

  <app-clone-action-popup
    *ngIf="showClonePopup"
    (closeClonePopup)="closeClonePopup($event)"
    [cloneBody]="cloneBody"
  >
  </app-clone-action-popup>

  <rd-modal
    [isVisible]="showRefreshSuccess"
    [isCloseable]="true"
    [isMaskClosable]="true"
    (modalCloseEvent)="closeRefreshModal()"
  >
    <ng-container ngProjectAs="[rdModalTitle]"
      ><span class="popup-heading">Information</span></ng-container
    >
    <ng-container ngProjectAs="[rdModalContent]"
      ><span class="popup-text"
        >Your job has been submitted successfully. You can view job status in
        the <a [routerLink]="['/logs']">My Jobs</a> tab.
      </span></ng-container
    >
    <ng-container ngProjectAs="[rdModalFooter]">
      <rd-button
        btnType="default"
        btnText="Cancel"
        (click)="closeRefreshModal()"
      ></rd-button>
    </ng-container>
  </rd-modal>
</div>
