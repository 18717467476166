export const environment = {
  production: true,
  env: 'development',
  serverUrl: 'https://ptj.dev.zsservices.com',
  cohortURL: 'https://pac-cpt-chrt-builder.dev.zsservices.com/',
  pacHomeURL:'https://pac.dev.zsservices.com/',
  dexUrl: 'https://data-explorer.dev.zsservices.com',
  config: {
    idm: {
      loginUrl:
        'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/login.aspx?ReturnUrl=',
      logoutUrl:
        'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/logout.aspx',
      accountUrl:
        'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/MyAccount.aspx',
    },
  },
  authEnabled: false,
  authConfig: {
    issuer: 'https://federationdev.abbvie.com',
    redirectUri: 'https://ptj.dev.zsservices.com',
    clientId: 'mfa.cdldev',
    responseType: 'code',
    cookieDomain: 'zsservices.com',
  },
};
