import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { API_LIST } from '../constants/api-list';
import { map } from 'rxjs/operators';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';
import { Studies } from '../models/my-studies.model';

@Injectable({
  providedIn: 'root',
})
export class StudiesServices {
  constructor(private transactionManager: TransactionManagerService) {}

  getStudies(payload: any): Observable<Studies> {
    return this.transactionManager.post(API_LIST.myStudies, payload).pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return new Studies(
              response.status,
              response.data,
              response.noOfStudies
            );
          } else {
            return this.errorHandler();
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  errorHandler(e: any = ''): any {
    // eslint-disable-next-line no-console
    console.log(e.toString());
    return { status: API_FAILURE_KEY };
  }
}
