import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CohortVisualizationPopupComponent } from './cohort-visualization-popup.component';
import { VennDiagramModule } from '../venn-diagram/venn-diagram.module';
import { HighchartsBarchartModule } from '../highcharts-barchart/highcharts-barchart.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { HighchartsLinechartModule } from '../highcharts-linechart/highcharts-linechart.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { RDSpinnerModule } from '@zs-ca/angular-cd-library';
import { AlertModule } from '../alert/alert.module';

@NgModule({
  declarations: [CohortVisualizationPopupComponent],
  exports: [CohortVisualizationPopupComponent],
  imports: [
    CommonModule,
    VennDiagramModule,
    HighchartsBarchartModule,
    NzModalModule,
    HighchartsLinechartModule,
    NzLayoutModule,
    NzDividerModule,
    RDSpinnerModule,
    AlertModule,
  ],
})
export class CohortVisualizationPopupModule {}
