import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateStudyDetailRoutingModule } from './create-study-detail-routing.module';


@NgModule({
  declarations: [],
  imports: [CommonModule, CreateStudyDetailRoutingModule],
})
export class CreateStudyDetailModule {}
