import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  API_FAILURE_KEY,
  API_SUCCESS_KEY,
  APP_STATE_KEYS,
  NO_RECORDS,
  NO_SEARCH_RESULT,
  SOMETHING_WENT_WRONG,
} from '../../shared/constants/app-constants';
import { StudiesServices } from '../../shared/services/myStudies.services';
import { ActionTriggerService } from '../../shared/services/action-trigger.service';
import { AppStateService } from '../../shared/services/app-state.service';
@Component({
  selector: 'app-my-studies',
  templateUrl: './my-studies.component.html',
  styleUrls: ['./my-studies.component.scss'],
})
export class MyStudiesComponent implements OnInit {
  @ViewChild('refreshSuccess', { read: TemplateRef }) refreshSuccess: any;
  isMyStudiesLoaded = false;
  breadcrumbData = [
    { displayName: 'All Studies', navigationPath: '/my-studies' },
    { displayName: 'Create Study', navigationPath: '' },
  ];
  myStudiesColumns = [
    {
      name: 'Study Title',
      key: 'studyTitle',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Cohort Name',
      key: 'cohortName',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Data Source',
      key: 'dataSource',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '20%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Created On',
      key: 'createdOn',
      dataType: 'date',
      dateFormat: 'MMM d y | h:mm:ss a',
      prefix: '',
      suffix: '',
      defaultSortOrder: 'desc',
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Created By',
      key: 'createdByArray',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '15%',
      align: 'center',
      showIcon: false,
      sortable: false,
      avatarStyle: {
        color: '#ffffff',
        background: '#27a6a4',
        cursor: 'default',
      },
      type: 'avatar',
    },
    {
      name: 'Progress',
      key: 'progress',
      dataType: 'number',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '20%',
      align: 'left',
      showIcon: false,
      sortable: false,
      type: 'progress',
      progressBarColor: '#52c41a',
    },
    {
      name: 'Actions',
      key: 'actions',
      dataType: 'string',
      defaultSortOrder: 'desc',
      sortDirections: ['ascend', 'descend'],
      width: '30%',
      showIcon: false,
      type: 'actions',
      align: 'center',
      sortable: false,
      actionIcons: [
        {
          type: 'custom:view-icon',
          key: 'view',
          title: 'View',
          theme: 'fill',
        },
        {
          type: 'custom:edit-icon',
          key: 'edit',
          title: 'Edit',
          theme: 'outline',
        },
        {
          type: 'custom:copy-icon',
          key: 'clone',
          title: 'Clone',
          theme: 'fill',
        },
        {
          type: 'custom:refresh-icon',
          key: 'refresh',
          title: 'Refresh',
          theme: 'outline',
        },
        {
          type: 'custom:download-icon',
          key: 'export',
          title: 'Export',
          theme: 'outline',
        },
        {
          type: 'custom:delete-icon',
          key: 'delete',
          title: 'Delete',
          theme: 'fill',
        },
      ],
    },
  ];

  myStudiesData: Array<any> = [];

  myStudiesScrollDetails = { x: '1250px', y: '40vh' };

  defaultPageSize = 10;

  totalNoOfStudies = this.defaultPageSize;

  offset = 0;

  pageIndex = 1;

  limit = this.defaultPageSize;

  orderBy = 'createdOn';

  orderType = 'desc';

  tableErrorType: any;

  tableErrorMessage: any;

  kpiDataErrorMessage = '';

  kpiDataError = false;

  kpiDataLoaded = false;

  totalStudiesPerformed: any = '-';

  studiesWithCollaborations: any = '-';

  showClonePopup = false;

  cloneBody: any = null;

  visibleActionButtonArray = {
    owner: ['edit', 'clone', 'refresh', 'export', 'delete'],
    viewer: ['view', 'clone', 'export'],
    collaborator: ['edit', 'clone', 'refresh', 'export'],
  };

  disabledActionButtonArray = {
    isCloning: ['edit', 'clone', 'refresh'],
    isRefresh: ['edit', 'clone', 'refresh', 'export', 'delete'],
    isProgressLess: ['export'],
    canRefresh: ['refresh'],
    isProgressLessTwenty: ['clone'],
  };

  confirmationConfig: any;
  searchText = '';

  filtersApplied = false;
  showPopup = false;
  actionButtonStudyId = '';
  showExportPopup = false;
  exportBody: any;
  showErrorAlert = false;
  alertErrorMessage: any;
  alertType: any;
  showRefreshSuccess = false;

  onMyStudiesTablePaginationChange(pageClicked: any) {
    this.pageIndex = pageClicked;
    this.fetchMyStudies();
  }

  onMyStudiesTableSort(columnClicked: any) {
    this.orderType = columnClicked.defaultSortOrder;
    this.orderBy = columnClicked.key;
    this.pageIndex = 1;
    this.fetchMyStudies();
  }

  myStudiesTableActionIconClicked(actionIconClicked: any) {
    if (actionIconClicked.icon.key === 'refresh') {
      this.showPopup = true;
      this.confirmationConfig = {
        header: 'Confirm refresh',
        message:
          'This option will refresh all the data for a study. Are you sure you want to update it?',
        confirmationButtonName: 'Yes',
        cancelButtonName: 'No',
        key: 'Refresh',
      };
    } else if (actionIconClicked.icon.key === 'delete') {
      this.showPopup = true;
      this.confirmationConfig = {
        header: 'Confirm delete',
        message:
          'This option will permanently delete the study. Are you sure you want to delete it?',
        confirmationButtonName: 'Yes',
        cancelButtonName: 'No',
        key: 'Delete',
      };
    } else if (actionIconClicked.icon.key === 'export') {
      this.actionTriggerService
        .getExportData(actionIconClicked.data.studyId)
        .subscribe((data) => {
          if (data.status === API_SUCCESS_KEY) {
            this.exportBody = data;
            this.showExportPopup = true;
          } else {
            this.alertErrorMessage = SOMETHING_WENT_WRONG;
            this.alertType = 'error';
            this.showErrorAlert = true;
          }
        });
    } else if (actionIconClicked.icon.key === 'clone') {
      this.cloneBody = actionIconClicked.data.studyId;
      this.showClonePopup = true;
    } else if (actionIconClicked.icon.key === 'edit') {
      this.viewEditStudy(actionIconClicked.data.studyId, 'edit');
    } else if (actionIconClicked.icon.key === 'view') {
      this.viewEditStudy(actionIconClicked.data.studyId, 'view');
    }
    this.actionButtonStudyId = actionIconClicked.data.studyId;
  }

  viewEditStudy(studyId: any, actionType: string) {
    this.actionTriggerService
      .getStudyDetails(studyId)
      .subscribe((data: any) => {
        if (data.status === API_SUCCESS_KEY) {
          this.appState.setGlobalState(APP_STATE_KEYS.STUDY_DETAILS, data.data);
          this.appState.setGlobalState(APP_STATE_KEYS.ACTION_TYPE, actionType);
          this.router.navigate([`/studies/edit/${studyId}`]);
        } else {
          this.alertType = 'error';
          this.alertErrorMessage = SOMETHING_WENT_WRONG;
          this.showErrorAlert = true;
        }
      });
  }

  ngOnInit(): void {
    this.fetchMyStudies();
  }

  constructor(
    private myStudiesService: StudiesServices,
    private actionTriggerService: ActionTriggerService,
    private router: Router,
    private appState: AppStateService
  ) {}

  fetchMyStudies(): void {    
    this.isMyStudiesLoaded = false;

    const payload = {
      limit: this.limit,
      offset: this.pageIndex * this.defaultPageSize - this.defaultPageSize,
      orderBy: this.orderBy,
      orderType: this.orderType,
      screen: 'myStudies',
      searchText: this.searchText,
    };

    this.myStudiesService.getStudies(payload).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.totalNoOfStudies = response.noOfStudies;
          if (response.noOfStudies > 0) {
            this.myStudiesData = this.formatMyStudiesData(response.data);
            this.isMyStudiesLoaded = true;
          } else {
            this.tableErrorType = 'info';
            this.myStudiesData = [];

            if (this.filtersApplied) {
              this.handleError(NO_SEARCH_RESULT, 'myStudies');
            } else {
              this.handleError(NO_RECORDS, 'myStudies');
            }
            this.isMyStudiesLoaded = true;
          }
        } else {
          this.tableErrorType = 'error';
          this.handleError(SOMETHING_WENT_WRONG, 'myStudies');
        }
      },
      (error: any) => {
        // eslint-disable-next-line no-console
        console.log(error);
        this.tableErrorType = 'error';
        this.handleError(SOMETHING_WENT_WRONG, 'myStudies');
      }
    );
  }

  formatMyStudiesData(data: any): any {
    data.forEach((item: any) => {
      switch (item.role) {
        case 'owner':
          item['visibleActionIcons'] = this.visibleActionButtonArray.owner;
          break;
        case 'collaborator':
          item['visibleActionIcons'] =
            this.visibleActionButtonArray.collaborator;
          break;
        case 'viewer':
          item['visibleActionIcons'] = this.visibleActionButtonArray.viewer;
          break;
      }

      item['disabledActionIcons'] = [];
      let disabledActionIconArray: string[] = [];
      if (item.isRefreshStudy) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.isRefresh,
            ...disabledActionIconArray,
          ]),
        ];
      }
      if (item.isCloning) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.isCloning,
            ...disabledActionIconArray,
          ]),
        ];
      }
      if (item.progress < 50) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.isProgressLess,
            ...disabledActionIconArray,
          ]),
        ];
      }
      if (item.progress < 20) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.isProgressLessTwenty,
            ...disabledActionIconArray,
          ]),
        ];
      }
      if (!item.canRefresh) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.canRefresh,
            ...disabledActionIconArray,
          ]),
        ];
      }

      item['disabledActionIcons'] = disabledActionIconArray;

      item.createdOn = new Date(item.createdOn * 1000);

      let initials;

      if (item.email?.includes('@zs.com')) {
        initials = item.createdBy?.substring(3, 5).toUpperCase();
      } else {
        initials = item.email?.substring(0, 2).toUpperCase();
      }

      item.createdByArray = [{ text: initials, tooltip: item.email }];
    });

    return data;
  }

  handleError(errorMessage: string, api: string): void {
    switch (api) {
      case 'myStudies':
        this.tableErrorMessage = errorMessage;
        this.isMyStudiesLoaded = true;
        break;
    }
  }

  searchStudy(): void {
    if (this.searchText.length > 2 || this.searchText.length === 0) {
      this.filtersApplied = true;
      this.fetchMyStudies();
    }
  }

  closePopup($event: any) {
    this.showPopup = false;
    if ($event === true) {
      if (this.confirmationConfig.key === 'Refresh') {
        this.actionTriggerService
          .getRefreshedData(this.actionButtonStudyId)
          .subscribe((data) => {
            this.fetchMyStudies();
            if (data.status === API_SUCCESS_KEY) {
              this.showRefreshSuccess = true;
            } else {
              this.alertType = 'error';
              this.alertErrorMessage = SOMETHING_WENT_WRONG;
              this.showErrorAlert = true;
            }
          });
      } else if (this.confirmationConfig.key === 'Delete') {
        this.actionTriggerService
          .getDeletedData(this.actionButtonStudyId)
          .subscribe((data) => {
            this.fetchMyStudies();
            if (data.status === API_SUCCESS_KEY) {
              this.alertType = 'info';
              this.alertErrorMessage = data.message;
              this.showErrorAlert = true;
            } else {
              this.alertType = 'error';
              this.alertErrorMessage = SOMETHING_WENT_WRONG;
              this.showErrorAlert = true;
            }
          });
      }
    }
  }

  closeExportPopup($event: any) {
    this.showExportPopup = false;
  }

  closeClonePopup($event: any) {
    if ($event === API_FAILURE_KEY) {
      // After closing the clone popup, user should get error alert in case of API failure.
      this.showErrorAlert = true;
      this.alertType = 'error';
      this.alertErrorMessage = SOMETHING_WENT_WRONG;
    } else {
      // After closing the clone popup, user should be navigated to my-studies page if clone is success.
      this.showErrorAlert = false;
    }
    this.showClonePopup = false;
  }

  navigateCreateStudy() {
    this.appState.setGlobalState(APP_STATE_KEYS.ACTION_TYPE, 'none');
    this.appState.removeGlobalState(APP_STATE_KEYS.STUDY_DETAILS);
    this.router.navigate(['/studies/create']);
  }

  alertClosed($event: any) {
    this.showErrorAlert = false;
  }

  closeRefreshModal() {
    this.showRefreshSuccess = false;
  }
}
