import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrdinalPipe } from './ordinal.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';
@NgModule({
  declarations: [OrdinalPipe, SearchFilterPipe, SentenceCasePipe],
  imports: [CommonModule],
  exports: [OrdinalPipe, SearchFilterPipe, SentenceCasePipe],
})
export class PipesModule {}
