import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  API_SUCCESS_KEY,
  SOMETHING_WENT_WRONG,
} from 'src/app/shared/constants/app-constants';
import { RegimenLotService } from 'src/app/shared/services/regimen-lot.service';

@Component({
  selector: 'app-select-subclass-drawer',
  templateUrl: './select-subclass-drawer.component.html',
  styleUrls: ['./select-subclass-drawer.component.scss'],
})
export class SelectSubclassDrawerComponent implements OnInit {
  @Input() show = false;

  @Input() ruleType = '';

  @Input() studyId = '';

  @Input() selectedListOfValues: string[] = [];

  @Input() set regimenDays(regimenDays: {
    operator: string;
    value: number | null;
  }) {
    this.regimenDaysData.operatorSelected = regimenDays.operator;
    this.regimenDaysData.value = regimenDays.value;
  }

  @Output() drawerClose = new EventEmitter<EventMetadata>();

  listOfValues: string[] = [];

  searchText = '';

  errorMessage = '';

  listFetchStatus: 'loading' | 'available' | 'error' = 'loading';

  allChecked = false;

  indeterminate = false;

  regimenDaysData = {
    operatorOptions: ['>=', '>', '<=', '<', '='],
    operatorSelected: '',
    value: null as number | null,
  };

  constructor(private regimenLotService: RegimenLotService) {}

  ngOnInit(): void {
    this.fetchListOfValues();
  }

  private fetchListOfValues(): void {
    this.regimenLotService
      .postTypeOptionsList(this.studyId, this.ruleType, { searchText: '' })
      .subscribe(
        (response: any) => {
          // TODO: Add type.
          if (response.status === API_SUCCESS_KEY) {
            this.listOfValues = [...response?.data];
            this.allChecked =
              this.selectedListOfValues.length === this.listOfValues.length;
            this.listFetchStatus = 'available';
          } else {
            this.listFetchStatus = 'error';
            this.errorMessage = `${SOMETHING_WENT_WRONG}`;
          }
        },
        () => {
          this.listFetchStatus = 'error';
          this.errorMessage = `${SOMETHING_WENT_WRONG}`;
        }
      );
  }

  handleSelectAllToggle(): void {
    this.indeterminate = false;
    if (this.allChecked) {
      this.selectedListOfValues = [...this.listOfValues];
    } else {
      this.selectedListOfValues = [];
    }
  }

  handleSingleChecked($event: boolean, value: string): void {
    if ($event) {
      this.selectedListOfValues.push(value);
    } else {
      const idx = this.selectedListOfValues.findIndex((v) => v === value);
      this.selectedListOfValues.splice(idx, 1);
    }

    if (this.selectedListOfValues.length === 0) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this.listOfValues.length === this.selectedListOfValues.length) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.allChecked = false;
      this.indeterminate = true;
    }
  }

  handleSaveButtonClick(): void {
    this.drawerClose.emit({
      subclasses: this.selectedListOfValues,
      operator: this.regimenDaysData.operatorSelected,
      regimenDays: this.regimenDaysData.value,
    });
  }
}

interface EventMetadata {
  subclasses: string[];
  operator?: string;
  regimenDays?: number | null;
}
