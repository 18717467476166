import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { HighchartsBarModel } from './highcharts-barchart.model';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-highcharts-barchart',
  templateUrl: './highcharts-barchart.component.html',
  styleUrls: ['./highcharts-barchart.component.scss'],
})
export class HighchartsBarchartComponent implements OnChanges {
  @Input() barChartConfig: HighchartsBarModel = new HighchartsBarModel();
  chart: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.barChartConfig) {
      this.displayBarChart();
    }
  }

  displayBarChart(): void {
    const yAxisTooltipLabel = this.barChartConfig.yAxisTooltipLabel;
    const xAxisTooltipLabel = this.barChartConfig.xAxisTooltipLabel;

    this.chart = Highcharts.chart('barGraph', {
      chart: {
        type: 'column',
        height: this.barChartConfig.height,
      },
      title: {
        text: this.barChartConfig.graphTitle,
        align: 'left',
        margin: 40,
        style: {
          fontFamily: 'Open Sans',
          fontSize: '18px',
          color: '#1A1628',
          fontWeight: 'bold',
        },
      },
      xAxis: {
        labels: {
          style: {
            fontSize: '9px',
            color: '#454250',
            fontFamily: 'Open Sans',
             fontWeight: '600',
          },
          formatter: function () {
            return this.value.toString().replace(/ /g, '<br />');
        }
        },
        title: {
          text: this.barChartConfig.xAxisTitle,
          style: {
            fontSize: '14px',
            color: '#454250',
            fontFamily: 'Open Sans',
            fontWeight: '600',
          },
        },
        categories: this.barChartConfig.xAxis,
      },
      yAxis: {
        allowDecimals: false,
        gridLineWidth: 1,
        labels: {
          style: {
            fontSize: '14px',
            color: '#454250',
            fontFamily: 'Open Sans',
            fontWeight: '400',
          },
        },
        title: {
          text: this.barChartConfig.yAxisTitle,
          style: {
            fontSize: '14px',
            color: '#454250',
            fontFamily: 'Open Sans',
            fontWeight: '600',
          },
        },
      },
      tooltip: {
        enabled: this.barChartConfig.showTooltip,
        useHTML: true,
        style: {
          fontFamily: 'Open Sans',
          fontSize: '12px',
          color: '#454250',
          fontWeight: 'normal',
          cursor: 'default',

        },
        formatter() {
          return (
            yAxisTooltipLabel + ' : '  + this.y  + '%' +'<br>' +
           xAxisTooltipLabel + ' : '  + this.x 
          ) 
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: undefined,
          color: this.barChartConfig.barColor,
          data: this.barChartConfig.data,
          states: { hover: { enabled: false } },
        },
      ],
      plotOptions: {
        series: {
          showInLegend: this.barChartConfig.showLegends,
        },
        column: {
          // color: '#416dab',
        },
      },
    });
  }
}
