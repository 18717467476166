import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { API_LIST } from '../constants/api-list';
import { map, switchMap } from 'rxjs/operators';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';
import { SensitiveInfoService } from './sensitive-info.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { consolidatedBaseUrl } from './baseUrls';
import { Router } from '@angular/router';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class CreateStudiesService {
  private endpoint: any;
  private httpOptions: any;

  constructor(
    private transactionManager: TransactionManagerService,
    private _sensitiveInfoService: SensitiveInfoService,
    private cookieService: CookieService,
    private commonService: CommonService,
    private http: HttpClient,
    private router: Router
  ) {
    this.httpOptions = {
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    this.endpoint = environment.serverUrl;
  }
  
  getIdmUsers(): Observable<any> {
    const project_id = this.cookieService.get('project_id');

    return this.commonService.getUserSession().pipe(
      switchMap((data: any) => {
        const user_id = data.username.toLowerCase();
        const API_ENDPOINT = API_LIST.projectUsers.replace('<<project_id>>', project_id).replace('<<user_id>>', user_id);

        return this.http.get(environment.pacHomeURL + API_ENDPOINT, this.httpOptions);
      }),
      map((response: any) => {
        if (response) {
          const users = JSON.parse(response).data.map((user: any) => {
            return {
              username: user.user_id,
              name: user.user_name,
              email: user.user_email,
            };
          });
          return users;
        } else {
          return this.errorHandler(response);
        }
      })
    );
  }


  postValidateS3Location(payload: any): Observable<any> {
    return this.transactionManager
      .post(API_LIST.validateS3Location, payload)
      .pipe(
        map((response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        })
      );
  }

  postSaveStudy(payload: any): Observable<any> {
    return this.transactionManager.post(API_LIST.createStudy, payload).pipe(
      map((response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          return response;
        } else {
          return this.errorHandler(response);
        }
      })
    );
  }

  postCreateStudy(studyId: any): Observable<any> {
    return this.transactionManager
      .post('mapping/' + studyId + '/execute', {})
      .pipe(
        map((response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        })
      );
  }

  getCohortVisualizationData(
    cohortId: string,
    exportVersion: string
  ): Observable<any> {
    // @ts-ignore
    const url =
      environment.cohortURL +
      API_LIST.getCohortVisualizationURL +
      cohortId +
      '&exportVersion=' +
      exportVersion;
    return this.http.get(url, this.httpOptions).pipe(
      map((response: any) => {
        if (response) {
          return response;
        }
      })
    );
  }

  errorHandler(e: any = ''): any {
    if (e.error == 'Study access issue') {
      this.router.navigate(['studies/error/access_issue']);
    }
    return { status: API_FAILURE_KEY };
  }
}
