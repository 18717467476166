import { Pipe, PipeTransform } from '@angular/core';

const ordinals = ['th', 'st', 'nd', 'rd'];
@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {
  transform(n?: number, keepNumber = true) {
    n = Number(n);
    if (isNaN(n)) {
      return '';
    }
    if (n === 0) {
      return ordinals[0];
    }
    const v = n % 100;
    return (
      (keepNumber ? n : '') +
      (ordinals[(v - 20) % 10] || ordinals[v] || ordinals[0])
    );
  }
}
