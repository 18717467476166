import { Component, ElementRef, OnInit } from '@angular/core';
import { DimensionMappingService } from 'src/app/shared/services/dimension-mapping.service';
import {
  API_FAILURE_KEY,
  API_SUCCESS_KEY,
  CLOSE_POPUP,
  JOB_TRIGGER_REQUIRED,
  MAPPING_JOB_RUNNING,
  MAXIMUM_CSV_FILE_SIZE,
  NO_RECORDS,
  SOMETHING_WENT_WRONG,
} from '../../../shared/constants/app-constants';
import { saveAs } from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { CreateStudiesService } from 'src/app/shared/services/create-studies.service';

@Component({
  selector: 'app-dimension-mapping',
  templateUrl: './dimension-mapping.component.html',
  styleUrls: ['./dimension-mapping.component.scss'],
})
export class DimensionMappingComponent implements OnInit {
  readonly JOB_TRIGGER_REQUIRED = JOB_TRIGGER_REQUIRED;
  readonly MAPPING_JOB_RUNNING = MAPPING_JOB_RUNNING;
  spinnerStyle = { 'font-size': '20px', color: '#27a6a4' };

  gridColumnElipsisCss = {
    overflow: 'hidden',
    'max-width': '25ch',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
  };

  private postMappingDataSubscription: any;

  studyId: any;

  defaultPageSize = 20;

  defaultHistoryGrigPageSize = 10;

  breadcrumbData: any;

  drugMapping = {
    isLoaded: false,
    downloadLoadingStatus: false,
    uploadStatus: false,
    uploadErrorStatus: true,
    uploadErrorMessage: '',
    columns: [
      {
        name: 'Product Code',
        key: 'productCode',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Name',
        key: 'drugName',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Vocabulary',
        key: 'vocabulary',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Product',
        key: 'brandName',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Drug Class',
        key: 'drugClass',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '1250px', y: '55vh' },
    pageIndex: 1,
    sortingOrder: '',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    previousSearchStatus: false,
    tableErrorType: 'error' as 'error',
    tableErrorMessage: '',
    searchToggleStatus: false,
  };

  diagnosisMapping = {
    isLoaded: false,
    downloadLoadingStatus: false,
    uploadStatus: false,
    uploadErrorStatus: true,
    uploadErrorMessage: '',
    columns: [
      {
        name: 'Diagnosis Code',
        key: 'diagnosisCode',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Diagnosis Description',
        key: 'diagnosisDescription',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Vocabulary',
        key: 'vocabulary',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'New Diagnosis Description',
        key: 'indication',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Primary Indication Flag',
        key: 'primaryIndicationFlag',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '1250px', y: '55vh' },
    pageIndex: 1,
    sortingOrder: '',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    previousSearchStatus: false,
    tableErrorType: 'error' as 'error',
    tableErrorMessage: '',
    searchToggleStatus: false,
  };

  procedureMapping = {
    isLoaded: false,
    downloadLoadingStatus: false,
    uploadStatus: false,
    uploadErrorStatus: true,
    uploadErrorMessage: '',
    columns: [
      {
        name: 'Procedure Code',
        key: 'procedureCode',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Procedure Description',
        key: 'procedureDescription',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Vocabulary',
        key: 'vocabulary',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'New Procedure Description',
        key: 'procedureName',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Procedure Class',
        key: 'procedureClass',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Procedure Type',
        key: 'procedureType',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '20%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '1250px', y: '55vh' },
    pageIndex: 1,
    sortingOrder: '',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    previousSearchStatus: false,
    tableErrorType: 'error' as 'error',
    tableErrorMessage: '',
    searchToggleStatus: false,
  };

  physicianSpecialityMapping = {
    isLoaded: false,
    downloadLoadingStatus: false,
    uploadStatus: false,
    uploadErrorStatus: true,
    uploadErrorMessage: '',
    columns: [
      {
        name: 'Specialty',
        key: 'speciality',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Specialty Group',
        key: 'specialityGroup',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '1250px', y: '55vh' },
    pageIndex: 1,
    sortingOrder: '',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    previousSearchStatus: false,
    tableErrorType: 'error' as 'error',
    tableErrorMessage: '',
    searchToggleStatus: false,
  };

  geographyMapping = {
    isLoaded: false,
    downloadLoadingStatus: false,
    uploadStatus: false,
    uploadErrorStatus: true,
    uploadErrorMessage: '',
    columns: [
      {
        name: 'Location ID',
        key: 'locationId',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'ZIP',
        key: 'zip',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'State',
        key: 'state',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Location Group',
        key: 'locationGroup1',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '1250px', y: '55vh' },
    pageIndex: 1,
    sortingOrder: '',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    previousSearchStatus: false,
    tableErrorType: 'error' as 'error',
    tableErrorMessage: '',
    searchToggleStatus: false,
  };

  physicianMapping = {
    isLoaded: false,
    downloadLoadingStatus: false,
    uploadStatus: false,
    uploadErrorStatus: true,
    uploadErrorMessage: '',
    columns: [
      {
        name: 'NPI ID',
        key: 'npiId',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '1250px', y: '55vh' },
    pageIndex: 1,
    sortingOrder: '',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    previousSearchStatus: false,
    tableErrorType: 'error' as 'error',
    tableErrorMessage: '',
    searchToggleStatus: false,
  };

  deepCopyOfGeographyMappingColumns = JSON.parse(
    JSON.stringify(this.geographyMapping.columns)
  );

  sectionsListing = [
    {
      code: 'drugMapping',
      title: 'Drug Mapping',
      historyCode: 'drug-mapping',
      headerName: 'drugMappingHeader',
      tooltip:
        'Drug prescription and procedure codes are grouped in the relevant brand names and drug classes. This mapping can be used to create drug market definition and will be used in subsequent metric calculation.',
      sectionConfig: this.drugMapping,
      minSearchLength: 3,
      mappingDownloadFileName: 'drug mapping-Export',
    },
    {
      code: 'diagnosisMapping',
      title: 'Diagnosis Mapping',
      historyCode: 'diagnosis-mapping',
      headerName: 'diagnosisMapping',
      tooltip:
        'Used for grouping diagnosis codes in the relevant indications along with flags for primary indication (1), comorbidities (2), symptoms (3), and other indications (0). This mapping can be used to create diagnosis market definition and will be used in the subsequent metric calculations.',
      sectionConfig: this.diagnosisMapping,
      minSearchLength: 3,
      mappingDownloadFileName: 'diagnosis mapping-Export',
    },
    {
      code: 'procedureMapping',
      title: 'Procedure Mapping',
      historyCode: 'procedure-mapping',
      headerName: 'procedureMapping',
      tooltip:
        'Used for grouping procedure codes in the relevant succinct procedure names. This mapping can be used to create drug/medical procedure market definition and will be used in the subsequent metric calculations.',
      sectionConfig: this.procedureMapping,
      minSearchLength: 3,
      mappingDownloadFileName: 'procedure mapping-Export',
    },
    {
      code: 'physicianSpecialityMapping',
      title: 'Physician Specialty Mapping',
      historyCode: 'physician-speciality-mapping',
      headerName: 'physicianSpecialityMapping',
      tooltip:
        'Used for grouping various specialty description into short specialty groups. All the HCP specialty related metrics will use the specialty groups for aggregation.',
      sectionConfig: this.physicianSpecialityMapping,
      minSearchLength: 3,
      mappingDownloadFileName: 'physician specialty mapping-Export',
    },
    {
      code: 'geographyMapping',
      title: 'Geography Mapping',
      historyCode: 'geography-mapping',
      headerName: 'GeographyMapping',
      tooltip:
        'Used for grouping various zip codes into custom territories. Custom geography grouping to be used for geography level aggregation.',
      sectionConfig: this.geographyMapping,
      minSearchLength: 1,
      mappingDownloadFileName: 'geography mapping-Export',
    },
  ];

  isMyhistoryLoaded = false;

  historyColumns = [
    {
      name: 'User ID',
      key: 'username',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: false,
    },
    {
      name: 'Study ID',
      key: 'studyId',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: false,
    },
    {
      name: 'Mapping Name',
      key: 'mappingName',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '20%',
      align: 'left',
      showIcon: false,
      sortable: false,
    },
    {
      name: 'Study Name',
      key: 'title',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '20%',
      align: 'left',
      showIcon: false,
      sortable: false,
    },
    {
      name: 'Timestamp',
      key: 'formattedTimeStamp',
      dataType: 'date',
      dateFormat: 'MMM d y | h:mm:ss a',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '20%',
      align: 'left',
      showIcon: false,
      sortable: false,
    },
    {
      name: 'Path',
      key: 'actions',
      dataType: 'string',
      width: '20%',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      showIcon: false,
      type: 'actions',
      align: 'center',
      sortable: false,
      actionIcons: [
        {
          type: 'download',
          key: 'download',
          title: 'Download',
          theme: 'outline',
        },
      ],
    },
  ];

  historyData: Array<any> = [];

  historyDataCount: any;

  historyScrollDetails = { x: '100%', y: '55vh' };

  historyPageIndex = 1;

  historyTableErrorType: any;

  historyTableErrorMessage: any;

  historySelectedSection: any;

  visible = false;

  errorLogLoaded = false;

  errorLogResponse: any;

  eligibleForEpisodeCreation = false;

  userInformation: any;

  dimensionMappingLoaderStatus = false;

  informationPopupStatus = false;

  checkSectionType = '';

  physicianMappingVisibilityStatus = false;
  physicianMappingLoaderStatus = false;

  //uploadfile

  public errorMessage = '';
  public successMessage = '';
  public fileMessage = {
    corruptedFile: 'File is corrupted and cannot be uploaded.',
    exceedSize: 'Maximum upload file size: 20MB.',
    csvExpected: 'Incorrect file type. CSV is expected',
    success: 'File uploaded successfully.',
  };
  public fileProcess = false;

  isRequiredJobTriggeredStatus = false;
  isMappingJobCompletedStatus = true;
  isUserRoleViewer = true;
  allBreadCrumbData: any = [];
  viewerBreadCrumbData: any = [];

  constructor(
    private dimensionMappingService: DimensionMappingService,
    public elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private createStudiesService: CreateStudiesService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      this.studyId = params.get('studyId');
      this.breadcrumbData = [
        { displayName: 'All Studies', navigationPath: '/home/' },
        {
          displayName: 'Study Details',
          navigationPath: `/studies/edit/${this.studyId}`,
        },
        { displayName: 'Dimension Mapping', navigationPath: '' },
      ];
    });
    this.fetchUserRole();
    this.fetchPhysicianMappingVisibilityStatus();
    this.fetchMappingJobStatus();
    this.setBreadCrumbForViewer();
  }

  fetchMappingJobStatus() {
    this.dimensionMappingLoaderStatus = true;
    this.commonService.getJobStatus(this.studyId).subscribe(
      (response) => {
        if (response.status === API_SUCCESS_KEY) {
          this.dimensionMappingLoaderStatus = false;
          if (response.data.length == 0) {
            this.isRequiredJobTriggeredStatus = true;
          } else if (response.data[0].status != 'COMPLETED') {
            this.isMappingJobCompletedStatus = false;
          }
        } else {
          this.dimensionMappingLoaderStatus = false;
          this.handleError(SOMETHING_WENT_WRONG, 'visualization', 'error');
        }
      },
      (error) => {
        this.dimensionMappingLoaderStatus = false;
        this.handleError(SOMETHING_WENT_WRONG, 'visualization', 'error');
      }
    );
  }

  fetchPhysicianMappingVisibilityStatus() {
    this.physicianMappingLoaderStatus = true;
    this.dimensionMappingService
      .getPhysicianMappingVisibilityStatus(this.studyId)
      .subscribe(
        (response) => {
          if (response.status === API_SUCCESS_KEY) {
            if (response.physicianMappingVisible) {
              this.sectionsListing.push({
                code: 'physicianMapping',
                title: 'Physician Mapping',
                historyCode: 'physician-mapping',
                headerName: 'physicianMapping',
                tooltip:
                  'Used for adding relevant market NPI information to the generated data. Along with NPI IDs, columns can be dynamically added to the include information such as decile, priority, etc.',
                sectionConfig: this.physicianMapping,
                minSearchLength: 3,
                mappingDownloadFileName: 'physician mapping-Export',
              });
            }
            this.physicianMappingLoaderStatus = false;
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'visualization', 'error');
          }
        },
        (error) => {
          this.handleError(SOMETHING_WENT_WRONG, 'visualization', 'error');
        }
      );
  }

  fetchUserRole() {
    this.dimensionMappingLoaderStatus = true;
    this.dimensionMappingService.getUserRole(this.studyId).subscribe(
      (response) => {
        if (response.status === API_SUCCESS_KEY) {
          this.userInformation = response.data;
          this.isUserRoleViewer = response.data.role == 'viewer' ? true : false;
          this.dimensionMappingLoaderStatus = false;
        } else {
          this.dimensionMappingLoaderStatus = false;
          this.handleError(SOMETHING_WENT_WRONG, 'visualization', 'error');
        }
      },
      (error) => {
        this.dimensionMappingLoaderStatus = false;
        this.handleError(SOMETHING_WENT_WRONG, 'visualization', 'error');
      }
    );
  }

  fetchProgressStatus() {
    this.dimensionMappingService.getProgressStatus(this.studyId).subscribe(
      (response) => {
        if (response.status === API_SUCCESS_KEY) {
          if (response.progress >= 20) {
            this.eligibleForEpisodeCreation = true;
          }
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'visualization', 'error');
        }
      },
      (error) => {
        this.handleError(SOMETHING_WENT_WRONG, 'visualization', 'error');
      }
    );
  }

  fetchGridMappingData(
    sectionType: any,
    pageIndex = this[sectionType as keyof DimensionMappingComponent].pageIndex,
    sortingOrder = this[sectionType as keyof DimensionMappingComponent]
      .sortingOrder,
    sortingOrderKey = this[sectionType as keyof DimensionMappingComponent]
      .sortingOrderKey
  ) {
    this[sectionType as keyof DimensionMappingComponent].isLoaded = false;
    this[sectionType as keyof DimensionMappingComponent].previousSearchStatus =
      this[sectionType as keyof DimensionMappingComponent].searchStatus
        ? true
        : false;
    let payload = {
      mappingType: sectionType,
      searchCriteria: this[sectionType as keyof DimensionMappingComponent]
        .searchStatus
        ? this[sectionType as keyof DimensionMappingComponent].searchCriteria
        : '',
      searchText: this[sectionType as keyof DimensionMappingComponent]
        .searchStatus
        ? this[sectionType as keyof DimensionMappingComponent].searchText
        : '',
      sortingCriteria: sortingOrderKey,
      sortingOrder: sortingOrder.toUpperCase(),
      limit: this.defaultPageSize,
      offset: (pageIndex - 1) * 20,
    };
    if (
      this.postMappingDataSubscription &&
      this.checkSectionType == sectionType
    ) {
      this.postMappingDataSubscription.unsubscribe();
    }
    this.checkSectionType = sectionType;
    this.postMappingDataSubscription = this.dimensionMappingService
      .postMappingData(this.studyId, payload)
      .subscribe(
        (response) => {
          if (response.status === API_SUCCESS_KEY) {
            this[sectionType as keyof DimensionMappingComponent].searchStatus =
              false;
            if (response.count == 0) {
              this[sectionType as keyof DimensionMappingComponent].isLoaded =
                true;
              this.handleError(NO_RECORDS, sectionType, 'info');
            } else {
              if (
                (sectionType == 'geographyMapping' ||
                  sectionType == 'physicianMapping') &&
                sortingOrderKey == ''
              ) {
                this.checkDynamicGeographyColumns(
                  response.data[0],
                  sectionType
                );
              }

              this[
                sectionType as keyof DimensionMappingComponent
              ].sortingOrder = sortingOrder;
              this[
                sectionType as keyof DimensionMappingComponent
              ].sortingOrderKey = sortingOrderKey;
              this[sectionType as keyof DimensionMappingComponent].isLoaded =
                true;
              this[sectionType as keyof DimensionMappingComponent].pageIndex =
                pageIndex;
              this[sectionType as keyof DimensionMappingComponent].gridData =
                response.data;
              this[sectionType as keyof DimensionMappingComponent].dataCount =
                response.count;
            }
          } else {
            this.handleError(SOMETHING_WENT_WRONG, sectionType, 'error');
          }
        },
        (error) => {
          this.handleError(SOMETHING_WENT_WRONG, sectionType, 'error');
        }
      );
  }

  restoreGridChangingSearchCreiteria(sectionType: any) {
    if (
      this[sectionType as keyof DimensionMappingComponent].previousSearchStatus
    ) {
      this[sectionType as keyof DimensionMappingComponent].searchText = '';
      this.fetchGridMappingData(sectionType, 1);
    } else {
      this[sectionType as keyof DimensionMappingComponent].searchText = '';
    }
  }

  checkDynamicGeographyColumns(response: any, sectionType: any) {
    if (sectionType == 'geographyMapping') {
      let defaultKeys = ['locationId', 'locationGroup1', 'state', 'zip'];
      this.geographyMapping.columns = JSON.parse(
        JSON.stringify(this.deepCopyOfGeographyMappingColumns)
      );
      if (Object.keys(response).length > 4) {
        for (const [key, value] of Object.entries(response)) {
          if (!defaultKeys.includes(key)) {
            let columnData = {
              name: key.charAt(0).toUpperCase() + key.slice(1),
              key: key,
              dataType: 'string',
              prefix: '',
              suffix: '',
              defaultSortOrder: null,
              sortDirections: ['ascend', 'descend'],
              width: '25%',
              align: 'left',
              showIcon: false,
              sortable: true,
              columnStyle: {
                overflow: 'hidden',
                'max-width': '25ch',
                'text-overflow': 'ellipsis',
                'white-space': 'nowrap',
              },
            };
            this.geographyMapping.columns.push(columnData);
          }
        }
      }
    } else if (sectionType == 'physicianMapping') {
      let defaultKeys = ['npiId'];
      this.physicianMapping.columns.splice(
        1,
        this.physicianMapping.columns.length - 1
      );
      if (Object.keys(response).length > 1) {
        for (const [key, value] of Object.entries(response)) {
          if (!defaultKeys.includes(key)) {
            let columnData = {
              name: key.charAt(0).toUpperCase() + key.slice(1),
              key: key,
              dataType: 'string',
              prefix: '',
              suffix: '',
              defaultSortOrder: null,
              sortDirections: ['ascend', 'descend'],
              width: '25%',
              align: 'left',
              showIcon: false,
              sortable: true,
              columnStyle: {
                overflow: 'hidden',
                'max-width': '25ch',
                'text-overflow': 'ellipsis',
                'white-space': 'nowrap',
              },
            };
            this.physicianMapping.columns.push(columnData);
          }
        }
      }
    }
  }

  fetchHistoryData(pageIndex = this.historyPageIndex) {
    this.isMyhistoryLoaded = false;
    let payload = { limit: 10, offset: (pageIndex - 1) * 10 };
    this.dimensionMappingService
      .putHistoryData(this.studyId, this.historySelectedSection, payload)
      .subscribe(
        (response) => {
          if (response.status === API_SUCCESS_KEY) {
            response.data.forEach((item: any) => {
              item['visibleActionIcons'] = ['download'];
              item['disabledActionIcons'] = [];
              item['formattedTimeStamp'] = new Date(item.timestamp * 1000);
            });
            this.historyData = response.data;
            this.historyPageIndex = pageIndex;
            this.historyDataCount = response.count;
            this.isMyhistoryLoaded = true;
          } else {
            this.isMyhistoryLoaded = true;
            this.handleError(SOMETHING_WENT_WRONG, 'history', 'error');
          }
        },
        (error) => {
          this.isMyhistoryLoaded = true;
          this.handleError(SOMETHING_WENT_WRONG, 'history', 'error');
        }
      );
  }

  downloadMappingVersions(
    e: any,
    mappingName: any,
    fileName: any,
    sectionType = 'historyLoader'
  ) {
    if (sectionType != 'historyLoader') {
      this[
        sectionType as keyof DimensionMappingComponent
      ].downloadLoadingStatus = true;
    } else {
      this.dimensionMappingLoaderStatus = true;
    }
    e && e.stopPropagation();
    this.dimensionMappingService
      .getDownloadMappingVersion(this.studyId, mappingName)
      .subscribe({
        next: (response) => {
          if (response.status) {
            if (sectionType != 'historyLoader') {
              this[
                sectionType as keyof DimensionMappingComponent
              ].downloadLoadingStatus = false;
            } else {
              this.dimensionMappingLoaderStatus = false;
            }
            const blob = this.base64ToBlob(response['data'], 'text/csv');
            saveAs(blob, `${fileName}.csv`);
          }
        },
      });
  }

  base64ToBlob(b64Data: any, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  uploadFile(event: any, sectionType: any, accordionTitle: any) {
    this[sectionType as keyof DimensionMappingComponent].uploadStatus = true;
    this.fileProcess = true;
    this.errorMessage = '';
    this.successMessage = '';
    event.cancelBubble = true;
    try {
      if (!this.validateFile(event)) {
        this[sectionType as keyof DimensionMappingComponent].uploadStatus =
          false;
        return;
      }

      const _file = event.target.files[0];
      const splittedFileName = _file.name.split('.');
      if (splittedFileName[splittedFileName.length - 1] === 'csv') {
        this.changeFile(_file).then((base64: any): any => {
          const base64File = base64;
          const payload = {
            table_name: accordionTitle,
            data: base64File.split(',')[1],
            study_id: this.studyId,
          };
          this.dimensionMappingService
            .postImportMapping(this.studyId, accordionTitle, payload)
            .subscribe({
              next: (res: any) => {
                if (res) {
                  this.successMessage = this.fileMessage.success;
                  this.fileProcess = false;
                  this[
                    sectionType as keyof DimensionMappingComponent
                  ].uploadStatus = false;
                  if (res.isUpload) {
                    this[
                      sectionType as keyof DimensionMappingComponent
                    ].uploadErrorStatus = true;
                    this[
                      sectionType as keyof DimensionMappingComponent
                    ].uploadErrorMessage = '';
                    this.commonService.createNotification(
                      'success',
                      res.message
                    );
                    this.fetchGridMappingData(sectionType, 1);
                  } else {
                    this[
                      sectionType as keyof DimensionMappingComponent
                    ].uploadStatus = false;
                    this[
                      sectionType as keyof DimensionMappingComponent
                    ].uploadErrorStatus = false;
                    this.commonService.createNotification('error', res.message);
                  }
                }
              },
              error: (error: any) => {
                this.fileProcess = false;
                this.errorMessage = error.error.message;
              },
            });
        });
      } else {
        this.fileProcess = false;
        this.errorMessage = this.fileMessage.csvExpected;
        this[sectionType as keyof DimensionMappingComponent].uploadStatus =
          false;
        this.commonService.createNotification(
          'error',
          this.fileMessage.csvExpected
        );
      }
    } catch (ex) {
      this.errorMessage = this.fileMessage.corruptedFile;
      this[sectionType as keyof DimensionMappingComponent].uploadStatus = false;
      this.commonService.createNotification(
        'error',
        this.fileMessage.corruptedFile
      );
    }
  }

  validateFile(uploadEvent: any) {
    if (
      uploadEvent.target.files[0].size > MAXIMUM_CSV_FILE_SIZE ||
      uploadEvent.target.files[0].size === 0
    ) {
      this.errorMessage =
        uploadEvent.target.files[0].size === 0
          ? this.fileMessage.corruptedFile
          : this.fileMessage.exceedSize;

      this.commonService.createNotification('error', this.errorMessage);
      uploadEvent.target.value = '';
      this.fileProcess = false;
      return false;
    }
    return true;
  }

  changeFile(_file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(_file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  resetFile($event: any) {
    $event.target.value = null;
  }

  preventEventBubbling(event: any) {
    event.stopPropagation();
  }

  historyTableActionIconClicked(actionIconClicked: any) {
    if (actionIconClicked.icon.key === 'download') {
      this.downloadMappingVersions(
        false,
        (actionIconClicked.data.mappingName == 'physician_specialty_mapping'
          ? 'physician_speciality_mapping'
          : actionIconClicked.data.mappingName) +
          '/' +
          actionIconClicked.data.mappingVersionId,
        actionIconClicked.data.mappingName +
          '-' +
          actionIconClicked.data.timestamp
      );
    }
  }

  clearSearch(sectionType: any) {
    this.elementRef.nativeElement.querySelector(
      'input[name="' + sectionType + 'SearchOptions' + '"]:checked'
    ).checked = false;
    this[sectionType as keyof DimensionMappingComponent].searchCriteria = '';
    this[sectionType as keyof DimensionMappingComponent].searchText = '';
    this.fetchGridMappingData(sectionType, 1);
  }

  close() {
    this.visible = false;
  }

  clickOutsideSearchClose(sectionType: any) {
    if (
      this[sectionType as keyof DimensionMappingComponent].searchToggleStatus ==
      true
    ) {
      this[sectionType as keyof DimensionMappingComponent].searchToggleStatus =
        false;
    }
  }

  searchToggle(sectionType: any) {
    this[sectionType as keyof DimensionMappingComponent].searchToggleStatus ==
      false &&
      setTimeout(() => {
        this[
          sectionType as keyof DimensionMappingComponent
        ].searchToggleStatus =
          !this[sectionType as keyof DimensionMappingComponent]
            .searchToggleStatus;
      }, 100);
  }

  openHistoryPopup(e: any, sectionType: any) {
    e.stopPropagation();
    this.visible = !this.visible;
    this.historySelectedSection = sectionType;
    this.fetchHistoryData(1);
  }

  executeJob() {
    this.dimensionMappingLoaderStatus = true;
    this.dimensionMappingService.postExecuteJob(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.fetchJobStatus();
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'navigationNext', 'error');
        }
      },
      (error) => {
        this.handleError(SOMETHING_WENT_WRONG, 'navigationNext', 'error');
      }
    );
  }

  fetchJobStatus() {
    this.dimensionMappingLoaderStatus = true;
    this.dimensionMappingService.getJobStatus(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          response.data.some((data: any) => {
            if (data.status != 'COMPLETED') {
              this.informationPopupStatus = true;
              this.dimensionMappingLoaderStatus = false;
              return true;
            }
          });
          if (!this.informationPopupStatus) {
            this.router.navigate(['/studies/episode-creation/' + this.studyId]);
            this.dimensionMappingLoaderStatus = false;
          }
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'navigationNext', 'error');
        }
      },
      (error) => {
        this.handleError(SOMETHING_WENT_WRONG, 'navigationNext', 'error');
      }
    );
  }

  findSearchTitleWrtSearchCrireria(sectionType: any, searchCriteriaName: any) {
    let searchCriteriaTitle = this[
      sectionType as keyof DimensionMappingComponent
    ].columns.find((data: any) => {
      return data.key == searchCriteriaName;
    });
    return searchCriteriaTitle.name;
  }

  formatString(data: any, pattern: any) {
    return data.replace('-', pattern);
  }

  handleError(errorMessage: string, api: string, errorType: any): void {
    switch (api) {
      case 'drugMapping':
        this.drugMapping.tableErrorType = errorType;
        this.drugMapping.isLoaded = true;
        this.drugMapping.gridData = [];
        this.drugMapping.tableErrorMessage = errorMessage;
        break;
      case 'diagnosisMapping':
        this.diagnosisMapping.tableErrorType = errorType;
        this.diagnosisMapping.isLoaded = true;
        this.diagnosisMapping.gridData = [];
        this.diagnosisMapping.tableErrorMessage = errorMessage;
        break;
      case 'procedureMapping':
        this.procedureMapping.tableErrorType = errorType;
        this.procedureMapping.isLoaded = true;
        this.procedureMapping.gridData = [];
        this.procedureMapping.tableErrorMessage = errorMessage;
        break;
      case 'physicianSpecialityMapping':
        this.physicianSpecialityMapping.tableErrorType = errorType;
        this.physicianSpecialityMapping.isLoaded = true;
        this.physicianSpecialityMapping.gridData = [];
        this.physicianSpecialityMapping.tableErrorMessage = errorMessage;
        break;
      case 'geographyMapping':
        this.geographyMapping.tableErrorType = errorType;
        this.geographyMapping.isLoaded = true;
        this.geographyMapping.gridData = [];
        this.geographyMapping.tableErrorMessage = errorMessage;
        break;
      case 'physicianMapping':
        this.physicianMapping.tableErrorType = errorType;
        this.physicianMapping.isLoaded = true;
        this.physicianMapping.gridData = [];
        this.physicianMapping.tableErrorMessage = errorMessage;
    }
  }

  navigateWrtUrl(routerLink: any, studyIdRequired: any = false) {
    if (studyIdRequired) {
      this.router.navigate([routerLink + this.studyId]);
    } else {
      this.router.navigate([routerLink]);
    }
  }

  setBreadCrumbForViewer() {
    let studyProgress;
    let index = 5;

    this.allBreadCrumbData = [
      {
        displayName: 'All Studies',
        navigationPath: '/home/',
        key: 'alStudies',
      },
      {
        displayName: 'Study Details',
        navigationPath: `/studies/edit/${this.studyId}`,
        key: 'studyDetails',
      },
      {
        displayName: 'Dimension Mapping',
        navigationPath: ``,
        key: 'dimensionMapping',
      },
      {
        displayName: 'Episode Creation',
        navigationPath: `/studies/episode-creation/${this.studyId}`,
        key: 'episodeCreation',
      },
      {
        displayName: 'Regimen & LoT',
        navigationPath: `/studies/regimen-lot/${this.studyId}`,
        key: 'regimenLot',
      },
      {
        displayName: 'Select Insights',
        navigationPath: `/studies/select-insights/${this.studyId}`,
        key: 'selectInsights',
      },
    ];

    this.commonService.getStudyProgress(this.studyId).subscribe((response) => {
      if (response.status === API_SUCCESS_KEY) {
        studyProgress = response.progress;

        if (studyProgress < 20) {
          index = 1;
        } else if (studyProgress === 20) {
          index = 2;
        } else if (studyProgress > 20 && studyProgress < 60) {
          index = 5;
        } else if (studyProgress >= 60) {
          index = 5;
        }

        this.allBreadCrumbData.forEach((item: any, counter: any) => {
          if (counter <= index) {
            this.viewerBreadCrumbData.push(item);
          }
        });
      }
    });
  }
}
