import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private appState = new BehaviorSubject<any>(null);


  appStateChange(): Observable<any> {
    return this.appState.asObservable();
  }

  constructor() {
    let globalState = sessionStorage.getItem('globalState');
    if (globalState === null || globalState === undefined) {
      globalState = JSON.parse('{}');
    } else {
      try {
        globalState = JSON.parse(globalState);
      } catch (e) {
        globalState = JSON.parse('{}');
      }
    }
    this.appState.next(globalState);
  }

  private parseAppState() {
    let localState = this.appState.getValue();
    if (localState === null || localState === undefined) {
      localState = {};
    } else {
      if (typeof (localState) === 'string') {
        try {
          localState = JSON.parse(localState);
        } catch (e) {
          localState = {};
        }
      } else {
        try {
          localState = JSON.stringify(localState);
          localState = JSON.parse(localState);
        } catch (e) {
          localState = {};
        }
      }
    }
    return localState;
  }

  // Exposed Functions --------------------------------------------------------------------- //
  public setGlobalState(key: string, value: any) {
    let tempState = this.parseAppState();
    try {
      tempState[key] = value;
    } catch (e) {
      tempState = {};
      tempState[key] = value;
    }
    window.sessionStorage.setItem('globalState', JSON.stringify(tempState));
    this.appState.next(tempState);
  }

  public removeGlobalState(key: string) {
    let tempState = this.parseAppState();
    delete tempState[key];
    this.appState.next(tempState);
    window.sessionStorage.setItem('globalState', JSON.stringify(tempState));
  }

  public clearGlobalState() {
    this.appState.next({});
    window.sessionStorage.clear();
  }
 }
