import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionTriggerService } from '../../services/action-trigger.service';
import {
  ALREADY_EXISTS,
  API_FAILURE_KEY,
  API_SUCCESS_KEY,
  SOMETHING_WENT_WRONG,
} from '../../constants/app-constants';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-clone-action-popup',
  templateUrl: './clone-action-popup.component.html',
  styleUrls: ['./clone-action-popup.component.scss'],
})
export class CloneActionPopupComponent implements OnInit {
  @Input() cloneBody: any;
  @Output() closeClonePopup: EventEmitter<string> = new EventEmitter();
  @Output() successClone: EventEmitter<boolean> = new EventEmitter();
  cloneForm!: FormGroup;
  triggerClone = 'trigger';
  isVisible: boolean = false;
  dataSource: Array<any> = [];
  studyDetailsData: any;
  dataSourceDefaultValue: any;
  cohortDefaultValue: any;
  cohortDetails: Array<any> = [];
  studyID: string = '';
  validationMessage: string = 'default';
  cloneResponse: any;
  showStudyTitleError = false;
  apiError = false;
  apiErrorMessage = '';
  isDataLoaded = false;
  disableCloneBtn = true;
  cohortLoaded = false;
  validateStudyTitleSubscription: any;
  validateStudyTitleLoadStatus = true;

  constructor(
    private fb: FormBuilder,
    private actionTriggerService: ActionTriggerService,
    private router: Router
  ) {}

  studyTitlePlaceholder = 'Enter New Study Title';
  isShowDiv = false;
  toggleName = "Import Custom Cohort"  
     
  toggleDisplayDiv() {  
    this.isShowDiv = !this.isShowDiv;
    this.toggleName = this.isShowDiv?"Select Existing Cohort":"Import Custom Cohort"
    
  }  

  ngOnInit() {
    this.cloneForm = this.fb.group({
      studyTitle: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern('^[A-Z].*$'),
        ],
      ],
      dataSource: [''],
      cohort: [''],
      cohortName: [''],
      cohortDescription: [''],
      s3Location: [''],
      dataSourceName: [''],
      s3Bucket: [''],
      s3Path: ['']
    });
    this.loadStudyDetailsandDataSource();
    this.cloneForm.controls['studyTitle'].valueChanges.subscribe((value) => {
      this.validateStudyTitle(value, false);
    });
  }

  loadStudyDetailsandDataSource() {
    this.dataSourceDefaultValue = '';
    if (this.cloneBody !== null) {
      this.isDataLoaded = false;
      this.studyID = this.cloneBody;
      this.apiError = false;
      this.getStudyDetailsAndDataSources();
      this.cloneBody = null;
    }
    this.showModal();
  }

  getStudyDetailsAndDataSources() {
    let getDataSource = this.actionTriggerService.getDataSource();
    let getStudyDetails = this.actionTriggerService.getStudyDetails(
      this.cloneBody
    );
    getDataSource
      .pipe(
        mergeMap((dataSourceResponse: any) => {
          if (dataSourceResponse.length > 0) {
            this.dataSource = dataSourceResponse;
          } else {
            this.handleError();
          }
          return getStudyDetails;
        })
      )
      .subscribe(
        (studyDetails: any) => {
          if (studyDetails.status === API_SUCCESS_KEY) {
            this.studyDetailsData = studyDetails.data;
            if (this.studyDetailsData.importedS3Location != '') {
              this.dataSource.push({
                dataSourceId: 0,
                dataSourceName: this.studyDetailsData.dataSourceName,
              });
              this.dataSourceDefaultValue = 0;
              this.cohortDefaultValue = 0;
            } else {
              this.dataSourceDefaultValue =
                this.studyDetailsData?.cbDataSourceId;
              this.cohortDefaultValue = this.studyDetailsData?.cbCohortId;
            }
            this.isDataLoaded = true;
          } else {
            this.handleError();
          }
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.handleError();
        }
      );
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(event: string): void {
    this.isVisible = false;
    this.closeClonePopup.emit(event);
  }

  onCloneClicked() {
    let requestPayload;
    // Regexp to match S3 bucket and Path in respective variables from String
    const regexS3Bucket: RegExp = /^(?:[^\/]*\/){2}([^\/]*)/;
    const regexS3Path: RegExp = /^(?:[^\/]*\/){3}(\S+)/;

    if(this.isShowDiv){
      const parsedS3Bucket: string[] = this.cloneForm.get('s3Location')?.value.match(regexS3Bucket)[1];
      const parsedS3Path: string[] = this.cloneForm.get('s3Location')?.value.match(regexS3Path)[1];
    const payload = {
      s3Location: this.cloneForm.get('s3Location')?.value
    };
    this.actionTriggerService.validateS3Location(payload).subscribe(
      (data) => {
        if (data.status === API_SUCCESS_KEY) {
          if (
            data?.dataSufficiency === true &&
            data?.tablesMissing?.length === 0
          ) {
            requestPayload = {
              title: this.cloneForm.get('studyTitle')?.value,
              cbCohortId: null,
              cohortName: this.cloneForm.get('cohortName')?.value,
              dataSourceName: this.cloneForm.get('dataSourceName')?.value,
              cbExportId: null,
              cbDataSourceId: null,
              globalConceptId: null,
              globalConceptName: null,
              importedS3Location: null,
              importedCohortDescription: this.cloneForm.get('cohortDescription')?.value,
              // importedS3Location: this.cloneForm.get('s3Location')?.value,
              cohortDescription: this.cloneForm.get('cohortDescription')?.value,
              s3CohortPath: parsedS3Bucket,
              cohortExportS3Path: parsedS3Path,
              isCohortChanged: true
            };
            this.cloneStudy(requestPayload);
          }
        }}
    );
    return;
      }
    else if (this.cloneForm.get('dataSource')?.value != 0) {
      const dataSourceIndex = this.dataSource.findIndex(
        (value: any) =>
          value.dataSourceId == this.cloneForm.get('dataSource')?.value
      );
      const cohortIndex = this.cohortDetails.findIndex(
        (value: any) => value.cohortId === this.cloneForm.get('cohort')?.value
      );
      let dataSourceChanged = !(
        this.studyDetailsData.cbDataSourceId ==
        this.dataSource[dataSourceIndex].dataSourceId
      );
      let cohortFieldChanged = !(
        this.studyDetailsData.cbCohortId === this.cloneForm.get('cohort')?.value
      );
      let cohortChanged = dataSourceChanged || cohortFieldChanged;
      this.apiError = false;
      requestPayload = {
        title: this.cloneForm.get('studyTitle')?.value,
        cbCohortId: this.cloneForm.get('cohort')?.value,
        cohortName: this.cohortDetails[cohortIndex].cohortName,
        dataSourceName: this.dataSource[dataSourceIndex].dataSourceName,
        cbExportId: this.cohortDetails[cohortIndex].cohortExport[0].version,
        cbDataSourceId: this.dataSource[dataSourceIndex].dataSourceId,
        globalConceptId:
          this.cohortDetails[cohortIndex].cohortExport[0].globalConceptSet[0]
            .conceptSetId,
        globalConceptName:
          this.cohortDetails[cohortIndex].cohortExport[0].globalConceptSet[0]
            .conceptSetName,
        importedS3Location: '',
        cohortDescription: this.cohortDetails[cohortIndex].cohortDescription,
        s3CohortPath:
          this.cohortDetails[cohortIndex].cohortExport[0].dataSourceList[0]
            .s3bucket,
        cohortExportS3Path:
          this.cohortDetails[cohortIndex].cohortExport[0].dataSourceList[0]
            .cohortExports3Path,
        isCohortChanged: cohortChanged,
      };
    } else {
      requestPayload = {
        title: this.cloneForm.get('studyTitle')?.value,
        cbCohortId: null,
        cohortName: this.studyDetailsData.cohortName,
        dataSourceName: this.studyDetailsData.dataSourceName,
        cbExportId: null,
        cbDataSourceId: null,
        globalConceptId: null,
        globalConceptName: null,
        importedS3Location: this.studyDetailsData.importedS3Location,
        cohortDescription: this.studyDetailsData.importedCohortDescription,
        s3CohortPath: null,
        cohortExportS3Path: null,
        isCohortChanged: false,
      };
    }
    this.cloneStudy(requestPayload);
    
  }

  cloneStudy(requestPayload: any) {
    this.actionTriggerService
    .getCloneStudy(requestPayload, this.studyID)
    .subscribe((data) => {
      this.cloneResponse = data;
      this.successClone.emit(true);
      if (data.status === API_SUCCESS_KEY) {
        this.handleCancel(API_SUCCESS_KEY);
      } else {
        this.handleError();
        return;
      }
    });
  }

  getCohortDetails($event: any) {
    this.cohortLoaded = false;
    if ($event == 0) {
      this.cohortDetails = [
        {
          cohortName: this.studyDetailsData.cohortName,
          cohortId: 0,
        },
      ];
      this.cohortDefaultValue = 0;
      this.cohortLoaded = true;
      return;
    }
    this.apiError = false;
    this.actionTriggerService.getCohort($event).subscribe((data) => {
      this.cohortDetails = data;
      let index = data.findIndex((data: any) => {
        return data.cohortId == this.cohortDefaultValue;
      });
      this.cohortDefaultValue =
        index != -1 ? this.cohortDefaultValue : this.cohortDetails[0].cohortId;
      this.cohortLoaded = true;
    });
  }

  validateStudyTitle(event: any, isRawData: any) {
    let studyTitleName = isRawData ? event.target.value : event;
    let payload = { studyTitle: studyTitleName.trim() };
    let control = this.cloneForm.get('studyTitle');
    this.validateStudyTitleLoadStatus = true;
    if (this.cloneForm.get('studyTitle')?.invalid) {
      this.validateStudyTitleLoadStatus = false;
      return false;
    }
    if (this.validateStudyTitleSubscription) {
      this.validateStudyTitleSubscription.unsubscribe();
    }
    this.validateStudyTitleSubscription = this.actionTriggerService
      .validateStudyTitle(payload)
      .subscribe((data) => {
        this.validateStudyTitleLoadStatus = false;
        if (data.isTitleDuplicate === true) {
          control?.setErrors({ validStudyTitle: true });
        } else {
          if (this.cloneForm.get('studyTitle')?.hasError('validStudyTitle')) {
            delete this.cloneForm.get('studyTitle')?.errors?.validStudyTitle;
            this.cloneForm.get('studyTitle')?.updateValueAndValidity();
          }
        }
      });
  }

  keyPressAlphaNumeric(event: any): boolean {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  allowAlphaNumeric(event: any, regexFormat: any) {
    let regex = new RegExp(regexFormat, 'i');
    if (event.type == 'paste') {
      let clipboardData = event.clipboardData.getData('text');
      if (regex.test(clipboardData)) {
        return true;
      } else {
        return false;
      }
    }
    if (regex.test(event.key)) {
      return true;
    } else {
      return false;
    }
  }

  allowAlphaNumericOnPaste(event: any, firstLetterUppercase = false) {
    if (event.type == 'paste') {
      let clipboardData = event.clipboardData.getData('text');
      if (!/^[0-9a-zA-Z_ ]+$/.test(clipboardData)) {
        return false;
      } else {
        return true;
      }
    }
  }

  private handleError() {
    this.isDataLoaded = true;
    this.apiError = true;
    this.apiErrorMessage = SOMETHING_WENT_WRONG;
    this.handleCancel(API_FAILURE_KEY);
  }

  alertClosed($event: any) {
    this.apiError = false;
  }
}
