export class ExportModel {
  status: string;
  data: [ExportData];

  constructor(response: [ExportData], status: string) {
    this.data = response;
    this.status = status;
  }
}

export class ExportData {
  fileName: string;
  filePath: string[];

  constructor(fileName: string, filePath: string[]) {
    this.fileName = fileName;
    this.filePath = filePath;
  }
}
