import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { consolidatedBaseUrl } from './baseUrls';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SelectInsightsService {
  private endpoint: string;
  private httpOptions: any;
  constructor(
    private transactionManager: TransactionManagerService,
    private http: HttpClient,
    private router: Router
  ) {
    this.httpOptions = {
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    this.endpoint = environment.serverUrl;
  }

  getUserRole(studyId: any): Observable<any> {
    return this.transactionManager.get('study/' + studyId + '/user-role').pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getProgressStatus(studyId: any): Observable<any> {
    return this.transactionManager.get('study/' + studyId + '/progress').pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getStudyConfig(studyId: any): Observable<any> {
    return this.transactionManager
      .post('study/' + studyId + '/config', { isptj: true })
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getInsightsData(studyId: any): Observable<any> {
    return this.transactionManager
      .get('visualizations/' + studyId + '/kpi')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  setTimeFrameDates(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .patch('study/' + studyId + '/config', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  saveInsightsData(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('visualizations/' + studyId + '/kpi', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  triggerVisualizationJob(studyId: any): Observable<any> {
    return this.transactionManager
      .post('visualizations/' + studyId + '/execute?force_execution=true', {
        username: null,
      })
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getJobStatus(studyId: any): Observable<any> {
    return this.transactionManager
      .get(
        '/study/' +
          studyId +
          '/job-status?job_type=parameter&job_category=regimen-distribution,regimen-drop,regimen-merge,line-of-therapy,stage-identification&job_status=running,pending,completed'
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  errorHandler(e: any = ''): any {
    if (e.error == 'Study access issue') {
      this.router.navigate(['studies/error/access_issue']);
    }
    return { status: API_FAILURE_KEY };
  }
}
