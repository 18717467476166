import { Component, OnInit } from '@angular/core';
import { ACCESS_ISSUE } from '../../constants/app-constants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  readonly accessIssue = ACCESS_ISSUE;
  constructor() {}
}
