import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { API_LIST } from '../constants/api-list';
import { map } from 'rxjs/operators';
import { RecentStudies } from '../models/dashboard.model';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private transactionManager: TransactionManagerService) {}

  getStudies(payload: any): Observable<any> {
    return this.transactionManager.post(API_LIST.myStudies, payload).pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler();
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getRecentStudies(payload: any): Observable<RecentStudies> {
    return this.transactionManager.post(API_LIST.recentStudies, payload).pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return new RecentStudies(
              response.data,
              response.status,
              response.noOfStudies
            );
          } else {
            return this.errorHandler();
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getDashboardDetails(): Observable<any> {
    return this.transactionManager.get(API_LIST.dashboardDetails).pipe(
      map((response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          return response;
        } else {
          return this.errorHandler();
        }
      })
    );
  }

  errorHandler(e: any = ''): any {
    // eslint-disable-next-line no-console
    console.log(e.toString());
    return { status: API_FAILURE_KEY };
  }
}
