import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { API_LIST } from '../constants/api-list';
import { map } from 'rxjs/operators';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';
import { SensitiveInfoService } from './sensitive-info.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { consolidatedBaseUrl } from './baseUrls';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DimensionMappingService {
  constructor(
    private transactionManager: TransactionManagerService,
    private router: Router
  ) {}

  getUserRole(studyId: any): Observable<any> {
    return this.transactionManager.get('study/' + studyId + '/user-role').pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getProgressStatus(studyId: any): Observable<any> {
    return this.transactionManager.get('study/' + studyId + '/progress').pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getPhysicianMappingVisibilityStatus(studyId: any): Observable<any> {
    return this.transactionManager
      .get('study/' + studyId + '/physician-mapping-visibility')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postMappingData(studyId: any, payload: any): Observable<any> {
    return this.transactionManager.post('mapping/' + studyId, payload).pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  putHistoryData(
    studyId: any,
    mappingName: any,
    payload: any
  ): Observable<any> {
    return this.transactionManager
      .put(
        'mapping/' + studyId + '/versions?mapping_name=' + mappingName,
        payload
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getDownloadMappingVersion(studyId: any, mappingName: any): Observable<any> {
    return this.transactionManager
      .get('mapping/' + studyId + '/download/' + mappingName)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postImportMapping(
    studyId: any,
    mappingName: any,
    payload: any
  ): Observable<any> {
    return this.transactionManager
      .post('mapping/' + studyId + '/upload/' + mappingName, payload)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postExecuteJob(studyId: any): Observable<any> {
    let payload = {
      runEmr: true,
    };
    return this.transactionManager
      .post('parameter/' + studyId + '/execute', payload)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getJobStatus(studyId: any): Observable<any> {
    return this.transactionManager
      .get(
        '/study/' +
          studyId +
          '/job-status?job_type=parameter&job_category=dos-imputation,grace-period-analysis&job_status=running,pending,completed,failed'
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  errorHandler(e: any = ''): any {
    if (e.error == 'Study access issue') {
      this.router.navigate(['studies/error/access_issue']);
    }
    return { status: API_FAILURE_KEY };
  }
}
