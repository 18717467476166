import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateStudyDetailComponent } from './create-study-detail.component';
import { DimensionMappingComponent } from './dimension-mapping/dimension-mapping.component';
import { EpisodeCreationComponent } from './episode-creation/episode-creation.component';
import { EpisodeCreationGuard } from './episode-creation/episode-creation.guard';
import { RegimenLotGuard } from './regimen-lot/guard/regimen-lot.guard';
import { RegimenLotComponent } from './regimen-lot/regimen-lot.component';
import { SelectInsightsComponent } from './select-insights/select-insights.component';

const routes: Routes = [
  {
    path: '',
    component: CreateStudyDetailComponent,
    children: [
      { path: '', redirectTo: 'dimension-mapping/:studyId', pathMatch: 'full' },
      {
        path: 'dimension-mapping/:studyId',
        component: DimensionMappingComponent,
      },
      {
        path: 'episode-creation/:studyId',
        component: EpisodeCreationComponent,
        canDeactivate: [EpisodeCreationGuard],
      },
      {
        path: 'regimen-lot/:studyId',
        component: RegimenLotComponent,
        canDeactivate: [RegimenLotGuard],
      },
      { path: 'select-insights/:studyId', component: SelectInsightsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreateStudyDetailRoutingModule {}
