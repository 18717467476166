export interface RuleCategory {
  name: string;
  key: string;
  rules: Rule[];
  stage?: string;
}

export interface Rule {
  type: 'template' | 'template-copy' | 'custom' | 'existing';
  template_type: number;
  id: string;
  priority: number;
  selected?: boolean;
  error?: boolean;
  text: string;
  textPlus: RuleTextPlus[];
  rule: RuleComponent[];
}

export interface RuleComponent {
  key?: string;
  value: string;
  options: string[];
  element: 'selectable' | 'input' | 'static';
  metadata?: string[];
  operator?: string;
  regimen_days?: number;
  data: string | number | null;
  exists?: boolean;
  required?: boolean;
}

export interface RuleTextPlus {
  text: string;
  metadata: string;
}

export const defaultCustomRuleComponents = (): RuleComponent[] => [
  {
    value: '',
    options: ['first', 'last', 'nth'],
    element: 'selectable',
    exists: true,
    data: null,
    key: 'occurrence1',
    required: true,
  },
  {
    value: 'occurrence of',
    options: [],
    element: 'static',
    exists: true,
    key: 'occurrence_of_1',
    data: null,
  },
  {
    value: '',
    options: ['treatment', 'diagnosis', 'procedure'],
    element: 'selectable',
    exists: true,
    key: 'event1',
    data: null,
    required: true,
  },
  {
    value: '',
    options: ['before', 'after'],
    element: 'selectable',
    exists: true,
    key: 'occurrence_time',
    data: null,
    required: true,
  },
  {
    value: '',
    options: ['first', 'last', 'nth'],
    element: 'selectable',
    exists: true,
    key: 'occurrence2',
    data: null,
    required: true,
  },
  {
    value: 'occurrence of',
    options: [],
    element: 'static',
    exists: true,
    key: 'occurrence_of_2',
    data: null,
  },
  {
    value: '',
    options: ['treatment', 'diagnosis', 'procedure'],
    element: 'selectable',
    exists: true,
    key: 'event2',
    data: null,
    required: true,
  },
  {
    value: 'and',
    options: [],
    element: 'static',
    exists: false,
    key: 'and',
    data: null,
  },
  {
    value: '',
    options: ['within', 'beyond'],
    element: 'selectable',
    exists: false,
    key: 'condition',
    data: null,
  },
  {
    value: 'gap of',
    options: [],
    element: 'static',
    exists: false,
    key: 'gap_of',
    data: null,
  },
  {
    value: '',
    options: [],
    element: 'input',
    exists: false,
    key: 'gap_days',
    data: null,
  },
  {
    value: 'days',
    options: [],
    element: 'static',
    exists: false,
    key: 'days',
    data: null,
  },
];

export const ruleCategories = () => ({
  'Neo-Adjuvant': 'Start of Neo-Adjuvant Stage is',
  Adjuvant: 'Start of Adjuvant Stage is',
  Metastatic: 'Start of Metastatic Stage is',
});
export interface StageIdentificationRulesApiResponse {
  status: string;
  data: {
    [stage: string]: {
      rule_stage: string;
      stage: string;
      rules: Rule[];
    };
  };
}

export interface StageIdentificationSaveRulesApiPayload {
  studyId: number;
  rules: {
    [stage: string]: {
      rule_stage: string;
      stage: string;
      rules: Rule[];
    };
  };
}
