<rd-drawer
  [title]="'Select ' + ruleType | titlecase"
  [isVisible]="show"
  [width]="500"
  [footer]="footer"
  [maskClosable]="false"
  (drawerClose)="drawerClose.emit()"
>
  <div class="select-subclass__content">
    <div class="input--search" *ngIf="listFetchStatus === 'available'">
      <nz-input-group nzSearch [nzSuffix]="suffixIconSearch">
        <input
          type="text"
          nz-input
          placeholder="Search"
          [(ngModel)]="searchText"
        />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </div>

    <rd-alert
      *ngIf="listFetchStatus === 'error'"
      type="error"
      [closable]="false"
      [message]="errorMessage"
      [showIcon]="true"
    ></rd-alert>

    <div class="loading-spinner" *ngIf="listFetchStatus === 'loading'">
      <rd-spinner></rd-spinner>
    </div>

    <ng-container *ngIf="listFetchStatus === 'available'">
      <div class="table-container">
        <nz-table
          *ngIf="
            (listOfValues | searchFilter: searchText).length;
            else noDataMessage
          "
          nzSize="middle"
          [nzData]="listOfValues"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          [nzScroll]="{
            y: ruleType.toLowerCase() === 'treatment' ? '376px' : '470px'
          }"
        >
          <thead>
            <tr>
              <th>
                <div class="subclass-row">
                  <label
                    nz-checkbox
                    [nzIndeterminate]="indeterminate"
                    [(ngModel)]="allChecked"
                    (ngModelChange)="handleSelectAllToggle()"
                  ></label>
                  <span>Select All</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let subClass of listOfValues | searchFilter: searchText"
            >
              <td>
                <div class="subclass-row">
                  <label
                    nz-checkbox
                    [ngModel]="selectedListOfValues.includes(subClass)"
                    (ngModelChange)="handleSingleChecked($event, subClass)"
                  ></label>
                  <span>{{ subClass }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>

      <div class="regimen-days" *ngIf="ruleType.toLowerCase() === 'treatment'">
        <label>Duration of Therapy</label>
        <div class="regimen-days__input">
          <nz-select
            nzPlaceHolder="Select"
            [nzAllowClear]="true"
            [style]="{ width: '85px' }"
            [(ngModel)]="regimenDaysData.operatorSelected"
          >
            <nz-option
              *ngFor="let option of regimenDaysData.operatorOptions"
              [nzLabel]="option"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
          <nz-input-number
            [(ngModel)]="regimenDaysData.value"
            [nzMin]="1"
            [nzStep]="1"
          ></nz-input-number>
        </div>
      </div>
    </ng-container>
  </div>
</rd-drawer>

<ng-template #footer>
  <div class="select-subclass__footer">
    <rd-button
      btnType="default"
      btnText="Cancel"
      [btnStyle]="{ margin: 0 }"
      (btnClick)="drawerClose.emit()"
    ></rd-button>
    <rd-button
      btnType="primary"
      btnText="Save"
      [btnStyle]="{ margin: 0 }"
      [isDisabled]="!selectedListOfValues.length"
      (btnClick)="handleSaveButtonClick()"
    ></rd-button>
  </div>
</ng-template>

<ng-template #noDataMessage>
  <p>
    {{
      searchText ? 'No results for "' + searchText + '".' : 'No data available.'
    }}
  </p>
</ng-template>
