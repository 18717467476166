import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionConfirmationPopupComponent } from "../action-confirmation-popup/action-confirmation-popup.component";
import { CloneActionPopupComponent } from "./clone-action-popup.component";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSelectModule } from "ng-zorro-antd/select";
import { RDButtonModule, RDSpinnerModule } from "@zs-ca/angular-cd-library";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from "ng-zorro-antd/input";
import { AlertModule } from "../alert/alert.module";

@NgModule({
    declarations: [
    CloneActionPopupComponent
  ],
  exports: [
    CloneActionPopupComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    NzFormModule,
    ReactiveFormsModule,
    NzGridModule,
    NzSelectModule,
    FormsModule,
    RDButtonModule,
    RDSpinnerModule,
    NzPopoverModule,
    NzIconModule,
    NzInputModule,
    AlertModule
  ]
})
export class CloneActionPopupModule { }
