import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  StageIdentificationRulesApiResponse,
  StageIdentificationSaveRulesApiPayload,
} from 'src/app/main/create-study-detail/regimen-lot/stage-identification/stage-identification.model';
import { environment } from '../../../environments/environment';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';
import { TransactionManagerService } from './transaction-manager.service';

@Injectable({
  providedIn: 'root',
})
export class RegimenLotService {
  private endpoint: string;
  private httpOptions: any;
  constructor(
    private transactionManager: TransactionManagerService,
    private http: HttpClient,
    private router: Router
  ) {
    this.httpOptions = {
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    this.endpoint = environment.serverUrl;
  }

  getUserRole(studyId: any): Observable<any> {
    return this.transactionManager.get('study/' + studyId + '/user-role').pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getProgressStatus(studyId: any): Observable<any> {
    return this.transactionManager.get('study/' + studyId + '/progress').pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getJobStatus(studyId: any): Observable<any> {
    return this.transactionManager
      .get(
        '/study/' +
          studyId +
          '/job-status?job_type=parameter&job_category=regimen-distribution,regimen-drop,regimen-merge,line-of-therapy,stage-identification&job_status=running,pending,completed'
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postTypeOptionsList(
    studyId: any,
    typeValue: any,
    payload: any
  ): Observable<any> {
    return this.transactionManager
      .post('/regimen/' + studyId + '/stages/?type=' + typeValue, payload)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  saveStageIdentification(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('/regimen/' + studyId + '/stage-identification', payload)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  // TODO: Remove
  getSavedStageIdentificationRules(studyId: any): Observable<any> {
    return this.transactionManager
      .get('/regimen/' + studyId + '/stage-identification')
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postJobExecute(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('/parameter/' + studyId + '/stage-identification/execute/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postConfig(studyId: any): Observable<any> {
    return this.transactionManager
      .post('study/' + studyId + '/config', { isptj: true })
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getExport(studyId: any): Observable<any> {
    return this.transactionManager
      .get(
        '/visualizations/' +
          studyId +
          '/export?visualization_name=regimen-and-lot'
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getDownloadRegimenSummary(studyId: any): Observable<any> {
    return this.transactionManager
      .get('/regimen/' + studyId + '/download/summary/')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }
  getImportDownloadTemplate(): Observable<any> {
    return this.transactionManager
      .get('regimen/download-template/stage-identification')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postLotJobExecute(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('/parameter/' + studyId + '/lines-of-therapy/execute/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getRegimenConditioningDrugsAndProductsList(studyId: any): Observable<any> {
    return this.transactionManager
      .get('/regimen/' + studyId + '/drug-and-product/')
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  submitImportS3Link(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('regimen/' + studyId + '/stage-identification', payload)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getRegimenConditioningDownloadTemplate(studyId: any): Observable<any> {
    return this.transactionManager
      .get('/regimen/download-template/regimen-exception-rule/')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getRegimenMergeSummary(studyId: any): Observable<any> {
    return this.transactionManager
      .get('/regimen/' + studyId + '/merge-summary/')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getCheckRegimenDrop(
    studyId: any,
    selectedProduct: any,
    cutOfValue: any
  ): Observable<any> {
    return this.transactionManager
      .get(
        '/parameter/' +
          studyId +
          '/regimen-selected-cutoff?products=' +
          selectedProduct +
          '&regimen_length=' +
          cutOfValue
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getOverallRegimenLengthDistribution(studyId: any, cutOfDays: any) {
    return this.transactionManager
      .get(
        '/parameter/' +
          studyId +
          '/regimen-distribution?regimen_drop_days=' +
          cutOfDays
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  patchApplyRegimenRefinementOne(studyId: any, payload: any) {
    return this.transactionManager
      .patch('/study/' + studyId + '/config', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postApplyRegimenRefinementTwo(studyId: any, payload: any) {
    return this.transactionManager
      .post('/parameter/' + studyId + '/regimen-drop/execute/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postRegimenConditioningUploadFile(
    studyId: any,
    payload: any
  ): Observable<any> {
    return this.transactionManager
      .post('/regimen/' + studyId + '/upload/regimen-exception-rule/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getExportData(studyId: any): Observable<any> {
    return this.transactionManager
      .get(
        '/visualizations/' +
          studyId +
          '/export?visualization_name=regimen-and-lot'
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getRegimenConditioningSavedRules(studyId: any): Observable<any> {
    return this.transactionManager
      .get('/regimen/' + studyId + '/regimen-merge-filter/')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  saveRegimenMergeFilters(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('/regimen/' + studyId + '/regimen-merge-filter/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  triggerApplyRulesJob(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('/parameter/' + studyId + '/regimen-merge/execute/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  errorHandler(e: any = ''): any {
    if (e.error == 'Study access issue') {
      this.router.navigate(['studies/error/access_issue']);
    }
    return { status: API_FAILURE_KEY };
  }
}
