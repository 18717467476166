import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  API_FAILURE_KEY,
  API_SUCCESS_KEY,
  APP_STATE_KEYS,
  CLOSE_POPUP,
  NO_RECORDS,
  PROJECT_NOT_SELECTED,
  SOMETHING_WENT_WRONG,
} from '../../shared/constants/app-constants';
import { DashboardService } from '../../shared/services/dashboard.service';
import { ActionTriggerService } from '../../shared/services/action-trigger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from '../../shared/services/app-state.service';
import { CommonService } from '../../shared/services/common.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('refreshSuccess', { read: TemplateRef }) refreshSuccess: any;
  isRecentStudiesLoaded = false;

  confirmationConfig: any;

  recentStudiesColumns = [
    {
      name: 'Study Title',
      key: 'studyTitle',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Data Source',
      key: 'dataSource',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '35%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Therapy Area',
      key: 'therapyArea',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Date Created',
      key: 'createdOn',
      dataType: 'date',
      dateFormat: 'd MMM yyyy',
      prefix: '',
      suffix: '',
      defaultSortOrder: 'desc',
      sortDirections: ['ascend', 'descend'],
      width: '20%',
      align: 'left',
      showIcon: true,
      sortable: true,
    },
    {
      name: 'Progress',
      key: 'progress',
      dataType: 'number',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '20%',
      align: 'left',
      showIcon: false,
      sortable: false,
      type: 'progress',
      progressBarColor: '#52c41a',
    },
    {
      name: 'Actions',
      key: 'actions',
      dataType: 'string',
      defaultSortOrder: 'desc',
      sortDirections: ['ascend', 'descend'],
      width: '30%',
      showIcon: false,
      type: 'actions',
      align: 'center',
      sortable: false,
      actionIcons: [
        {
          type: 'custom:view-icon',
          key: 'view',
          title: 'View',
          theme: 'fill',
        },
        {
          type: 'custom:edit-icon',
          key: 'edit',
          title: 'Edit',
          theme: 'outline',
        },
        {
          type: 'custom:copy-icon',
          key: 'clone',
          title: 'Clone',
          theme: 'fill',
        },
        {
          type: 'custom:refresh-icon',
          key: 'refresh',
          title: 'Refresh',
          theme: 'outline',
        },
        {
          type: 'custom:download-icon',
          key: 'export',
          title: 'Export',
          theme: 'outline',
        },
        {
          type: 'custom:delete-icon',
          key: 'delete',
          title: 'Delete',
          theme: 'fill',
        },
      ],
    },
  ];

  recentStudiesData: Array<any> = [];

  recentStudiesScrollDetails = { x: '1250px', y: '35vh' };

  defaultPageSize = 10;

  selectedProjectId = 1;

  totalNoOfStudies = this.defaultPageSize;

  pageIndex = 1;

  limit = this.defaultPageSize;

  orderBy = 'createdOn';

  orderType = 'desc';

  tableErrorType: any;

  tableErrorMessage: any;

  kpiDataErrorMessage = '';

  kpiDataError = false;

  kpiDataLoaded = false;

  totalStudiesPerformed: any = '-';

  studiesWithCollaborations: any = '-';

  exportBody: any;

  visibleActionButtonArray = {
    owner: ['edit', 'clone', 'refresh', 'export', 'delete'],
    viewer: ['view', 'clone', 'export'],
    collaborator: ['edit', 'clone', 'refresh', 'export'],
  };

  visibleActionButtonArrayAllStudies = ['view'];

  disabledActionButtonArray = {
    isCloning: ['edit', 'clone', 'refresh', 'delete'],
    isRefresh: ['edit', 'clone', 'refresh', 'export', 'delete'],
    isProgressLess: ['export'],
    canRefresh: ['refresh'],
    isProgressLessTwenty: ['clone'],
  };
  showPopup = false;
  actionButtonStudyId = '';
  showExportPopup = false;
  showClonePopup = false;
  cloneBody: any = null;
  alertErrorMessage: any;
  showErrorAlert = false;
  alertType: any;
  showRefreshSuccess = false;
  searchText = '';
  studiesType = '';
  objectKeys = Object.keys;

  getStudiesSubscription: any;
  tileStyle = {
    width: '100%',
    background: '#fff',
    border: '1px solid #f4f3f3',
    boxSizing: 'border-box',
    boxShadow: '2px 2px 0px rgba(26, 22, 40, 0.12)',
    borderRadius: '2px',
    padding: 0,
  };

  kpiDataArray = [
    {
      key: 'myStudiesCount',
      text: 'My Studies',
      isSelected: false,
      value: '-',
      routerLink: '/home/my-studies',
    },
    {
      key: 'teamStudiesCount',
      text: 'Shared Studies',
      isSelected: false,
      value: '-',
      routerLink: '/home/shared-studies',
    },
    {
      key: 'allStudiesCount',
      text: 'All Studies',
      isSelected: true,
      value: '-',
      routerLink: '/home',
    },
  ];

  constructor(
    private dashboardService: DashboardService,
    private actionTriggerService: ActionTriggerService,
    private router: Router,
    private appState: AppStateService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    this.selectedProjectId = this.cookieService.get('project_id')
    this.fetchKpiData();
    this.route.paramMap.subscribe((params: any) => {
      let value = params.get('studiesType');
      this.studiesType =
        value == 'my-studies'
          ? 'myStudies'
          : value == 'shared-studies'
          ? 'teamStudies'
          : 'allStudies';
      this.pageIndex = 1;
      this.fetchRecentStudies();
      this.kpiDataArray.map((data) => {
        if (this.studiesType + 'Count' == data.key) {
          data.isSelected = true;
        } else {
          data.isSelected = false;
        }
      });
    });
    this.recentStudiesScrollDetails.y =
      JSON.stringify(Math.round(window.innerHeight) - 400) + 'px';
  }

  projectIdMatchesCookies(){
    return this.selectedProjectId == this.cookieService.get('project_id')
  }

  fetchKpiData(): void {
    this.kpiDataLoaded = false;

    this.kpiDataError = false;

    this.dashboardService.getDashboardDetails().subscribe(
      (response) => {
        if (response.status === API_SUCCESS_KEY) {
          this.kpiDataArray.forEach((kpi: any) => {
            kpi.value =
              response.data[kpi.key] > 0 ? response.data[kpi.key] : '-';
          });

          this.kpiDataLoaded = true;
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'kpiData');
        }
      },
      (error) => {
        // eslint-disable-next-line no-console
        //if the error is 428, that means project is not selected
        if (error.status == 428) {
          //Display error that project is not selected
          this.handleError(PROJECT_NOT_SELECTED, 'kpiData');
          // Redirect to PAC HOME
          window.location.replace(environment.pacHomeURL);
        } else {
          console.log(error);
          this.handleError(SOMETHING_WENT_WRONG, 'kpiData');
        }
      }
    );
  }

  fetchRecentStudies(event = this.searchText): void {
    this.isRecentStudiesLoaded = false;
    if(!this.projectIdMatchesCookies()){
      window.location.reload();
    }
    const payload = {
      limit: this.limit,
      offset: this.pageIndex * this.defaultPageSize - this.defaultPageSize,
      orderBy: this.orderBy,
      orderType: this.orderType,
      screen: this.studiesType ? this.studiesType : 'allStudies',
      searchText: event,
    };
    if (this.getStudiesSubscription) {
      this.getStudiesSubscription.unsubscribe();
    }

    this.getStudiesSubscription = this.dashboardService
      .getStudies(payload)
      .subscribe(
        (response) => {
          if (response.status === API_SUCCESS_KEY) {
            this.totalNoOfStudies = response.noOfStudies;
            if (response.noOfStudies > 0) {
              this.recentStudiesData = this.formatRecentStudiesData(
                response.data
              );

              this.isRecentStudiesLoaded = true;
            } else {
              this.tableErrorType = 'info';
              this.recentStudiesData = [];
              this.handleError(NO_RECORDS, 'recentStudies');
              this.isRecentStudiesLoaded = true;
            }
          } else {
            this.tableErrorType = 'error';
            this.handleError(SOMETHING_WENT_WRONG, 'recentStudies');
          }
        },
        (error) => {
          // eslint-disable-next-line no-console
          if (error.status == 428) {
            //Display error that project is not selected
            this.handleError(PROJECT_NOT_SELECTED, 'recentStudies');
            // Redirect to PAC HOME
            window.location.replace(environment.pacHomeURL);
          } else {
            console.log(error);
            this.tableErrorType = 'error';
            this.handleError(SOMETHING_WENT_WRONG, 'recentStudies');
          }
        }
      );
  }

  navigateUrl(url: any) {
    this.router.navigate([url]);
  }

  onRecentStudiesTablePaginationChange(pageClicked: any) {
    this.pageIndex = pageClicked;
    this.fetchRecentStudies();
  }

  onRecentStudiesTableSort(columnClicked: any) {
    this.orderType = columnClicked.defaultSortOrder;
    this.orderBy = columnClicked.key;
    this.pageIndex = 1;
    this.fetchRecentStudies();
  }

  recentStudiesTableActionIconClicked(actionIconClicked: any) {
    if (actionIconClicked.icon.key === 'refresh') {
      this.showPopup = true;
      this.confirmationConfig = {
        header: 'Confirm refresh',
        message:
          'This option will refresh all the data for a study. Are you sure you want to update it?',
        confirmationButtonName: 'Yes',
        cancelButtonName: 'No',
        key: 'Refresh',
      };
    } else if (actionIconClicked.icon.key === 'delete') {
      this.showPopup = true;
      this.confirmationConfig = {
        header: 'Confirm delete',
        message:
          'This option will permanently delete the study. Are you sure you want to delete it?',
        confirmationButtonName: 'Yes',
        cancelButtonName: 'No',
        key: 'Delete',
      };
    } else if (actionIconClicked.icon.key === 'export') {
      this.actionTriggerService
        .getExportData(actionIconClicked.data.studyId)
        .subscribe((data) => {
          if (data.status === API_SUCCESS_KEY) {
            data.data.map((item: any) => {
              item.filename = item.filename + ' :';
              return item;
            });
            this.exportBody = data;
            this.showExportPopup = true;
          } else {
            this.alertErrorMessage = SOMETHING_WENT_WRONG;
            this.alertType = 'error';
            this.showErrorAlert = true;
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
          }
        });
    } else if (actionIconClicked.icon.key === 'clone') {
      this.cloneBody = actionIconClicked.data.studyId;
      this.showClonePopup = true;
    } else if (actionIconClicked.icon.key === 'edit') {
      this.viewEditStudy(actionIconClicked.data.studyId, 'edit');
    } else if (actionIconClicked.icon.key === 'view') {
      this.viewEditStudy(actionIconClicked.data.studyId, 'view');
    }
    this.actionButtonStudyId = actionIconClicked.data.studyId;
  }

  viewEditStudy(studyId: any, actionType: string) {
    this.actionTriggerService
      .getStudyDetails(studyId)
      .subscribe((data: any) => {
        if (data.status === API_SUCCESS_KEY) {
          this.appState.setGlobalState(APP_STATE_KEYS.STUDY_DETAILS, data.data);
          this.appState.setGlobalState(APP_STATE_KEYS.ACTION_TYPE, actionType);
          this.router.navigate([`/studies/edit/${studyId}`]);
        } else {
          this.alertType = 'error';
          this.alertErrorMessage = SOMETHING_WENT_WRONG;
          this.showErrorAlert = true;
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      });
  }

  handleError(errorMessage: string, api: string): void {
    switch (api) {
      case 'recentStudies':
        this.tableErrorMessage = errorMessage;
        this.isRecentStudiesLoaded = true;
        break;

      case 'kpiData':
        this.kpiDataErrorMessage = errorMessage;
        this.kpiDataError = true;
        this.kpiDataLoaded = false;
        break;
    }
  }

  formatRecentStudiesData(data: any): any {
    data.forEach((item: any) => {
      let date = new Date(item.createdOn * 1000);
      item['createdOn_iconAfter'] = [
        {
          type: 'info-circle',
          theme: 'outline',
          tooltip: `Created by ${item.email} on ${date.toLocaleString(
            'default',
            { month: 'short' }
          )} ${date.getDate()}, ${date.getFullYear()} at ${date
            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            .replace(' ', '')}`,

          title: '',
          style: {
            cursor: 'pointer',
          },
        },
      ];
      switch (item.role) {
        case 'owner':
          item['visibleActionIcons'] = this.visibleActionButtonArray.owner;
          break;
        case 'collaborator':
          item['visibleActionIcons'] =
            this.visibleActionButtonArray.collaborator;
          break;
        case 'viewer':
          item['visibleActionIcons'] = this.visibleActionButtonArray.viewer;
          break;
      }

      if (this.studiesType === 'allStudies') {
        item['visibleActionIcons'] = this.visibleActionButtonArrayAllStudies;
      }

      item['disabledActionIcons'] = [];
      let disabledActionIconArray: string[] = [];
      if (item.isRefreshStudy) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.isRefresh,
            ...disabledActionIconArray,
          ]),
        ];
      }
      if (item.isCloning) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.isCloning,
            ...disabledActionIconArray,
          ]),
        ];
      }
      if (item.progress < 50) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.isProgressLess,
            ...disabledActionIconArray,
          ]),
        ];
      }
      if (item.progress < 20) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.isProgressLessTwenty,
            ...disabledActionIconArray,
          ]),
        ];
      }
      if (!item.canRefresh) {
        disabledActionIconArray = [
          ...new Set([
            ...this.disabledActionButtonArray.canRefresh,
            ...disabledActionIconArray,
          ]),
        ];
      }

      item['disabledActionIcons'] = disabledActionIconArray;

      item.createdOn = new Date(item.createdOn * 1000);

      let initials;

      if (item.email?.includes('@zs.com')) {
        initials = item.createdBy?.substring(3, 5).toUpperCase();
      } else {
        initials = item.email?.substring(0, 2).toUpperCase();
      }

      item.createdByArray = [{ text: initials, tooltip: item.email }];
    });
    return data;
  }

  closePopup($event: any) {
    this.showPopup = false;
    if ($event === true) {
      if (this.confirmationConfig.key === 'Refresh') {
        this.actionTriggerService
          .getRefreshedData(this.actionButtonStudyId)
          .subscribe((data) => {
            this.fetchRecentStudies();
            this.fetchKpiData();
            if (data.status === API_SUCCESS_KEY) {
              this.showRefreshSuccess = true;
            } else {
              this.alertType = 'error';
              this.alertErrorMessage = SOMETHING_WENT_WRONG;
              this.showErrorAlert = true;
              this.commonService.createNotification(
                'error',
                SOMETHING_WENT_WRONG
              );
            }
          });
      } else if (this.confirmationConfig.key === 'Delete') {
        this.actionTriggerService
          .getDeletedData(this.actionButtonStudyId)
          .subscribe((data) => {
            this.fetchRecentStudies();
            this.fetchKpiData();
            if (data.status === API_SUCCESS_KEY) {
              this.alertType = 'info';
              this.alertErrorMessage = data.message;
              this.showErrorAlert = true;
              this.commonService.createNotification('success', data.message);
            } else {
              this.alertType = 'error';
              this.alertErrorMessage = SOMETHING_WENT_WRONG;
              this.showErrorAlert = true;
              this.commonService.createNotification(
                'error',
                SOMETHING_WENT_WRONG
              );
            }
          });
      }
    }
  }

  closeExportPopup($event: any) {
    this.showExportPopup = false;
  }

  closeClonePopup($event: any) {
    this.showClonePopup = false;
    if ($event === API_SUCCESS_KEY) {
      // After closing the clone popup, user should be navigated to my-studies page if clone is success.
      if (this.studiesType == 'myStudies') {
        this.fetchRecentStudies();
        this.fetchKpiData();
      } else {
        this.router.navigateByUrl('/home/my-studies');
      }
      this.showErrorAlert = false;
    } else if ($event === API_FAILURE_KEY) {
      // After closing the clone popup, user should get error alert in case of API failure.
      this.alertType = 'error';
      this.alertErrorMessage = SOMETHING_WENT_WRONG;
      this.showErrorAlert = true;
      this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
    }
  }

  alertClosed($event: any) {
    this.showErrorAlert = false;
  }

  navigateCreateStudy() {
    this.appState.setGlobalState(APP_STATE_KEYS.ACTION_TYPE, 'none');
    this.appState.removeGlobalState(APP_STATE_KEYS.STUDY_DETAILS);
    this.router.navigate(['/studies/create']);
  }

  closeRefreshModal() {
    this.showRefreshSuccess = false;
  }

  searchStudy(): void {
    if (this.searchText.length > 2 || this.searchText.length === 0) {
      // Perform Search Operation
    }
  }

  kpiClicked(kpiItem: any) {
    this.kpiDataArray.forEach((item: any) => {
      if (item.key !== kpiItem.key) {
        item.isSelected = false;
      } else {
        item.isSelected = true;
      }
    });
  }
}
