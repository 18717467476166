import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { RecentStudies } from '../models/dashboard.model';
import { API_LIST } from '../constants/api-list';
import { map } from 'rxjs/operators';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class DataFilterService {
  constructor(private transactionManager: TransactionManagerService) {}

  callDataFilterAPI(studyId: string, payload: any): Observable<any> {
    const url = 'parameter/' + studyId + '/dos-imputation/execute/';
    return this.transactionManager.post(url, payload).pipe(
      map((response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          return response;
        } else {
          return this.errorHandler();
        }
      })
    );
  }

  errorHandler(e: any = ''): any {
    // eslint-disable-next-line no-console
    return { status: API_FAILURE_KEY };
  }
}
