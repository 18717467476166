<div class="regimen-lot pb-4">
  <nz-breadcrumb class="breadcrumb endClickable" [nzSeparator]="'>'">
    <nz-breadcrumb-item
      class="breadcrumb endClickable"
      *ngFor="let item of viewerBreadCrumbData"
    >
      <a
        class="breadcrumb endClickable"
        *ngIf="item.navigationPath.length > 0"
        [routerLink]="item.navigationPath"
      >
        {{ item.displayName }}
      </a>
      <span
        [ngClass]="
          item.navigationPath.length === 0 ? 'nonClickableBreadCrumb' : ''
        "
        *ngIf="item.navigationPath.length === 0"
      >
        {{ item.displayName }}
      </span>
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <div *ngIf="isRequiredJobTriggeredStatus" class="m-auto w-fit-content mb-3">
    <app-alert
      [closable]="false"
      [message]="'You have not triggered the required jobs to view this page.'"
      [type]="'error'"
    ></app-alert>
  </div>

  <div
    *ngIf="!isRequiredJobTriggeredStatus"
    [ngClass]="{
      'disable-section':
        jobStatus.regimenDistributionJobStatusCompleted === false
    }"
  >
    <div class="export-section">
      <rd-button
        btnType="text"
        btnText="Export"
        [isIconNeeded]="true"
        iconType="download"
        [iconStyle]="buttonIconStyle"
        [btnStyle]="{ margin: 0, marginLeft: 'auto' }"
        (btnClick)="exportButtonClick()"
      ></rd-button>
    </div>

    <div
      *ngIf="jobStatus.regimenDistributionJobStatusCompleted === false"
      class="m-auto w-fit-content mb-3"
    >
      <app-alert
        [closable]="false"
        [message]="
          'A job is running to compute Regimen Distribution. Please try after sometime.'
        "
        [type]="'warning'"
      ></app-alert>
    </div>

    <div
      class="regimen-lot-collapse mb-3"
      [ngClass]="{
        'disable-section':
          jobStatus.regimenDistributionJobStatusCompleted === false ||
          selectedMethod
      }"
    >
      <rd-collapse
        [panelHeader]="stageIdentificationHeader"
        [isDisabled]="false"
        [expandLocation]="'right'"
        [isActive]="false"
        [showArrowIcon]="false"
      >
        <ng-template #stageIdentificationHeader>
          <div class="accordian-header">
            <div class="title">
              Stage Identification
              <rd-tooltip
                tooltipPlacement="right"
                [tooltipText]="collapseTooltipText['Stage Identification']"
              ></rd-tooltip>
            </div>
            <i nz-icon nzType="caret-down"></i>
          </div>
        </ng-template>
        <div class="collapse-body">
          <div
            class="stage-identification"
            [ngSwitch]="stageIdentificationFetchStatus"
          >
            <ng-container
              *ngSwitchCase="'loading'"
              [ngTemplateOutlet]="loadingSpinner"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'error'"
              [ngTemplateOutlet]="errorMessageContainer"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'available'"
              [ngTemplateOutlet]="stageIdentificationContent"
            ></ng-container>
            <ng-container *ngSwitchDefault></ng-container>
          </div>
        </div>
      </rd-collapse>
    </div>

    <div
      class="regimen-lot-collapse mb-3"
      [ngClass]="{
        'disable-section':
          jobStatus.regimenDistributionJobStatusCompleted === false ||
          selectedMethod
      }"
    >
      <rd-collapse
        [panelHeader]="regimenConditioningHeader"
        [isDisabled]="false"
        [expandLocation]="'right'"
        [isActive]="false"
        [showArrowIcon]="false"
        (collapseClicked)="($event)"
      >
        <div class="collapse-body">
          <div
            *ngIf="
              jobStatus.regimenMergeJobStatusCompleted === false ||
              jobStatus.stageIdentificationJobStatusCompleted === false
            "
            class="m-auto w-fit-content"
          >
            <app-alert
              [closable]="false"
              [message]="
                'A job is running to merge the Regimens. Please try after sometime.'
              "
              [type]="'warning'"
            ></app-alert>
          </div>
          <div
            class="d-flex-center-space-between collapse-inner-header mb-4"
            [ngClass]="{
              'disable-section':
                jobStatus.regimenMergeJobStatusCompleted === false ||
                jobStatus.stageIdentificationJobStatusCompleted === false
            }"
          >
            <div class="collapse-inner-header-subtitle">
              <div class="title-16-700">Regimen Transitions</div>
              <div class="title-12-500-grey">
                Select rule for and select all required options.
              </div>
            </div>
          </div>
          <div
            class="collapse-inner d-flex-space-between"
            [ngClass]="{
              'disable-section':
                jobStatus.regimenMergeJobStatusCompleted === false ||
                jobStatus.stageIdentificationJobStatusCompleted === false ||
                isUserRoleViewer
            }"
          >
            <div class="collapse-inner-section w-40-percent">
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  Add regimen transition rule for:
                  <span class="required-field"> *</span>
                </div>
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  nzPlaceHolder="Select"
                  [(ngModel)]="selectedRegimenTransitionRuleFor"
                  (ngModelChange)="
                    noOfIterations = 2;
                    $event !== 'Maintenance' &&
                      (regimenLengthRules.lineNumberMaintenanceApplicableValue =
                        null)
                  "
                >
                  <nz-option
                    *ngFor="let data of regimenTransitionRulesList"
                    [nzLabel]="data"
                    [nzValue]="data"
                  ></nz-option>
                </nz-select>
              </div>
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  No. of iterations
                  <span class="required-field"> *</span>
                </div>
                <input
                  class="custom-input"
                  type="text"
                  [disabled]="selectedRegimenTransitionRuleFor !== 'Merge'"
                  (paste)="allowNumeric($event)"
                  (keypress)="allowNumeric($event)"
                  [(ngModel)]="noOfIterations"
                />
                <p *ngIf="noOfIterations > 10" class="text-12-red">
                  Iterations should be between 0-10
                </p>
              </div>

              <div class="title-14-600 mb-1">Previous Regimen</div>
              <!-- <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">Drug Class</div>
                <nz-select
                  nzMode="multiple"
                  nzPlaceHolder="Please select"
                  [(ngModel)]="listOfSelectedPreviousRegimenDrugs.data"
                  (ngModelChange)="onSelectDrugsList('previousRegimen')"
                  [nzMaxTagCount]="1"
                  [nzShowArrow]="true"
                  [nzLoading]="drugListLoadingStatus"
                >
                  <nz-option
                    *ngFor="let item of drugsList"
                    [nzLabel]="item"
                    [nzValue]="item"
                  ></nz-option>
                </nz-select>
              </div> -->
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  Drug Class
                  <span class="required-field"> *</span>
                </div>
                <nz-select
                  nzMode="multiple"
                  nzPlaceHolder="Please select"
                  [(ngModel)]="listOfSelectedPreviousRegimenDrugs.data"
                  (ngModelChange)="
                    selectAllDropdownOption(
                      $event,
                      'drugsList',
                      'listOfSelectedPreviousRegimenDrugs',
                      'previousRegimen'
                    )
                  "
                  [nzMaxTagCount]="1"
                  [nzShowArrow]="true"
                  [nzLoading]="drugListLoadingStatus"
                >
                  <nz-option
                    *ngIf="drugsList.length > 0"
                    [nzLabel]="'All'"
                    [nzValue]="'All'"
                  ></nz-option>
                  <nz-option
                    *ngFor="let item of drugsList"
                    [nzLabel]="item"
                    [nzValue]="item"
                  ></nz-option>
                </nz-select>
              </div>
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  Drug Product
                  <span class="required-field"> *</span>
                </div>
                <nz-select
                  nzMode="multiple"
                  nzPlaceHolder="Please select"
                  [(ngModel)]="listOfSelectedPreviousRegimenProducts.data"
                  [nzShowArrow]="true"
                  [nzMaxTagCount]="1"
                  (ngModelChange)="
                    selectAllDropdownOption(
                      $event,
                      'listOfPreviousRegimenProducts',
                      'listOfSelectedPreviousRegimenProducts'
                    )
                  "
                >
                  <nz-option
                    *ngIf="listOfPreviousRegimenProducts.length > 0"
                    [nzLabel]="'All'"
                    [nzValue]="'All'"
                  ></nz-option>
                  <nz-option
                    *ngFor="let item of listOfPreviousRegimenProducts"
                    [nzLabel]="item"
                    [nzValue]="item"
                  ></nz-option>
                </nz-select>
              </div>
              <div class="w-100 select-product mb-3">
                <div class="title-14-600 mb-1">
                  Rule
                  <span class="required-field"> *</span>
                </div>
                <nz-select
                  nzPlaceHolder="Please select"
                  [nzShowArrow]="true"
                  [(ngModel)]="regimenMergingRule"
                >
                  <nz-option
                    *ngFor="let data of regimenMergingRulesList"
                    [nzLabel]="data.label"
                    [nzValue]="data.value"
                  ></nz-option>
                </nz-select>
              </div>
              <div class="title-14-600 mb-1">Current Regimen</div>
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  Drug Class
                  <span class="required-field"> *</span>
                </div>
                <nz-select
                  nzMode="multiple"
                  nzPlaceHolder="Please select"
                  [(ngModel)]="listOfSelectedCurrentRegimenDrugs.data"
                  (ngModelChange)="
                    selectAllDropdownOption(
                      $event,
                      'drugsList',
                      'listOfSelectedCurrentRegimenDrugs',
                      'currentRegimen'
                    )
                  "
                  [nzMaxTagCount]="1"
                  [nzShowArrow]="true"
                  [nzLoading]="drugListLoadingStatus"
                >
                  >
                  <nz-option
                    *ngIf="drugsList.length > 0"
                    [nzLabel]="'All'"
                    [nzValue]="'All'"
                  ></nz-option>
                  <nz-option
                    *ngFor="let item of drugsList"
                    [nzLabel]="item"
                    [nzValue]="item"
                  ></nz-option>
                </nz-select>
              </div>
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  Drug Product
                  <span class="required-field"> *</span>
                </div>
                <nz-select
                  nzMode="multiple"
                  nzPlaceHolder="Please select"
                  [(ngModel)]="listOfSelectedCurrentRegimenProducts.data"
                  [nzMaxTagCount]="1"
                  [nzShowArrow]="true"
                  (ngModelChange)="
                    selectAllDropdownOption(
                      $event,
                      'listOfCurrentRegimenProducts',
                      'listOfSelectedCurrentRegimenProducts'
                    )
                  "
                >
                  <nz-option
                    *ngIf="listOfCurrentRegimenProducts.length > 0"
                    [nzLabel]="'All'"
                    [nzValue]="'All'"
                  ></nz-option>
                  <nz-option
                    *ngFor="let item of listOfCurrentRegimenProducts"
                    [nzLabel]="item"
                    [nzValue]="item"
                  ></nz-option>
                </nz-select>
              </div>
              <div class="title-14-600 mb-1">Regimen Length Rules</div>
              <div class="title-12-500-grey mb-1">
                Gap (in days) between previous regimen and current regimen
              </div>
              <div class="d-flex-center-space-between">
                <div class="w-60 select-product">
                  <nz-select
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Select"
                    [(ngModel)]="regimenLengthRules.selectedGapInDaysOperator"
                  >
                    <nz-option
                      *ngFor="let data of comparatorList"
                      [nzLabel]="data"
                      [nzValue]="data"
                    ></nz-option>
                  </nz-select>
                </div>
                <div class="select-product w-calc-40-10">
                  <input
                    class="custom-input"
                    type="number"
                    placeholder="Enter value"
                    (paste)="allowNumeric($event)"
                    (keypress)="allowNumeric($event)"
                    [(ngModel)]="regimenLengthRules.gapInDaysValue"
                  />
                </div>
              </div>
              <div
                class="w-100 select-product mb-3"
                *ngIf="regimenLengthRules.selectedGapInDaysOperator"
              >
                <div class="title-12-500-grey mb-1">Gap calculated from</div>
                <div class="d-flex-center-space-between">
                  <div class="w-60 select-product">
                    <nz-select
                      nzPlaceHolder="Select"
                      [(ngModel)]="regimenLengthRules.selectedGapCalculatedFrom"
                    >
                      <nz-option
                        [nzLabel]="'start'"
                        [nzValue]="'start'"
                      ></nz-option>
                      <nz-option
                        [nzLabel]="'end'"
                        [nzValue]="'end'"
                      ></nz-option>
                    </nz-select>
                  </div>
                  <div class="select-product w-calc-40-10">
                    of previous regimen
                  </div>
                </div>
              </div>
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  Length (in days) of previous regimen therapy
                </div>
                <div class="d-flex-center-space-between">
                  <div class="w-60 select-product">
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select"
                      [(ngModel)]="
                        regimenLengthRules.selectedLengthInDaysPreviousRegimenOperator
                      "
                    >
                      <nz-select
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Select"
                      >
                        <nz-option
                          *ngFor="let data of comparatorList"
                          [nzLabel]="data"
                          [nzValue]="data"
                        ></nz-option>
                      </nz-select>
                    </nz-select>
                  </div>
                  <div class="select-product w-calc-40-10">
                    <input
                      class="custom-input"
                      type="number"
                      placeholder="Enter value"
                      (paste)="allowNumeric($event)"
                      (keypress)="allowNumeric($event)"
                      [(ngModel)]="
                        regimenLengthRules.lengthInDaysPreviousRegimenValue
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  Length (in days) of current regimen therapy
                </div>
                <div class="d-flex-center-space-between">
                  <div class="w-60 select-product">
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="Select"
                      [(ngModel)]="
                        regimenLengthRules.selectedLengthInDaysCurrentRegimenOperator
                      "
                    >
                      <nz-select
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Select"
                      >
                        <nz-option
                          *ngFor="let data of comparatorList"
                          [nzLabel]="data"
                          [nzValue]="data"
                        ></nz-option>
                      </nz-select>
                    </nz-select>
                  </div>
                  <div class="select-product w-calc-40-10">
                    <input
                      class="custom-input"
                      type="number"
                      placeholder="Enter value"
                      (paste)="allowNumeric($event)"
                      (keypress)="allowNumeric($event)"
                      [(ngModel)]="
                        regimenLengthRules.lengthInDaysCurrentRegimenValue
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="w-100 select-product mb-3">
                <div class="title-12-500-grey mb-1">
                  Line number for which maintenance is applicable
                  <span
                    *ngIf="selectedRegimenTransitionRuleFor === 'Maintenance'"
                    class="required-field"
                  >
                    *</span
                  >
                </div>
                <div class="d-flex-center-space-between">
                  <div class="select-product w-calc-40-10">
                    <input
                      class="custom-input"
                      type="number"
                      placeholder="Enter value"
                      (paste)="allowNumeric($event)"
                      (keypress)="allowNumeric($event)"
                      [disabled]="
                        selectedRegimenTransitionRuleFor !== 'Maintenance'
                      "
                      [(ngModel)]="
                        regimenLengthRules.lineNumberMaintenanceApplicableValue
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="text-right">
                <rd-button
                  [btnType]="'primary'"
                  btnText="Add Rule"
                  [isIconNeeded]="false"
                  [btnClass]="'custom-primaryfill-greenbtn'"
                  [isDisabled]="
                    selectedRegimenTransitionRuleFor === '' ||
                    noOfIterations > 10 ||
                    listOfSelectedPreviousRegimenDrugs.data.length === 0 ||
                    listOfSelectedCurrentRegimenDrugs.data.length === 0 ||
                    listOfSelectedPreviousRegimenProducts.data.length === 0 ||
                    listOfSelectedCurrentRegimenProducts.data.length === 0 ||
                    regimenMergingRule === '' ||
                    isUserRoleViewer ||
                    (selectedRegimenTransitionRuleFor === 'Maintenance' &&
                      !regimenLengthRules.lineNumberMaintenanceApplicableValue)
                  "
                  (btnClick)="addRegimenTransitionRule()"
                ></rd-button>
              </div>
              <div class="title-14-600 mb-1">Regimen Overriding Rules</div>
              <div class="title-12-500-grey mb-1">
                Please provide all the combination of previous and current
                regimens to be/not to be merged
              </div>
              <div
                class="d-flex-center-space-between mb-3 d-lg-flex-start flex-flow-lg-column"
              >
                <label
                  [for]="'rules_upload'"
                  class="title-options-inner title-12-600-green cursor-pointer w-lg-100"
                  [ngClass]="{
                    'disable-section': isUserRoleViewer
                  }"
                >
                  <i nz-icon nzType="upload" nzTheme="outline"></i>
                  Upload Data
                  <input
                    class="upload-input"
                    type="file"
                    [attr.id]="'rules_upload'"
                    accept=".csv"
                    (click)="resetFile($event)"
                    (change)="uploadFile($event)"
                    style="opacity: 0; width: 0px"
                  />
                </label>
                <button
                  class="title-12-600-green button-default-css-override enable-section p-lg-0"
                  (click)="downloadRegimenConditioningTemplate()"
                >
                  <img src="assets/icons/download.svg" alt="" />
                  Download Template
                </button>
              </div>
              <div class="title-14-600 mb-1">Merge Regimen Renaming Rules</div>
              <div
                class="swap-blocks"
                cdkDropList
                [cdkDropListData]="mergeRegimenRenamingRulesList"
                (cdkDropListDropped)="drop($event)"
              >
                <div
                  class="swap-block"
                  *ngFor="
                    let item of mergeRegimenRenamingRulesList;
                    let i = index
                  "
                  cdkDrag
                >
                  <i
                    nz-icon
                    nzType="vertical-align-middle"
                    nzTheme="outline"
                  ></i
                  >&nbsp;&nbsp;{{ i + 1 }}. {{ item }}
                </div>
              </div>
            </div>
            <div class="w-calc-60-20">
              <div class="collapse-inner-section h-calc-100-424">
                <div
                  class="d-flex-center-space-between flex-flow-lg-column d-lg-flex-start mb-2"
                >
                  <div class="title-14-600 mb-lg-2">Rule Details</div>
                  <div
                    *ngIf="regimenTransitionRules.length > 0"
                    class="d-flex-center-center"
                  >
                    <div class="title-14-600">Select Rule:&nbsp;</div>
                    <div class="w-150px">
                      <nz-select
                        [(ngModel)]="regimenTransitionRuleFilter"
                        (ngModelChange)="checkRuleFilterDataStatus($event)"
                      >
                        <nz-option nzValue="All" nzLabel="All"></nz-option>
                        <nz-option nzValue="Merge" nzLabel="Merge"></nz-option>
                        <nz-option
                          nzValue="Progression"
                          nzLabel="Progression"
                        ></nz-option>
                        <nz-option
                          nzValue="Maintenance"
                          nzLabel="Maintenance"
                        ></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>

                <div
                  class="rules-section"
                  [ngClass]="{
                    'd-flex-center-center h-inherit':
                      regimenTransitionRules.length === 0 ||
                      regimenTransitionRulesDataFilterStatus
                  }"
                >
                  <div
                    *ngIf="regimenTransitionRules.length > 0"
                    cdkDropList
                    [cdkDropListData]="regimenTransitionRules"
                    (cdkDropListDropped)="drop($event)"
                  >
                    <ng-container
                      *ngFor="let rule of regimenTransitionRules; let i = index"
                    >
                      <div
                        *ngIf="
                          regimenTransitionRuleFilter ===
                            rule.regimen_transition_rule ||
                          regimenTransitionRuleFilter === 'All'
                        "
                        class="section-rules p-3"
                        cdkDrag
                      >
                        <div class="title-14-600 mb-2">
                          Regimen {{ rule.regimen_transition_rule }} Rule #{{
                            i + 1
                          }}
                        </div>
                        <div class="d-flex-center-space-between">
                          <div class="w-60">
                            {{ renderMergingRuleInHumanReadableFormat(rule) }}
                          </div>
                          <div class="w-calc-40-10">
                            <div class="title-14-600 mb-2">
                              Previous Product
                            </div>
                            <div class="text-ellipsis">
                              {{
                                formatJoinArray(
                                  rule.previous_regimen_product_name
                                )
                              }}
                            </div>
                            <div class="title-14-600 mb-2">Current Product</div>
                            <div class="text-ellipsis">
                              {{
                                formatJoinArray(
                                  rule.current_regimen_product_name
                                )
                              }}
                            </div>
                          </div>
                          <button
                            class="close-filled-green d-flex-center-center"
                            (click)="deleteRules('regimenTransitionRules', i)"
                          >
                            <i nz-icon nzType="close" nzTheme="outline"></i>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                    <div
                      *ngIf="regimenTransitionRulesDataFilterStatus"
                      class="title-14-600"
                    >
                      Please add a rule
                    </div>
                  </div>
                  <div
                    *ngIf="regimenTransitionRules.length === 0"
                    class="title-16-500-grey"
                  >
                    Please add a rule
                  </div>
                </div>
              </div>
              <div class="collapse-inner-section h-400px mt-4">
                <div class="title-14-600 mb-1">Regimen Merge Summary</div>
                <div class="table">
                  <rd-table
                    [isLoading]="!regimenMergeSummaryGrid.isLoaded"
                    [tableColumns]="regimenMergeSummaryGrid.columns"
                    [tableData]="regimenMergeSummaryGrid.gridData"
                    [tableScrollDetails]="
                      regimenMergeSummaryGrid.gridData.length > 0
                        ? regimenMergeSummaryGrid.scrollDetails
                        : {}
                    "
                    [totalCount]="regimenMergeSummaryGrid.dataCount"
                    [errorMessage]="regimenMergeSummaryGrid.tableErrorMessage"
                    [errorType]="regimenMergeSummaryGrid.tableErrorType"
                    [showPagination]="false"
                    [frontEndPagination]="false"
                    [showPagination]="false"
                    [frontEndPagination]="false"
                    [pageIndex]="regimenMergeSummaryGrid.pageIndex"
                    [loadingRows]="10"
                  ></rd-table>
                  <!-- [pageSize]="defaultPageSize" -->
                  <!-- (sortApply)="fetchMappingData(1, $event.defaultSortOrder)" -->
                  <!-- (paginationChange)="fetchMappingData($event)" -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="text-right mt-4"
            [ngClass]="{
              'disable-section':
                jobStatus.regimenMergeJobStatusCompleted === false ||
                jobStatus.stageIdentificationJobStatusCompleted === false
            }"
          >
            <rd-button
              [btnType]="'default'"
              btnText="Save Rules"
              [isIconNeeded]="false"
              [size]="'default'"
              [btnClass]="'custom-primaryfill-greenbtn'"
              (btnClick)="saveRules(false)"
              [isDisabled]="
                checkRegimenConditioningChangeDetectionStatus() ||
                isUserRoleViewer
              "
            ></rd-button>
            <rd-button
              [btnType]="'primary'"
              btnText="Apply Rules"
              [isIconNeeded]="false"
              [size]="'default'"
              [btnClass]="'custom-primaryfill-greenbtn'"
              (btnClick)="saveRules(true)"
              [isDisabled]="
                this.regimenTransitionRules.length === 0 || isUserRoleViewer
              "
            ></rd-button>
          </div>
          <div class="collapse-inner-section mt-4">
            <div
              class="m-auto w-fit-content"
              *ngIf="
                jobStatus.regimenDropJobStatusCompleted === false ||
                jobStatus.stageIdentificationJobStatusCompleted === false ||
                jobStatus.regimenMergeJobStatusCompleted === false
              "
            >
              <app-alert
                [closable]="false"
                [message]="
                  'A job is running to compute Regimen Drop. Please try after sometime.'
                "
                [type]="'warning'"
              ></app-alert>
            </div>
            <div
              class="title-16-700 mb-1"
              [ngClass]="{
                'disable-section':
                  jobStatus.regimenDropJobStatusCompleted === false ||
                  jobStatus.stageIdentificationJobStatusCompleted === false ||
                  jobStatus.regimenMergeJobStatusCompleted === false
              }"
            >
              Regimen Refinement
            </div>
            <div
              class="d-flex-start-space-between flex-flow-lg-column"
              [ngClass]="{
                'disable-section':
                  jobStatus.regimenDropJobStatusCompleted === false ||
                  jobStatus.stageIdentificationJobStatusCompleted === false ||
                  jobStatus.regimenMergeJobStatusCompleted === false ||
                  isUserRoleViewer
              }"
            >
              <div class="linechart-graph w-40-percent w-lg-100">
                <div class="title-12-500-grey mb-1">
                  Overall Regimen Length Distribution graph
                </div>
                <div
                  *ngIf="lineChartRegimenLengthDistributionStatus"
                  class="border-light-grey"
                >
                  <app-highcharts-linechart
                    [lineChartConfig]="
                      lineChartConfigForRegimenLengthDistribution
                    "
                  ></app-highcharts-linechart>
                </div>
              </div>
              <div class="w-calc-60-20 w-lg-100">
                <div class="d-flex-center-space-between mb-3">
                  <div class="w-50 pr-2">
                    <div class="title-12-500-grey mb-1">Drug Class</div>
                    <nz-select
                      nzMode="multiple"
                      nzPlaceHolder="Please select"
                      [(ngModel)]="listOfSelectedRegimenDropDrugs.data"
                      [nzMaxTagCount]="1"
                      [nzShowArrow]="true"
                      [nzLoading]="drugListLoadingStatus"
                      (ngModelChange)="
                        selectAllDropdownOption(
                          $event,
                          'drugsList',
                          'listOfSelectedRegimenDropDrugs',
                          'regimenDrop'
                        )
                      "
                    >
                      <nz-option
                        *ngIf="drugsList.length > 0"
                        [nzLabel]="'All'"
                        [nzValue]="'All'"
                      ></nz-option>
                      <nz-option
                        *ngFor="let item of drugsList"
                        [nzLabel]="item"
                        [nzValue]="item"
                      ></nz-option>
                    </nz-select>
                  </div>
                  <div class="w-50 pl-2">
                    <div class="title-12-500-grey mb-1">Products</div>
                    <nz-select
                      nzMode="multiple"
                      nzPlaceHolder="Please select"
                      [(ngModel)]="listOfSelectedRegimenDropProducts.data"
                      [nzMaxTagCount]="1"
                      [nzShowArrow]="true"
                      (ngModelChange)="
                        selectAllDropdownOption(
                          $event,
                          'listOfRegimenDropProducts',
                          'listOfSelectedRegimenDropProducts'
                        )
                      "
                    >
                      <nz-option
                        *ngIf="listOfRegimenDropProducts.length > 0"
                        [nzLabel]="'All'"
                        [nzValue]="'All'"
                      ></nz-option>
                      <nz-option
                        *ngFor="let item of listOfRegimenDropProducts"
                        [nzLabel]="item"
                        [nzValue]="item"
                      ></nz-option>
                    </nz-select>
                  </div>
                </div>
                <div class="d-flex-center-space-between mb-3">
                  <div class="title-12-500-grey">
                    Enter a cut-off length to drop regimens (in days)
                  </div>
                  <div class="w-25">
                    <input
                      class="custom-input"
                      type="text"
                      placeholder="Enter days"
                      (paste)="allowNumeric($event)"
                      (keypress)="allowNumeric($event)"
                      [(ngModel)]="cutOfDaysValue"
                    />
                    <p
                      *ngIf="convertStringToNumber(cutOfDaysValue) > 100"
                      class="mt-1 red-icon title-12-500-grey"
                    >
                      The days should be between 0 - 100
                    </p>
                  </div>
                </div>
                <div class="d-flex-center-space-between mb-3">
                  <button
                    class="title-12-600-green button-default-css-override"
                    [disabled]="
                      listOfRegimenDropProducts.length === 0 ||
                      cutOfDaysValue === '' ||
                      cutOfDaysValue === '0' ||
                      convertStringToNumber(cutOfDaysValue) > 100
                    "
                    [ngClass]="{
                      'disable-section':
                        listOfRegimenDropProducts.length === 0 ||
                        cutOfDaysValue === '' ||
                        cutOfDaysValue === '0' ||
                        convertStringToNumber(cutOfDaysValue) > 100
                    }"
                    (click)="
                      checkRegimenDrop();
                      fetchOverallRegimenLengthDistribution()
                    "
                  >
                    <i nz-icon nzType="check" nzTheme="outline"></i>&nbsp;&nbsp;
                    Check Regimen Drop
                  </button>
                  <button
                    class="title-12-600-green button-default-css-override"
                    (click)="addRegimenDropRule()"
                    [disabled]="
                      listOfRegimenDropProducts.length === 0 ||
                      cutOfDaysValue === '' ||
                      convertStringToNumber(cutOfDaysValue) > 100
                    "
                    [ngClass]="{
                      'disable-section':
                        listOfRegimenDropProducts.length === 0 ||
                        cutOfDaysValue === '' ||
                        isUserRoleViewer ||
                        convertStringToNumber(cutOfDaysValue) > 100
                    }"
                  >
                    <i nz-icon nzType="plus" nzTheme="outline"></i>&nbsp;&nbsp;
                    Add Regimen Drop Rule
                  </button>
                </div>
                <div class="title-12-500-grey mb-2">
                  % Regimens removed for selected cutoff: {{ regimenCutOff }}
                </div>
                <div class="section-rules p-3 h-340px">
                  <div class="title-14-600 mb-1">Regimen Drop Rules</div>
                  <rd-table
                    [isLoading]="!regimenDropRulesGrid.isLoaded"
                    [tableColumns]="regimenDropRulesGrid.columns"
                    [tableData]="regimenDropRulesGrid.gridData"
                    [tableScrollDetails]="
                      regimenDropRulesGrid.gridData.length > 0
                        ? regimenDropRulesGrid.scrollDetails
                        : {}
                    "
                    [errorMessage]="regimenDropRulesGrid.tableErrorMessage"
                    [loadingRows]="10"
                    [errorType]="regimenDropRulesGrid.tableErrorType"
                    [showPagination]="false"
                    [frontEndPagination]="false"
                    (tableRowClick)="
                      regimenDropRulesTableActionIconClicked($event)
                    "
                  ></rd-table>
                </div>
              </div>
            </div>
            <div
              class="text-right mt-3"
              [ngClass]="{
                'disable-section':
                  jobStatus.regimenDropJobStatusCompleted === false ||
                  jobStatus.stageIdentificationJobStatusCompleted === false ||
                  jobStatus.regimenMergeJobStatusCompleted === false
              }"
            >
              <rd-button
                [btnType]="'primary'"
                btnText="Apply"
                [isIconNeeded]="false"
                [size]="'default'"
                [btnClass]="'custom-primaryfill-greenbtn'"
                (click)="applyRegimenRefinementOne()"
                [isDisabled]="
                  regimenDropRulesGrid.gridData.length === 0 || isUserRoleViewer
                "
              ></rd-button>
            </div>
          </div>
        </div>
        <ng-template #regimenConditioningHeader>
          <div class="accordian-header">
            <div class="d-flex-center-space-between w-100">
              <div class="title">
                Regimen Conditioning
                <rd-tooltip
                  [tooltipText]="
                    'The section provides the user with the option to consolidate successive regimens to create the consistent treatment sequences in the market.'
                  "
                  tooltipPlacement="right"
                ></rd-tooltip>
              </div>
              <div>
                <button
                  class="title-12-600-green button-default-css-override"
                  (click)="downloadRegimenSummary($event)"
                >
                  <i nz-icon nzType="download" nzTheme="outline"></i
                  >&nbsp;&nbsp; Regimen Summary
                </button>
              </div>
            </div>

            <i nz-icon nzType="caret-down" nzTheme="outline"></i>
          </div>
        </ng-template>
      </rd-collapse>
    </div>

    <div
      class="regimen-lot-collapse mb-3"
      [ngClass]="{
        'disable-section':
          jobStatus.regimenDistributionJobStatusCompleted === false
      }"
    >
      <rd-collapse
        [panelHeader]="linesOfTherapyHeader"
        [isDisabled]="false"
        [expandLocation]="'right'"
        [isActive]="false"
        [showArrowIcon]="false"
        (collapseClicked)="($event)"
      >
        <div class="collapse-body">
          <div
            *ngIf="
              jobStatus.lineofTherapyJobStatusCompleted === false ||
              jobStatus.stageIdentificationJobStatusCompleted === false ||
              jobStatus.regimenMergeJobStatusCompleted === false ||
              jobStatus.regimenDropJobStatusCompleted === false
            "
            class="m-auto w-fit-content"
          >
            <app-alert
              [closable]="false"
              [message]="
                'A job is running to compute Line of Therapy. Please try after sometime.'
              "
              [type]="'warning'"
            ></app-alert>
          </div>
          <div
            class="collapse-body"
            [ngClass]="{
              'disable-section':
                jobStatus.lineofTherapyJobStatusCompleted === false ||
                jobStatus.stageIdentificationJobStatusCompleted === false ||
                jobStatus.regimenMergeJobStatusCompleted === false ||
                jobStatus.regimenDropJobStatusCompleted === false ||
                isUserRoleViewer
            }"
          >
            <nz-radio-group
              [(ngModel)]="selectedValue"
              (ngModelChange)="lotRadioButtonClicked($event)"
            >
              <div>
                <div
                  class="radioButton"
                  [nzDisabled]="selectedMethod"
                  nz-radio
                  [nzValue]="lotDropDownData[0].value"
                >
                  {{ lotDropDownData[0].label }}
                </div>
                <div
                  class="radioButton"
                  [nzDisabled]="selectedMethod"
                  nz-radio
                  [nzValue]="lotDropDownData[1].value"
                >
                  {{ lotDropDownData[1].label }}
                </div>

                <div
                  class="radioButton"
                  [nzDisabled]="selectedMethod"
                  nz-radio
                  [nzValue]="lotDropDownData[2].value"
                >
                  {{ lotDropDownData[2].label }}
                </div>

                <div
                  class="radioButton"
                  [nzDisabled]="
                    jobStatus.regimenMergeJobStatusCompleted === false ||
                    jobStatus.regimenMergeJobStatusCompleted === null ||
                    selectedMethod
                  "
                  nz-radio
                  [nzValue]="lotDropDownData[3].value"
                >
                  {{ lotDropDownData[3].label }}
                </div>
              </div>
            </nz-radio-group>
          </div>
          <div
            class="apply-button-style"
            [ngClass]="{
              'disable-section':
                jobStatus.lineofTherapyJobStatusCompleted === false ||
                jobStatus.stageIdentificationJobStatusCompleted === false ||
                jobStatus.regimenMergeJobStatusCompleted === false ||
                jobStatus.regimenDropJobStatusCompleted === false
            }"
          >
            <rd-button
              [btnType]="'primary'"
              btnText="Apply"
              [size]="'large'"
              [iconType]="'check-circle'"
              [btnClass]="'custom-primaryfill-greenbtn'"
              (btnClick)="showLotConfirmationFlag = true"
              [isDisabled]="isUserRoleViewer"
            ></rd-button>
          </div>
          <ng-template #linesOfTherapyHeader>
            <div class="accordian-header">
              <div class="title">
                Lines of Therapy
                <rd-tooltip
                  tooltipPlacement="right"
                  [tooltipText]="lotToolTop"
                ></rd-tooltip>
              </div>
              <div class="title-12-600-green">
                <input
                  type="checkbox"
                  (change)="onEpisodes($event)"
                  [checked]="selectedMethod"
                />
                <label>&nbsp;&nbsp; Create LoT on Episodes</label>
              </div>
              <i nz-icon nzType="caret-down" nzTheme="outline"></i>
            </div>
          </ng-template>
        </div>
      </rd-collapse>
    </div>
  </div>

  <div class="footer-section d-flex justify-content-end">
    <div class="footer-section-wrapper">
      <rd-button
        [btnType]="'default'"
        btnText="Back"
        [isIconNeeded]="false"
        [size]="'large'"
        (btnClick)="navigateWrtUrl('studies/episode-creation/', true)"
      ></rd-button>
      <rd-button
        [btnType]="'default'"
        btnText="Exit"
        [isIconNeeded]="false"
        [size]="'large'"
        [isDisabled]="isUserRoleViewer"
        (btnClick)="navigateWrtUrl('/home/')"
      ></rd-button>
      <rd-button
        [btnType]="'primary'"
        btnText="Next"
        [btnClass]="'custom-primaryfill-greenbtn'"
        (btnClick)="navigationNext()"
        [isDisabled]="isUserRoleViewer"
      ></rd-button>
    </div>
  </div>
</div>

<nz-drawer
  [nzTitle]="titleTpl"
  [nzClosable]="false"
  [nzVisible]="selectTypeOptionsPopupVisibleStatus"
  [nzWidth]="400"
  nzPlacement="right"
  nzWrapClassName="execution-logs-drawer-style"
>
  <ng-container *nzDrawerContent>
    <div class="w-100 select-product mb-3">
      <nz-input-group [nzSuffix]="inputClearTpl">
        <input
          type="text"
          nz-input
          [(ngModel)]="searchTypeListValues"
          placeholder="Search"
          (ngModelChange)="
            (searchTypeListValues.length > 2 ||
              searchTypeListValues.length === 0) &&
              fetchTypeOptionsList()
          "
        />
      </nz-input-group>
      <ng-template #inputClearTpl>
        <i
          nz-icon
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          *ngIf="searchTypeListValues"
          (click)="searchTypeListValues = ''; fetchTypeOptionsList()"
        ></i>
      </ng-template>
    </div>
    <div class="d-flex-center-center h-100" *ngIf="!isTypeValuesLoaded">
      <rd-spinner></rd-spinner>
    </div>
    <div *ngIf="isTypeValuesLoaded" class="box-container">
      <div *ngIf="typeValuesList.length > 0" class="check-list">
        <div style="border-bottom: 1px solid rgb(233, 233, 233)">
          <label
            nz-checkbox
            [(ngModel)]="allChecked"
            (ngModelChange)="checkAllCheckbox(allChecked)"
          >
            Select all
          </label>
        </div>
        <br />
        <nz-checkbox-wrapper
          style="width: 100%"
          (nzOnChange)="clickedCheckbox($event)"
        >
          <label
            nz-checkbox
            [nzValue]="data"
            [ngModel]="allCheckedStatus ? true : false"
            *ngFor="let data of typeValuesList"
            >{{ data }}</label
          >
        </nz-checkbox-wrapper>
        <div class="text-right p-3 side-popup-footer">
          <rd-button
            [btnType]="'default'"
            btnText="Cancel"
            [isIconNeeded]="false"
            [size]="'large'"
            (btnClick)="
              resetCreateRuleTable();
              selectTypeOptionsPopupVisibleStatus = false
            "
          ></rd-button>
          <rd-button
            [btnType]="'primary'"
            btnText="Add"
            [isIconNeeded]="false"
            [size]="'default'"
            [btnClass]="'custom-primaryfill-greenbtn'"
            (btnClick)="creatingRules()"
            [isDisabled]="isUserRoleViewer"
          ></rd-button>
        </div>
      </div>
      <div *ngIf="typeValuesList.length === 0" class="title-14-600 text-center">
        No data available
      </div>
    </div>
  </ng-container>
</nz-drawer>

<rd-drawer
  [title]="importTitle"
  [isCloseable]="false"
  [isVisible]="showImportDrawer"
  [width]="500"
  [placement]="'right'"
  [footer]="importdrawerfooterTpl"
>
  <div class="error-alert" *ngIf="!showImportLoading && showImportError">
    <app-alert
      [closable]="false"
      [message]="importErrorMessage"
      [type]="'error'"
      [closeText]="''"
    ></app-alert>
  </div>

  <div>
    <div class="spinner-style" *ngIf="showImportLoading">
      <rd-spinner></rd-spinner>
    </div>
    <div>
      <div class="label-heading">
        Enter a valid S3 location<span class="required-star">&nbsp;*</span>
      </div>
      <div class="d-flex">
        <input
          nz-input
          placeholder=""
          [(ngModel)]="importS3Link"
          (ngModelChange)="importS3LinkEntered()"
          [ngClass]="importS3error && submitImportClicked ? 'red-outline' : ''"
        />
        <div
          class="error d-flex align-items-center px-2 red-icon"
          *ngIf="importS3error && submitImportClicked"
        >
          <i
            nz-icon
            nzType="exclamation-circle"
            nzTheme="fill"
            nz-popover
            [nzPopoverContent]="contentImportTemplate"
          ></i>
          <ng-template #contentImportTemplate>
            <div>S3 Location is required</div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="download-template">
      <rd-button
        [btnType]="'primary'"
        btnText="Download Template"
        [isIconNeeded]="true"
        [btnClass]="'custom-primaryfill-greenbtn'"
        [iconType]="'download'"
        (btnClick)="downloadTemplate()"
      ></rd-button>
    </div>
  </div>
</rd-drawer>

<ng-template #titleTpl>
  <div class="side-popup-header d-flex-center-space-between">
    <div class="drawer-header">Select {{ selectedRuleType }}</div>
    <div class="drawer-cross-button text-right">
      <a
        nz-button
        nzType="link"
        (click)="
          selectTypeOptionsPopupVisibleStatus = false;
          selectedRuleType = '';
          allChecked = false;
          searchTypeListValues = '';
          allCheckedStatus = false
        "
      >
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #importTitle>
  <div class="side-popup-header d-flex-center-space-between">
    <div class="drawer-header">Import Stage Identification</div>
    <div class="drawer-cross-button text-right">
      <a nz-button nzType="link" (click)="closeImport()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </a>
    </div>
  </div>
</ng-template>

<app-action-confirmation-popup
  *ngIf="showStageIdentificationConfirmationFlag"
  (closePopup)="closeStageIdentificationConfirmationPopup($event)"
  (close)="showStageIdentificationConfirmationFlag = false"
  [confirmationConfig]="stageIdentificationConfirmationConfig"
></app-action-confirmation-popup>

<ng-template #importdrawerfooterTpl>
  <div class="import-footer">
    <rd-button
      [btnType]="'default'"
      btnText="Cancel"
      [isIconNeeded]="false"
      (btnClick)="closeImport()"
    ></rd-button>
    <rd-button
      [btnType]="'primary'"
      btnText="Import"
      [isIconNeeded]="false"
      [btnClass]="'custom-primaryfill-greenbtn'"
      (btnClick)="onImportSubmitClick()"
    ></rd-button>
  </div>
</ng-template>

<app-action-confirmation-popup
  *ngIf="showLotConfirmationFlag"
  (closePopup)="closeLotConfirmationPopup($event)"
  (closed)="showLotConfirmationFlag = false"
  [confirmationConfig]="lotConfirmationConfig"
></app-action-confirmation-popup>

<app-action-confirmation-popup
  *ngIf="showRegimenRefinementConfirmationFlag"
  (closePopup)="closeRegimenRefinementConfirmationPopup($event)"
  (close)="showRegimenRefinementConfirmationFlag = false"
  [confirmationConfig]="regimenRefinementConfirmationConfig"
></app-action-confirmation-popup>

<app-information-popup
  *ngIf="informationPopupStatus"
  (closePopup)="informationPopupStatus = false"
>
</app-information-popup>

<div
  class="spinner-style"
  *ngIf="regimenLotLoaderStatus || showLoaderForUserRole"
>
  <rd-spinner></rd-spinner>
</div>

<app-export-action-popup
  *ngIf="showExportPopup"
  (closeExportPopup)="closeExportPopup($event)"
  [exportBody]="exportBody"
></app-export-action-popup>

<app-action-confirmation-popup
  *ngIf="showApplyRulesConfirmation"
  (closePopup)="closeApplyRulesConfirmationPopup($event)"
  (close)="showApplyRulesConfirmation = false"
  [confirmationConfig]="lotConfirmationConfig"
></app-action-confirmation-popup>

<app-action-confirmation-popup
  *ngIf="showConfirmationFlag"
  (closePopup)="closeConfirmationPopup($event)"
  (close)="showWarningPopupValue = true; showConfirmationFlag = false"
  [confirmationConfig]="confirmationConfig"
></app-action-confirmation-popup>

<ng-template #stageIdentificationContent>
  <div
    class="m-auto w-fit-content mb-3"
    *ngIf="jobStatus.stageIdentificationJobStatusCompleted === false"
  >
    <app-alert
      [closable]="false"
      message="A job is running to compute Stage Identification. Please try after sometime."
      type="warning"
    ></app-alert>
  </div>
  <div class="stage-identification__header" *ngIf="!isUserRoleViewer">
    <!-- XXX: Import study button with onclick function as importClick() -->
    <rd-button
      btnText="Add Rules"
      btnType="default"
      [isIconNeeded]="true"
      iconType="plus"
      [iconStyle]="buttonIconStyle"
      [isDisabled]="jobStatus.stageIdentificationJobStatusCompleted === false"
      (btnClick)="handleAddRulesButtonClick()"
    ></rd-button>
  </div>
  <div class="stage-identification__content"
  [ngClass]="{'disable-section': (jobStatus.stageIdentificationJobStatusCompleted === false)}">
    <ng-container
      *ngIf="stageIdentificationMode === 'manual'; else rulesImportedMessage"
    >
      <ng-container *ngIf="isRuleListNonEmpty(); else noRulesMessage">
        <ng-container *ngFor="let category of stageIdentificationRules; let categoryIndex = index">
          <div class="rule-category" *ngIf="category.rules.length">
            <div class="rule__category-header">
              <p class="rule__category-title">{{ category.name }}:</p>
              <p class="rule__category-title rule__category-stage">
                {{ category.stage | titlecase }}
              </p>
            </div>
            <!-- TODO: Replace with RADUIS table. -->
            <nz-table
              nzSize="middle"
              [nzData]="category.rules"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
            >
            <hr>
              <tbody>
                <tr *ngFor="let rule of category.rules; let ruleIndex = index">
                  <td>
                    <ng-container
                      *ngFor="let item of rule.textPlus; let first = first"
                    >
                      <span
                        *ngIf="item.metadata?.length; else justText"
                        class="rule__popover-text"
                        nz-popover
                        nzType="primary"
                        nzPopoverOverlayClassName="subclass-popover"
                        [nzPopoverContent]="subclassPopoverContent"
                      >
                        {{ first ? ('' + item.text | titlecase) : item.text }}
                      </span>
                      <ng-template #subclassPopoverContent>
                        <p class="subclass-popover-content">
                          {{ item.metadata }}
                        </p>
                      </ng-template>
                      <ng-template #justText>
                        <span>
                          {{ first ? ('' + item.text | titlecase) : item.text }}
                        </span>
                      </ng-template>
                    </ng-container>
                  </td>
                  <td style="width:2rem">
                    <rd-button
                      btnType="text"
                      [isIconNeeded]="true"
                      [iconStyle]="{ color: 'rgba(0,0,0,.85)' }"
                      iconType="custom:delete-icon"
                      [btnStyle]="iconOnlyButtonStyle"
                      (btnClick)="handleRemoveRuleButtonClick(categoryIndex, ruleIndex)"
                    ></rd-button>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="stage-identification__footer" *ngIf="!isUserRoleViewer">
    <rd-button
      btnText="Apply Rules"
      [isDisabled]="jobStatus.stageIdentificationJobStatusCompleted === false"
      (btnClick)="handleApplyRulesButtonClick()"
    ></rd-button>
  </div>
</ng-template>

<app-stage-identification
  *ngIf="showStageIdentificationModal"
  [show]="showStageIdentificationModal"
  [studyId]="studyId"
  [existingRulesList]="stageIdentificationRules"
  (modalClose)="handleStageIdentificationModalClose($event)"
></app-stage-identification>

<ng-template #noRulesMessage>
  <p class="message-container">No rules have been configured yet.</p>
</ng-template>

<ng-template #rulesImportedMessage>
  <p class="message-container">Rules imported from S3 location.</p>
</ng-template>

<ng-template #errorMessageContainer>
  <rd-alert
    [showIcon]="true"
    type="error"
    message="Something went wrong. Please try again later."
  ></rd-alert>
</ng-template>

<ng-template #loadingSpinner>
  <div class="message-container">
    <rd-spinner></rd-spinner>
  </div>
</ng-template>
