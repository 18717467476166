import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action-confirmation-popup',
  templateUrl: './action-confirmation-popup.component.html',
  styleUrls: ['./action-confirmation-popup.component.scss'],
})
export class ActionConfirmationPopupComponent implements OnInit {
  @Input() confirmationConfig: ConfirmationConfig = {
    header: 'Confirmation',
    message: 'Do you really want to continue?',
    confirmationButtonName: 'Yes',
    cancelButtonName: 'cancel',
  };

  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Output() closed: EventEmitter<boolean> = new EventEmitter();
  @Output() closeViewPopup: EventEmitter<boolean> = new EventEmitter();

  isVisible: boolean = false;

  ngOnInit() {
    this.showModal();
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closePopup.emit(false);
  }
  handleClose() {
    this.isVisible = false;
    this.closed.emit();
  }

  handleOk() {
    this.isVisible = false;
    this.closePopup.emit(true);
  }
}

export interface ConfirmationConfig {
  header: string;
  message: string;
  confirmationButtonName?: string;
  cancelButtonName?: string;
}
