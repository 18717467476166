import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';
import { TransactionManagerService } from './shared/services/transaction-manager.service';
import {
  RDButtonModule,
  RDSpinnerModule,
  RDTableModule,
  RDTagModule,
  RDBreadcrumbModule,
  RDSelectModule,
  RDCollapseModule,
  RDTooltipModule,
  RDCheckBoxModule,
  RDDrawerModule,
  RDModalModule,
  RDStepsModule,
  RDAlertModule,
} from '@zs-ca/angular-cd-library';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { MyStudiesComponent } from './main/my-studies/my-studies.component';
import { ExecutionLogComponent } from './main/execution-log/execution-log.component';
import { CreateStudiesComponent } from './main/create-studies/create-studies.component';
import { TeamStudiesComponent } from './main/team-studies/team-studies.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ExportActionPopupModule } from './shared/components/export-action-popup/export-action-popup.module';
import { ActionConfirmationPopupModule } from './shared/components/action-confirmation-popup/action-confirmation-popup.module';
import { CloneActionPopupModule } from './shared/components/clone-action-popup/clone-action-popup.module';
import { AlertModule } from './shared/components/alert/alert.module';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { CohortVisualizationPopupModule } from './shared/components/cohort-visualization-popup/cohort-visualization-popup.module';
import { VennDiagramModule } from './shared/components/venn-diagram/venn-diagram.module';
import { HighchartsBarchartModule } from './shared/components/highcharts-barchart/highcharts-barchart.module';
import { HighchartsLinechartModule } from './shared/components/highcharts-linechart/highcharts-linechart.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { CreateStudyDetailModule } from './main/create-study-detail/create-study-detail.module';
import { DimensionMappingComponent } from './main/create-study-detail/dimension-mapping/dimension-mapping.component';
import { CreateStudyDetailComponent } from './main/create-study-detail/create-study-detail.component';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { EpisodeCreationComponent } from './main/create-study-detail/episode-creation/episode-creation.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { RegimenLotComponent } from './main/create-study-detail/regimen-lot/regimen-lot.component';
import { SelectInsightsComponent } from './main/create-study-detail/select-insights/select-insights.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { InformationPopupModule } from './shared/components/information-popup/information-popup.module';
import { ErrorComponent } from './shared/components/error/error.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { StageIdentificationComponent } from './main/create-study-detail/regimen-lot/stage-identification/stage-identification.component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SelectSubclassDrawerComponent } from './main/create-study-detail/regimen-lot/stage-identification/select-subclass-drawer/select-subclass-drawer.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MyStudiesComponent,
    ExecutionLogComponent,
    TeamStudiesComponent,
    CreateStudiesComponent,
    DimensionMappingComponent,
    CreateStudyDetailComponent,
    ClickOutsideDirective,
    EpisodeCreationComponent,
    RegimenLotComponent,
    SelectInsightsComponent,
    ErrorComponent,
    StageIdentificationComponent,
    SelectSubclassDrawerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    RDTableModule,
    RDButtonModule,
    RDTagModule,
    RDBreadcrumbModule,
    RDSelectModule,
    RDTooltipModule,
    RDCheckBoxModule,
    NzIconModule,
    NzInputModule,
    ReactiveFormsModule,
    NzDrawerModule,
    NzGridModule,
    NzSelectModule,
    NzPopoverModule,
    NzCheckboxModule,
    NzBreadCrumbModule,
    ExportActionPopupModule,
    ActionConfirmationPopupModule,
    InformationPopupModule,
    CloneActionPopupModule,
    RDSpinnerModule,
    RDCollapseModule,
    AlertModule,
    NzPopoverModule,
    RDButtonModule,
    CohortVisualizationPopupModule,
    VennDiagramModule,
    HighchartsBarchartModule,
    HighchartsLinechartModule,
    PipesModule,
    CreateStudyDetailModule,
    BrowserAnimationsModule,
    NzRadioModule,
    NzAlertModule,
    DragDropModule,
    RDDrawerModule,
    NzDatePickerModule,
    RDModalModule,
    NzSkeletonModule,
    RDStepsModule,
    NzInputNumberModule,
    NzTableModule,
    RDAlertModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    TransactionManagerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    NzNotificationService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
