import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CookieService } from 'src/app/shared/services/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  OAuthHost = environment.authConfig.issuer;
  appStateValues: any = {};

  constructor(
    private authService: AuthenticationService,
    private appState: AppStateService,
    private cookieService: CookieService
  ) {
    this.appState.appStateChange().subscribe((val: any) => {
      if (val && Object.keys(val).length > 0) {
        this.appStateValues = val;
      } else {
        this.appStateValues = {};
      }
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.custom_request(request)).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (error.status === 401) {
            if (environment.authEnabled) {
              this.appState.clearGlobalState();
              this.authService.login();
            } else {
              window.open(
                `${environment.config.idm.loginUrl}${window.location.origin}`,
                '_self'
              );
            }
          }
        },
      })
    );
  }

  custom_request(request: any) {
    let url = request.url;
    if (url.includes(this.OAuthHost) && environment.authEnabled) {
      const reqClone = request.clone({});
      return reqClone;
    } else {
      let headers = request.headers;
      if (environment.authEnabled) {
        headers = headers
          .set(
            'Authorization',
            `Bearer ${
              this.cookieService.get(
                this.authService.accessTokenKey
              ) || ''
            }`
          )
          .set(
            'X-ID-Token',
            `Bearer ${
              this.cookieService.get(this.authService.idTokenKey) ||
              ''
            }`
          );
      }
      const reqClone = request.clone({
        withCredentials: true,
        headers: headers,
      });
      return reqClone;
    }
  }
}
