export class RecentStudies {
  data: [RecentStudiesDetails];

  status: string;

  noOfStudies : number;

  constructor(response: [RecentStudiesDetails], status: string , noOfStudies : number) {
    this.data = response;
    this.status = status;
    this.noOfStudies = noOfStudies;
  }
}


export class RecentStudiesDetails {
  studyId: number;

  studyTitle: string;

  cohortName: string;

  dataSource: string;

  createdOn: string;

  createdBy: string;

  isCloning: boolean;

  isRefreshStudy: boolean;

  role: boolean;

  email : boolean;

  constructor(data: any) {
    this.studyId = data.studyId;
    this.studyTitle = data.studyTitle;
    this.cohortName = data.cohortName;
    this.dataSource = data.dataSource;
    this.createdOn = data.createdOn;
    this.createdBy = data.createdBy;
    this.isCloning = data.isCloning;
    this.isRefreshStudy = data.isRefreshStudy;
    this.role = data.role;
    this.email = data.email;
  }
}
