
export class HighchartsLineChartModel {
  height: any;

  width: any;

  xAxis: any[] = []; // xAxis values

  data: any[] = [];

  graphId: string = '';

  graphTitle: string = '';

  xAxisTitle: string = '';

  yAxisTitle: string = '';

  yAxisLabel: string = '';

  xAxisLabel: string = '';

  // @ts-ignore
  showLegends: boolean;

  showTooltip: boolean = false;

  xAxisTooltipLabel: string = '';

  yAxisTooltipLabel: string = '';

  tickPositions: any[] = [0, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132];

  plotLines: any[] = [];

  percentageFormatter: boolean = false;
}
