import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionConfirmationPopupComponent } from "./action-confirmation-popup.component";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzButtonModule } from "ng-zorro-antd/button";



@NgModule({
  declarations: [
    ActionConfirmationPopupComponent
  ],
  exports: [
    ActionConfirmationPopupComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule
  ]
})
export class ActionConfirmationPopupModule { }
