<script src="/bower_components/venn.js/venn.min.js"></script>
<script src="/bower_components/angular-venn/angular-venn.js"></script>

<div id="vennChart"></div>

<div nz-row class="legend-details" *ngIf="displayVennLegend">
  <div nz-col nzSpan="1" class="box px-box"></div>
  <div nz-col nzSpan="1" class="legend-name">Px</div>
    <div nz-col nzSpan="1" class="box rx-box"></div>
  <div nz-col nzSpan="1" class="legend-name">Rx</div>
    <div nz-col nzSpan="1" class="box dx-box"></div>
  <div nz-col nzSpan="1" class="legend-name">Dx</div>
</div>
