import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { APPLICATION_TITLE } from './shared/constants/app-constants';
import { CustomIconService } from './shared/services/custom-icon.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  appStateValues: any = {};

  showErrorMessage = true;
  authErrorMessage: any = 'Authenticating, Please wait...';
  appStateSubs: any;
  authServiceSubs: any;
  pageInitialized = false;

  constructor(
    private titleService: Title,
    private customIconService: CustomIconService,
    private router: Router,
    private authService: AuthenticationService,
    private appState: AppStateService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(APPLICATION_TITLE);
    this.customIconService.addCustomIconsToNzLibrary();
    if (environment.authEnabled) {
      this.authenticateUser();
      this.appStateSubs = this.appState
        .appStateChange()
        .subscribe((val: any) => {
          if (val && Object.keys(val).length > 0) {
            this.appStateValues = val;
          } else {
            this.appStateValues = {};
          }
          if (!this.pageInitialized) {
            if (this.cookieService.get(this.authService.accessTokenKey)) {
              this.showErrorMessage = false;
            } else {
              const code = this.getUrlParam('code');
              if (code === 'undefined' || !code) {
                this.authService.login();
              } else if (code) {
                this.router.navigate([], {
                  queryParams: {
                    yourParamName: null,
                    youCanRemoveMultiple: null,
                  },
                  queryParamsHandling: 'merge',
                });
                this.authService.getAccessToken(code);
              }
            }
          }
          this.pageInitialized = true;
        });
    } else {
      this.showErrorMessage = false;
    }
  }

  authenticateUser() {
    this.authServiceSubs = this.authService.authResponse.subscribe((res) => {
      if (res['valid']) {
        this.showErrorMessage = false;
      } else {
        this.showErrorMessage = true;
        this.authErrorMessage = res['message'] || 'Something went wrong.';
      }
    });
  }

  getUrlParam(key: string) {
    const results = new RegExp('[?&]' + key + '=([^&#]*)').exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }

  ngOnDestroy() {
    this.appStateSubs?.unsubscribe();
    this.authServiceSubs?.unsubscribe();
  }
}
