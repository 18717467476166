import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { SOMETHING_WENT_WRONG } from '../../constants/app-constants';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() closable: boolean = false;
  @Input() type: 'success' | 'info' | 'warning' | 'error' = 'info';
  @Input() message: string | TemplateRef<void> = '';
  @Input() showIcon: boolean = false;
  @Input() closeText: string | TemplateRef<void> = 'X';
  @Output() alertClosed: EventEmitter<any> = new EventEmitter<any>();

  alertClosedFunction($event: any) {
    this.alertClosed.emit($event);
  }
}
