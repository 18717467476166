<div class="main-class">
  <div class="button-section">
    <div class="page-heading">Team Studies</div>

    <!--  Alert Start  -->
    <div class="list-error-box" *ngIf="showErrorAlert">
      <app-alert
        [closable]="false"
        [message]="alertErrorMessage"
        (alertClosed)="alertClosed($event)"
        [type]="alertType"
      ></app-alert>
    </div>
    <!--  Alert End  -->

    <div>
      <rd-button
        [btnType]="'primary'"
        btnText="Create Study"
        [isIconNeeded]="true"
        [iconType]="'plus'"
        [btnClass]="'custom-primaryfill-greenbtn'"
        (btnClick)="navigateCreateStudy()"
      ></rd-button>
    </div>
  </div>
  <div class="search-section">
    <div class="search-box">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input
          type="text"
          nz-input
          placeholder="Search Study Topic"
          [(ngModel)]="searchText"
          (ngModelChange)="searchStudy()"
        />
      </nz-input-group>
    </div>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
  <div class="team-studies-section scrollable-table page-scroll">
    <rd-table
      [isLoading]="!isTeamStudiesLoaded"
      [tableColumns]="teamStudiesColumns"
      [tableData]="teamStudiesData"
      [tableScrollDetails]="teamStudiesScrollDetails"
      [pageIndex]="pageIndex"
      [pageSize]="defaultPageSize"
      [totalCount]="totalNoOfStudies"
      [errorMessage]="tableErrorMessage"
      [loadingRows]="defaultPageSize"
      [errorType]="tableErrorType"
      [showPagination]="true"
      [frontEndPagination]="false"
      (paginationChange)="onTeamStudiesTablePaginationChange($event)"
      (sortApply)="onTeamStudiesTableSort($event)"
      (tableRowClick)="teamStudiesTableActionIconClicked($event)"
    ></rd-table>
  </div>
</div>

<app-action-confirmation-popup
  *ngIf="showPopup"
  (closePopup)="closePopup($event)"
  (close)="closePopup($event)"
  [confirmationConfig]="confirmationConfig"
></app-action-confirmation-popup>

<app-export-action-popup
  *ngIf="showExportPopup"
  (closeExportPopup)="closeExportPopup($event)"
  [exportBody]="exportBody"
></app-export-action-popup>

<app-clone-action-popup
  *ngIf="showClonePopup"
  (closeClonePopup)="closeClonePopup($event)"
  [cloneBody]="cloneBody"
>
</app-clone-action-popup>

<rd-modal
  [isVisible]="showRefreshSuccess"
  [isCloseable]="true"
  [isMaskClosable]="true"
  (modalCloseEvent)="closeRefreshModal()"
>
  <ng-container ngProjectAs="[rdModalTitle]"
    ><span class="popup-heading">Information</span></ng-container
  >
  <ng-container ngProjectAs="[rdModalContent]"
    ><span class="popup-text"
      >Your job has been submitted successfully. You can view job status in the
      <a [routerLink]="['/logs']">My Jobs</a> tab.
    </span></ng-container
  >
  <ng-container ngProjectAs="[rdModalFooter]">
    <rd-button
      btnType="default"
      btnText="Cancel"
      (click)="closeRefreshModal()"
    ></rd-button>
  </ng-container>
</rd-modal>

