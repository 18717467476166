import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { HighchartsLineChartModel } from 'src/app/shared/components/highcharts-linechart/highcharts-linechart.model';
import {
  API_SUCCESS_KEY,
  MAXIMUM_CSV_FILE_SIZE,
  SOMETHING_WENT_WRONG,
} from 'src/app/shared/constants/app-constants';
import { StageIdentificationService } from 'src/app/shared/services';
import { CommonService } from 'src/app/shared/services/common.service';
import { RegimenLotService } from 'src/app/shared/services/regimen-lot.service';
import {
  ruleCategories,
  RuleCategory,
} from './stage-identification/stage-identification.model';

@Component({
  selector: 'app-regimen-lot',
  templateUrl: './regimen-lot.component.html',
  styleUrls: ['./regimen-lot.component.scss'],
})
export class RegimenLotComponent implements OnInit, OnDestroy {

  readonly iconOnlyButtonStyle = {
    padding: '0.25rem',
    margin: 0,
    height: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'right',
  };

  readonly collapseTooltipText = {
    'Stage Identification': `
      The section provides the user with the option to design Neo-Adjuvant, Adjuvant and
      Metastatic Stage Identification rules such that the patients can be tagged accordingly.
      `,
  };

  studyId: any;
  breadcrumbData: any[] = [];
  selectTypeOptionsPopupVisibleStatus = false;
  // ruleSelectors
  selectedRuleStartOfThe: any;
  selectedRuleStageIs: any;
  inputRuleDate: any;
  selectedRuleDays: any;
  selectesRuleDaysAfter: any;
  selectedRuleType: any;
  selectedCheckboxes = [];
  ruleTypeEnableStatus = false;
  lotToolTop =
    'Provides the user with option to create LOT using  3 standard rules or define their own LOT and maintenance rules.';
  lotJobStatusAlert =
    'A job is running to compute Line of Therapy. Please try after sometime.';

  lotDropDownData: any = [
    {
      label: 'A switch from a product is a line change',
      value: '1',
      code: 'SWITCH',
    },
    {
      label:
        'Addition of a product is a line change but, drop off from a product is not',
      value: '2',
      code: 'DROP_OFF',
    },
    {
      label: 'Increment the line only if the product has not been used before',
      value: '3',
      code: 'INCREMENT',
    },
    {
      label:
        'Use progression/maintenance rules defined in the regimen conditioning',
      value: '4',
      code: 'PROGRESSION_MAINTAINENCE',
    },
  ];

  // TODO: Remove.
  neoAdjuvantRulesList: any = {
    rules: [] as any,
    stage: 'Neo-Adjuvant',
    rule: '',
    studyId: '',
  };

  // TODO: Remove.
  adjuvantRulesList: any = {
    rules: [] as any,
    stage: 'Adjuvant',
    rule: '',
    studyId: '',
  };

  // TODO: Remove.
  metastaticRulesList: any = {
    rules: [],
    stage: 'Metastatic',
    rule: '',
    studyId: '',
  };

  savedRegimenDropRules: any = [];

  readonly buttonIconStyle = {
    alignItems: 'center',
    color: 'rgba(0,0,0,.85)',
    display: 'flex',
  };

  ruleDetail = [
    {
      code: 'neoAdjuvantRulesList',
      ruleStartName: 'Neo Adjuvant Rules',
      rulesList: this.neoAdjuvantRulesList.rules,
      rule: this.neoAdjuvantRulesList.rule,
      dragDropList: ['adjuvantRulesList', 'metastaticRulesList'] as any,
    },
    {
      code: 'adjuvantRulesList',
      ruleStartName: 'Adjuvant Rules',
      rulesList: this.adjuvantRulesList.rules,
      rule: this.adjuvantRulesList.rule,
      dragDropList: ['neoAdjuvantRulesList', 'metastaticRulesList'] as any,
    },
    {
      code: 'metastaticRulesList',
      ruleStartName: 'Metastatic Rules',
      rulesList: this.metastaticRulesList.rules,
      rule: this.metastaticRulesList.rule,
      dragDropList: ['adjuvantRulesList', 'neoAdjuvantRulesList'] as any,
    },
  ];

  typeValuesList = [];

  searchTypeListValues = '';

  isTypeValuesLoaded = false;

  allChecked = false;
  allCheckedStatus = false;
  onSuccessJobCreation = false;

  showStageIdentificationConfirmationFlag = false;
  showLotConfirmationFlag = false;
  stageIdentificationConfirmationConfig = {
    header: 'Confirmation',
    message: 'Do you want downstream dependent parameters?',
    confirmationButtonName: 'Yes',
    cancelButtonName: 'No',
  };
  lotConfirmationConfig = {
    header: 'Confirmation',
    message: 'Do you want downstream dependent parameters?',
    confirmationButtonName: 'Yes',
    cancelButtonName: 'No',
  };

  lotSectionEnableStatus = false;
  // readonly noDataErrorMsg = NO_DATA;

  selectedRegimenTransitionRuleFor = '';
  noOfIterations = 2;
  listOfPreviousRegimenProducts: any = [];
  listOfCurrentRegimenProducts: any = [];
  listOfRegimenDropProducts: any = [];
  drugsList: any = [];
  drugsAndProductsList: any;
  mergeRegimenRenamingRulesList = [
    'All the products',
    'Initial Regimen',
    'Most Recent Regimen',
    'Longest Regimen',
    'Regimen with highest constituents',
    'Regimen with lowest constituents',
  ];
  mergeRegimenRenamingRulesListCopy: any = [];
  regimenMergingRule = '';
  public fileMessage = {
    corruptedFile: 'File is corrupted and cannot be uploaded.',
    exceedSize: 'Maximum upload file size: 20MB.',
    csvExpected: 'Incorrect file type. CSV is expected',
    success: 'File uploaded successfully.',
  };
  regimenLengthRules = {
    selectedGapInDaysOperator: null,
    gapInDaysValue: null,
    selectedGapCalculatedFrom: 'end',
    selectedLengthInDaysPreviousRegimenOperator: null,
    lengthInDaysPreviousRegimenValue: null,
    selectedLengthInDaysCurrentRegimenOperator: null,
    lengthInDaysCurrentRegimenValue: null,
    lineNumberMaintenanceApplicableValue: null,
  };

  regimenTransitionRules: any = [];
  showImportDrawer = false;

  comparatorList = ['>', '<', '=', '>=', '<=', '!='];
  regimenMergingRulesList = [
    { label: 'equals to', value: 'Equals' },
    { label: 'is a subset of', value: 'Subset' },
    { label: 'is a superset of', value: 'Superset' },
    { label: 'has of common products with', value: 'Common' },
    { label: 'is exclusive of', value: 'Exclusive' },
    { label: 'transitioning to', value: 'Transitioning' },
  ];
  regimenTransitionRulesList = ['Merge', 'Progression', 'Maintenance'];

  regimenMergeSummaryGrid = {
    isLoaded: true,
    uploadStatus: false,
    columns: [
      {
        name: '# of iterations',
        key: 'no_of_iteration',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '70%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
      {
        name: '% of Regimens merged',
        key: 'regimen_merged',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '30%',
        align: 'left',
        showIcon: false,
        sortable: false,
        columnStyle: {
          overflow: 'hidden',
          'max-width': '25ch',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
        },
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '100%', y: '200px' },
    pageIndex: 1,
    sortingOrder: 'asc',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    tableErrorType: 'info' as 'info',
    tableErrorMessage: 'No Data found',
    searchToggleStatus: false,
  };

  regimenDropRulesGrid = {
    isLoaded: true,
    uploadStatus: false,
    columns: [
      {
        name: 'Product',
        key: 'product',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '40%',
        align: 'left',
        showIcon: false,
        sortable: false,
        columnStyle: {
          overflow: 'hidden',
          'max-width': '25ch',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
        },
      },
      {
        name: 'Cutoff (Days)',
        key: 'cutOfDays',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '30%',
        align: 'left',
        showIcon: false,
        sortable: false,
        columnStyle: {
          overflow: 'hidden',
          'max-width': '25ch',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
        },
      },
      {
        name: 'Action',
        key: 'actions',
        dataType: 'string',
        defaultSortOrder: 'desc',
        sortDirections: ['ascend', 'descend'],
        width: '30%',
        showIcon: false,
        type: 'actions',
        align: 'center',
        sortable: false,
        actionIcons: [
          {
            type: 'custom:delete-icon',
            key: 'delete',
            title: 'Delete',
            theme: 'fill',
          },
        ],
      },
    ],
    gridData: [] as any,
    dataCount: 0,
    scrollDetails: { x: '100%', y: '240px' },
    pageIndex: 1,
    sortingOrder: 'asc',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    tableErrorType: 'info' as 'info',
    tableErrorMessage: 'No Data found',
    searchToggleStatus: false,
  };

  cutOfDaysValue = '';

  regimenDropRulesList: any = [];

  importS3Link = '';

  importS3error = false;

  submitImportClicked = false;

  showImportLoading = false;

  showImportError = false;

  importErrorMessage = SOMETHING_WENT_WRONG;

  selectedValue = '1';

  selectedMethod = false;

  ngModelChange: any;

  navigateUrl = '';

  lineChartConfigForRegimenLengthDistribution: HighchartsLineChartModel =
    new HighchartsLineChartModel();

  lineChartConfig: HighchartsLineChartModel = new HighchartsLineChartModel();

  lineChartRegimenLengthDistributionStatus = false;

  public regimenLengthDistributionLineData: number[] = [];

  showRegimenRefinementConfirmationFlag = false;

  appNavigate: Subscription;

  regimenRefinementConfirmationConfig = {
    header: 'Confirmation',
    message: 'Do you want downstream dependent parameters?',
    confirmationButtonName: 'Yes',
    cancelButtonName: 'No',
  };

  regimenCutOff = '-';

  jobStatus: any = {
    lineofTherapyJobStatusCompleted: null,
    regimenDistributionJobStatusCompleted: null,
    regimenDropJobStatusCompleted: null,
    regimenMergeJobStatusCompleted: null,
    stageIdentificationJobStatusCompleted: null,
  };

  informationPopupStatus = false;

  regimenLotLoaderStatus = false;

  showLoaderForUserRole = false;

  exportBody: any;

  showExportPopup = false;
  showApplyRulesConfirmation = false;

  showConfirmationFlag = false;
  confirmationConfig = {
    header: 'Unsaved Changes',
    message: 'Your study will not be saved.',
    confirmationButtonName: 'Ok',
    cancelButtonName: 'Cancel',
  };

  showWarningPopupValue = true;

  selectesRuleDaysAfterInputValue: any;

  drugListLoadingStatus = true;

  private postTypeOptionsListSubscription: any;

  checked = true;

  regimenTransitionRuleFilter = 'All';

  regimenTransitionRulesDataFilterStatus = false;

  listOfSelectedPreviousRegimenDrugs = {
    data: [] as any,
    selectAllStatus: false,
    selectAllLoadingStatus: false,
  };

  listOfSelectedPreviousRegimenProducts = {
    data: [] as any,
    selectAllStatus: false,
    selectAllLoadingStatus: false,
  };

  listOfSelectedCurrentRegimenDrugs = {
    data: [] as any,
    selectAllStatus: false,
    selectAllLoadingStatus: false,
  };

  listOfSelectedCurrentRegimenProducts = {
    data: [] as any,
    selectAllStatus: false,
    selectAllLoadingStatus: false,
  };
  listOfSelectedRegimenDropDrugs = {
    data: [] as any,
    selectAllStatus: false,
    selectAllLoadingStatus: false,
  };
  listOfSelectedRegimenDropProducts = {
    data: [] as any,
    selectAllStatus: false,
    selectAllLoadingStatus: false,
  };

  savedRegimenTransitionRule: any = [];

  isRequiredJobTriggeredStatus = false;

  isUserRoleViewer = true;
  allBreadCrumbData: any = [];
  viewerBreadCrumbData: any = [];

  savedLotobject: any;
  savedLotMethod: any;

  stageIdentificationMode: 'import' | 'manual' = 'manual';

  stageIdentificationFetchStatus: 'loading' | 'error' | 'available' = 'loading';

  stageIdentificationRules: RuleCategory[] = [];

  isRuleListNonEmpty = () =>
    this.stageIdentificationRules.some((rC) => rC.rules?.length);

  showStageIdentificationModal = false;

  constructor(
    private route: ActivatedRoute,
    private regimenLotService: RegimenLotService,
    private router: Router,
    private commonService: CommonService,
    private stageIdentification: StageIdentificationService
  ) {
    this.appNavigate = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigateUrl = event.url;
      }
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      this.studyId = params.get('studyId');
      this.neoAdjuvantRulesList.studyId = this.studyId;
      this.adjuvantRulesList.studyId = this.studyId;
      this.metastaticRulesList.studyId = this.studyId;
    });
    this.breadcrumbData = [
      { displayName: 'All Studies', navigationPath: '/home/' },
      {
        displayName: 'Study Details',
        navigationPath: `/studies/edit/${this.studyId}`,
      },
      {
        displayName: 'Dimension Mapping',
        navigationPath: `/studies/dimension-mapping/${this.studyId}`,
      },
      {
        displayName: 'Episode Creation',
        navigationPath: `/studies/episode-creation/${this.studyId}`,
      },
      {
        displayName: 'Regimen & LoT',
        navigationPath: '',
      },
    ];

    this.fetchUserRole();
    this.checkJobStatus();
    this.fetchStageIdentificationRules();
    this.fetchRegimenConditioningDrugsAndProductsList();
    this.fetchOverallRegimenLengthDistribution();
    this.fetchRegimenMergeSummary();
    this.fetchRegimenConditioningSavedRules();
    this.fetchConfigData();
    this.setBreadCrumbForViewer();
  }

  selectAllDropdownOption(
    event: any,
    originalList: any,
    selectedList: any,
    selectType = ''
  ) {
    let index = event.indexOf('All');
    let length = event.length;

    if (event[length - 1] == 'All') {
      this[selectedList as keyof RegimenLotComponent].data = [
        'All',
        ...this[originalList as keyof RegimenLotComponent],
      ];
      this[selectedList as keyof RegimenLotComponent].selectAllStatus = true;
    } else if (
      index == -1 &&
      this[selectedList as keyof RegimenLotComponent].selectAllStatus
    ) {
      this[selectedList as keyof RegimenLotComponent].data = [];
      this[selectedList as keyof RegimenLotComponent].selectAllStatus = false;
    } else if (
      index != -1 &&
      this[selectedList as keyof RegimenLotComponent].data.length !=
        this[originalList as keyof RegimenLotComponent].length + 1
    ) {
      this[selectedList as keyof RegimenLotComponent].data = event
        .slice(0, index)
        .concat(event.slice(index + 1, event.length));
      this[selectedList as keyof RegimenLotComponent].selectAllStatus = false;
    } else if (
      this[selectedList as keyof RegimenLotComponent].data.length ==
      this[originalList as keyof RegimenLotComponent].length
    ) {
      this[selectedList as keyof RegimenLotComponent].data = [
        'All',
        ...this[originalList as keyof RegimenLotComponent],
      ];
      this[selectedList as keyof RegimenLotComponent].selectAllStatus = true;
    }
    if (selectType != '') {
      this.onSelectDrugsList(selectType);
    }
  }

  checkAllCheckbox(event: any) {
    this.allCheckedStatus = false;
    setTimeout(() => {
      if (event) {
        this.allCheckedStatus = true;
        this.selectedCheckboxes = JSON.parse(
          JSON.stringify(this.typeValuesList)
        );
      } else {
        this.allCheckedStatus = false;
        this.selectedCheckboxes = [];
      }
    }, 100);
  }

  clickedCheckbox(event: any) {
    this.allChecked = false;
    this.selectedCheckboxes = event;
  }

  fetchTypeOptionsList() {
    this.isTypeValuesLoaded = false;
    let selectedTypeValue = this.selectedRuleType;

    let searchString = { searchText: this.searchTypeListValues };
    if (this.postTypeOptionsListSubscription) {
      this.postTypeOptionsListSubscription.unsubscribe();
    }
    this.postTypeOptionsListSubscription = this.regimenLotService
      .postTypeOptionsList(this.studyId, selectedTypeValue, searchString)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.typeValuesList = response.data.splice(0, 50);
            this.isTypeValuesLoaded = true;
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  fetchConfigData() {
    this.regimenLotService.postConfig(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.regimenDropRulesGrid.gridData = [];
          this.savedRegimenDropRules = [];
          this.savedLotobject = this.lotDropDownData.find((data: any) => {
            return data.code == response.lotMethod;
          });
          this.selectedValue = this.savedLotobject.value;
          this.savedLotMethod = this.lotDropDownData.find((data: any) => {
            return response.isLotOnEpisodes;
          });
          this.selectedMethod = Boolean(this.savedLotMethod?.value);
          if (
            response.regimenDropRules &&
            Object.keys(response.regimenDropRules).length > 0
          ) {
            Object.keys(response.regimenDropRules).forEach((key) => {
              let obj = {
                product: key,
                cutOfDays: response.regimenDropRules[key],
                visibleActionIcons: ['delete'],
                disabledActionIcons: [],
              };
              this.regimenDropRulesGrid.gridData = [
                ...this.regimenDropRulesGrid.gridData,
                obj,
              ];
              this.savedRegimenDropRules = [
                ...this.regimenDropRulesGrid.gridData,
              ];
            });
          } else {
            this.savedRegimenDropRules = [];
          }
          this.mergeRegimenRenamingRulesList =
            response.regimenMergeNamingRules &&
            response.regimenMergeNamingRules.length > 0
              ? response.regimenMergeNamingRules
              : this.mergeRegimenRenamingRulesList;
          this.mergeRegimenRenamingRulesListCopy = JSON.stringify(
            this.mergeRegimenRenamingRulesList
          );
          this.stageIdentificationMode = response.stageIdentificationFilePath
            ?.length
            ? 'import'
            : 'manual';
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      },
      (error: any) => {
        this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
      }
    );
  }

  allowNumeric(event: any) {
    if (event.type == 'paste') {
      let clipboardData = event.clipboardData.getData('text');
      return !/^[0-9]+$/.test(clipboardData) ? false : true;
    } else {
      return !/^[0-9 ]+$/.test(event.key) ? false : true;
    }
  }

  checkRuleFilterDataStatus(event: any) {
    this.regimenTransitionRulesDataFilterStatus = false;
    if (event != 'All') {
      this.regimenTransitionRulesDataFilterStatus =
        !this.regimenTransitionRules.some((data: any) => {
          return data.regimen_transition_rule == event;
        });
    }
  }

  validatingCreateRules() {
    if (
      this.selectedRuleStartOfThe &&
      this.selectedRuleStageIs &&
      this.inputRuleDate &&
      this.selectedRuleDays &&
      this.selectesRuleDaysAfter
    ) {
      if (
        (this.selectesRuleDaysAfter == 'Nth' &&
          this.selectesRuleDaysAfterInputValue) ||
        this.selectesRuleDaysAfter != 'Nth'
      ) {
        this.ruleTypeEnableStatus = true;
      } else {
        this.selectedRuleType = '';
        this.selectesRuleDaysAfterInputValue = '';
        this.ruleTypeEnableStatus = false;
      }
    } else {
      this.selectedRuleType = '';
      this.ruleTypeEnableStatus = false;
    }
  }

  creatingRules() {
    let obj = {
      days: this.inputRuleDate,
      occurrence:
        this.selectesRuleDaysAfter != 'Nth'
          ? this.selectesRuleDaysAfter.toLowerCase()
          : this.selectesRuleDaysAfterInputValue,
      occurrenceTime: this.selectedRuleDays.toLowerCase(),
      type: this.selectedRuleType,
      name: this.selectedCheckboxes,
      priority: 1,
      studyId: this.studyId,
    };

    let objKeyName =
      this.selectedRuleStartOfThe == 'Neo-Adjuvant'
        ? 'neoAdjuvantRulesList'
        : this.selectedRuleStartOfThe == 'Adjuvant'
        ? 'adjuvantRulesList'
        : this.selectedRuleStartOfThe == 'Metastatic' && 'metastaticRulesList';
    this[objKeyName as keyof RegimenLotComponent].rules.push(obj);
    this[objKeyName as keyof RegimenLotComponent].rule =
      this.selectedRuleStageIs;
    this.selectTypeOptionsPopupVisibleStatus = false;
    this.searchTypeListValues = '';
    this.allChecked = false;
    this.resetCreateRuleTable();
  }

  deleteRules(sectionCode: any, index: any) {
    if (sectionCode == 'regimenTransitionRules') {
      this[sectionCode as keyof RegimenLotComponent].splice(index, 1);
    } else {
      this[sectionCode as keyof RegimenLotComponent].rules.splice(index, 1);
      if (this[sectionCode as keyof RegimenLotComponent].rules.length == 0) {
        this[sectionCode as keyof RegimenLotComponent].rule = '';
      }
    }
  }

  checkJobStatus(triggerInformationPopup = false) {
    this.regimenLotLoaderStatus = true;
    this.regimenLotService.getJobStatus(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          if (response.data.length === 0) {
            this.regimenLotLoaderStatus = false;
            this.isRequiredJobTriggeredStatus = true;
          } else {
            response.data.forEach((data: any) => {
              const variableName = (
                data.category.charAt(0).toLowerCase() + data.category.slice(1)
              ).replace(/ /g, '');
              this.jobStatus[variableName + 'JobStatusCompleted'] =
                data.status === 'COMPLETED';
            });
            if (triggerInformationPopup) {
              this.informationPopupStatus = true;
            }
            this.regimenLotLoaderStatus = false;
          }
          this.fetchConfigData();
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      },
      (error: any) => {
        this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
      }
    );
  }

  navigationNext() {
    for (let [key, value] of Object.entries(this.jobStatus)) {
      if (value == false) {
        this.informationPopupStatus = true;
        return true;
      }
    }
    this.navigateWrtUrl('studies/select-insights/', true);
  }

  checkLotJobStatus() {
    this.regimenLotService.getJobStatus(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          if (
            this.findStatusValue(response.data, 'Regimen Distribution') !=
              'COMPLETED' ||
            this.findStatusValue(response.data, 'Line of Therapy') !=
              'COMPLETED'
          ) {
            this.onSuccessJobCreation = true;
            this.lotSectionEnableStatus = true;
          } else {
            this.onSuccessJobCreation = false;
            this.lotSectionEnableStatus = false;
          }
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
        this.onSuccessJobCreation = false;
      },
      (error: any) => {
        this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
      }
    );
  }

  findStatusValue(response: any, categoryName: any) {
    let obj = response.find((data: any) => {
      return data.category == categoryName;
    });
    if (categoryName == 'Stage Identification') {
      return obj ? obj.status : 'COMPLETED';
    } else {
      return obj.status;
    }
  }

  private fetchStageIdentificationRules() {
    this.stageIdentificationFetchStatus = 'loading';
    this.stageIdentification
      .getStageIdentificationRules(this.studyId)
      .subscribe(
        (data) => {
          if (data) {
            this.stageIdentificationFetchStatus = 'available';
            this.stageIdentificationRules = Object.entries(
              ruleCategories()
            ).map(([category, name]) => ({
              name,
              key: category,
              rules: this.stageIdentification.prepareTextForRules(
                data[category]?.rules || []
              ),
              stage: data[category]?.rule_stage || 'Earliest',
            }));
          } else {
            this.stageIdentificationFetchStatus = 'error';
            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        () => {
          this.stageIdentificationFetchStatus = 'error';
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  checkStageIdentificationChangeDetectionStatus() {
    // TODO: Add Change Detection.
    return true;
  }

  checkRegimenConditioningChangeDetectionStatus() {
    if (
      JSON.stringify(this.regimenTransitionRules) ==
      JSON.stringify(this.savedRegimenTransitionRule)
    ) {
      return true;
    } else {
      return false;
    }
  }

  triggerStageIdentificationJob(status: any) {
    this.regimenLotLoaderStatus = true;
    let payload = {
      run_emr: true,
      update_downstream_jobs: status,
    };
    this.regimenLotService.postJobExecute(this.studyId, payload).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.checkJobStatus(true);
        } else {
          this.regimenLotLoaderStatus = false;
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      },
      (error: any) => {
        this.regimenLotLoaderStatus = false;
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
      }
    );
  }

  fetchRegimenConditioningDrugsAndProductsList() {
    this.drugListLoadingStatus = true;
    this.regimenLotService
      .getRegimenConditioningDrugsAndProductsList(this.studyId)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.drugsAndProductsList = response.data;
            this.drugsList = Object.keys(response.data);
            this.drugListLoadingStatus = false;
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  exportButtonClick() {
    this.regimenLotLoaderStatus = true;
    this.regimenLotService.getExportData(this.studyId).subscribe(
      (data: any) => {
        if (data.status === API_SUCCESS_KEY) {
          this.regimenLotLoaderStatus = false;

          data.data.map((item: any) => {
            item.filename = item.filename + ' :';
            return item;
          });
          this.exportBody = data;
          this.showExportPopup = true;
        }
      },
      (error: any) => {
        this.regimenLotLoaderStatus = false;

        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  closeExportPopup($event: any) {
    this.showExportPopup = false;
  }

  downloadRegimenSummary(event: any) {
    this.regimenLotLoaderStatus = true;
    event.stopPropagation();
    this.regimenLotService.getDownloadRegimenSummary(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          if (response.data == '') {
            this.commonService.createNotification(
              'success',
              'No file present for downloading.'
            );
            this.regimenLotLoaderStatus = false;
          } else {
            const blob = this.base64ToBlob(response['data'], 'text/csv');
            saveAs(blob, `regimen_summary.csv`);
            this.regimenLotLoaderStatus = false;
          }
        } else {
          this.regimenLotLoaderStatus = false;
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      },
      (error: any) => {
        this.regimenLotLoaderStatus = false;
        this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
      }
    );
  }

  setLineChartForGrace(): void {
    this.lineChartConfigForRegimenLengthDistribution.graphTitle = '';
    this.lineChartConfigForRegimenLengthDistribution.graphId = 'rldChart';
    this.lineChartConfigForRegimenLengthDistribution.yAxisTitle = '%Regimens';
    this.lineChartConfigForRegimenLengthDistribution.xAxisTitle =
      'Regimen Length (in days)';
    this.lineChartConfigForRegimenLengthDistribution.height = 500;
    this.lineChartConfigForRegimenLengthDistribution.xAxisTooltipLabel =
      'Regimen Length (in days)';
    this.lineChartConfigForRegimenLengthDistribution.yAxisTooltipLabel =
      '%Regimens';
    this.lineChartConfigForRegimenLengthDistribution.showTooltip = true;
    this.lineChartConfigForRegimenLengthDistribution.showLegends = false;
    this.lineChartConfigForRegimenLengthDistribution.percentageFormatter = true;
    this.lineChartConfigForRegimenLengthDistribution.tickPositions = [
      0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390,
    ];
    this.lineChartConfigForRegimenLengthDistribution.data = [
      {
        data: this.regimenLengthDistributionLineData,
      },
    ];

    this.lineChartRegimenLengthDistributionStatus = true;
  }

  formatJoinArray(array: any) {
    return array.slice(0, 20).join(', ');
  }

  renderMergingRuleInHumanReadableFormat(mergingRule: any) {
    if (mergingRule.fileUploaded) {
      return;
    }
    let regimenMergeRule = '';
    if (mergingRule.regimen_merging_rule) {
      const foundIndex = this.regimenMergingRulesList.findIndex(
        (rule) => rule.value === mergingRule.regimen_merging_rule
      );
      const mergeRule =
        foundIndex > -1 ? this.regimenMergingRulesList[foundIndex].label : '';
      regimenMergeRule = `Previous regimen should be ${mergeRule} current regimen`;
    }
    regimenMergeRule = regimenMergeRule.includes('be is')
      ? regimenMergeRule.replace('be is', 'be')
      : regimenMergeRule.includes('be has')
      ? regimenMergeRule.replace('be has', 'have')
      : regimenMergeRule;

    const regimenLengthRules: any = [];
    Object.entries(mergingRule.regimen_length_rules).forEach(
      ([rule, value]: any) => {
        if (!value) {
          return;
        }
        const lengthRule =
          rule === 'gap_between_current_and_prev_regimen'
            ? 'gap between current and previous regimen'
            : rule === 'regimen_length'
            ? 'current regimen length'
            : rule === 'prev_regimen_length'
            ? 'previous regimen length'
            : '';

        if (!value['comparator'] || !value['comparision_value']) {
          return;
        }

        let regimenLengthRule = `${lengthRule} should be ${value['comparator']} ${value['comparision_value']} days`;
        if (rule === 'gap_between_current_and_prev_regimen') {
          regimenLengthRule += ` (calculated from ${mergingRule.regimen_length_rules['gap_calculation_parameter']} of previous regimen)`;
        }
        regimenLengthRule += mergingRule.line_number_for_maintenance
          ? ` with Line Number = ${mergingRule.line_number_for_maintenance}`
          : '';
        regimenLengthRules.push(regimenLengthRule);
      }
    );

    const outputString =
      regimenMergeRule && regimenLengthRules.length
        ? regimenMergeRule + ` and ${regimenLengthRules.join(' and ')}.`
        : !regimenMergeRule && regimenLengthRules.length
        ? `${regimenLengthRules.join(' and ')}.`
        : `${regimenMergeRule}.`;

    return outputString.charAt(0).toUpperCase() + outputString.slice(1);
  }

  triggerLotJob(status: any) {
    let payload = {
      run_emr: true,
      method: this.selectedValue,
      on_episode: this.selectedMethod,
    };
    this.regimenLotService.postLotJobExecute(this.studyId, payload).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.onSuccessJobCreation = true;
          this.checkJobStatus(true);
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'postLotJobExecute');
        }
      },
      (error: any) => {
        this.handleError(SOMETHING_WENT_WRONG, 'postLotJobExecute');
      }
    );
  }

  downloadRegimenConditioningTemplate() {
    this.regimenLotLoaderStatus = true;
    this.regimenLotService
      .getRegimenConditioningDownloadTemplate(this.studyId)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            const blob = this.base64ToBlob(response['data'], 'text/csv');
            saveAs(blob, `regimen_exception_template.csv`);
            this.regimenLotLoaderStatus = false;
          } else {
            this.regimenLotLoaderStatus = false;
            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.regimenLotLoaderStatus = false;
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  base64ToBlob(b64Data: any, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  uploadRegimenConditioningFile() {
    let payload = {};
    this.regimenLotService
      .postRegimenConditioningUploadFile(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  removeAllOption(data: any) {
    let index = data.indexOf('All');
    if (index == -1) {
      return data;
    }
    return data.slice(0, index).concat(data.slice(index + 1, data.length));
  }

  handleRemoveRuleButtonClick(categoryIndex: number, ruleIndex: number) {
    this.stageIdentificationRules[categoryIndex]?.rules?.splice(ruleIndex, 1);
    this.stageIdentification.saveStageIdentficationRules(this.studyId, this.stageIdentificationRules);
  }


  addRegimenTransitionRule() {
    let obj = {
      previous_regimen_drug_class: this.removeAllOption(
        this.listOfSelectedPreviousRegimenDrugs.data
      ),
      previous_regimen_product_name: this.removeAllOption(
        this.listOfSelectedPreviousRegimenProducts.data
      ),
      current_regimen_drug_class: this.removeAllOption(
        this.listOfSelectedCurrentRegimenDrugs.data
      ),
      current_regimen_product_name: this.removeAllOption(
        this.listOfSelectedCurrentRegimenProducts.data
      ),
      regimen_merging_rule: this.regimenMergingRule,
      regimen_length_rules: {
        gap_between_current_and_prev_regimen: {
          comparator: this.regimenLengthRules.selectedGapInDaysOperator,
          comparision_value: this.regimenLengthRules.gapInDaysValue,
        },
        gap_calculation_parameter: !this.regimenLengthRules
          .selectedGapInDaysOperator
          ? 'end'
          : this.regimenLengthRules.selectedGapCalculatedFrom,
        regimen_length: {
          comparator:
            this.regimenLengthRules.selectedLengthInDaysCurrentRegimenOperator,
          comparision_value:
            this.regimenLengthRules.lengthInDaysCurrentRegimenValue,
        },
        prev_regimen_length: {
          comparator:
            this.regimenLengthRules.selectedLengthInDaysPreviousRegimenOperator,
          comparision_value:
            this.regimenLengthRules.lengthInDaysPreviousRegimenValue,
        },
      },
      regimen_transition_rule: this.selectedRegimenTransitionRuleFor,
      line_number_for_maintenance:
        this.regimenLengthRules.lineNumberMaintenanceApplicableValue,
    };
    this.regimenTransitionRules.push(obj);
    this.selectedRegimenTransitionRuleFor = '';
    this.noOfIterations = 2;
    this.listOfSelectedPreviousRegimenDrugs.data = [];
    this.listOfSelectedCurrentRegimenDrugs.data = [];
    this.listOfSelectedPreviousRegimenProducts.data = [];
    this.listOfSelectedCurrentRegimenProducts.data = [];
    this.regimenMergingRule = '';
    this.regimenLengthRules = {
      selectedGapInDaysOperator: null,
      gapInDaysValue: null,
      selectedGapCalculatedFrom: 'end',
      selectedLengthInDaysPreviousRegimenOperator: null,
      lengthInDaysPreviousRegimenValue: null,
      selectedLengthInDaysCurrentRegimenOperator: null,
      lengthInDaysCurrentRegimenValue: null,
      lineNumberMaintenanceApplicableValue: null,
    };
    this.regimenTransitionRulesDataFilterStatus = false;
  }

  convertStringToNumber(data: any) {
    return parseInt(data);
  }

  addRegimenDropRule() {
    this.regimenDropRulesGrid.isLoaded = false;
    let obj = {
      product:
        this.listOfSelectedRegimenDropProducts.data.length > 0
          ? this.removeAllOption(
              this.listOfSelectedRegimenDropProducts.data
            ).join()
          : this.removeAllOption(this.listOfRegimenDropProducts).join(),
      cutOfDays: this.cutOfDaysValue,
      visibleActionIcons: ['delete'],
      disabledActionIcons: [],
    };
    this.regimenDropRulesGrid.gridData = [
      ...this.regimenDropRulesGrid.gridData,
      obj,
    ];
    this.listOfSelectedRegimenDropProducts.data = [];
    this.listOfRegimenDropProducts = [];
    this.listOfSelectedRegimenDropDrugs.data = [];
    this.cutOfDaysValue = '';
    this.regimenDropRulesGrid.isLoaded = true;
  }

  uploadFile(event: any) {
    event.cancelBubble = true;
    try {
      if (!this.validateFile(event)) {
        return;
      }

      const _file = event.target.files[0];
      const splittedFileName = _file.name.split('.');
      if (splittedFileName[splittedFileName.length - 1] === 'csv') {
        this.changeFile(_file).then((base64: any): any => {
          const base64File = base64;
          const payload = {
            data: base64File.split(',')[1],
            fileName: _file,
          };
          this.regimenLotLoaderStatus = true;
          this.regimenLotService
            .postRegimenConditioningUploadFile(this.studyId, payload)
            .subscribe({
              next: (res: any) => {
                if (res.fileUploaded) {
                  this.regimenLotLoaderStatus = false;
                  this.commonService.createNotification('success', res.message);
                } else {
                  this.regimenLotLoaderStatus = false;
                  this.commonService.createNotification('error', res.message);
                }
              },
              error: (error: any) => {
                this.regimenLotLoaderStatus = false;
                this.commonService.createNotification(
                  'error',
                  error.error.message
                );
              },
            });
        });
      } else {
        this.commonService.createNotification(
          'error',
          this.fileMessage.csvExpected
        );
      }
    } catch (ex) {
      this.commonService.createNotification(
        'error',
        this.fileMessage.corruptedFile
      );
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  validateFile(uploadEvent: any) {
    if (
      uploadEvent.target.files[0].size > MAXIMUM_CSV_FILE_SIZE ||
      uploadEvent.target.files[0].size === 0
    ) {
      let errorMessage =
        uploadEvent.target.files[0].size === 0
          ? this.fileMessage.corruptedFile
          : this.fileMessage.exceedSize;
      this.commonService.createNotification('error', errorMessage);
      uploadEvent.target.value = '';
      return false;
    }
    return true;
  }

  resetFile($event: any) {
    $event.target.value = null;
  }

  changeFile(_file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(_file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  onSelectDrugsList(drugListSection: any) {
    if (drugListSection == 'previousRegimen') {
      this.listOfPreviousRegimenProducts = [];
      this.listOfSelectedPreviousRegimenDrugs.data.forEach((data: any) => {
        if (data != 'All') {
          this.listOfPreviousRegimenProducts = [
            ...new Set([
              ...this.listOfPreviousRegimenProducts,
              ...this.drugsAndProductsList[data],
            ]),
          ];
        }
      });
      this.listOfSelectedPreviousRegimenProducts.data =
        this.listOfSelectedPreviousRegimenProducts.data.filter((data: any) => {
          return this.listOfPreviousRegimenProducts.includes(data);
        });
    } else if (drugListSection == 'currentRegimen') {
      this.listOfCurrentRegimenProducts = [];
      this.listOfSelectedCurrentRegimenDrugs.data.forEach((data: any) => {
        if (data != 'All') {
          this.listOfCurrentRegimenProducts = [
            ...new Set([
              ...this.listOfCurrentRegimenProducts,
              ...this.drugsAndProductsList[data],
            ]),
          ];
        }
      });
      this.listOfSelectedCurrentRegimenProducts.data =
        this.listOfSelectedCurrentRegimenProducts.data.filter((data: any) => {
          return this.listOfCurrentRegimenProducts.includes(data);
        });
    } else if (drugListSection == 'regimenDrop') {
      this.listOfRegimenDropProducts = [];
      this.listOfSelectedRegimenDropDrugs.data.forEach((data: any) => {
        if (data != 'All') {
          this.listOfRegimenDropProducts = [
            ...new Set([
              ...this.listOfRegimenDropProducts,
              ...this.drugsAndProductsList[data],
            ]),
          ];
        }
      });
      this.listOfSelectedRegimenDropProducts.data =
        this.listOfSelectedRegimenDropProducts.data.filter((data: any) => {
          return this.listOfRegimenDropProducts.includes(data);
        });
    }
  }

  closeStageIdentificationConfirmationPopup($event: any) {
    this.showStageIdentificationConfirmationFlag = false;
    this.triggerStageIdentificationJob($event);
  }

  closeLotConfirmationPopup($event: any) {
    this.showLotConfirmationFlag = !!$event;
    this.triggerLotJob(!!$event);
  }

  closeRegimenRefinementConfirmationPopup(event: any) {
    if (event === true) {
      this.showRegimenRefinementConfirmationFlag = false;
      this.applyRegimenRefinementTwo(true);
    } else {
      this.showRegimenRefinementConfirmationFlag = false;
      this.applyRegimenRefinementTwo(false);
    }
  }

  close() {
    this.selectedRuleType = '';
    this.selectTypeOptionsPopupVisibleStatus = false;
  }

  resetCreateRuleTable() {
    this.selectedRuleStartOfThe = '';
    this.selectedRuleStageIs = '';
    this.inputRuleDate = '';
    this.selectedRuleDays = '';
    this.selectesRuleDaysAfter = '';
    this.selectedRuleType = '';
    this.selectedCheckboxes = [];
    this.selectesRuleDaysAfterInputValue = '';
    this.ruleTypeEnableStatus = false;
    this.searchTypeListValues = '';
    this.allChecked = false;
    this.allCheckedStatus = false;
  }

  handleError(errorMessage: string, api: string): void {
    switch (api) {
      case 'postTypeOptionsList':
        break;
    }
  }

  importClick() {
    this.showImportDrawer = true;
  }

  closeImport() {
    this.showImportDrawer = false;
    this.submitImportClicked = false;
    this.importS3Link = '';
    this.showImportError = false;
    this.importS3error = false;
  }

  onImportSubmitClick() {
    const payload = {
      stageIdentificationFilePath: this.importS3Link,
    };

    this.submitImportClicked = true;

    if (this.importS3Link.length === 0) {
      this.importS3error = true;
    } else {
      this.showImportLoading = true;
      this.showImportError = false;
      this.importS3error = false;

      this.regimenLotService
        .submitImportS3Link(this.studyId, payload)
        .subscribe(
          (response) => {
            if (response.status === API_SUCCESS_KEY && response.isValid) {
              this.showImportDrawer = false;
              this.showImportLoading = false;
              this.commonService.createNotification(
                'success',
                response.message
              );
              this.closeImport();
              this.stageIdentificationMode = 'import';
            } else if (!response.isValid) {
              this.showImportDrawer = false;
              this.showImportLoading = false;
              this.commonService.createNotification('error', 'Invalid S3 Path');
              this.closeImport();
            } else if (response.status !== API_SUCCESS_KEY) {
              this.showImportDrawer = false;
              this.showImportLoading = false;
              this.commonService.createNotification(
                'error',
                SOMETHING_WENT_WRONG
              );
              this.closeImport();
            }
          },
          (error) => {
            this.showImportDrawer = false;
            this.showImportLoading = false;
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
            this.closeImport();
          }
        );
    }
  }

  fetchOverallRegimenLengthDistribution() {
    this.lineChartRegimenLengthDistributionStatus = false;
    this.regimenLotService
      .getOverallRegimenLengthDistribution(
        this.studyId,
        this.cutOfDaysValue != '' ? this.cutOfDaysValue : 0
      )
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.regimenLengthDistributionLineData = response['linedata'] || [];
            this.regimenLengthDistributionLineData =
              this.regimenLengthDistributionLineData.map((i) => Number(i));
            this.setLineChartForGrace();
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  downloadTemplate() {
    this.showImportLoading = true;
    this.showImportError = false;

    this.regimenLotService.getImportDownloadTemplate().subscribe(
      (data) => {
        if (data.status === API_SUCCESS_KEY) {
          const blob = this.base64ToBlob(data['data'], 'text/csv');
          saveAs(blob, `stage_identification_template.csv`);
          this.showImportLoading = false;
        } else {
          this.showImportError = true;
          this.showImportLoading = false;
        }
      },
      (error) => {
        this.showImportError = true;
        this.showImportLoading = false;
      }
    );
  }

  importS3LinkEntered() {
    if (this.submitImportClicked && this.importS3Link.length > 0) {
      this.importS3error = false;
    } else if (this.submitImportClicked && this.importS3Link.length === 0) {
      this.importS3error = true;
    }
  }

  applyRegimenRefinementOne() {
    this.regimenLotLoaderStatus = true;
    let obj = {} as any;
    this.regimenDropRulesGrid.gridData.forEach((data: any) => {
      obj[data.product] = data.cutOfDays;
    });
    let payload = {
      regimen_drop_rules: obj,
    };
    this.regimenLotService
      .patchApplyRegimenRefinementOne(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.fetchConfigData();
            this.showRegimenRefinementConfirmationFlag = true;
            this.regimenLotLoaderStatus = false;
          } else {
            this.regimenLotLoaderStatus = false;

            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.regimenLotLoaderStatus = false;

          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  applyRegimenRefinementTwo(status: any) {
    this.regimenLotLoaderStatus = true;

    let payload = { run_emr: true, update_downstream_jobs: status };
    this.regimenLotService
      .postApplyRegimenRefinementTwo(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.checkJobStatus(true);
          } else {
            this.regimenLotLoaderStatus = false;

            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.regimenLotLoaderStatus = false;

          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  regimenDropRulesTableActionIconClicked(event: any) {
    this.regimenDropRulesGrid['gridData'] =
      this.regimenDropRulesGrid.gridData.filter((data: any) => {
        return data.product != event.data.product;
      });
  }

  lotRadioButtonClicked(event: any) {}

  checkRegimenDrop() {
    this.regimenLotService
      .getCheckRegimenDrop(
        this.studyId,
        this.listOfSelectedRegimenDropProducts.data.length > 0
          ? this.listOfSelectedRegimenDropProducts.data
          : this.listOfRegimenDropProducts,
        this.cutOfDaysValue
      )
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.regimenCutOff =
              response['countRegimens'] || response['countRegimens'] == 0
                ? `${response['countRegimens']}%`
                : '-';
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  fetchRegimenMergeSummary() {
    this.regimenMergeSummaryGrid.isLoaded = false;
    this.regimenLotService.getRegimenMergeSummary(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          if (response.regimenMergeSummary.length > 0) {
            this.regimenMergeSummaryGrid.columns[0].key = Object.keys(
              response.regimenMergeSummary[0]
            ).includes('rule_no')
              ? 'rule_no'
              : 'no_of_iteration';
          }
          this.regimenMergeSummaryGrid.gridData = response.regimenMergeSummary;
          this.regimenMergeSummaryGrid.isLoaded = true;
        } else {
          this.regimenMergeSummaryGrid.isLoaded = true;
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      },
      (error: any) => {
        this.regimenMergeSummaryGrid.isLoaded = true;
        this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
      }
    );
  }

  fetchRegimenConditioningSavedRules() {
    this.regimenLotService
      .getRegimenConditioningSavedRules(this.studyId)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            response.data.forEach((data: any) => {
              let obj = {
                previous_regimen_drug_class: data.previousRegimenDrugClass,
                previous_regimen_product_name: data.previousRegimenProductName,
                current_regimen_drug_class: data.currentRegimenDrugClass,
                current_regimen_product_name: data.currentRegimenProductName,
                regimen_merging_rule: data.regimenMergingRule,
                regimen_length_rules: data.regimenLengthRules,
                regimen_transition_rule: data.regimenTransitionRule,
                line_number_for_maintenance: data.lineNumberForMaintenance,
              };
              this.savedRegimenTransitionRule.push(obj);
              this.regimenTransitionRules.push(obj);
            });
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
          }
        },
        (error: any) => {
          this.handleError(SOMETHING_WENT_WRONG, 'postMappingData');
        }
      );
  }

  onEpisodes(event: any) {
    if (event.target.checked) {
      this.selectedValue = '1';
      this.selectedMethod = true;
    } else {
      this.selectedMethod = false;
    }
  }

  onNgModelChange() {
    this.ngModelChange.emit(this.selectedValue);
  }

  redirectAfterJobsTriggered() {
    this.router.navigate(['/logs']);
  }

  navigateWrtUrl(routerLink: any, studyIdRequired: any = false) {
    if (studyIdRequired) {
      this.router.navigate([routerLink + this.studyId]);
    } else {
      this.router.navigate([routerLink]);
    }
  }
  saveRules(applyRules: boolean) {
    this.regimenLotLoaderStatus = true;
    const configPayload = {
      regimen_merge_iterations: this.noOfIterations,

      regimen_merge_naming_rules: this.mergeRegimenRenamingRulesList,
    };

    this.regimenLotService
      .patchApplyRegimenRefinementOne(this.studyId, configPayload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.saveApplyFilter(applyRules);
          } else {
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
          }
        },
        (error) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  saveApplyFilter(applyRules: boolean) {
    const saveRegimenMergeFiltersPayload = this.regimenTransitionRules;

    this.regimenLotService
      .saveRegimenMergeFilters(this.studyId, saveRegimenMergeFiltersPayload)
      .subscribe(
        (response) => {
          if (response.status === API_SUCCESS_KEY) {
            this.regimenLotLoaderStatus = false;

            this.savedRegimenTransitionRule = JSON.parse(
              JSON.stringify(this.regimenTransitionRules)
            );
            this.mergeRegimenRenamingRulesListCopy = JSON.stringify(
              this.mergeRegimenRenamingRulesList
            );
            if (applyRules) {
              this.showApplyRulesConfirmation = true;
            } else {
              this.commonService.createNotification(
                'success',
                'Regimen conditioning rules have been saved successfully'
              );
            }
          } else {
            this.regimenLotLoaderStatus = false;
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
          }
        },
        (error) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  triggerApplyFilterJob(downStreamClicked: boolean) {
    this.regimenLotLoaderStatus = true;
    const payload = {
      run_emr: true,
      run_regimen_drop_job: downStreamClicked,
    };

    this.regimenLotService
      .triggerApplyRulesJob(this.studyId, payload)
      .subscribe(
        (response) => {
          if (response.status === API_SUCCESS_KEY) {
            this.regimenLotLoaderStatus = false;

            this.checkJobStatus(true);
          } else {
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
          }
        },
        (error) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  closeApplyRulesConfirmationPopup(event: boolean) {
    if (event === true) {
      this.showApplyRulesConfirmation = false;
      this.triggerApplyFilterJob(true);
    } else {
      this.showApplyRulesConfirmation = false;
      this.triggerApplyFilterJob(false);
    }
  }

  showNavigationStatus() {
    if (
      !this.checkStageIdentificationChangeDetectionStatus() ||
      !this.checkRegimenConditioningChangeDetectionStatus() ||
      JSON.stringify(this.regimenDropRulesGrid.gridData) !=
        JSON.stringify(this.savedRegimenDropRules) ||
      this.selectedValue != this.savedLotobject?.value ||
      JSON.stringify(this.mergeRegimenRenamingRulesList) !=
        this.mergeRegimenRenamingRulesListCopy
    ) {
      return this.showWarningPopupValue;
    } else {
      return false;
    }
  }

  dismissWarningPopup() {
    this.showConfirmationFlag = true;
    this.showWarningPopupValue = false;
    return false;
  }

  closeConfirmationPopup(event: any) {
    if (event) {
      this.router.navigate([this.navigateUrl]);
    } else {
      this.showWarningPopupValue = true;
      this.showConfirmationFlag = false;
    }
  }

  ngOnDestroy() {
    this.appNavigate.unsubscribe();
  }

  setBreadCrumbForViewer() {
    let studyProgress;
    let index = 5;

    this.allBreadCrumbData = [
      {
        displayName: 'All Studies',
        navigationPath: '/home/',
        key: 'alStudies',
      },
      {
        displayName: 'Study Details',
        navigationPath: `/studies/edit/${this.studyId}`,
        key: 'studyDetails',
      },
      {
        displayName: 'Dimension Mapping',
        navigationPath: `/studies/dimension-mapping/${this.studyId}`,
        key: 'dimensionMapping',
      },
      {
        displayName: 'Episode Creation',
        navigationPath: `/studies/episode-creation/${this.studyId}`,
        key: 'episodeCreation',
      },
      {
        displayName: 'Regimen & LoT',
        navigationPath: ``,
        key: 'regimenLot',
      },
      {
        displayName: 'Select Insights',
        navigationPath: `/studies/select-insights/${this.studyId}`,
        key: 'selectInsights',
      },
    ];
    this.regimenLotLoaderStatus = true;
    this.commonService.getStudyProgress(this.studyId).subscribe((response) => {
      if (response.status === API_SUCCESS_KEY) {
        this.regimenLotLoaderStatus = false;
        studyProgress = response.progress;

        if (studyProgress < 20) {
          index = 1;
        } else if (studyProgress === 20) {
          index = 2;
        } else if (studyProgress > 20 && studyProgress < 60) {
          index = 5;
        } else if (studyProgress >= 60) {
          index = 5;
        }

        this.allBreadCrumbData.forEach((item: any, counter: any) => {
          if (counter <= index) {
            this.viewerBreadCrumbData.push(item);
          }
        });
      }
    });
  }

  fetchUserRole() {
    this.showLoaderForUserRole = true;
    this.commonService.getUserRole(this.studyId).subscribe(
      (response: any) => {
        // TODO: Add type.
        this.showLoaderForUserRole = false;
        if (response.status === API_SUCCESS_KEY) {
          this.isUserRoleViewer = response.data.role == 'viewer';
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'API');
        }
      },
      () => {
        this.showLoaderForUserRole = false;
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  handleAddRulesButtonClick(): void {
    this.showStageIdentificationModal = true;
  }

  handleApplyRulesButtonClick(): void {
    this.showStageIdentificationConfirmationFlag = true;
  }

  handleStageIdentificationModalClose(refreshData?: boolean): void {
    this.showStageIdentificationModal = false;
    if (refreshData) {
      this.fetchStageIdentificationRules();
      this.stageIdentificationMode = 'manual';
    }
  }
}
