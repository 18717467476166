import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-information-popup',
  templateUrl: './information-popup.component.html',
  styleUrls: ['./information-popup.component.scss'],
})
export class InformationPopupComponent implements OnInit {
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();

  isVisible: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.showModal();
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
    this.closePopup.emit(false);
  }

  navigateToLogs() {
    this.router.navigate(['/logs']);
  }
}
