export const APPLICATION_NAME = 'PATIENT JOURNEY';
export const APPLICATION_TITLE = 'Patient Journey';

export const PROJECT_NOT_SELECTED =
  'Project is not selected. Please select a project';
export const SOMETHING_WENT_WRONG =
  'Something went wrong. Please try again later.';
export const API_SUCCESS_KEY = 'SUCCESS';
export const API_FAILURE_KEY = 'ERROR';
export const NO_RECORDS = 'No records found.';
export const NO_SEARCH_RESULT = 'No records found.';
export const CLOSE_POPUP = 'Close popup';
export const NO_COHORT = 'No cohort found for the selected data source';
export const ALREADY_EXISTS =
  'This study title already exists. Try entering another name.';
export const INVALID_S3_LOCATION =
  'Please enter valid S3 location and try again.';
export const NO_DATA = 'No Data Available';
export const EXECUTE_PREVIOUS_JOBS = 'Please execute previous jobs';
export const ACCESS_ISSUE = 'The user does not have access to this study.';
export const JOB_TRIGGER_REQUIRED =
  'You have not triggered the required jobs to view this page.';
export const MAPPING_JOB_RUNNING =
  'A job is running to compute Mapping. Please try after sometime.';
export const MAPPING_JOB_FAILED =
  'A job has failed. Please try after sometime.';
export const STUDY_ACCESS_ISSUE = 'Study access issue';
export const STAGE_IDENTIFICATION_RULE_ERROR = {
  INSUFFICIENT_DATA: 'Please enter all required fields',
  INSUFFICIENT_DATA_TREATMENT: 'Please select appropriate sub categories for treatment/diagnosis',
  INSUFFICIENT_DATA_OCCURRENCE: 'Please enter the nth occurrence count',
  INSUFFICIENT_DATA_GAP_DAYS: 'Please enter gap days',
};
export const APP_STATE_KEYS = {
  STUDY_DETAILS: 'studyDetails',
  ACTION_TYPE: 'actionType',
};
export const MAXIMUM_CSV_FILE_SIZE = 20000000;
