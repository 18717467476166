import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-study-detail',
  templateUrl: './create-study-detail.component.html',
  styleUrls: ['./create-study-detail.component.scss'],
})
export class CreateStudyDetailComponent {
  constructor() {}
}
