<div class="pb-4">
  <nz-breadcrumb class="breadcrumb" [nzSeparator]="'>'">
    <nz-breadcrumb-item class="breadcrumb" *ngFor="let item of breadcrumbData">
      <a
        *ngIf="item.navigationPath.length > 0"
        class="breadcrumb"
        (click)="breadcrumbClick(item)"
      >
        {{ item.displayName }}
      </a>
      <span
        [ngClass]="
          item.navigationPath.length === 0 ? 'nonClickableBreadCrumb' : ''
        "
        *ngIf="item.navigationPath.length === 0"
      >
        {{ item.displayName }}
      </span>
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <div *ngIf="isRequiredJobTriggeredStatus" class="m-auto w-fit-content mb-3">
    <app-alert
      [closable]="false"
      [message]="'You have not triggered the required jobs to view this page.'"
      [type]="'error'"
    ></app-alert>
  </div>
  <div *ngIf="!isRequiredJobTriggeredStatus">
    <div
      class="spinner-style"
      *ngIf="!eligibleForSelectInsights || saveInProgress"
    >
      <rd-spinner></rd-spinner>
    </div>

    <div class="d-flex justify-content-center" *ngIf="showSaveError">
      <app-alert
        [closable]="false"
        [message]="saveErrorMessage"
        [type]="'error'"
        [closeText]="''"
      ></app-alert>
    </div>

    <div class="time-frame-section flex-wrap-lg-wrap">
      <div class="title-text w-lg-100 mb-lg-2">
        Select time frame for generating visualizations:
      </div>
      <div class="d-flex-center-start">
        <div class="date-picker ml-lg-0">
          <nz-date-picker
            [nzDisabled]="isViewer || !isDateLoaded"
            [nzInputReadOnly]="true"
            [nzAllowClear]="false"
            [nzDisabledDate]="disabledFromDate"
            [nzSize]="'large'"
            [nzFormat]="'MM/dd/yyyy'"
            [(ngModel)]="fromDate"
            (ngModelChange)="onFromDateChange($event)"
          ></nz-date-picker>
        </div>
        <div class="title-text">To</div>
        <div class="date-picker">
          <nz-date-picker
            [nzDisabled]="isViewer || !isDateLoaded"
            [nzInputReadOnly]="true"
            [nzAllowClear]="false"
            [nzDisabledDate]="disabledToDate"
            [nzSize]="'large'"
            [nzFormat]="'MM/dd/yyyy'"
            [(ngModel)]="toDate"
            (ngModelChange)="onToDateChange($event)"
          ></nz-date-picker>
        </div>
        <i
          *ngIf="!isDateLoaded"
          nz-icon
          [nzType]="'loading'"
          class="theme-primary-color"
        ></i>
      </div>
    </div>

    <div class="select-insights-section">
      <div class="insights-box">
        <div *ngIf="availableMetrics.length > 0 && !availableInsightsLoading">
          <div *ngFor="let metric of availableMetrics">
            <span
              class="metric-title"
              [ngClass]="metric.isSelected ? 'selected-insights' : ''"
              (click)="
                onMetricTitleClick(metric?.kpiCategoryId, metric?.category)
              "
            >
              {{ metric?.category }}
            </span>
            <ul class="list-unstyled">
              <ng-container *ngFor="let element of metric.data">
                <li
                  class="list-item"
                  [style.background]="
                    activeMetrics.includes(element.kpiId) ? '#27a6a41a' : ''
                  "
                >
                  {{ element.name }}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>

        <div
          class="error-insights"
          *ngIf="
            availableMetrics.length === 0 &&
            !availableInsightsLoading &&
            !availableMetricsInsightsError
          "
        >
          No Available Insights
        </div>

        <div class="error-insights" *ngIf="availableMetricsInsightsError">
          <app-alert
            [closable]="false"
            [message]="insightLoadErrorMessage"
            [type]="'error'"
            [closeText]="''"
          ></app-alert>
        </div>

        <div class="error-insights" *ngIf="availableInsightsLoading">
          <rd-spinner></rd-spinner>
        </div>
      </div>

      <div class="button-section">
        <div
          class="d-flex flex-column justify-content-between align-items-center"
        >
          <rd-button
            [btnType]="'primary'"
            btnText=""
            [isIconNeeded]="true"
            [btnStyle]="actionButtonClass"
            [iconType]="'double-right'"
            [isDisabled]="isViewer"
            (btnClick)="selectAllMetrics()"
          ></rd-button>

          <rd-button
            [btnType]="'primary'"
            btnText=""
            [isIconNeeded]="true"
            [btnStyle]="actionButtonClass"
            [iconType]="'right'"
            [isDisabled]="isViewer"
            (btnClick)="selectMetrics()"
          ></rd-button>

          <rd-button
            [btnType]="'primary'"
            btnText=""
            [isIconNeeded]="true"
            [btnStyle]="actionButtonClass"
            [iconType]="'left'"
            [isDisabled]="isViewer"
            (btnClick)="removeSelectedMetrics()"
          ></rd-button>

          <rd-button
            [btnType]="'primary'"
            btnText=""
            [isIconNeeded]="true"
            [btnStyle]="actionButtonClass"
            [iconType]="'double-left'"
            [isDisabled]="isViewer"
            (btnClick)="removeAllSelectedMetrics()"
          ></rd-button>
        </div>
      </div>

      <div class="insights-box">
        <div class="error-insights" *ngIf="availableInsightsLoading">
          <rd-spinner></rd-spinner>
        </div>

        <div
          *ngIf="
            !selectedMetrics.length &&
            !availableInsightsLoading &&
            !availableMetricsInsightsError
          "
          class="error-insights"
        >
          Please Select Insights
        </div>
        <div class="error-insights" *ngIf="availableMetricsInsightsError">
          <app-alert
            [closable]="false"
            [message]="insightLoadErrorMessage"
            [type]="'error'"
            [closeText]="''"
          ></app-alert>
        </div>
        <div class="metrics-list" *ngIf="selectedMetrics.length">
          <ul class="list-unstyled" *ngIf="selectedMetrics">
            <ng-container *ngFor="let metric of selectedMetrics">
              <li
                class="selected-metrics"
                (click)="
                  onSelectSelectedInsight(metric.kpiCategoryId, metric.category)
                "
                [ngClass]="
                  isInsightSelected(metric.kpiCategoryId)
                    ? 'selected-insights'
                    : ''
                "
              >
                <div class="p2 selected-insight-title">
                  {{ metric.category }}
                </div>
                <span style="font-size: 14px">{{
                  getMetricsForInsight(metric.category)
                }}</span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <div class="footer-section d-flex justify-content-end">
      <div class="footer-section-wrapper">
        <rd-button
          [btnType]="'default'"
          btnText="Back"
          [isIconNeeded]="false"
          [size]="'large'"
          (btnClick)="navigateWrtUrl('/studies/regimen-lot/' + studyId)"
        ></rd-button>
        <rd-button
          [btnType]="'default'"
          btnText="Save & Exit"
          [isIconNeeded]="false"
          [size]="'large'"
          [isDisabled]="isViewer"
          (btnClick)="saveStudy(false)"
        ></rd-button>
        <rd-button
          [btnType]="'primary'"
          btnText="Submit"
          [isDisabled]="!selectedMetrics.length || isViewer"
          [btnClass]="'custom-primaryfill-greenbtn'"
          (btnClick)="saveStudy(true)"
        ></rd-button>
      </div>
    </div>
  </div>
</div>

<app-action-confirmation-popup
  *ngIf="showConfirmationFlag"
  (closePopup)="closeConfirmationPopup($event)"
  [confirmationConfig]="confirmationConfig"
  (close)="showConfirmationFlag = false"
></app-action-confirmation-popup>

<app-information-popup
  *ngIf="submitSuccessful"
  (closePopup)="submitSuccessful = false"
>
</app-information-popup>
