<div class="dimension-mapping">
  <nz-breadcrumb class="breadcrumb endClickable" [nzSeparator]="'>'">
    <nz-breadcrumb-item
      class="breadcrumb endClickable"
      *ngFor="let item of viewerBreadCrumbData"
    >
      <a
        class="breadcrumb endClickable"
        *ngIf="item.navigationPath.length > 0"
        [routerLink]="item.navigationPath"
      >
        {{ item.displayName }}
      </a>
      <span
        [ngClass]="
          item.navigationPath.length === 0 ? 'nonClickableBreadCrumb' : ''
        "
        *ngIf="item.navigationPath.length === 0"
      >
        {{ item.displayName }}
      </span>
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <div *ngIf="isRequiredJobTriggeredStatus" class="m-auto w-fit-content mb-3">
    <app-alert
      [closable]="false"
      [message]="JOB_TRIGGER_REQUIRED"
      [type]="'error'"
    ></app-alert>
  </div>
  <div *ngIf="!isMappingJobCompletedStatus" class="m-auto w-fit-content mb-3">
    <app-alert
      [closable]="false"
      [message]="MAPPING_JOB_RUNNING"
      [type]="'warning'"
    ></app-alert>
  </div>
  <ng-container *ngIf="!isRequiredJobTriggeredStatus">
    <div
      class="dimension-mapping-collapse"
      *ngFor="let section of sectionsListing"
      [ngClass]="{
        'disable-section': !isMappingJobCompletedStatus
      }"
    >
      <rd-collapse
        [panelHeader]="drugMappingHeader"
        [isDisabled]="false"
        [expandLocation]="'right'"
        [isActive]="false"
        [showArrowIcon]="false"
        (collapseClicked)="$event && this.fetchGridMappingData(section.code)"
      >
        <div class="dimesion-mapping-grid">
          <div
            class="section justify-content-center"
            *ngIf="!section.sectionConfig.uploadErrorStatus"
          ></div>
          <div class="section">
            <div class="check-radio-search">
              <div class="check-radio-search-inner">
                <div class="menu-wrapper">
                  <button
                    class="burger-menu"
                    (click)="searchToggle(section.code)"
                  >
                    <img src="assets/icons/burger_menu.png" alt="" />
                  </button>
                </div>
                <div
                  *ngIf="section.sectionConfig.searchCriteria"
                  class="selected-options"
                >
                  <p>Search By</p>
                  <div>
                    {{
                      findSearchTitleWrtSearchCrireria(
                        section.code,
                        section.sectionConfig.searchCriteria
                      )
                    }}
                    <button
                      (click)="
                        section.sectionConfig.searchCriteria = '';
                        restoreGridChangingSearchCreiteria(section.code)
                      "
                    >
                      x
                    </button>
                  </div>
                </div>
                <input
                  type="text"
                  [placeholder]="
                    section.sectionConfig.searchCriteria
                      ? 'Enter ' +
                        findSearchTitleWrtSearchCrireria(
                          section.code,
                          section.sectionConfig.searchCriteria
                        ) +
                        ' here'
                      : 'Select criteria to search'
                  "
                  [ngStyle]="{
                    padding:
                      section.sectionConfig.searchCriteria === ''
                        ? '0px'
                        : '12px 0 0 0'
                  }"
                  [(ngModel)]="section.sectionConfig.searchText"
                  [disabled]="section.sectionConfig.searchCriteria === ''"
                />
                <button
                  *ngIf="section.sectionConfig.searchText"
                  (click)="
                    section.sectionConfig.searchText = '';
                    this.fetchGridMappingData(section.code, 1)
                  "
                  class="cleartext-btn"
                >
                  <i nz-icon nzType="close-circle" nzTheme="outline"></i>
                </button>
              </div>
              <button
                class="search-btn"
                (click)="
                  section.sectionConfig.searchStatus = true;
                  fetchGridMappingData(section.code, 1)
                "
                [disabled]="
                  section.sectionConfig.searchCriteria === '' ||
                  section.sectionConfig.searchText.length <
                    section.minSearchLength
                "
              >
                <i nz-icon nzType="search" nzTheme="outline"></i>
              </button>
              <div
                *ngIf="section.sectionConfig.searchToggleStatus"
                class="search-dropdown"
                (appClickOutside)="clickOutsideSearchClose(section.code)"
              >
                <div class="dropdown-title">
                  <p>Search by</p>
                </div>
                <div class="search-options">
                  <div
                    class="checkbox-option"
                    *ngFor="let item of section.sectionConfig.columns"
                  >
                    <label class="checkbox-option-container"
                      >{{ item.name }}
                      <input
                        type="radio"
                        name="search-options"
                        [value]="item.key"
                        [(ngModel)]="section.sectionConfig.searchCriteria"
                        (ngModelChange)="
                          restoreGridChangingSearchCreiteria(section.code)
                        "
                      />
                      <!-- section.sectionConfig.searchText !== '' &&
                          (section.sectionConfig.searchText = '');
                          fetchGridMappingData(section.code, 1) -->
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <p class="record-count">
              Total No. of records: {{ section.sectionConfig.dataCount }}
            </p>
          </div>
          <p class="note">
            *Please download the data and create a custom mapping in pipe
            delimited format and upload it upon completion.
          </p>
          <rd-table
            [isLoading]="!section.sectionConfig.isLoaded"
            [tableColumns]="section.sectionConfig.columns"
            [tableData]="section.sectionConfig.gridData"
            [tableScrollDetails]="section.sectionConfig.scrollDetails"
            [pageIndex]="section.sectionConfig.pageIndex"
            [pageSize]="defaultPageSize"
            [totalCount]="section.sectionConfig.dataCount"
            [errorMessage]="section.sectionConfig.tableErrorMessage"
            [loadingRows]="defaultPageSize"
            [errorType]="section.sectionConfig.tableErrorType"
            [showPagination]="true"
            [frontEndPagination]="false"
            (paginationChange)="fetchGridMappingData(section.code, $event)"
            (sortApply)="
              fetchGridMappingData(
                section.code,
                1,
                $event.defaultSortOrder,
                $event.key
              )
            "
          ></rd-table>
        </div>

        <ng-template #drugMappingHeader>
          <div class="accordian-header">
            <div class="title">
              {{ section.title }}
              <rd-tooltip
                [tooltipText]="section.tooltip"
                tooltipPlacement="right"
                tooltipColor="#000000d9"
                [tooltipVisible]="false"
              ></rd-tooltip>
            </div>
            <div class="header-options">
              <div
                class="title-options-inner"
                (click)="openHistoryPopup($event, section.historyCode)"
              >
                <i nz-icon nzType="history" nzTheme="outline"></i>
                History
              </div>
              <div
                class="title-options-inner"
                [ngClass]="
                  section.sectionConfig.downloadLoadingStatus
                    ? 'disable-section'
                    : 'enable-section'
                "
                (click)="
                  downloadMappingVersions(
                    $event,
                    section.historyCode,
                    section.mappingDownloadFileName,
                    section.code
                  )
                "
              >
                <img src="assets/icons/excel.svg" alt="" />

                Download Data
              </div>
              <div
                class="d-flex"
                (click)="preventEventBubbling($event)"
                [ngStyle]="
                  section.sectionConfig.uploadStatus
                    ? { 'margin-right': '20px' }
                    : { 'margin-right': '0px' }
                "
                [ngClass]="{
                  'disable-section': isUserRoleViewer
                }"
              >
                <label
                  [for]="section.historyCode"
                  class="title-options-inner w-110px"
                  [ngStyle]="
                    section.sectionConfig.uploadStatus
                      ? { 'pointer-events': 'none', opacity: '0.7' }
                      : { opacity: '1' }
                  "
                >
                  <img src="assets/icons/publish.svg" alt="" />
                  Upload Data
                  <input
                    class="upload-input"
                    [attr.id]="section.historyCode"
                    type="file"
                    accept=".csv"
                    (click)="resetFile($event)"
                    (change)="
                      uploadFile($event, section.code, section.historyCode)
                    "
                    style="opacity: 0; width: 0px"
                  />
                </label>
                <div *ngIf="section.sectionConfig.uploadStatus">
                  <rd-spinner [spinnerStyle]="spinnerStyle"></rd-spinner>
                </div>
              </div>
            </div>
            <i nz-icon nzType="caret-down" nzTheme="outline"></i>
          </div>
        </ng-template>
      </rd-collapse>
    </div>
    <div class="footer-section d-flex justify-content-end">
      <div class="footer-section-wrapper">
        <rd-button
          [btnType]="'default'"
          btnText="Back"
          [isIconNeeded]="false"
          [size]="'large'"
          (btnClick)="navigateWrtUrl('/studies/edit/', true)"
        ></rd-button>
        <rd-button
          [btnType]="'default'"
          btnText="Exit"
          [isIconNeeded]="false"
          [size]="'large'"
          [isDisabled]="isUserRoleViewer"
          (btnClick)="navigateWrtUrl('/home/')"
        ></rd-button>
        <rd-button
          [btnType]="'primary'"
          btnText="Next"
          [btnClass]="'custom-primaryfill-greenbtn'"
          [isDisabled]="!isMappingJobCompletedStatus || isUserRoleViewer"
          (btnClick)="executeJob()"
        ></rd-button>
      </div>
    </div>
  </ng-container>
</div>

<div class="spinner-style" *ngIf="dimensionMappingLoaderStatus">
  <rd-spinner></rd-spinner>
</div>

<nz-drawer
  [nzTitle]="titleTpl"
  [nzClosable]="false"
  [nzVisible]="visible"
  [nzWidth]="800"
  nzPlacement="right"
  (nzOnClose)="close()"
  nzWrapClassName="execution-logs-drawer-style"
>
  <ng-container *nzDrawerContent>
    <div class="box-container">
      <div nz-row>
        <rd-table
          [isLoading]="!isMyhistoryLoaded"
          [tableColumns]="historyColumns"
          [tableData]="historyData"
          [tableScrollDetails]="historyScrollDetails"
          [pageIndex]="historyPageIndex"
          [totalCount]="historyDataCount"
          [pageSize]="defaultHistoryGrigPageSize"
          [errorMessage]="historyTableErrorMessage"
          [loadingRows]="defaultHistoryGrigPageSize"
          [errorType]="historyTableErrorType"
          [showPagination]="true"
          [frontEndPagination]="false"
          (tableRowClick)="historyTableActionIconClicked($event)"
          (paginationChange)="fetchHistoryData($event)"
        ></rd-table>
        <button class="history-submit" (click)="this.visible = false">
          Close
        </button>
      </div>
    </div>
    <div
      *ngIf="errorLogLoaded"
      class="response-style"
      [innerHTML]="errorLogResponse"
    ></div>
    <div class="logs-loading" *ngIf="!errorLogLoaded"></div>
  </ng-container>
</nz-drawer>

<ng-template #titleTpl>
  <div nz-row class="history-popup-header">
    <div nz-col nzSpan="8" class="drawer-header">History</div>
    <div nz-col nzSpan="16" class="drawer-cross-button text-right">
      <a nz-button nzType="link" (click)="close()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </a>
    </div>
  </div>
</ng-template>

<app-information-popup
  *ngIf="informationPopupStatus"
  (closePopup)="informationPopupStatus = false"
>
</app-information-popup>

<div class="spinner-style" *ngIf="physicianMappingLoaderStatus">
  <rd-spinner></rd-spinner>
</div>
