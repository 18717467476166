import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataSource } from '../../shared/models/clone-action.model';
import { ActionTriggerService } from '../../shared/services/action-trigger.service';
import { CreateStudiesService } from '../../shared/services/create-studies.service';
import { mergeMap } from 'rxjs/operators';
import { isObject } from 'rxjs/internal-compatibility';
import {
  API_SUCCESS_KEY,
  APP_STATE_KEYS,
  INVALID_S3_LOCATION,
  NO_COHORT,
  SOMETHING_WENT_WRONG,
} from '../../shared/constants/app-constants';
import { SensitiveInfoService } from '../../shared/services/sensitive-info.service';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AppStateService } from '../../shared/services/app-state.service';
import { CommonService } from 'src/app/shared/services/common.service';
// @ts-ignore
import differenceBy from 'lodash/differenceBy';

@Component({
  selector: 'app-create-studies',
  templateUrl: './create-studies.component.html',
  styleUrls: ['./create-studies.component.scss'],
})
export class CreateStudiesComponent implements OnInit {
  @ViewChild('dataSourceCtrl', { static: false })
  dataSourceCtrl: any;
  @ViewChild('cohortNameCtrl', { static: false })
  cohortNameCtrl: any;
  @ViewChild('cohortDescriptionCtrl', { static: false })
  cohortDescriptionCtrl: any;
  @ViewChild('s3LocationCtrl', { static: false })
  s3LocationCtrl: any;
  importBtnStyle = {
    height: '40px',
    'margin-left': '0',
  };

  selectCohortBtnStyle = {
    height: '40px',
    'margin-left': '-20px',
  };

  studyDetailForm: FormGroup;

  createStudyLoader = false;
  separator = '>';

  breadcrumbData = [
    { displayName: 'All Studies', navigationPath: '/home/' },
    { displayName: 'Study Details', navigationPath: '' },
  ];

  showConfirmationFlag = false;
  confirmationConfig = {
    header: 'Unsaved Changes',
    message: 'Your study will not be saved.',
    confirmationButtonName: 'Ok',
    cancelButtonName: 'Cancel',
  };

  saveStudyData = false;

  createStudyData = false;
  chartLoadingStatus = true;

  dataSourceTableColumns = [
    {
      name: 'Data Source',
      key: 'dataSourceName',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: false,
      type: 'clickable',
    },
  ];

  value: any;

  cohortTableColumns = [
    {
      name: 'Cohort Name',
      key: 'cohortName',
      dataType: 'string',
      prefix: '',
      suffix: '',
      sortable: true,
      width: '30%',
      align: 'left',
      showIcon: false,
      defaultSortOrder: 'asc',
      type: 'clickable',
    },
    {
      name: 'Cohort Description',
      key: 'cohortDescription',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      width: '30%',
      align: 'left',
      showIcon: false,
      sortable: false,
    },
    {
      name: 'Actions',
      key: 'actions',
      dataType: 'string',
      width: '20%',
      showIcon: false,
      type: 'actions',
      align: 'center',
      defaultSortOrder: null,
      sortable: false,
      actionIcons: [
        {
          type: 'custom:view-icon',
          key: 'view',
          title: 'View',
          theme: 'fill',
        },
      ],
    },
  ];

  dataSourceTableScrollDetails = { y: '300px' };

  dataSourceTableErrorMessage: any;

  dataSourceTableErrorType: any;

  dataSourceNameList: any[] = [];

  dataSourceList: DataSource[] = [];

  selectedDataSource: any;

  cohortDetails = [];

  cohortTableErrorMessage: any;

  cohortTableErrorType: any;

  isDataSourceLoaded = false;

  isCohortDataLoaded = false;

  idmUsers: any[] = [];

  backupIdmUsers: any[] = [];

  listOfSelectedColloborators: any = [];

  fetchedListOfSelectedCollaborator: any = [];

  fetchedListOfSelectedViewers: any = [];

  listOfSelectedViewers: any = [];

  disableImportBtn = false;

  importDrawerVisible = false;

  dataSource = '';

  cohortName = '';

  cohortDescription = '';

  s3Location = '';

  showCohortTable = true;

  showBox = false;

  cohortDetailsImportSelection: any;

  isValidateTableDataLoaded = true;

  dataSourceError = false;

  cohortNameError = false;

  cohortDescriptionError = false;

  s3LocationError = false;

  cohortImportFormError = false;

  invalidS3Location = false;

  alertErrorMessageForAPIfailure: any;

  disableSubmit = true;

  indicator = false;

  showWarningPopupValue = true;

  navigateUrl = '';

  appNavigate: Subscription;

  openViewPopup: boolean = false;

  eyeIconCohortID: any;

  eyeIconExportVersion: any;

  eyeIconDataSourceId: any;

  studyTitle: any = '';

  studyDescription: any = '';

  therapyArea: any = '';

  studyDetails: any;

  actionType: 'edit' | 'view' | 'none' = 'none';

  disableInput = false;

  viewEditClicked = false;

  viewModeClicked = false;

  collabratorViewerChanged = false;

  studyProgress = 0;

  currentUserId = '';

  studyOwner = '';
  spinnerOnCohortViewClick = false;

  studyId = '';

  listOfOption: Array<{ label: string; value: string }> = [];
  listOfTagOptions = [];

  idmUsersListCollaborators: any = [];
  idmUsersListViewers: any = [];

  collaboratorViewerLoaded = true;
  showBoxNew = false;
  editCohort: any;

  isUserRoleCollaborator = false;

  informationPopupStatus = false;

  isUserRoleViewer = true;

  allBreadCrumbData: any = [];

  viewerBreadCrumbData: any = [];

  isStudyAccessIssue = true;

  defaultBreadcrumbhide = false;

  isStudyDetailDataLoaded = true;

  constructor(
    private actionTriggerService: ActionTriggerService,
    private createStudiesService: CreateStudiesService,
    private _sensitiveInfoService: SensitiveInfoService,
    private location: Location,
    private router: Router,
    private appState: AppStateService,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe((params: any) => {
      this.studyId = params.get('studyId');
      if (this.studyId) {
        this.fetchClickedStudyDetails();
        this.fetchUserRole();
      } else {
        this.isUserRoleCollaborator = true;
      }
    });

    this.studyDetailForm = new FormGroup({
      studyTitle: new FormControl(
        { value: this.studyTitle, disabled: this.disableInput },
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern('^[A-Z].*$'),
        ]
      ),
      studyDescription: new FormControl({
        value: this.studyDescription,
        disabled: this.disableInput,
      }),
      therapyArea: new FormControl(
        { value: this.therapyArea, disabled: this.disableInput },
        [Validators.required]
      ),
    });

    this.appNavigate = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigateUrl = event.url;
      }
    });
  }

  ngOnInit() {
    this.getDataSourceNames();
    this.getLoggedUserId();
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
    this.setBreadCrumbForViewer();
  }

  fetchClickedStudyDetails() {
    // let studyId = '';
    this.createStudyLoader = true;
    this.collaboratorViewerLoaded = false;
    this.isStudyDetailDataLoaded = false;
    this.actionTriggerService
      .getStudyDetails(this.studyId)
      .subscribe((data: any) => {
        this.editCohort = data.data;
        this.studyDetailForm.controls.studyTitle.setValue(
          this.editCohort.studyTitle
        );
        this.studyDetailForm.controls.therapyArea.setValue(
          this.editCohort.therapyArea
        );
        this.studyDetailForm.controls.studyDescription.setValue(
          this.editCohort.studyDescription
        );

        if (data.status === API_SUCCESS_KEY) {
          this.createStudyLoader = false;
          this.appState.setGlobalState(APP_STATE_KEYS.STUDY_DETAILS, data.data);
        }
        if (this.editCohort.importedS3Location != '') {
          this.showBoxNew = true;
          this.showCohortTable = false;
        }
      });

    this.appState.appStateChange().subscribe((appState) => {
      this.actionType = appState[APP_STATE_KEYS.ACTION_TYPE];
      this.studyDetails = appState[APP_STATE_KEYS.STUDY_DETAILS];
    });
    if (this.actionType !== 'none') {
      this.disableInput = true;
      this.viewEditClicked = true;
      this.studyTitle = this.studyDetails?.studyTitle;
      this.therapyArea = this.studyDetails?.therapyArea;
      this.studyDescription = this.studyDetails?.studyDescription;
      this.studyProgress = this.studyDetails?.progress;
      this.studyOwner = this.studyDetails?.createdBy;
    }

    if (this.actionType === 'view') {
      this.viewModeClicked = true;
    }
  }

  dismissWarningPopup() {
    this.showConfirmationFlag = true;
    this.showWarningPopupValue = false;
    return false;
  }

  showNavigationStatus() {
    let formDefaultValue = {
      studyTitle: '',
      studyDescription: '',
      therapyArea: '',
    };

    if (this.checkDataNotSaved()) {
      return this.showWarningPopupValue;
    } else {
      return false;
    }
  }

  checkDataNotSaved() {
    let formDefaultValue = {
      studyTitle: '',
      studyDescription: '',
      therapyArea: '',
    };
    if (
      (!this.studyId &&
        JSON.stringify(this.studyDetailForm.value) !=
          JSON.stringify(formDefaultValue)) ||
      JSON.stringify(this.listOfSelectedColloborators) !=
        JSON.stringify(this.fetchedListOfSelectedCollaborator) ||
      JSON.stringify(this.listOfSelectedViewers) !=
        JSON.stringify(this.fetchedListOfSelectedViewers)
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateStudyTitle(event: any) {
    let studyTitleName = event.target.value;
    let payload = { studyTitle: studyTitleName };
    let control = this.studyDetailForm.get('studyTitle');
    if (this.studyDetailForm.get('studyTitle')?.invalid) {
      return false;
    }
    this.actionTriggerService.validateStudyTitle(payload).subscribe((data) => {
      if (data.isTitleDuplicate === true) {
        control?.setErrors({ validStudyTitle: true });
      } else {
        if (
          this.studyDetailForm.get('studyTitle')?.hasError('validStudyTitle')
        ) {
          delete this.studyDetailForm.get('studyTitle')?.errors
            ?.validStudyTitle;
          this.studyDetailForm.get('studyTitle')?.updateValueAndValidity();
        }
      }
    });
  }

  fetchUserRole() {
    this.createStudyLoader = true;
    this.commonService.getUserRole(this.studyId).subscribe((response) => {
      if (response.status === API_SUCCESS_KEY) {
        this.isUserRoleCollaborator =
          response.data.role == 'viewer' ? false : true;

        this.isUserRoleViewer = response.data.role == 'viewer' ? true : false;
        this.defaultBreadcrumbhide =
          response.data.role == 'viewer' ? true : false;
        this.createStudyLoader = false;
      } else {
        this.createStudyLoader = false;
        this.handleAPIError(SOMETHING_WENT_WRONG, 'API');
      }
    });
  }

  getLoggedUserId() {
    this.commonService.getUserSession().subscribe((data: any) => {
      this.currentUserId = data.username.toLowerCase();
      this.getIdmUsers(data.username.toLowerCase());
    });
  }

  private getIdmUsers(loggedUserId: any) {
    this.createStudiesService.getIdmUsers().subscribe((data) => {
      this.idmUsers = data.reduce((finalResult: any, user: any) => {
        user.isCollobotator = false;
        user.isViewer = false;
        if (user.username.toLowerCase() != loggedUserId) {
          if (
            !(
              this.viewEditClicked &&
              user.username.toLowerCase() === this.studyOwner
            )
          ) {
            finalResult.push(user);
          }
        }
        return finalResult;
      }, []);

      if (this.studyOwner !== '') {
        this.idmUsers = this.idmUsers.filter(
          (item) => item.username !== this.studyOwner
        );
      }

      this.idmUsersListCollaborators = JSON.parse(
        JSON.stringify(this.idmUsers)
      );
      this.idmUsersListViewers = JSON.parse(JSON.stringify(this.idmUsers));
      this.backupIdmUsers = JSON.parse(JSON.stringify(this.idmUsers));
      if (this.viewEditClicked) {
        this.getSelectedViewerCollaborators();
      }
    });
  }

  getSelectedViewerCollaborators() {
    this.collaboratorViewerLoaded = false;

    let selectedCollaborators = this._sensitiveInfoService.decrypt(
      this.studyDetails.collaborators
    );
    let selectedViewers = this._sensitiveInfoService.decrypt(
      this.studyDetails.viewers
    );
    if (selectedCollaborators.length > 0) {
      selectedCollaborators.forEach((id: any) => {
        let index = this.idmUsersListCollaborators.findIndex(
          (val: any) => val.username === id
        );

        if (index !== -1) {
          let collaborators = this.idmUsersListCollaborators[index];
          collaborators.isCollobotator = true;
          this.listOfSelectedColloborators.push(collaborators);
        }
      });
    }

    if (selectedViewers.length > 0) {
      selectedViewers.forEach((id: any) => {
        let index = this.idmUsersListViewers.findIndex(
          (val: any) => val.username === id
        );

        if (index !== -1) {
          let viewer = this.idmUsersListViewers[index];
          viewer.isViewer = true;
          this.listOfSelectedViewers.push(viewer);
        }
      });
    }
    this.onSelectColloboratorsViewers(
      this.listOfSelectedColloborators,
      true,
      false
    );
    this.onSelectColloboratorsViewers(this.listOfSelectedViewers, false, false);

    this.fetchedListOfSelectedCollaborator = this.listOfSelectedColloborators;
    this.fetchedListOfSelectedViewers = this.listOfSelectedViewers;

    this.collaboratorViewerLoaded = true;
    this.createStudyLoader = false;
    this.isStudyDetailDataLoaded = true;
  }

  titleCaseWord(word: string) {
    if (!word) {
      return word;
    }
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  allowAlphaNumeric(event: any, regexFormat: any) {
    let regex = new RegExp(regexFormat, 'i');
    if (event.type == 'paste') {
      let clipboardData = event.clipboardData.getData('text');
      if (regex.test(clipboardData)) {
        return true;
      } else {
        return false;
      }
    }
    if (regex.test(event.key)) {
      return true;
    } else {
      return false;
    }
  }

  onSelectColloboratorsViewers(
    event: any,
    isCollabotator: any,
    isRefreshRequired = true
  ) {
    this.collabratorViewerChanged = true;
    if (isCollabotator) {
      if (isRefreshRequired) {
        this.idmUsersListViewers = JSON.parse(
          JSON.stringify(this.backupIdmUsers)
        );
      }
      event.forEach((data: any) => {
        let index = this.idmUsers.findIndex(
          (item: any) => data.username == item.username
        );
        if (index != -1) {
          this.idmUsersListViewers[index].isCollobotator = true;
        }
      });
    } else {
      if (isRefreshRequired) {
        this.idmUsersListCollaborators = JSON.parse(
          JSON.stringify(this.backupIdmUsers)
        );
      }
      event.forEach((data: any) => {
        let index = this.idmUsers.findIndex(
          (item: any) => data.username == item.username
        );
        if (index != -1) {
          this.idmUsersListCollaborators[index].isViewer = true;
        }
      });
    }
  }

  closeConfirmationPopup(event: any) {
    if (event == true) {
      this.router.navigate(['/home/']);
    } else {
      this.showWarningPopupValue = true;
      this.showConfirmationFlag = false;
    }
  }

  private getDataSourceNames() {
    let index = 0;

    this.actionTriggerService
      .getDataSource()
      .pipe(
        mergeMap((dataSourceResponse: any) => {
          if (this.viewEditClicked) {
            index = dataSourceResponse.findIndex(
              (key: any) =>
                Number(key.dataSourceId) === this.studyDetails.cbDataSourceId
            );
          }
          this.dataSourceList = this.selectTableRow(dataSourceResponse, index);
          return this.actionTriggerService.getCohort(
            dataSourceResponse[index]?.dataSourceId
          );
        })
      )
      .subscribe(
        (cohortDetails: any) => {
          this.cohortDetails = this.formatCohortDetails(cohortDetails);
          this.isDataSourceLoaded = true;
          this.isCohortDataLoaded = true;
          if (this.cohortDetails === []) {
            this.handleAPIError(NO_COHORT, 'cohort');
            this.isCohortDataLoaded = true;
          }
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.handleAPIError(SOMETHING_WENT_WRONG, 'dataSource');
          this.handleAPIError(SOMETHING_WENT_WRONG, 'cohort');
        }
      );
  }

  selectTableRow(response: any, index: number) {
    response.forEach((item: any) => {
      Object.assign(item, {
        isSelected: false,
        disabledColumns: this.viewEditClicked ? ['dataSourceName'] : [],
      });
    });
    if (index >= 0 && index < response.length) {
      response[index].isSelected = true;
    }
    return response;
  }

  private getCohortDetails(selectedDataSource: DataSource) {
    this.isCohortDataLoaded = false;
    if (isObject(this.selectedDataSource)) {
      this.actionTriggerService
        .getCohort(selectedDataSource?.dataSourceId.toString())
        .subscribe(
          (data) => {
            if (data?.length > 0) {
              this.cohortDetails = this.formatCohortDetails(data);
              this.isCohortDataLoaded = true;
            } else {
              this.handleAPIError(NO_COHORT, 'cohort');
              this.isCohortDataLoaded = true;
            }
          },
          (error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
            this.handleAPIError(SOMETHING_WENT_WRONG, 'cohort');
            this.isCohortDataLoaded = true;
          }
        );
    }
  }

  onDataSourceTableRowClick(event: any) {
    this.selectedDataSource = this.dataSourceList.find((item) => {
      return item.dataSourceId === event.data.dataSourceId;
    });
    let selectedDataSourceIndex = this.dataSourceList.findIndex((item) => {
      return item.dataSourceId === event.data.dataSourceId;
    });
    this.selectTableRow(this.dataSourceList, selectedDataSourceIndex);
    this.getCohortDetails(this.selectedDataSource);
  }

  onCohortViewButtonClick(event: any) {
    if (event.column.key === 'actions') {
      let selectedCohortIndex = this.cohortDetails.findIndex((item: any) => {
        return item?.cohortId === event.data.cohortId;
      });
      this.selectTableRow(this.cohortDetails, event.index);
      this.eyeIconCohortID = this.getCohortRequestBody().cbCohortId;
      this.eyeIconExportVersion = this.getCohortRequestBody().cbExportId;
      this.eyeIconDataSourceId = this.getCohortRequestBody().cbDataSourceId;
      this.openViewPopup = true;
    } else {
      this.selectTableRow(this.cohortDetails, event.index);
    }
  }

  closeViewPopup() {
    this.openViewPopup = false;
  }

  formatCohortDetails(data: any) {
    let index = 0;
    data = data.reduce((dataList: any, item: any) => {
      item.cohortExport.forEach((itemList: any) => {
        let obj = {
          cohortId: item.cohortId,
          cohortName: itemList.version,
          disabledActionIcons: item.disabledActionIcons,
          disabledColumns: item.disabledColumns,
          isSelected: item.isSelected,
          visibleActionIcons: item.visibleActionIcons,
          cohortDescription: item.cohortDescription,
          cohortExport: item.cohortExport,
        };
        dataList.push(obj);
      });
      return dataList;
    }, []);
    data.sort((a: any, b: any) => {
      return a?.cohortName.localeCompare(b?.cohortName);
    });
    if (this.viewEditClicked) {
      index = data.findIndex(
        (item: any) => item?.cohortName === this.studyDetails.cbExportId
      );

      this.cohortTableColumns[0].type = 'none';
    }
    data.forEach((item: any, counter: any) => {
      Object.assign(item, {
        visibleActionIcons: ['view'],
        disabledActionIcons:
          this.viewEditClicked && counter != index ? ['view'] : [],
        isSelected: false,
        disabledColumns: this.viewEditClicked ? ['cohortName'] : [],
      });
    });
    this.selectTableRow(data, index);
    return data;
  }

  handleAPIError(errorMessage: string, api: string): void {
    switch (api) {
      case 'dataSource':
        this.dataSourceList = [];
        this.dataSourceTableErrorMessage = errorMessage;
        this.dataSourceTableErrorType = 'info';
        break;

      case 'cohort':
        this.cohortDetails = [];
        this.cohortTableErrorMessage = errorMessage;
        this.cohortTableErrorType = 'info';
        break;

      case 'validateTable':
        this.isValidateTableDataLoaded = true;
        this.importDrawerVisible = false;
        this.showBox = false;
        this.showCohortTable = true;
        this.cohortName = '';
        this.cohortDescription = '';
        this.dataSource = '';
        this.s3Location = '';
        this.commonService.createNotification('error', errorMessage);
        break;
    }
  }

  saveStudy(payload: any, submitType: any) {
    this.createStudyLoader = true;
    this.createStudiesService.postSaveStudy(payload).subscribe(
      (data: any) => {
        if (data.status === API_SUCCESS_KEY) {
          if (submitType == 'NEXT') {
            if (!this.studyId) {
              this.commonService.createNotification(
                'success',
                'Study created successfully'
              );
            }
            if (this.studyId) {
              this.commonService.createNotification(
                'success',
                'Study saved successfully'
              );
            }
            this.createStudy(data.studyId);
          } else if (submitType == 'SAVE') {
            this.createStudyLoader = false;
            this.showWarningPopupValue = false;
            if (!this.studyId) {
              this.commonService.createNotification(
                'success',
                'Study created successfully'
              );
            }
            if (this.studyId) {
              this.commonService.createNotification(
                'success',
                'Study saved successfully'
              );
            }

            this.router.navigate(['/home/']);
          }
        } else {
          this.createStudyLoader = false;
        }
      },
      (error: any) => {
        this.createStudyLoader = false;
        this.handleAPIError(SOMETHING_WENT_WRONG, 'createStudy');
      }
    );
  }

  createStudy(studyId: any) {
    this.createStudiesService.postCreateStudy(studyId).subscribe(
      (data: any) => {
        if (data.status === API_SUCCESS_KEY) {
          this.createStudyLoader = false;
          this.showWarningPopupValue = false;
          if (this.studyProgress >= 20) {
            this.router.navigate([
              '/studies/dimension-mapping/' + this.studyDetails.studyId,
            ]);
          } else {
            this.informationPopupStatus = true;
          }
        }
      },
      (error: any) => {
        this.createStudyLoader = false;
        this.handleAPIError(SOMETHING_WENT_WRONG, 'createStudy');
      }
    );
  }

  onImportClick() {
    this.importDrawerVisible = true;
    this.dataSource = '';
    this.cohortName = '';
    this.cohortDescription = '';
    this.s3Location = '';
    this.disableSubmit = true;
    this.indicator = false;
    this.invalidS3Location = false;
    this.cohortImportFormError = false;
    this.dataSourceError = false;
    this.cohortDescriptionError = false;
    this.cohortNameError = false;
    this.s3LocationError = false;
  }

  validateTable(payload: any, cohortDetailsArg = {}, submitType = '') {
    this.actionTriggerService.validateS3Location(payload).subscribe(
      (data) => {
        if (data.status === API_SUCCESS_KEY) {
          if (
            data?.dataSufficiency === true &&
            data?.tablesMissing?.length === 0
          ) {
            if (submitType != '') {
              this.saveStudy(cohortDetailsArg, submitType);
            } else {
              this.cohortDetailsImportSelection = data;
              Object.assign(this.cohortDetailsImportSelection, {
                isMyCohortChecked: true,
                dataSourceName: this.dataSource,
                importedS3Location: this.s3Location,
                cohortName: this.cohortName,
                importedCohortDescription: this.cohortDescription,
              });
              this.showCohortTable = false;
              this.showBox = true;
              this.importDrawerVisible = false;
              this.isValidateTableDataLoaded = true;
            }
          } else {
            this.createStudyLoader = false;

            if (submitType != '') {
              this.commonService.createNotification('error', data?.message);
            } else {
              this.commonService.createNotification(
                'error',
                INVALID_S3_LOCATION
              );
              this.isValidateTableDataLoaded = true;
              this.invalidS3Location = true;
              this.s3LocationError = true;
              this.s3Location = '';
              this.cohortImportFormError = true;
            }
          }
        }
      },
      (error) => {
        this.createStudyLoader = false;
        this.handleAPIError(SOMETHING_WENT_WRONG, 'validateTable');
      }
    );
  }

  collaborator(displayName: any, routerLink: any) {
    if (
      displayName != 'All Studies' &&
      displayName != 'Study Details' &&
      this.getCohortRequestBody() === false
    ) {
      this.commonService.createNotification(
        'error',
        'Cohort is not publicly shared'
      );
    } else {
      this.router.navigate([routerLink]);
    }
  }

  navigateBack() {
    this.router.navigate(['/home/']);
  }

  onCloseImportDrawerClick() {
    this.importDrawerVisible = false;
  }

  onSubmitClick() {
    const payload = {
      s3Location: this.s3Location,
    };
    this.isValidateTableDataLoaded = false;
    this.validateTable(payload);
  }

  onCancelImportClick() {
    this.importDrawerVisible = false;
    this.dataSource = '';
    this.cohortName = '';
    this.cohortDescription = '';
    this.s3Location = '';
    this.disableSubmit = true;
    this.indicator = false;
    this.invalidS3Location = false;
    this.cohortImportFormError = false;
    this.dataSourceError = false;
    this.cohortDescriptionError = false;
    this.cohortNameError = false;
    this.s3LocationError = false;
  }

  onSelectCohortClick() {
    this.chartLoadingStatus = true;
    this.showBox = false;
    this.showCohortTable = true;
    this.cohortDetailsImportSelection = {};
  }

  getCohortRequestBody() {
    let selectedCohortdetails: any;
    let dataSourceDetails: any;
    let mainRequestBody: any;
    let selectedCohortVersion: any;
    if (this.showCohortTable === true) {
      if (this.dataSourceList.length > 0 && this.cohortDetails.length > 0) {
        selectedCohortdetails = this.cohortDetails.find((item: any) => {
          return item.isSelected === true;
        });
        if (!selectedCohortdetails) {
          return false;
        }
        selectedCohortVersion = selectedCohortdetails.cohortExport.find(
          (item: any) => {
            return item.version === selectedCohortdetails.cohortName;
          }
        );
        dataSourceDetails = this.dataSourceList.find((item: any) => {
          return item.isSelected === true;
        });
        mainRequestBody = {
          cbCohortId: selectedCohortdetails?.cohortId,
          cohortName: selectedCohortdetails?.cohortName,
          dataSourceName: dataSourceDetails?.dataSourceName,
          cbExportId: selectedCohortVersion?.version,
          cbDataSourceId: dataSourceDetails?.dataSourceId,
          globalConceptId:
            selectedCohortVersion?.globalConceptSet[0].conceptSetId,
          globalConceptName:
            selectedCohortVersion?.globalConceptSet[0]?.conceptSetName,
          cohortDescription: selectedCohortdetails?.cohortDescription,
          s3CohortPath: selectedCohortVersion?.dataSourceList[0]?.s3bucket,
          cohortExportS3Path:
            selectedCohortVersion?.dataSourceList[0]?.cohortExports3Path,
          isMyCohortChecked: '',
          validateTables3Location:
            's3://' +
            selectedCohortVersion?.dataSourceList[0]?.s3bucket +
            '/' +
            selectedCohortVersion?.dataSourceList[0]?.cohortExports3Path,
        };
      } else {
        mainRequestBody = {};
      }
      return mainRequestBody;
    } else if (this.showBox === true) {
      mainRequestBody = {
        isMyCohortChecked: true,
        dataSourceName: this.cohortDetailsImportSelection?.dataSourceName,
        importedS3Location:
          this.cohortDetailsImportSelection?.importedS3Location,
        cohortName: this.cohortDetailsImportSelection.cohortName,
        importedCohortDescription:
          this.cohortDetailsImportSelection?.importedCohortDescription,
        validateTables3Location:
          this.cohortDetailsImportSelection?.importedS3Location,
      };
      return mainRequestBody;
    }
    return '';
  }

  validateForm(fieldName: string, $event: any) {
    this.disableSubmit = false;
    switch (fieldName) {
      case 'dataSource':
        if ($event.trim().length === 0) {
          this.dataSourceError = true;
        } else {
          this.dataSourceError = false;
        }
        break;

      case 'cohortName':
        if ($event.trim().length === 0) {
          this.cohortNameError = true;
        } else {
          this.cohortNameError = false;
        }
        break;

      case 'cohortDescription':
        if ($event.trim().length === 0) {
          this.cohortDescriptionError = true;
        } else {
          this.cohortDescriptionError = false;
        }
        break;

      case 's3Location':
        if ($event.trim().length === 0) {
          this.s3LocationError = true;
        } else {
          this.s3LocationError = false;
        }

        if (this.invalidS3Location) {
          this.indicator = true;
          this.invalidS3Location = false;
        }
        break;
    }
    if (
      this.dataSourceCtrl?.dirty &&
      this.cohortDescriptionCtrl?.dirty &&
      this.cohortNameCtrl?.dirty &&
      this.s3LocationCtrl?.dirty
    ) {
      this.disableSubmit = false;
    } else {
      this.disableSubmit = true;
    }

    if (this.indicator) {
      this.disableSubmit = false;
    }

    this.cohortImportFormError =
      this.dataSourceError ||
      this.cohortNameError ||
      this.cohortDescriptionError ||
      this.s3LocationError;
  }

  onStudyDetailsSubmitClick(submitType: any) {
    this.createStudyLoader = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let cohortPayload = this.getCohortRequestBody();
    //-------------
    if (cohortPayload === false) {
      this.commonService.createNotification(
        'error',
        'Cohort is not publicly shared'
      );
      this.createStudyLoader = false;
      return;
    }
    //-------------
    let selectedColloborators = this.listOfSelectedColloborators.map(
      (data: any) => {
        return data.username;
      }
    );

    if (this.currentUserId !== this.studyOwner) {
      selectedColloborators = this.addCurrentUser(selectedColloborators);
    }

    let selectedViewers = this.listOfSelectedViewers.map((data: any) => {
      return data.username;
    });

    if (this.viewEditClicked) {
      this.editStudy(submitType);
    } else {
      let payload;
      if (cohortPayload == {}) {
        console.log('Please enter valid Cohort');
      } else if (cohortPayload.cbCohortId) {
        payload = {
          title: this.studyDetailForm.get('studyTitle')?.value,
          studyDescription: this.studyDetailForm.get('studyDescription')?.value,
          therapyArea: this.studyDetailForm.get('therapyArea')?.value,
          outcome: '',
          collaborators: this._sensitiveInfoService.encrypt(
            selectedColloborators
          ),
          viewers: this._sensitiveInfoService.encrypt(selectedViewers),
          cbCohortId: cohortPayload.cbCohortId,
          cohortName: cohortPayload.cohortName,
          dataSourceName: cohortPayload.dataSourceName,
          cbExportId: cohortPayload.cbExportId,
          cbDataSourceId: cohortPayload.cbDataSourceId,
          globalConceptId: cohortPayload.globalConceptId,
          globalConceptName: cohortPayload.globalConceptName,
          importedS3Location: '',
          cohortDescription: cohortPayload.cohortDescription,
          s3CohortPath: cohortPayload.s3CohortPath,
          cohortExportS3Path: cohortPayload.cohortExportS3Path,
          isMyCohortChecked: true,
        };
        this.validateTable(
          { s3Location: cohortPayload.validateTables3Location },
          payload,
          submitType
        );
      } else {
        payload = {
          title: this.studyDetailForm.get('studyTitle')?.value,
          studyDescription: this.studyDetailForm.get('studyDescription')?.value,
          therapyArea: this.studyDetailForm.get('therapyArea')?.value,
          outcome: '',
          isMyCohortChecked: true,
          collaborators: this._sensitiveInfoService.encrypt(
            selectedColloborators
          ),
          viewers: this._sensitiveInfoService.encrypt(selectedViewers),
          dataSourceName: cohortPayload.dataSourceName,
          importedS3Location: cohortPayload.importedS3Location,
          cohortName: cohortPayload.cohortName,
          importedCohortDescription: cohortPayload.importedCohortDescription,
        };
        this.saveStudy(payload, submitType);
      }
    }
  }

  addCurrentUser(selectedColloborators: any) {
    selectedColloborators.push(this.currentUserId);
    return selectedColloborators;
  }

  editStudy(submitType: string) {
    let selectedColloborators = this.listOfSelectedColloborators.map(
      (data: any) => {
        return data.username;
      }
    );

    if (this.currentUserId !== this.studyOwner) {
      selectedColloborators = this.addCurrentUser(selectedColloborators);
    }

    let selectedViewers = this.listOfSelectedViewers.map((data: any) => {
      return data.username;
    });
    const payload = {
      collaborators: this._sensitiveInfoService.encrypt(selectedColloborators),
      viewers: this._sensitiveInfoService.encrypt(selectedViewers),
    };

    this.actionTriggerService
      .editStudyDetails(this.studyDetails.studyId, payload)
      .subscribe(
        (data: any) => {
          if (data.status === API_SUCCESS_KEY) {
            if (submitType == 'NEXT') {
              if (this.checkDataNotSaved()) {
                this.commonService.createNotification(
                  'success',
                  'Study saved successfully'
                );
              }
              this.createStudy(this.studyDetails.studyId);
            } else if (submitType == 'SAVE') {
              this.createStudyLoader = false;
              this.showWarningPopupValue = false;
              if (!this.studyId) {
                this.commonService.createNotification(
                  'success',
                  'Study created successfully'
                );
              }
              if (this.studyId) {
                this.commonService.createNotification(
                  'success',
                  'Study saved successfully'
                );
              }
              this.router.navigate(['/home/']);
            }
          } else {
            this.createStudyLoader = false;
          }
        },
        (error: any) => {
          this.createStudyLoader = false;
          this.handleAPIError(SOMETHING_WENT_WRONG, 'createStudy');
        }
      );
  }

  setBreadCrumbForViewer() {
    let studyProgress;
    let index = 5;

    this.allBreadCrumbData = [
      {
        displayName: 'All Studies',
        navigationPath: '/home/',
        key: 'alStudies',
      },
      {
        displayName: 'Study Details',
        navigationPath: '',
        key: 'studyDetails',
      },
      {
        displayName: 'Dimension Mapping',
        navigationPath: `/studies/dimension-mapping/${this.studyId}`,
        key: 'dimensionMapping',
      },
      {
        displayName: 'Episode Creation',
        navigationPath: `/studies/episode-creation/${this.studyId}`,
        key: 'episodeCreation',
      },
      {
        displayName: 'Regimen & LoT',
        navigationPath: `/studies/regimen-lot/${this.studyId}`,
        key: 'regimenLot',
      },
      {
        displayName: 'Select Insights',
        navigationPath: `/studies/select-insights/${this.studyId}`,
        key: 'selectInsights',
      },
    ];
    this.createStudyLoader = true;
    if (!this.studyId) {
      this.createStudyLoader = false;
      return;
    }
    this.commonService.getStudyProgress(this.studyId).subscribe((response) => {
      this.createStudyLoader = false;
      if (response.status === API_SUCCESS_KEY) {
        this.isStudyAccessIssue = false;
        studyProgress = response.progress;

        if (studyProgress < 20) {
          index = 1;
        } else if (studyProgress === 20) {
          index = 2;
        } else if (studyProgress > 20 && studyProgress < 60) {
          index = 5;
        } else if (studyProgress >= 60) {
          index = 5;
        }

        this.allBreadCrumbData.forEach((item: any, counter: any) => {
          if (counter <= index) {
            this.viewerBreadCrumbData.push(item);
          }
        });
      }
    });
  }
}
