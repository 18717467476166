import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsBarchartComponent } from './highcharts-barchart.component';

@NgModule({
  declarations: [HighchartsBarchartComponent],
  imports: [CommonModule],
  exports: [HighchartsBarchartComponent],
})
export class HighchartsBarchartModule {}
