<div class="episode-creation pb-4">
  <nz-breadcrumb class="breadcrumb endClickable" [nzSeparator]="'>'">
    <nz-breadcrumb-item
      class="breadcrumb endClickable"
      *ngFor="let item of viewerBreadCrumbData"
    >
      <a
        class="breadcrumb endClickable"
        *ngIf="item.navigationPath.length > 0"
        [routerLink]="item.navigationPath"
      >
        {{ item.displayName }}
      </a>
      <span
        [ngClass]="
          item.navigationPath.length === 0 ? 'nonClickableBreadCrumb' : ''
        "
        *ngIf="item.navigationPath.length === 0"
      >
        {{ item.displayName }}
      </span>
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <div *ngIf="isRequiredJobTriggeredStatus" class="m-auto w-fit-content mb-3">
    <app-alert
      [closable]="false"
      [message]="'You have not triggered the required jobs to view this page.'"
      [type]="'error'"
    ></app-alert>
  </div>
  <div *ngIf="!isRequiredJobTriggeredStatus">
    <div class="export-section cursor-pointer" (click)="exportButtonClick()">
      <i nz-icon nzType="custom:download-green-icon"></i>
      <span class="export-text ml-1">Export</span>
    </div>

    <div *ngIf="hideDataFilterSection === true" class="section mb-4">
      <div
        class="section mb-5"
        [ngClass]="{
          'disable-section': dataFilterDisableStatus || isUserRoleViewer
        }"
      >
        <div class="title">Data Filter</div>
        <div class="data-filter-section">
          <span class="claim-type">Do you want to select the claim type?</span>
          <nz-radio-group
            [(ngModel)]="claimTypeRadioValue"
            (ngModelChange)="claimTypeChanged($event)"
          >
            <label nz-radio nzValue="Yes">Yes</label>
            <label nz-radio nzValue="No">No</label>
          </nz-radio-group>
        </div>

        <div>
          <span class="claim-type">Choose the claim type to include:</span>

          <span>
            <label
              nz-checkbox
              [(ngModel)]="isRejectedChecked"
              [nzDisabled]="claimTypeRadioValue === 'No'"
            >
              Rejected
            </label>
            <label
              nz-checkbox
              [(ngModel)]="isApprovedChecked"
              [nzDisabled]="claimTypeRadioValue === 'No'"
            >
              Approved
            </label>
            <label
              nz-checkbox
              [(ngModel)]="isReversedChecked"
              [nzDisabled]="claimTypeRadioValue === 'No'"
            >
              Reversed
            </label>
          </span>

          <div class="apply-button-style mb-30">
            <rd-button
              [btnType]="'primary'"
              [btnStyle]="applyBtnStyle"
              btnText="Apply"
              [isIconNeeded]="false"
              (btnClick)="onApplyClick()"
              [btnClass]="'custom-primaryfill-greenbtn'"
              [isDisabled]="!enableApplyForDataFilter || isUserRoleViewer"
            ></rd-button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="section mb-5"
      [ngClass]="{
        'disable-section': dosImputationDisableStatus || isUserRoleViewer
      }"
    >
      <div class="title d-flex-center-space-between">
        <div>
          DOS Imputation&nbsp;<rd-tooltip
            [tooltipText]="
              'The section imputes DoS values for procedures and outlier values in prescriptions. The default days of supply value for imputation for all the products is 28.'
            "
            tooltipPlacement="right"
            tooltipColor="#000000bf"
            [tooltipVisible]="false"
          ></rd-tooltip>
        </div>
        <div class="w-min-content d-flex-center-start">
          <label
            [for]="'dos_grid'"
            class="title-options-inner w-max-content text-right"
            [ngClass]="{
              'disable-section': isUserRoleViewer
            }"
          >
            <i nz-icon nzType="upload" nzTheme="outline"></i>
            Upload Data
            <input
              class="upload-input"
              [attr.id]="'dos_grid'"
              type="file"
              accept=".csv"
              (click)="resetFile($event)"
              (change)="uploadFile($event)"
              style="opacity: 0; width: 0px"
            />
          </label>
          <rd-button
            [btnType]="'default'"
            btnText="Download Data"
            [isIconNeeded]="true"
            [iconType]="dosDownloadBtnLoaderStatus ? 'loading' : 'download'"
            [btnClass]="
              dosDownloadBtnLoaderStatus
                ? 'refreshBtn disable-section'
                : 'refreshBtn enable-section'
            "
            (btnClick)="downloadGridData()"
          ></rd-button>
        </div>
      </div>
      <div class="err-message" *ngIf="dosImputationDisableStatusMessage">
        <app-alert
          [closable]="false"
          [message]="
            'A job is running to compute Dos Imputation. Please try after sometime.'
          "
          [type]="'warning'"
        ></app-alert>
      </div>
      <div class="err-message" *ngIf="dosImputationDisableFailedStatus">
        <app-alert
          [closable]="false"
          [message]="MAPPING_JOB_FAILED"
          [type]="'error'"
        ></app-alert>
      </div>
      <div class="w-50 select-product mb-30 pr-3">
        <div class="subtitle">Select Product</div>
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Select Product"
          [(ngModel)]="selectedDrugName"
          [nzLoading]="drugListLoadingStatus"
          (ngModelChange)="dosDistributionGraphData()"
        >
          <nz-option
            *ngFor="let drug of drugList"
            [nzLabel]="drug"
            [nzValue]="drug"
          ></nz-option>
        </nz-select>
      </div>
      <div class="d-flex">
        <div class="w-50 select-product pr-3">
          <div
            (click)="
              overrideMethodDosValuesSectionToggle = false;
              enteredDosValue = null;
              disableApply = disableRefresh = !(selectedDrugName?.length > 0)
            "
            [ngClass]="{
              'section-active': overrideMethodDosValuesSectionToggle
            }"
          >
            <div class="subtitle d-flex-center-start">
              <i
                *ngIf="!overrideMethodDosValuesSectionToggle"
                nz-icon
                nzType="check-circle"
                nzTheme="fill"
              ></i>
              <div
                *ngIf="overrideMethodDosValuesSectionToggle"
                class="custom-checkmark"
              ></div>
              &nbsp;&nbsp;
              <span> Select override method </span>
            </div>
            <nz-select
              nzShowSearch
              nzPlaceHolder="Select"
              [(ngModel)]="selectedOverrideMethod"
            >
              <nz-option
                *ngFor="let option of selectOverrideMethodDropdownData"
                [nzLabel]="option.label"
                [nzValue]="option.value"
              ></nz-option>
            </nz-select>
          </div>
          <div class="mt-2 font-500">
            <label nz-checkbox [(ngModel)]="dosValuesChecked"
              >Impute DOS values exceeding 95th percentile</label
            >
          </div>
        </div>
        <div class="w-50 select-product custom-input pl-3 d-flex">
          <div class="custom-delimeter">OR</div>
          <div class="w-webkit-fill-available select-product-inner">
            <div
              class="w-webkit-fill-available"
              [ngClass]="{
                'section-active': !overrideMethodDosValuesSectionToggle
              }"
              (click)="
                overrideMethodDosValuesSectionToggle = true;
                disableApply = disableRefresh = enteredDosValue === null
              "
            >
              <div class="subtitle d-flex-center-start">
                <i
                  *ngIf="overrideMethodDosValuesSectionToggle"
                  nz-icon
                  nzType="check-circle"
                  nzTheme="fill"
                ></i>
                <div
                  *ngIf="!overrideMethodDosValuesSectionToggle"
                  class="custom-checkmark"
                ></div>
                &nbsp;&nbsp;
                <span> Enter DoS (in Days) </span>
              </div>
              <div class="d-flex-center-space-between">
                <input
                  type="number"
                  min="0"
                  placeholder="Enter here"
                  [(ngModel)]="enteredDosValue"
                  (paste)="allowNumeric($event)"
                  (keypress)="allowNumeric($event)"
                  (ngModelChange)="
                    disableApply = disableRefresh =
                      !(
                        enteredDosValue >= 0 &&
                        enteredDosValue !== null &&
                        selectedDrugName
                      )
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-30 text-right">
        <rd-button
          [isDisabled]="disableApply || isUserRoleViewer"
          [btnType]="'primary'"
          btnText="Apply"
          [isIconNeeded]="false"
          [btnClass]="'custom-primaryfill-greenbtn'"
          (btnClick)="refreshGridData(true)"
        ></rd-button>
      </div>
      <div class="d-flex flex-flow-lg-column">
        <div class="w-50 w-lg-100 pr-3">
          <div class="subtitle">DoS Distribution for the selected product</div>
          <div class="distribution-graph">
            <div class="spinner-dos-graph" *ngIf="dosChartLoaderStatus">
              <rd-spinner></rd-spinner>
            </div>
            <app-highcharts-linechart
              *ngIf="setLineChartValue"
              [lineChartConfig]="lineChartConfig"
            ></app-highcharts-linechart>
            <div *ngIf="setLineChartValue">
              <ul class="list-unstyled claims-mapping-bar text-center">
                <li><span class="median"></span> Median</li>
                <li>
                  <span class="mean"></span> Mean
                  <div class="col-sm-6"></div>
                </li>
                <li><span class="percentile"></span> 85th percentile</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="w-50 w-lg-100 pl-3 mt-lg-5">
          <div class="sub-header d-flex-center-space-between-grid">
            <div class="subtitle"></div>
            <div
              [ngClass]="{
                'disable-section': isUserRoleViewer
              }"
            >
              <rd-button
                [isDisabled]="disableRefresh"
                [btnType]="'default'"
                btnText="Refresh"
                [isIconNeeded]="true"
                [iconType]="'redo'"
                [btnClass]="'refreshBtn'"
                (btnClick)="refreshGridData()"
              ></rd-button>
            </div>
          </div>
          <div class="enable-section-dos">
            <rd-table
              [isLoading]="!dosGrid.isLoaded"
              [tableColumns]="dosGrid.columns"
              [tableData]="dosGrid.gridData"
              [tableScrollDetails]="dosGrid.scrollDetails"
              [totalCount]="dosGrid.dataCount"
              [errorMessage]="dosGrid.tableErrorMessage"
              [loadingRows]="10"
              [errorType]="dosGrid.tableErrorType"
              [showPagination]="false"
              [frontEndPagination]="false"
              (sortApply)="fetchMappingData(1, $event.defaultSortOrder)"
              [showPagination]="true"
              [frontEndPagination]="false"
              [pageIndex]="dosGrid.pageIndex"
              [pageSize]="defaultPageSize"
              (paginationChange)="fetchMappingData($event)"
            ></rd-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Grace Period Analysis -->

    <div
      class="section"
      [ngClass]="{
        'disable-section': graceImputationDisableStatus || isUserRoleViewer
      }"
    >
      <div class="section">
        <div class="title d-flex-center-space-between">
          <div>
            Grace Period Analysis&nbsp;<rd-tooltip
              [tooltipText]="
                'The section allows you to choose the grace period to stitch consecutive prescriptions  to form a therapy episode. The default grace period value for all products is 30.'
              "
              tooltipPlacement="right"
              tooltipColor="#000000bf"
              [tooltipVisible]="false"
            ></rd-tooltip>
          </div>
          <div class="w-min-content d-flex-center-start">
            <label
              [for]="'grace_grid'"
              class="title-options-inner w-max-content text-right"
              [ngClass]="{
                'disable-section': isUserRoleViewer
              }"
            >
              <i nz-icon nzType="upload" nzTheme="outline"></i>
              Upload Data
              <input
                class="upload-input"
                [attr.id]="'grace_grid'"
                type="file"
                accept=".csv"
                (click)="resetFile($event)"
                (change)="uploadGraceFile($event)"
                style="opacity: 0; width: 0px"
              />
            </label>
            <rd-button
              [btnType]="'default'"
              btnText="Download Data"
              [isIconNeeded]="true"
              [iconType]="graceDownloadBtnLoaderStatus ? 'loading' : 'download'"
              [btnClass]="
                graceDownloadBtnLoaderStatus
                  ? 'refreshBtn disable-section'
                  : 'refreshBtn enable-section'
              "
              (btnClick)="downloadGraceGridData()"
            ></rd-button>
          </div>
        </div>
        <div class="err-message" *ngIf="graceImputationDisableStatusMessage">
          <app-alert
            [closable]="false"
            [message]="
              'A job is running to compute Grace Period. Please try after sometime.'
            "
            [type]="'warning'"
          ></app-alert>
        </div>
        <div class="err-message" *ngIf="graceImputationDisableFailedStatus">
          <app-alert
            [closable]="false"
            [message]="MAPPING_JOB_FAILED"
            [type]="'error'"
          ></app-alert>
        </div>
        <div class="w-50 select-product mb-30 pr-3">
          <div class="subtitle">Select Product</div>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select Product"
            [(ngModel)]="selectedGraceDrugName"
            [nzLoading]="drugListLoading"
            (ngModelChange)="gracePeriodAnalysisGraph()"
          >
            <nz-option
              *ngFor="let drug of graceDrugList"
              [nzLabel]="drug"
              [nzValue]="drug"
            ></nz-option>
          </nz-select>
        </div>
        <div class="d-flex">
          <div class="w-50 select-product pr-3">
            <div
              (click)="
                overrideMethodGraceValuesSectionToggle = false;
                enteredGraceValue = null;
                disableGraceApply = disableGraceRefresh =
                  !(selectedGraceDrugName?.length > 0)
              "
              [ngClass]="{
                'section-active': overrideMethodGraceValuesSectionToggle
              }"
            >
              <div class="subtitle d-flex-center-start">
                <i
                  *ngIf="!overrideMethodGraceValuesSectionToggle"
                  nz-icon
                  nzType="check-circle"
                  nzTheme="fill"
                ></i>
                <div
                  *ngIf="overrideMethodGraceValuesSectionToggle"
                  class="custom-checkmark"
                ></div>
                &nbsp;&nbsp;
                <span> Select override method</span>
              </div>
              <nz-select
                nzShowSearch
                nzPlaceHolder="Select"
                [(ngModel)]="selectedGraceOverrideMethod"
              >
                <nz-option
                  *ngFor="let option of selectGraceOverrideMethodDropdownData"
                  [nzLabel]="option.label"
                  [nzValue]="option.value"
                ></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="w-50 select-product custom-input pl-3 d-flex">
            <div class="custom-delimeter">OR</div>
            <div class="w-webkit-fill-available select-product-inner">
              <div
                class="w-webkit-fill-available"
                [ngClass]="{
                  'section-active': !overrideMethodGraceValuesSectionToggle
                }"
                (click)="
                  overrideMethodGraceValuesSectionToggle = true;
                  disableGraceApply = disableGraceRefresh =
                    enteredGraceValue === null
                "
              >
                <div class="subtitle d-flex-center-start">
                  <i
                    *ngIf="overrideMethodGraceValuesSectionToggle"
                    nz-icon
                    nzType="check-circle"
                    nzTheme="fill"
                  ></i>
                  <div
                    *ngIf="!overrideMethodGraceValuesSectionToggle"
                    class="custom-checkmark"
                  ></div>
                  &nbsp;&nbsp;
                  <span> Enter grace value (in Days) </span>
                </div>
                <div class="d-flex-center-space-between">
                  <input
                    type="number"
                    min="0"
                    placeholder="Enter here"
                    [(ngModel)]="enteredGraceValue"
                    (paste)="allowNumeric($event)"
                    (keypress)="allowNumeric($event)"
                    (ngModelChange)="
                      disableGraceApply = disableGraceRefresh =
                        !(
                          enteredGraceValue >= 0 &&
                          enteredGraceValue !== null &&
                          selectedGraceDrugName
                        )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-30 text-right mt-3">
          <rd-button
            [isDisabled]="disableGraceApply || isUserRoleViewer"
            [btnType]="'primary'"
            btnText="Apply"
            [isIconNeeded]="false"
            [btnClass]="'custom-primaryfill-greenbtn'"
            (btnClick)="refreshGraceGridData(true)"
          ></rd-button>
        </div>
        <div class="d-flex flex-flow-lg-column">
          <div class="w-50 w-lg-100 pr-3">
            <div class="subtitle">
              Cumulative gap distribution for the selected product
            </div>
            <div class="distribution-graph-gap">
              <div class="spinner-style-graph" *ngIf="chartLoaderStatus">
                <rd-spinner></rd-spinner>
              </div>
              <app-highcharts-linechart
                *ngIf="setGraceValue"
                [lineChartConfig]="lineChartConfigForGrace"
              ></app-highcharts-linechart>
            </div>
          </div>
          <div class="w-50 w-lg-100 pl-3 mt-lg-5">
            <div class="sub-header d-flex-center-space-between-grid">
              <div class="subtitle"></div>
              <div
                [ngClass]="{
                  'disable-section': isUserRoleViewer
                }"
              >
                <rd-button
                  [isDisabled]="disableGraceRefresh"
                  [btnType]="'default'"
                  btnText="Refresh"
                  [isIconNeeded]="true"
                  [iconType]="'redo'"
                  [btnClass]="'refreshBtn'"
                  (btnClick)="refreshGraceGridData()"
                ></rd-button>
              </div>
            </div>
            <div class="enable-section-dos">
              <rd-table
                [isLoading]="!dosGridGrace.isLoaded"
                [tableColumns]="dosGridGrace.columns"
                [tableData]="dosGridGrace.gridData"
                [tableScrollDetails]="dosGridGrace.scrollDetails"
                [totalCount]="dosGridGrace.dataCount"
                [errorMessage]="dosGridGrace.tableErrorMessage"
                [loadingRows]="10"
                [errorType]="dosGridGrace.tableErrorType"
                [showPagination]="false"
                [frontEndPagination]="false"
                (sortApply)="
                  fetchMappingDataForGrace(1, $event.defaultSortOrder)
                "
                [showPagination]="true"
                [frontEndPagination]="false"
                [pageIndex]="dosGridGrace.pageIndex"
                [pageSize]="defaultPageSize"
                (paginationChange)="fetchMappingDataForGrace($event)"
              ></rd-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-section d-flex justify-content-end">
      <div class="footer-section-wrapper">
        <rd-button
          [btnType]="'default'"
          btnText="Back"
          [isIconNeeded]="false"
          [size]="'large'"
          (btnClick)="navigateBack()"
        ></rd-button>
        <rd-button
          [btnType]="'default'"
          btnText="Exit"
          [isIconNeeded]="false"
          [size]="'large'"
          (btnClick)="onClickOfExit()"
          [isDisabled]="isUserRoleViewer"
        ></rd-button>
        <rd-button
          [btnType]="'primary'"
          btnText="Next"
          [btnClass]="'custom-primaryfill-greenbtn'"
          (btnClick)="checkJobStatusOnNext()"
          [isDisabled]="isUserRoleViewer"
        ></rd-button>
      </div>
    </div>
  </div>

  <app-action-confirmation-popup
    *ngIf="showConfirmationFlag"
    (closePopup)="closeConfirmationPopup($event)"
    (close)="showConfirmationFlag = false"
    [confirmationConfig]="confirmationConfig"
  ></app-action-confirmation-popup>

  <app-action-confirmation-popup
    *ngIf="showDataConfirmationFlag"
    (closePopup)="closeNavigateConfirmationPopup($event)"
    (close)="closeNavigateConfirmationPopup($event)"
    [confirmationConfig]="confirmationDataConfig"
  ></app-action-confirmation-popup>

  <app-action-confirmation-popup
    *ngIf="showDosImputationConfirmationFlag"
    (closePopup)="closeDosImputationConfirmationPopup($event)"
    (close)="showDosImputationConfirmationFlag = false"
    [confirmationConfig]="dosImputationConfirmationConfig"
  ></app-action-confirmation-popup>

  <app-action-confirmation-popup
    *ngIf="showGraceImputationConfirmationFlag"
    (closePopup)="closeRegimenDistributionConfirmationPopup($event)"
    (close)="showGraceImputationConfirmationFlag = false"
    [confirmationConfig]="graceImputationConfirmationConfig"
  ></app-action-confirmation-popup>

  <app-information-popup
    *ngIf="informationPopupStatus"
    (closePopup)="informationPopupStatus = false"
  >
  </app-information-popup>

  <div class="spinner-style" *ngIf="epiodeCreationLoaderStatus">
    <rd-spinner></rd-spinner>
  </div>

  <app-export-action-popup
    *ngIf="showExportPopup"
    (closeExportPopup)="closeExportPopup($event)"
    [exportBody]="exportBody"
  ></app-export-action-popup>
</div>
