export class StudiesDetails {
  studyId: number;
  studyTitle: string;
  cohortName: string;
  dataSource: string;
  createdOn: number;
  createdBy: string;
  progress: string;
  isCloning: boolean;
  isRefreshStudy: boolean;
  role: string;
  email: string;
  constructor(
    studyId: number,
    studyTitle: string,
    cohortName: string,
    dataSource: string,
    createdOn: number,
    createdBy: string,
    progress: string,
    isCloning: boolean,
    isRefreshStudy: boolean,
    role: string,
    email : string,
  ) {
    this.studyId = studyId;
    this.studyTitle = studyTitle;
    this.cohortName = cohortName;
    this.dataSource = dataSource;
    this.createdOn = createdOn;
    this.createdBy = createdBy;
    this.progress = progress;
    this.isCloning = isCloning;
    this.isRefreshStudy = isRefreshStudy;
    this.role = role;
    this.email = email;
  }
}

export class Studies {
  status: string;
  noOfStudies: number;
  data: StudiesDetails[];

  constructor(status: string, data: StudiesDetails[], noOfStudies: number) {
    this.status = status;
    this.noOfStudies = noOfStudies;
    this.data = data;
  }
}
