import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import {
  API_SUCCESS_KEY,
  MAXIMUM_CSV_FILE_SIZE,
  SOMETHING_WENT_WRONG,
  MAPPING_JOB_FAILED,
} from 'src/app/shared/constants/app-constants';
import { EpisodeCreationService } from 'src/app/shared/services/episode-creation.service';
import { saveAs } from 'file-saver';
import { DataFilterService } from '../../../shared/services/data-filter.service';
import { HighchartsLineChartModel } from 'src/app/shared/components/highcharts-linechart/highcharts-linechart.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-episode-creation',
  templateUrl: './episode-creation.component.html',
  styleUrls: ['./episode-creation.component.scss'],
})
export class EpisodeCreationComponent implements OnInit, DoCheck {
  readonly MAPPING_JOB_FAILED = MAPPING_JOB_FAILED;
  private postMappingDataForGrace: any;
  private postMappingData: any;
  setLineChartValue = false;
  setGraceValue = false;
  xAxisValueForLineChartFinal: any[] = [];
  yAxisValueForLineChart: any[] = [];
  public dosLineData: number[] = [];
  public graceLineData: number[] = [];
  public dosPercentile = [];
  graphData: any[] = [];
  dosDropDownData: boolean = false;
  lineChartConfigForGrace: HighchartsLineChartModel =
    new HighchartsLineChartModel();
  lineChartConfig: HighchartsLineChartModel = new HighchartsLineChartModel();
  studyId: any;
  breadcrumbData: any = null;
  enteredDosValue: any = null;
  enteredGraceValue: any = null;
  selectedOverrideMethod: any = 'mode';
  selectedGraceOverrideMethod: any = 'median';
  dosValuesChecked = false;
  graceValuesChecked = false;
  selectedDrugName: any = '';
  selectedGraceDrugName: any = '';
  epiodeCreationLoaderStatus = false;
  overrideMethodDosValuesSectionToggle = false;
  overrideMethodGraceValuesSectionToggle = false;
  isRequiredJobTriggeredStatus = false;
  informationPopupStatus = false;

  dosImputationConfirmationConfig = {
    header: 'Confirmation',
    message: 'Do you want downstream dependent parameters?',
    confirmationButtonName: 'Yes',
    cancelButtonName: 'No',
  };
  graceImputationConfirmationConfig = {
    header: 'Confirmation',
    message: 'Do you want downstream dependent parameters?',
    confirmationButtonName: 'Yes',
    cancelButtonName: 'No',
  };
  gridColumnElipsisCss = {
    overflow: 'hidden',
    'max-width': '25ch',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
  };
  dosGrid = {
    isLoaded: true,
    uploadStatus: false,
    columns: [
      {
        name: 'Product',
        key: 'drug',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'DOS Value',
        key: 'dosValue',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
        columnStyle: {
          overflow: 'hidden',
          'max-width': '25ch',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
        },
      },
      {
        name: 'Count',
        key: 'count',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '13%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
      {
        name: 'Mean',
        key: 'mean',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
      {
        name: 'Median',
        key: 'median',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
      {
        name: 'Mode',
        key: 'mode',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
      {
        name: '85th Percentile',
        key: 'percentile85th',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '800px', y: '55vh' },
    pageIndex: 1,
    sortingOrder: 'asc',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    tableErrorType: 'error' as 'error',
    tableErrorMessage:  '',
    searchToggleStatus: false,
  };

  dosGridGrace = {
    isLoaded: true,
    uploadStatus: false,
    columns: [
      {
        name: 'Product',
        key: 'drug',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: 'asc',
        sortDirections: ['ascend', 'descend'],
        width: '25%',
        align: 'left',
        showIcon: false,
        sortable: true,
        columnStyle: this.gridColumnElipsisCss,
      },
      {
        name: 'Grace Value',
        key: 'graceValue',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
        columnStyle: {
          overflow: 'hidden',
          'max-width': '25ch',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
        },
      },
      {
        name: 'Median',
        key: 'median',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
      {
        name: '75th percentile',
        key: 'percentile75th',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
      {
        name: '80th percentile',
        key: 'percentile80th',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },

      {
        name: '85th Percentile',
        key: 'percentile85th',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
      {
        name: '90th Percentile',
        key: 'percentile90th',
        dataType: 'string',
        prefix: '',
        suffix: '',
        defaultSortOrder: null,
        sortDirections: ['ascend', 'descend'],
        width: '10%',
        align: 'left',
        showIcon: false,
        sortable: false,
      },
    ],
    gridData: [],
    dataCount: 0,
    scrollDetails: { x: '800px', y: '55vh' },
    pageIndex: 1,
    sortingOrder: 'asc',
    sortingOrderKey: '',
    searchCriteria: '',
    searchText: '',
    searchStatus: false,
    tableErrorType: 'error' as 'error',
    tableErrorMessage:  'No data available',
    searchToggleStatus: false,
  };

  userInformation: any;

  eligibleForEpisodeCreation = false;

  drugList = [];

  graceDrugList = [];

  selectedValue: any;

  drugListLoadingStatus = true;

  drugListLoading = true;

  defaultPageSize = 10;
  dataFilterDisableStatus = false;
  dosImputationDisableStatus = false;
  graceImputationDisableStatus = false;
  graceImputationDisableStatusMessage = false;
  graceImputationDisableFailedStatus = false;
  dosImputationDisableFailedStatus = false;
  dosImputationDisableStatusMessage = false;
  separator = '>';
  disableApply = false;
  disableRefresh = false;

  // refreshLoadingStatus

  selectOverrideMethodDropdownData = [
    { label: 'Mean', value: 'mean' },
    { label: 'Mode', value: 'mode' },
    { label: 'Median', value: 'median' },
    { label: '85th Percentile', value: 'percentile85th' },
  ];

  selectGraceOverrideMethodDropdownData = [
    { label: 'Median', value: 'median' },
    { label: '75th percentile', value: 'percentile75th' },
    { label: '80th percentile', value: 'percentile80th' },
    { label: '85th percentile', value: 'percentile85th' },
    { label: '90th percentile', value: 'percentile90th' },
  ];

  //uploadfile

  public errorMessage = '';
  public successMessage = '';
  public fileMessage = {
    corruptedFile: 'File is corrupted and cannot be uploaded.',
    exceedSize: 'Maximum upload file size: 20MB.',
    csvExpected: 'Incorrect file type. CSV is expected',
    success: 'File uploaded successfully.',
    valueMissing:
      'The Grace_Values file does not contain all the required columns.',
  };
  showDataConfirmationFlag = false;
  confirmationDataConfig = {
    header: 'Unsaved Changes',
    message: 'Your study will not be saved.',
    confirmationButtonName: 'Ok',
    cancelButtonName: 'Cancel',
  };

  public fileProcess = false;

  claimTypeRadioValue = 'No';
  isRejectedChecked = false;
  isRejectedDisabled = true;
  isApprovedChecked = false;

  isApprovedDisabled = true;
  isReversedChecked = false;
  isReversedDisabled = true;
  showConfirmationFlag = false;
  showDosImputationConfirmationFlag = false;
  showGraceImputationConfirmationFlag = false;

  configDataObject = {
    claimTypeRadioValue: 'No',
    isRejectedChecked: false,
    isApprovedChecked: false,
    isReversedChecked: false,
    selectedDrugName: '',
    overrideMethodDosValuesSectionToggle: false,
    selectedOverrideMethod: 'mode',
    enteredDosValue: null,
    dosValuesChecked: false,
    selectedGraceDrugName: '',
    overrideMethodGraceValuesSectionToggle: false,
    selectedGraceOverrideMethod: 'median',
    enteredGraceValue: null,
    graceValuesChecked: false,
  };

  confirmationConfig: any;
  applyBtnStyle = {
    height: '40px',
    'margin-left': '0',
  };
  enableApplyForDataFilter = false;

  showExportPopup = false;

  exportBody: any;

  exportError = false;

  exportErrorMessage = '';
  toPreventExponent: any;
  toPreventDosExponent: any;
  chartLoaderStatus = false;
  dosChartLoaderStatus = false;
  showWarningPopupValue = true;
  appNavigate: Subscription;
  navigateUrl = '';
  showUnSavedConfirmationFlag = false;
  disableGraceApply = false;
  disableGraceRefresh = false;
  graceJobStatus: any;
  dosJobStatus: any;
  isUserRoleViewer = true;
  allBreadCrumbData: any = [];
  viewerBreadCrumbData: any = [];
  configData: any;
  hideDataFilterSection = false;
  includeClaimTypes: any;
  jobFailedStatus = false;

  dosDownloadBtnLoaderStatus = false;
  graceDownloadBtnLoaderStatus = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataFilterService: DataFilterService,
    private episodeCreationService: EpisodeCreationService,
    private notification: NzNotificationService,
    private commonService: CommonService
  ) {
    this.appNavigate = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigateUrl = event.url;
      }
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      this.studyId = params.get('studyId');
    });
    this.breadcrumbData = [
      { displayName: 'All Studies', navigationPath: '/home/' },
      {
        displayName: 'Study Details',
        navigationPath: `/studies/edit/${this.studyId}`,
      },
      {
        displayName: 'Dimension Mapping',
        navigationPath: `/studies/dimension-mapping/${this.studyId}`,
      },
      { displayName: 'Episode Creation', navigationPath: '' },
    ];
    this.fetchUserRole();
    this.fetchProgressStatus();
    this.fetchMappingData();
    // this.fetchDrugsList();
    this.checkJobStatus(true);
    // this.fetchDrugsListForGrace();
    this.fetchMappingDataForGrace();
    this.setBreadCrumbForViewer();
    this.fetchConfigData(true);
    // this.toRemoveGraceExponent();
    // this.toRemoveDosExponent();
  }

  ngDoCheck() {
    // for enabling apply button in data filter
    if (
      this.isApprovedChecked === true ||
      this.isRejectedChecked === true ||
      this.isReversedChecked === true ||
      this.claimTypeRadioValue === 'No'
    ) {
      this.enableApplyForDataFilter = true;
    } else {
      this.enableApplyForDataFilter = false;
    }
  }

  createNotification(type: string): void {
    this.commonService.createNotification(
      type,
      'This is the content of the notification'
    );
  }

  fetchUserRole() {
    this.epiodeCreationLoaderStatus = true;
    this.episodeCreationService.getUserRole(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.userInformation = response.data.role;
          this.isUserRoleViewer = response.data.role == 'viewer' ? true : false;
          this.epiodeCreationLoaderStatus = false;
        } else {
          this.epiodeCreationLoaderStatus = false;
          this.handleError(SOMETHING_WENT_WRONG, 'API');
        }
      },
      (error: any) => {
        this.epiodeCreationLoaderStatus = false;
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  fetchProgressStatus() {
    this.epiodeCreationLoaderStatus = true;
    this.episodeCreationService.getProgressStatus(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.epiodeCreationLoaderStatus = false;
          if (response.progress >= 20) {
            this.eligibleForEpisodeCreation = true;
          }
        } else {
          this.epiodeCreationLoaderStatus = false;

          this.handleError(SOMETHING_WENT_WRONG, 'API');
        }
      },
      (error: any) => {
        this.epiodeCreationLoaderStatus = false;
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  fetchMappingData(
    pageNumber = this.dosGrid.pageIndex,
    sorting = this.dosGrid.sortingOrder
  ) {
    let payload = {
      asc: sorting == 'desc' ? false : true,
      limit: this.defaultPageSize,
      offset: (pageNumber - 1) * 10,
    };
    this.dosGrid.isLoaded = false;
    if (this.postMappingData) {
      this.postMappingData.unsubscribe();
    }
    this.postMappingData = this.episodeCreationService
      .postMappingData(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.dosGrid.isLoaded = true;
            this.dosGrid.gridData = response.dosValues;
            this.dosGrid.dataCount = response.count;
            this.dosGrid.sortingOrder = sorting;
            this.dosGrid.pageIndex = pageNumber;
            this.dosGrid.gridData.forEach((record: any) => {
              var rec_count = String(record['count']);
              if (record['count'] > 9999999) {
                rec_count = String(+(record['count']/1000000).toFixed(1)) + ' M';
              } else if (record['count'] > 9999) {
                rec_count = String(+(record['count']/1000).toFixed(1)) + ' K';
              }
              record['count'] = rec_count;
            });
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'API');
          }
        },
        (error: any) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  fetchMappingDataForGrace(
    pageNumber = this.dosGridGrace.pageIndex,
    sorting = this.dosGridGrace.sortingOrder
  ) {
    let payload = {
      asc: sorting == 'desc' ? false : true,
      limit: this.defaultPageSize,
      offset: (pageNumber - 1) * 10,
    };
    this.dosGridGrace.isLoaded = false;
    if (this.postMappingDataForGrace) {
      this.postMappingDataForGrace.unsubscribe();
    }

    this.postMappingDataForGrace = this.episodeCreationService
      .postMappingDataForGrace(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.dosGridGrace.isLoaded = true;
            this.dosGridGrace.gridData = response.gapValues;
            this.dosGridGrace.dataCount = response.count;
            this.dosGridGrace.sortingOrder = sorting;
            this.dosGridGrace.pageIndex = pageNumber;
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'API');
          }
        },
        (error: any) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  checkJobStatus(isFirstTimeLoaded = false) {
    this.episodeCreationService.getJobStatus(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.isRequiredJobTriggeredStatus =
            response.data.length == 0 ? true : false;
          let findGracePeriodAnalysisStatus = response.data.find((obj: any) => {
            return obj.category == 'Grace Period Analysis';
          });
          let findDosImputationStatus = response.data.find((obj: any) => {
            return obj.category == 'DOS Imputation';
          });
          let findRegimenImputationStatus = response.data.find((obj: any) => {
            return obj.category == 'Regimen Distribution';
          });

          if (findGracePeriodAnalysisStatus.status != 'COMPLETED') {
            this.graceJobStatus = findGracePeriodAnalysisStatus.status;
            this.dataFilterDisableStatus = false;
            this.dosImputationDisableStatus = false;
            this.graceImputationDisableStatus = true;
            this.graceImputationDisableStatusMessage = true;
          }
          if (findDosImputationStatus.status != 'COMPLETED') {
            this.dosJobStatus = findDosImputationStatus.status;
            this.dataFilterDisableStatus = true;
            this.dosImputationDisableStatus = true;
            this.graceImputationDisableStatus = true;
            this.graceImputationDisableStatusMessage = true;
            this.dosImputationDisableStatusMessage = true;
          }
          if (findRegimenImputationStatus.status != 'COMPLETED') {
            this.graceImputationDisableStatus = true;
            this.graceImputationDisableStatusMessage = true;
          }
          if (findGracePeriodAnalysisStatus.status == 'FAILED') {
            this.dataFilterDisableStatus = false;
            this.dosImputationDisableStatus = false;
            this.graceImputationDisableStatus = true;
            this.graceImputationDisableStatusMessage = false;
            this.graceImputationDisableFailedStatus = true;
          }
          if (findDosImputationStatus.status == 'FAILED') {
            this.dataFilterDisableStatus = true;
            this.dosImputationDisableStatus = true;
            this.graceImputationDisableStatus = true;
            this.graceImputationDisableStatusMessage = false;
            this.dosImputationDisableStatusMessage = false;
            this.graceImputationDisableFailedStatus = true;
            this.dosImputationDisableFailedStatus = true;
          }
          if (findRegimenImputationStatus.status == 'FAILED') {
            this.graceImputationDisableStatus = true;
            this.graceImputationDisableStatusMessage = false;
            this.graceImputationDisableFailedStatus = true;
          }
          if (!isFirstTimeLoaded) {
            this.fetchConfigData();
          }
        } else {
          this.handleError(SOMETHING_WENT_WRONG, 'API');
        }
      },
      (error: any) => {
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  checkJobStatusOnNext() {
    this.epiodeCreationLoaderStatus = true;
    this.episodeCreationService.getJobStatus(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.epiodeCreationLoaderStatus = false;
          let findGracePeriodAnalysisStatus = response.data.find((obj: any) => {
            return obj.category == 'Grace Period Analysis';
          });
          let findDosImputationStatus = response.data.find((obj: any) => {
            return obj.category == 'DOS Imputation';
          });
          let findRegimenImputationStatus = response.data.find((obj: any) => {
            return obj.category == 'Regimen Distribution';
          });

          if (
            findGracePeriodAnalysisStatus.status != 'COMPLETED' ||
            findDosImputationStatus.status != 'COMPLETED' ||
            findRegimenImputationStatus.status != 'COMPLETED'
          ) {
            this.informationPopupStatus = true;
          } else {
            this.router.navigate(['/studies/regimen-lot/' + this.studyId]);
          }
        } else {
          this.epiodeCreationLoaderStatus = false;
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      },
      (error: any) => {
        this.epiodeCreationLoaderStatus = false;
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  fetchStudyConfig() {
    this.episodeCreationService.postStudyConfig(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
        } else {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      },
      (error: any) => {
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  fetchDrugsList() {
    this.drugListLoadingStatus = true;
    this.episodeCreationService.getDrugsList(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.drugList = response.drugs;
          if (this.configDataObject.selectedDrugName == '') {
            this.selectedDrugName = this.drugList[1];
            this.configDataObject.selectedDrugName = this.drugList[1];
          }
          this.dosDistributionGraphData();
          this.drugListLoadingStatus = false;
        } else {
          this.drugListLoadingStatus = false;
          this.handleError(SOMETHING_WENT_WRONG, 'API');
        }
      },
      (error: any) => {
        this.drugListLoadingStatus = false;
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  fetchDrugsListForGrace() {
    this.drugListLoading = true;
    this.episodeCreationService.getDrugsListForGrace(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.graceDrugList = response.drugs;
          if (this.configDataObject.selectedGraceDrugName == '') {
            this.selectedGraceDrugName = this.graceDrugList[1];
            this.configDataObject.selectedGraceDrugName = this.graceDrugList[1];
          }
          this.gracePeriodAnalysisGraph();
          this.drugListLoading = false;
        } else {
          this.drugListLoading = false;
          this.handleError(SOMETHING_WENT_WRONG, 'API');
        }
      },
      (error: any) => {
        this.drugListLoading = false;
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  refreshGridData(isJobTriggerRequired = false) {
    let payload = {
      drugName: this.selectedDrugName,
      selection: !this.overrideMethodDosValuesSectionToggle
        ? this.selectedOverrideMethod
        : null,
      dosValue: this.overrideMethodDosValuesSectionToggle
        ? this.enteredDosValue
        : null,
      dosImpute95thPercentile: this.dosValuesChecked,
    };
    this.epiodeCreationLoaderStatus = true;

    this.episodeCreationService
      .putRefreshGridList(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.epiodeCreationLoaderStatus = false;
            this.fetchMappingData();
            if (isJobTriggerRequired) {
              this.showDosImputationConfirmationFlag = true;
            }
          } else {
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
          }
        },
        (error: any) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  refreshGraceGridData(isGraceJobTriggerRequired = false) {
    let payload = {
      drugName: this.selectedGraceDrugName,
      selection: !this.overrideMethodGraceValuesSectionToggle
        ? this.selectedGraceOverrideMethod
        : null,
      graceValue: this.overrideMethodGraceValuesSectionToggle
        ? this.enteredGraceValue
        : null,
    };
    this.epiodeCreationLoaderStatus = true;

    this.episodeCreationService
      .putRefreshGraceGridList(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.epiodeCreationLoaderStatus = false;
            this.fetchMappingDataForGrace();
            if (isGraceJobTriggerRequired) {
              this.showGraceImputationConfirmationFlag = true;
            }
          } else {
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
          }
        },
        (error: any) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  triggerGracePeriodAnalysisJob(confirmationStatus: any) {
    let payload = {
      run_emr: true,
      run_regimen_distribution_job: confirmationStatus,
      impute_95th_percentile: this.dosValuesChecked,
    };
    this.epiodeCreationLoaderStatus = true;
    this.episodeCreationService
      .postTriggerGracePeriodAnalysisJob(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.epiodeCreationLoaderStatus = false;
            this.fetchMappingData();
            this.checkJobStatus();
          } else {
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
          }
        },
        (error: any) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  triggerJob(graceConfirmationStatus: any) {
    let payload = {
      run_emr: true,
      run_regimen_line_of_therapy_job: graceConfirmationStatus,
    };
    this.epiodeCreationLoaderStatus = true;

    this.episodeCreationService.postTriggerJob(this.studyId, payload).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.epiodeCreationLoaderStatus = false;
          this.fetchMappingDataForGrace();
          this.checkJobStatus();
        } else {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      },
      (error: any) => {
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  base64ToBlob(b64Data: any, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadGridData() {
    this.dosDownloadBtnLoaderStatus = true;
    let payload = {};
    this.episodeCreationService.getDownloadGridData(this.studyId).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          const blob = this.base64ToBlob(response['data'], 'text/csv');
          saveAs(blob, `dos_values-Export.csv`);
          this.dosDownloadBtnLoaderStatus = false;
        } else {
          this.dosDownloadBtnLoaderStatus = false;
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      },
      (error: any) => {
        this.dosDownloadBtnLoaderStatus = false;
        this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
      }
    );
  }

  fetchConfigData(isFirstTimeLoaded = false) {
    this.epiodeCreationLoaderStatus = true;
    let payload = {
      tables: ['claim_detail'],
      isptj: true,
    };
    this.episodeCreationService
      .fetchConfigDetails(this.studyId, payload)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            this.configData = response;
            this.hideDataFilterSection = !this.configData.claimDetail
              ? false
              : true;
            this.includeClaimTypes = this.configData.includeClaimTypes;
            this.configDataObject = {
              claimTypeRadioValue: response.includeClaimTypes ? 'Yes' : 'No',
              isRejectedChecked:
                response.includeClaimTypes &&
                JSON.parse(response.includeClaimTypes).includes(0)
                  ? true
                  : false,
              isApprovedChecked:
                response.includeClaimTypes &&
                JSON.parse(response.includeClaimTypes).includes(1)
                  ? true
                  : false,
              isReversedChecked:
                response.includeClaimTypes &&
                JSON.parse(response.includeClaimTypes).includes(2)
                  ? true
                  : false,
              selectedDrugName: response.dosDrug
                ? response.dosDrug
                : isFirstTimeLoaded
                ? ''
                : this.drugList[1],

              overrideMethodDosValuesSectionToggle: response.dosDays
                ? true
                : false,
              selectedOverrideMethod: response.dosOverrideMethod
                ? response.dosOverrideMethod
                : 'mode',
              enteredDosValue: response.dosDays ? response.dosDays : null,
              dosValuesChecked: response.dosImpute95thPercentile,
              selectedGraceDrugName: response.graceDrug
                ? response.graceDrug
                : '',
              overrideMethodGraceValuesSectionToggle: response.graceDays
                ? true
                : false,
              selectedGraceOverrideMethod: response.graceOverrideMethod
                ? response.graceOverrideMethod
                : 'median',
              enteredGraceValue: response.graceDays ? response.graceDays : null,
              graceValuesChecked: false,
            };
            this.claimTypeRadioValue = response.includeClaimTypes
              ? 'Yes'
              : 'No';
            this.isRejectedChecked =
              response.includeClaimTypes &&
              JSON.parse(response.includeClaimTypes).includes(0)
                ? true
                : false;
            this.isApprovedChecked =
              response.includeClaimTypes &&
              JSON.parse(response.includeClaimTypes).includes(1)
                ? true
                : false;
            this.isReversedChecked =
              response.includeClaimTypes &&
              JSON.parse(response.includeClaimTypes).includes(2)
                ? true
                : false;

            this.selectedDrugName = response.dosDrug
              ? response.dosDrug
              : isFirstTimeLoaded
              ? ''
              : this.drugList[1];
            this.overrideMethodDosValuesSectionToggle = response.dosDays
              ? true
              : false;
            this.selectedOverrideMethod = response.dosOverrideMethod
              ? response.dosOverrideMethod
              : 'mode';
            this.enteredDosValue = response.dosDays ? response.dosDays : null;
            this.dosValuesChecked = response.dosImpute95thPercentile;
            this.selectedGraceDrugName = response.graceDrug
              ? response.graceDrug
              : '';
            this.overrideMethodGraceValuesSectionToggle = response.graceDays
              ? true
              : false;
            this.selectedGraceOverrideMethod = response.graceOverrideMethod
              ? response.graceOverrideMethod
              : 'median';
            this.enteredGraceValue = response.graceDays
              ? response.graceDays
              : null;
            this.graceValuesChecked = false;
            this.epiodeCreationLoaderStatus = false;
            if (isFirstTimeLoaded) {
              this.fetchDrugsList();
              this.fetchDrugsListForGrace();
            }
          } else {
            this.handleError(SOMETHING_WENT_WRONG, 'API');
          }
        },
        (error: any) => {
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  downloadGraceGridData() {
    this.graceDownloadBtnLoaderStatus = true;
    let payload = {};
    this.episodeCreationService
      .getDownloadGraceGridData(this.studyId)
      .subscribe(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            const blob = this.base64ToBlob(response['data'], 'text/csv');
            saveAs(blob, `gap_values-Export.csv`);
            this.graceDownloadBtnLoaderStatus = false;
          } else {
            this.graceDownloadBtnLoaderStatus = false;
            this.commonService.createNotification(
              'error',
              SOMETHING_WENT_WRONG
            );
          }
        },
        (error: any) => {
          this.graceDownloadBtnLoaderStatus = false;
          this.commonService.createNotification('error', SOMETHING_WENT_WRONG);
        }
      );
  }

  uploadFile(event: any) {
    this.dosGrid.uploadStatus = true;
    this.epiodeCreationLoaderStatus = true;

    this.fileProcess = true;
    this.errorMessage = '';
    this.successMessage = '';
    event.cancelBubble = true;
    try {
      if (!this.validateFile(event)) {
        this.dosGrid.uploadStatus = false;
        this.epiodeCreationLoaderStatus = false;
        return;
      }

      const _file = event.target.files[0];
      const splittedFileName = _file.name.split('.');
      if (splittedFileName[splittedFileName.length - 1] === 'csv') {
        this.changeFile(_file).then((base64: any): any => {
          const base64File = base64;
          const payload = {
            data: base64File.split(',')[1],
          };
          this.episodeCreationService
            .postUploadGridData(this.studyId, payload)
            .subscribe({
              next: (res: any) => {
                if (res) {
                  this.successMessage = this.fileMessage.success;
                  this.fileProcess = false;
                  this.dosGrid.uploadStatus = false;
                  this.epiodeCreationLoaderStatus = false;
                  this.selectedOverrideMethod = 'bulk_upload';
                  this.enteredDosValue = null;
                  this.overrideMethodDosValuesSectionToggle = false;
                  if (res.isParameterSaved) {
                    this.fetchMappingData(1, 'asc');
                    this.commonService.createNotification(
                      'success',
                      res.message
                    );
                  } else {
                    this.commonService.createNotification('error', res.message);
                  }
                }
              },
              error: (error: any) => {
                this.fileProcess = false;
                this.errorMessage = error.error.message;
                this.commonService.createNotification(
                  'error',
                  this.errorMessage
                );
              },
            });
        });
      } else {
        this.fileProcess = false;
        this.errorMessage = this.fileMessage.csvExpected;
        this.epiodeCreationLoaderStatus = false;
        this.commonService.createNotification('error', this.errorMessage);
      }
    } catch (ex) {
      this.errorMessage = this.fileMessage.corruptedFile;
      this.epiodeCreationLoaderStatus = false;
      this.commonService.createNotification('error', this.errorMessage);
    }
  }

  validateFile(uploadEvent: any) {
    if (
      uploadEvent.target.files[0].size > MAXIMUM_CSV_FILE_SIZE ||
      uploadEvent.target.files[0].size === 0
    ) {
      this.errorMessage =
        uploadEvent.target.files[0].size === 0
          ? this.fileMessage.corruptedFile
          : this.fileMessage.exceedSize;
      this.commonService.createNotification('error', this.errorMessage);
      uploadEvent.target.value = '';
      this.fileProcess = false;
      return false;
    }
    return true;
  }

  changeFile(_file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(_file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  resetFile($event: any) {
    $event.target.value = null;
  }

  uploadGraceFile(event: any) {
    this.dosGridGrace.uploadStatus = true;
    this.epiodeCreationLoaderStatus = true;

    this.fileProcess = true;
    this.errorMessage = '';
    this.successMessage = '';
    event.cancelBubble = true;
    try {
      if (!this.validateFile(event)) {
        this.dosGridGrace.uploadStatus = false;
        return;
      }

      const _file = event.target.files[0];
      const splittedFileName = _file.name.split('.');
      if (splittedFileName[splittedFileName.length - 1] === 'csv') {
        this.changeFile(_file).then((base64: any): any => {
          const base64File = base64;
          const payload = {
            data: base64File.split(',')[1],
          };
          this.episodeCreationService
            .postUploadGraceGridData(this.studyId, payload)
            .subscribe({
              next: (res: any) => {
                if (res) {
                  this.successMessage = this.fileMessage.success;
                  this.fileProcess = false;
                  this.dosGridGrace.uploadStatus = false;
                  this.epiodeCreationLoaderStatus = false;
                  if (res.isParameterSaved) {
                    this.fetchMappingDataForGrace(1, 'asc');
                    this.commonService.createNotification(
                      'success',
                      res.message
                    );
                  } else {
                    this.commonService.createNotification('error', res.message);
                  }
                }
              },
              error: (error: any) => {
                this.fileProcess = false;
                this.errorMessage = error.error.message;
                this.commonService.createNotification(
                  'error',
                  this.errorMessage
                );
              },
            });
        });
      } else {
        this.fileProcess = false;
        this.errorMessage = this.fileMessage.csvExpected;
        this.epiodeCreationLoaderStatus = false;
        this.commonService.createNotification('error', this.errorMessage);
      }
    } catch (ex) {
      this.errorMessage = this.fileMessage.corruptedFile;
      this.epiodeCreationLoaderStatus = false;
      this.commonService.createNotification('error', this.errorMessage);
    }
  }

  handleError(errorMessage: string, api: string): void {
    switch (api) {
      case 'postMappingData':
        this.dosGrid.isLoaded = true;
        this.dosGrid.tableErrorMessage = errorMessage;
        break;

      case 'exportError':
        this.exportError = true;
        this.exportErrorMessage = errorMessage;
        break;

      case 'postMappingDataForGrace':
        this.dosGridGrace.isLoaded = true;
        this.dosGridGrace.tableErrorMessage = errorMessage;
        break;
    }
  }

  claimTypeChanged($event: any) {
    if ($event.toString() === 'No') {
      this.isReversedDisabled = true;
      this.isApprovedDisabled = true;
      this.isRejectedDisabled = true;
      this.isReversedChecked = false;
      this.isRejectedChecked = false;
      this.isApprovedChecked = false;
    } else {
      this.isReversedDisabled = false;
      this.isApprovedDisabled = false;
      this.isRejectedDisabled = false;
    }
  }

  callDataFilterService(confirmationStatus: any) {
    let payload;
    if (this.claimTypeRadioValue === 'No') {
      payload = {
        include_claim_types: null,
        run_emr: true,
        run_grace_period_analysis_job: confirmationStatus,
      };
    } else {
      let includeClaimType = [];
      if (this.isRejectedChecked === true) {
        includeClaimType.push(0);
      }
      if (this.isApprovedChecked === true) {
        includeClaimType.push(1);
      }
      if (this.isReversedChecked === true) {
        includeClaimType.push(2);
      }

      payload = {
        include_claim_types: includeClaimType,
        run_emr: true,
        run_grace_period_analysis_job: confirmationStatus,
      };
    }
    this.dataFilterService
      .callDataFilterAPI(this.studyId, payload)
      .subscribe((data) => {
        this.checkJobStatus();
      });
  }

  onApplyClick() {
    this.confirmationConfig = {
      header: 'Confirmation',
      message: 'Do you want downstream dependent parameters?',
      confirmationButtonName: 'Yes',
      cancelButtonName: 'No',
    };
    this.showConfirmationFlag = true;
  }

  closeConfirmationPopup(event: any) {
    if (event === true) {
      this.callDataFilterService(true);
      this.showConfirmationFlag = false;
    } else {
      this.callDataFilterService(false);
      this.showConfirmationFlag = false;
    }
  }

  closeDosImputationConfirmationPopup(event: any) {
    if (event === true) {
      this.showDosImputationConfirmationFlag = false;
      this.triggerGracePeriodAnalysisJob(true);
    } else {
      this.showDosImputationConfirmationFlag = false;
      this.triggerGracePeriodAnalysisJob(false);
    }
  }

  closeRegimenDistributionConfirmationPopup(event: any) {
    if (event === true) {
      this.triggerJob(true);
      this.showGraceImputationConfirmationFlag = false;
    } else {
      this.triggerJob(false);
      this.showGraceImputationConfirmationFlag = false;
    }
  }

  exportButtonClick() {
    this.epiodeCreationLoaderStatus = true;
    this.episodeCreationService.getExportData(this.studyId).subscribe(
      (data) => {
        if (data.status === API_SUCCESS_KEY) {
          data.data.map((item: any) => {
            item.filename = item.filename + ' :';
            return item;
          });
          this.exportBody = data;
          this.showExportPopup = true;
        }
        this.epiodeCreationLoaderStatus = false;
      },
      (error: any) => {
        this.epiodeCreationLoaderStatus = false;
        this.commonService.createNotification('error', this.errorMessage);
      }
    );
  }

  closeExportPopup($event: any) {
    this.showExportPopup = false;
  }

  dosDistributionGraphData() {
    this.setLineChartValue = false;
    this.dosChartLoaderStatus = true;
    this.disableApply = this.disableRefresh =
      this.selectedDrugName?.length > 0 &&
      ((this.overrideMethodDosValuesSectionToggle &&
        this.enteredDosValue >= 0) ||
        (!this.overrideMethodDosValuesSectionToggle &&
          this.selectedOverrideMethod?.length > 0))
        ? false
        : true;
    this.episodeCreationService
      .getDosChartsDetails(this.studyId, this.selectedDrugName)
      .subscribe(
        (response: any) => {
          this.dosLineData = response['linedata'] || [];
          this.dosPercentile = response['percentile'] || [];
          this.dosLineData = this.dosLineData.map((i) => Number(i));
          this.dosChartLoaderStatus = false;
          if (this.selectedDrugName != 'ALL') {
            this.setLineChart();
          }
        },
        (error: any) => {
          this.commonService.createNotification('error', this.errorMessage);
        }
      );
  }

  setLineChart(): void {
    this.setLineChartValue = false;
    if (this.selectedDrugName == null) {
      this.lineChartConfig.graphTitle = ' ';
      this.lineChartConfig.yAxisTitle = ' ';
      this.lineChartConfig.xAxisTitle = ' ';
      this.lineChartConfig.data = [];
    } else {
      this.lineChartConfig.graphTitle =
        'DoS distribution for ' + this.selectedDrugName;
      this.lineChartConfig.yAxisTitle = '%Claims';
      this.lineChartConfig.xAxisTitle = 'Days of Supply';
      this.lineChartConfig.data = [
        {
          data: this.dosLineData,
        },
      ];
    }
    this.lineChartConfig.graphId = 'dosChart';
    this.lineChartConfig.height = 450;
    this.lineChartConfig.xAxisTooltipLabel = 'days of supply: ';
    this.lineChartConfig.yAxisTooltipLabel = 'No. of claims: ';
    this.lineChartConfig.showTooltip = true;
    this.lineChartConfig.showLegends = false;
    this.lineChartConfig.percentageFormatter = true;
    this.lineChartConfig.tickPositions = [0, 30, 60, 90, 120, 150, 180, 200];

    this.lineChartConfig.plotLines = [
      {
        color: '#ecd229',
        width: 2,
        value: this.dosPercentile[0],
      },
      {
        color: '#8fbf6f',
        width: 2,
        value: this.dosPercentile[1],
      },
      {
        color: '#fd7e14',
        width: 2,
        value: this.dosPercentile[2],
      },
    ];

    this.setLineChartValue = true;
  }

  gracePeriodAnalysisGraph() {
    this.setGraceValue = false;
    this.chartLoaderStatus = true;
    this.disableGraceApply = this.disableGraceRefresh =
      this.selectedGraceDrugName?.length > 0 &&
      ((this.overrideMethodGraceValuesSectionToggle &&
        this.enteredGraceValue >= 0) ||
        (!this.overrideMethodGraceValuesSectionToggle &&
          this.selectedGraceOverrideMethod?.length > 0))
        ? false
        : true;
    this.episodeCreationService
      .getGraceChartsDetails(this.studyId, this.selectedGraceDrugName)
      .subscribe(
        (response: any) => {
          this.graceLineData = response['linedata'] || [];
          this.graceLineData = this.graceLineData.map((i) => Number(i));
          if (this.selectedGraceDrugName != 'ALL') {
            this.setLineChartForGrace();
          }

          this.chartLoaderStatus = false;
        },
        (error: any) => {
          this.handleError(SOMETHING_WENT_WRONG, 'getGraceChartsDetails');
        }
      );
  }
  setLineChartForGrace(): void {
    this.setGraceValue = false;
    if (this.selectedGraceDrugName == null) {
      this.lineChartConfigForGrace.graphTitle = ' ';
      this.lineChartConfigForGrace.yAxisTitle = ' ';
      this.lineChartConfigForGrace.xAxisTitle = ' ';
      this.lineChartConfigForGrace.graphId = ' ';
      this.lineChartConfigForGrace.height = ' ';
      this.lineChartConfigForGrace.xAxisTooltipLabel = ' ';
      this.lineChartConfigForGrace.yAxisTooltipLabel = ' ';
      this.lineChartConfigForGrace.showTooltip = false;
      this.lineChartConfigForGrace.showLegends = false;
      this.lineChartConfigForGrace.percentageFormatter = false;
      this.lineChartConfigForGrace.tickPositions = [];
      this.lineChartConfigForGrace.data = [];
    } else {
      this.lineChartConfigForGrace.graphTitle =
        'Cumulative gap distribution for ' + this.selectedGraceDrugName;
      this.lineChartConfigForGrace.yAxisTitle = 'Cumulative % Gaps';
      this.lineChartConfigForGrace.xAxisTitle = 'Gap (In Days)';
      this.lineChartConfigForGrace.graphId = 'gpaChart';
      this.lineChartConfigForGrace.height = 500;
      this.lineChartConfigForGrace.xAxisTooltipLabel = 'In Days: ';
      this.lineChartConfigForGrace.yAxisTooltipLabel = '%gaps: ';
      this.lineChartConfigForGrace.showTooltip = true;
      this.lineChartConfigForGrace.showLegends = false;
      this.lineChartConfigForGrace.percentageFormatter = true;
      this.lineChartConfigForGrace.tickPositions = [
        0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390,
      ];
      this.lineChartConfigForGrace.data = [
        {
          data: this.graceLineData,
        },
      ];
    }
    this.setGraceValue = true;
  }

  navigateWrtUrl(routerLink: any, studyIdRequired: any = false) {
    if (studyIdRequired) {
      this.router.navigate([routerLink + this.studyId]);
    } else {
      this.router.navigate([routerLink]);
    }
  }

  dismissWarningPopup() {
    this.showDataConfirmationFlag = true;
    this.showWarningPopupValue = false;
    return false;
  }
  closeNavigateConfirmationPopup(event: any) {
    if (event == true) {
      this.router.navigate([this.navigateUrl]);
    } else if (event == undefined) {
      this.showWarningPopupValue = true;
      this.showDataConfirmationFlag = false;
    } else {
      this.showWarningPopupValue = true;
      this.showDataConfirmationFlag = false;
    }
  }

  showNavigationStatus() {
    if (
      this.configDataObject.claimTypeRadioValue != this.claimTypeRadioValue ||
      this.configDataObject.isRejectedChecked != this.isRejectedChecked ||
      this.configDataObject.isApprovedChecked != this.isApprovedChecked ||
      this.configDataObject.isReversedChecked != this.isReversedChecked ||
      this.configDataObject.selectedDrugName != this.selectedDrugName ||
      this.configDataObject.overrideMethodDosValuesSectionToggle !=
        this.overrideMethodDosValuesSectionToggle ||
      this.configDataObject.selectedOverrideMethod !=
        this.selectedOverrideMethod ||
      this.configDataObject.enteredDosValue != this.enteredDosValue ||
      this.configDataObject.dosValuesChecked != this.dosValuesChecked ||
      this.configDataObject.selectedGraceDrugName !=
        this.selectedGraceDrugName ||
      this.configDataObject.overrideMethodGraceValuesSectionToggle !=
        this.overrideMethodGraceValuesSectionToggle ||
      this.configDataObject.selectedGraceOverrideMethod !=
        this.selectedGraceOverrideMethod ||
      this.configDataObject.enteredGraceValue != this.enteredGraceValue ||
      this.configDataObject.graceValuesChecked != this.graceValuesChecked
    ) {
      return this.showWarningPopupValue;
    } else {
      return false;
    }
  }

  onClickOfExit() {
    this.router.navigate(['/home/' + this.studyId]);
  }
  navigateBack() {
    this.router.navigate(['/studies/dimension-mapping/' + this.studyId]);
  }

  toRemoveGraceExponent() {
    var invalidChars = ['-', '+', 'e', 'E', '.'];
    this.toPreventExponent = document.getElementById('toPreventExponent');
    this.toPreventExponent.addEventListener('keydown', function (e: any) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  }
  toRemoveDosExponent() {
    var invalidChars = ['-', '+', 'e', 'E', '.'];
    this.toPreventDosExponent = document.getElementById('toPreventDosExponent');
    this.toPreventDosExponent.addEventListener('keydown', function (e: any) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  }
  allowNumeric(event: any) {
    if (event.type == 'paste') {
      let clipboardData = event.clipboardData.getData('text');
      return !/^[0-9]+$/.test(clipboardData) ? false : true;
    } else {
      return !/^[0-9 ]+$/.test(event.key) ? false : true;
    }
  }

  setBreadCrumbForViewer() {
    let studyProgress;
    let index = 5;

    this.allBreadCrumbData = [
      {
        displayName: 'All Studies',
        navigationPath: '/home/',
        key: 'alStudies',
      },
      {
        displayName: 'Study Details',
        navigationPath: `/studies/edit/${this.studyId}`,
        key: 'studyDetails',
      },
      {
        displayName: 'Dimension Mapping',
        navigationPath: `/studies/dimension-mapping/${this.studyId}`,
        key: 'dimensionMapping',
      },
      {
        displayName: 'Episode Creation',
        navigationPath: ``,
        key: 'episodeCreation',
      },
      {
        displayName: 'Regimen & LoT',
        navigationPath: `/studies/regimen-lot/${this.studyId}`,
        key: 'regimenLot',
      },
      {
        displayName: 'Select Insights',
        navigationPath: `/studies/select-insights/${this.studyId}`,
        key: 'selectInsights',
      },
    ];
    this.epiodeCreationLoaderStatus = true;
    this.commonService.getStudyProgress(this.studyId).subscribe((response) => {
      if (response.status === API_SUCCESS_KEY) {
        this.epiodeCreationLoaderStatus = false;
        studyProgress = response.progress;

        if (studyProgress < 20) {
          index = 1;
        } else if (studyProgress === 20) {
          index = 2;
        } else if (studyProgress > 20 && studyProgress < 60) {
          index = 5;
        } else if (studyProgress >= 60) {
          index = 5;
        }

        this.allBreadCrumbData.forEach((item: any, counter: any) => {
          if (counter <= index) {
            this.viewerBreadCrumbData.push(item);
          }
        });
      }
    });
  }
}
