import { Component, OnInit } from '@angular/core';
import {
  API_SUCCESS_KEY,
  APP_STATE_KEYS,
  NO_RECORDS,
  NO_SEARCH_RESULT,
  PROJECT_NOT_SELECTED,
  SOMETHING_WENT_WRONG,
} from '../../shared/constants/app-constants';
import { ExecutionLogsService } from '../../shared/services/execution-logs.service';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { ActionTriggerService } from '../../shared/services/action-trigger.service';
import { AppStateService } from '../../shared/services/app-state.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'src/app/shared/services/cookie.service';

@Component({
  selector: 'app-execution-log',
  templateUrl: './execution-log.component.html',
  styleUrls: ['./execution-log.component.scss'],
})
export class ExecutionLogComponent implements OnInit {
  constructor(
    private executionLogsService: ExecutionLogsService,
    private actionTriggerService: ActionTriggerService,
    private cookieService: CookieService,
    private router: Router,
    private appState: AppStateService
  ) {}

  isExecutionLogsLoaded = false;

  errorFlyoutInfo: any;

  executionLogsColumns = [
    {
      name: 'Job ID',
      key: 'jobId',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '35%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Study Title',
      key: 'studyTitle',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'left',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Cluster ID',
      key: 'clusterId',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      width: '20%',
      align: 'left',
      showIcon: false,
      sortable: false,
    },
    {
      name: 'Created On',
      key: 'startedOn',
      dataType: 'date',
      dateFormat: 'MMM d y | h:mm:ss a',
      prefix: '',
      suffix: '',
      defaultSortOrder: 'desc',
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'center',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Completed On',
      key: 'completedOn',
      dataType: 'date',
      dateFormat: 'MMM d y | h:mm:ss a',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '25%',
      align: 'center',
      showIcon: false,
      sortable: true,
    },
    {
      name: 'Analyst Name',
      key: 'createdByArray',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '15%',
      align: 'center',
      showIcon: false,
      sortable: false,
      avatarStyle: {
        color: '#ffffff',
        background: '#27a6a4',
        cursor: 'default',
      },
      type: 'avatar',
    },
    {
      name: 'Status',
      key: 'status',
      dataType: 'string',
      prefix: '',
      suffix: '',
      defaultSortOrder: null,
      sortDirections: ['ascend', 'descend'],
      width: '20%',
      align: 'left',
      showIcon: false,
      sortable: true,
      linkColumn: true,
      linkColumnAssociatedKey: ['studyId'],
    },
  ];

  visible = false;

  errorLogResponse: any;

  downloadContent: any;

  errorLogLoaded = false;

  executionLogsData: Array<any> = [];

  executionLogsScrollDetails = { x: '1250px', y: '40vh' };

  defaultPageSize = 10;

  selectedProjectId = 1;

  totalNoOfExecutionLogs = this.defaultPageSize;

  pageIndex = 1;

  limit = this.defaultPageSize;

  orderBy = 'createdOn';

  orderType = 'desc';

  tableErrorType: any;

  tableErrorMessage: any;

  searchText = '';

  filtersApplied = false;

  // Drawer methods start
  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  onCloseButtonClick() {
    this.visible = false;
  }

  // Drawer method end

  onExecutionLogsPaginationChange(pageClicked: any) {
    this.pageIndex = pageClicked;
    this.fetchExecutionLogs();
  }

  onExecutionLogsTableSort(columnClicked: any) {
    this.orderType = columnClicked.defaultSortOrder;
    this.orderBy = columnClicked.key;
    this.pageIndex = 1;
    this.fetchExecutionLogs();
  }

  ngOnInit(): void {
    this.selectedProjectId = this.cookieService.get('project_id')
    this.fetchExecutionLogs();
    this.executionLogsScrollDetails.y =
      JSON.stringify(Math.round(window.innerHeight) - 325) + 'px';
  }

  projectIdMatchesCookies(){
    return this.selectedProjectId == this.cookieService.get('project_id')
  }

  fetchExecutionLogs(): void {
    this.isExecutionLogsLoaded = false;
    if(!this.projectIdMatchesCookies()){
      window.location.reload();
    }
    const payload = {
      limit: this.limit,
      offset: this.pageIndex * this.defaultPageSize - this.defaultPageSize,
      orderBy: this.orderBy,
      orderType: this.orderType,
      searchText: this.searchText,
    };
    this.executionLogsService.getLogsDetails(payload).subscribe(
      (response: any) => {
        if (response.status === API_SUCCESS_KEY) {
          this.totalNoOfExecutionLogs = response.noOfExecutionLogs;
          if (response.noOfExecutionLogs > 0) {
            this.executionLogsData = this.formatExecutionLogsData(
              response.response
            );
            this.isExecutionLogsLoaded = true;
          } else {
            this.tableErrorType = 'info';
            this.executionLogsData = [];

            if (this.filtersApplied) {
              this.handleError(NO_SEARCH_RESULT, 'executionLogs');
            } else {
              this.handleError(NO_RECORDS, 'executionLogs');
            }
            this.isExecutionLogsLoaded = true;
          }
        } else {
          this.tableErrorType = 'error';
          this.handleError(SOMETHING_WENT_WRONG, 'executionLogs');
        }
      },
      (error: any) => {
        // eslint-disable-next-line no-console
        //if the error is 428, that means project is not selected
        if (error.status == 428) {
          //Display error that project is not selected
          this.handleError(PROJECT_NOT_SELECTED, 'kpiData');
          // Redirect to PAC HOME
          window.location.replace(environment.pacHomeURL);
        } else {
          console.log(error);
          this.tableErrorType = 'error';
          this.handleError(SOMETHING_WENT_WRONG, 'executionLogs');
        }
      }
    );
  }

  formatExecutionLogsData(data: any): any {
    data.forEach((item: any) => {
      if (item.status.toLowerCase() == 'failed') {
        item['status'] = 'ERROR';
      }
      switch (item.status.toLowerCase()) {
        case 'completed':
          item['status_style'] = { color: 'green' };
          item['studyTitle_style'] = {
            'pointer-events': 'none',
            cursor: 'none!important',
            'text-decoration': 'none',
            color: 'black',
          };
          break;
        case 'error':
          item['status_style'] = { color: 'red' };
          break;
        case 'running':
          item['status_style'] = { color: '#ec7200' };
          item['studyTitle_style'] = {
            'pointer-events': 'none',
            cursor: 'none!important',
            'text-decoration': 'none',
            color: 'black',
          };
          break;
        case 'cancelled':
          item['status_style'] = { color: 'red' };
          item['studyTitle_style'] = {
            'pointer-events': 'none',
            cursor: 'none!important',
            'text-decoration': 'none',
            color: 'black',
          };
          break;
        case 'pending':
          item['status_style'] = { color: '#48557f' };
          item['studyTitle_style'] = {
            'pointer-events': 'none',
            cursor: 'none!important',
            'text-decoration': 'none',
            color: 'black',
          };
          break;
      }
      item['status'] = this.toTitleCase(item['status']);
      if (item.completedOn !== null && item.completedOn !== undefined) {
        item.completedOn = new Date(item.completedOn * 1000);
      } else {
        item.completedOn = '-';
      }
      if (item.startedOn !== null && item.startedOn !== undefined) {
        item.startedOn = new Date(item.startedOn * 1000);
      } else {
        item.startedOn = '-';
      }
      let initials;

      if (item.email?.includes('@zs.com')) {
        initials = item.username?.substring(3, 5).toUpperCase();
      } else {
        initials = item.email?.substring(0, 2).toUpperCase();
      }

      item.createdByArray = [{ text: initials, tooltip: item.email }];
    });
    return data;
  }

  handleError(errorMessage: string, api: string): void {
    switch (api) {
      case 'executionLogs':
        this.tableErrorMessage = errorMessage;
        this.isExecutionLogsLoaded = true;
        break;
    }
  }

  navigateToStudyDetail(jobId: any) {
    const studyId = jobId.split('-')[2];

    this.actionTriggerService
      .getStudyDetails(studyId)
      .subscribe((data: any) => {
        this.appState.setGlobalState(APP_STATE_KEYS.STUDY_DETAILS, data.data);
        this.appState.setGlobalState(APP_STATE_KEYS.ACTION_TYPE, 'edit');
        this.router.navigate([`/studies/edit/${studyId}`]);
      });
  }

  searchLogs(): void {
    if (this.searchText.length > 2 || this.searchText.length === 0) {
      this.filtersApplied = true;
      this.fetchExecutionLogs();
    }
  }

  executionLogsClicked($event: any): void {
    const executionLogDetails = {
      status: $event.columnData.status,
      stepId: $event.columnData.stepId,
      jobId: $event.columnData.jobId,
      studyTitle: $event.columnData.studyTitle,
      clusterId: $event.columnData.clusterId,
      analystEmail: $event.columnData.email,
    };
    this.errorFlyoutInfo = executionLogDetails;
    if (executionLogDetails.status.toLowerCase() === 'error') {
      this.callErrorDetails();
      this.open();
    }
  }

  onRefreshClick() {
    this.fetchExecutionLogs();
  }

  toTitleCase(str: string): string {
    return str
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  callErrorDetails() {
    this.errorLogResponse = '';
    this.errorLogLoaded = false;
    const payload = {
      clusterId: this.errorFlyoutInfo.clusterId,
      stepId: this.errorFlyoutInfo.stepId,
    };
    this.executionLogsService
      .getErrorDetails(payload)
      .subscribe(async (data) => {
        if (data.status === API_SUCCESS_KEY && data.data !== null) {
          try {
            const blob = this.base64ToBlob(data['data'], 'text/plain');
            this.downloadContent = blob;
            blob.text().then((text) => {
              this.errorLogResponse = text;
              this.errorLogLoaded = true;
            });
          } catch {
            this.errorLogResponse = data.data;
            this.errorLogLoaded = true;
          }
        } else {
          this.errorLogLoaded = true;
        }
      });
  }

  onDownloadLogsClicked() {
    saveAs(
      this.downloadContent,
      `log_${this.errorFlyoutInfo.clusterId}_${this.errorFlyoutInfo.stepId}.txt`
    );
  }

  emailToNameFormatter(email: any) {
    let formattedName = email
      .substring(0, email.indexOf('@'))
      .replaceAll('.', ' ');
    let words = formattedName.split(' ');
    let capitalizedWords: any = [];
    words.forEach((element: any) => {
      capitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return capitalizedWords.join(' ');
  }

  public base64ToBlob(b64Data: any, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); // IE compatibility...
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
