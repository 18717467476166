import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationPopupComponent } from './information-popup.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [InformationPopupComponent],
  exports: [InformationPopupComponent],
  imports: [CommonModule, NzModalModule, NzButtonModule],
})
export class InformationPopupModule {}
