import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import vennInit from 'highcharts/modules/venn';

vennInit(Highcharts);

@Component({
  selector: 'app-venn-diagram',
  templateUrl: './venn-diagram.component.html',
  styleUrls: ['./venn-diagram.component.scss'],
})
export class VennDiagramComponent implements OnInit {
  @Input() vennChartConfig: any[] = [];
  chart: any;
  displayVennLegend = false;
  constructor() {}

  ngOnInit(): void {
    this.displayVennChart();
  }

  displayVennChart(): void {
    this.displayVennLegend = false;

    const tooltipLabel = this.vennChartConfig;
    this.chart = Highcharts.chart('vennChart', {
      accessibility: {},
      credits: {
        enabled: false,
      },
      title: {
        text: '',
        align: 'left',
        style: {
          fontFamily: 'Open Sans',
          fontSize: '18px',
          color: '#1A1628',
          fontWeight: 'bold',
        },
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        style: {
          fontFamily: 'Open Sans',
          fontSize: '12px',
          color: '#454250',
          fontWeight: 'normal',
          cursor: 'default',
        },
        formatter: function () {
          return (
            'Number of patients with ' +
            tooltipLabel[this.point.index].tooltiptext + ' claims : ' +
            tooltipLabel[this.point.index].size
          );
        },
      },
      xAxis: this.vennChartConfig,
      series: [
        {
          type: 'venn',
          name: '',
          data: this.vennChartConfig,
        },
      ],
    });
    if (this.vennChartConfig.length > 0) {
      this.displayVennLegend = true;
    }
  }
}
