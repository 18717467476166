import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { API_LIST } from '../constants/api-list';
import { map } from 'rxjs/operators';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';
import { SensitiveInfoService } from './sensitive-info.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { consolidatedBaseUrl } from './baseUrls';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class EpisodeCreationService {
  private endpoint: string;
  private httpOptions: any;
  constructor(
    private transactionManager: TransactionManagerService,
    private http: HttpClient,
    private router: Router
  ) {
    this.httpOptions = {
      responseType: 'text',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    this.endpoint = environment.serverUrl;
  }

  getUserRole(studyId: any): Observable<any> {
    return this.transactionManager.get('study/' + studyId + '/user-role').pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getProgressStatus(studyId: any): Observable<any> {
    return this.transactionManager.get('study/' + studyId + '/progress').pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getDosChartsDetails(studyId: any, drugName: any): Observable<any> {
    return this.transactionManager
      .get('parameter/' + studyId + '/charts' + '/3?drug_name=' + drugName)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }
  getGraceChartsDetails(studyId: any, drugName: any): Observable<any> {
    return this.transactionManager
      .get('parameter/' + studyId + '/charts' + '/4?drug_name=' + drugName)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postMappingData(studyId: any, payload: any) {
    return this.http
      .post(
        this.endpoint + '/parameter/' + studyId + '/values/1',
        payload,
        this.httpOptions
      )
      .pipe(
        map(
          (response: any) => {
            let formattedResponse = JSON.parse(response.replace(/NaN/g, null));
            if (formattedResponse.status === API_SUCCESS_KEY) {
              return formattedResponse;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postMappingDataForGrace(studyId: any, payload: any) {
    return this.http
      .post(
        this.endpoint + '/parameter/' + studyId + '/values/2',
        payload,
        this.httpOptions
      )
      .pipe(
        map(
          (response: any) => {
            let formattedResponse = JSON.parse(response.replace(/NaN/g, null));
            if (formattedResponse.status === API_SUCCESS_KEY) {
              return formattedResponse;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            console.log('responserrr', err);
            return this.errorHandler(err);
          }
        )
      );
  }

  postStudyConfig(studyId: any): Observable<any> {
    return this.transactionManager
      .post('/parameter/' + studyId + '/drugs/1', {})
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getJobStatus(studyId: any): Observable<any> {
    return this.transactionManager
      .get(
        '/study/' +
          studyId +
          '/job-status?job_type=parameter&job_category=dos-imputation,grace-period-analysis,regimen-distribution&job_status=running,pending,completed,failed'
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postUploadGridData(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('parameter/' + studyId + '/upload/1', payload)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }
  postUploadGraceGridData(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .post('parameter/' + studyId + '/upload/2', payload)
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getDownloadGridData(studyId: any): Observable<any> {
    return this.transactionManager
      .get('parameter/' + studyId + '/download/1')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }
  fetchConfigDetails(studyId: any, payload: any) {
    return this.transactionManager
      .post('/study/' + studyId + '/config/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getDownloadGraceGridData(studyId: any): Observable<any> {
    return this.transactionManager
      .get('parameter/' + studyId + '/download/2')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getDrugsList(studyId: any): Observable<any> {
    return this.transactionManager
      .get('parameter/' + studyId + '/drugs/1')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }
  getDrugsListForGrace(studyId: any): Observable<any> {
    return this.transactionManager
      .get('parameter/' + studyId + '/drugs/2')
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  putRefreshGridList(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .put('parameter/' + studyId + '/values/1', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  putRefreshGraceGridList(studyId: any, payload: any): Observable<any> {
    return this.transactionManager
      .put('parameter/' + studyId + '/values/2', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postTriggerGracePeriodAnalysisJob(studyId: any, payload: any) {
    return this.transactionManager
      .post('parameter/' + studyId + '/grace-period-analysis/execute/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  postTriggerJob(studyId: any, payload: any) {
    return this.transactionManager
      .post('parameter/' + studyId + '/regimen-distribution/execute/', payload)
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  getExportData(studyId: any): Observable<any> {
    return this.transactionManager
      .get(
        '/visualizations/' +
          studyId +
          '/export?visualization_name=episode-creation'
      )
      .pipe(
        map(
          (response: any) => {
            if (response.status === API_SUCCESS_KEY) {
              return response;
            } else {
              return this.errorHandler(response);
            }
          },
          (err: any) => {
            return this.errorHandler(err);
          }
        )
      );
  }

  errorHandler(e: any = ''): any {
    if (e.error == 'Study access issue') {
      this.router.navigate(['studies/error/access_issue']);
    }
    return { status: API_FAILURE_KEY };
  }
}
