import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SOMETHING_WENT_WRONG } from 'src/app/shared/constants/app-constants';
import { STAGE_IDENTIFICATION_RULE_ERROR } from 'src/app/shared/constants/app-constants';
import { StageIdentificationService } from 'src/app/shared/services';
import {
  defaultCustomRuleComponents,
  Rule,
  ruleCategories,
  RuleCategory,
  RuleComponent,
  StageIdentificationSaveRulesApiPayload,
} from './stage-identification.model';

@Component({
  selector: 'app-stage-identification',
  templateUrl: './stage-identification.component.html',
  styleUrls: ['./stage-identification.component.scss'],
  providers: [TitleCasePipe],
})
export class StageIdentificationComponent implements OnInit, OnDestroy {
  @Input() show = false;

  @Input() rulesList: RuleCategory[] = [];

  @Input() existingRulesList: RuleCategory[] = [];

  @Input() studyId = '';

  @Output() modalClose = new EventEmitter<boolean>();

  templateRulesList: RuleCategory[] = [];

  priorityRulesList: RuleCategory[] = [];

  ruleListFetchStatus: 'loading' | 'error' | 'available' = 'loading';

  errorMessage = '';

  currentStage = '';

  stepsOption = [
    { title: 'Select Staging Rule Templates' },
    { title: 'Configure Medical Events To Rules', disabled: true },
    { title: 'Define Priority To Rules', disabled: true },
  ];

  readonly stageOptions = ['earliest', 'latest', 'prioritized'];

  readonly iconOnlyButtonStyle = {
    padding: '0.25rem',
    margin: 0,
    height: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  readonly buttonStyle = { margin: 0 };

  readonly buttonIconStyle = {
    alignItems: 'center',
    color: 'rgba(0,0,0,.85)',
    display: 'flex',
  };

  currentStep = 0;

  readonly buttonLabel = (button: 'next' | 'back') =>
    [
      { back: 'Cancel', next: 'Configure & Add Custom Rules' },
      { back: 'Back', next: 'Define Priority To Rules' },
      { back: 'Back', next: 'Save Rules' },
    ][this.currentStep]?.[button];

  isPriorityRuleListNonEmpty = () =>
    this.priorityRulesList.some((rule) => rule.rules?.length);

  private customRuleTemplate = (): Rule => ({
    type: 'custom',
    id: `${new Date().valueOf()}`,
    text: '',
    textPlus: [],
    priority: 0,
    template_type: 1,
    rule: defaultCustomRuleComponents(),
  });

  subclassDrawerData = {
    show: false,
    studyId: this.studyId,
    ruleType: '',
    ruleComponent: null as RuleComponent | null,
    selectedValues: [] as string[],
    regimenDays: { operator: '', value: null as number | null },
  };

  constructor(
    private stageIdentification: StageIdentificationService,
    private titleCasePipe: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.fetchTemplateRules();
    this.rulesList = this.stageIdentification.populateRuleList();
    this.populateRulesStage();
  }

  ngOnDestroy(): void {
    this.templateRulesList = [];
    this.rulesList = [];
  }

  handleAddCustomRuleButtonClick(categoryIndex: number): void {
    this.rulesList[categoryIndex]?.rules?.push(this.customRuleTemplate());
  }

  populateRulesStage(): void {
    this.rulesList.forEach((rules) => {
        rules.stage = this.getSelectedRulesStage(rules.key)
    })
  }

  getSelectedRulesStage(key: string) : string {
      this.existingRulesList.forEach((rules) => {
        if (rules.key === key){
          this.currentStage = rules.stage?.toLowerCase() ?? '';
        }
      })
      return this.currentStage;
  }

  handleRemoveRuleButtonClick(categoryIndex: number, ruleIndex: number): void {
    const rule = this.rulesList[categoryIndex]?.rules?.[ruleIndex];
    if (rule.type === 'template') {
      const templateRuleIndex = this.templateRulesList[
        categoryIndex
      ]?.rules.findIndex((r) => r.id === rule.id);
      this.templateRulesList[categoryIndex].rules[templateRuleIndex].selected =
        false;
    }
    this.rulesList[categoryIndex]?.rules?.splice(ruleIndex, 1);
  }

  handleRemovePriorityRuleButtonClick(
    categoryIndex: number,
    ruleIndex: number
  ): void {
    const rule = this.priorityRulesList[categoryIndex]?.rules?.[ruleIndex];
    if (rule.type !== 'existing') {
      const customRuleIndex = this.rulesList[categoryIndex]?.rules.findIndex(
        (r) => r.id === rule.id
      );
      this.handleRemoveRuleButtonClick(categoryIndex, customRuleIndex);
    }
    this.priorityRulesList[categoryIndex]?.rules?.splice(ruleIndex, 1);
  }

  handleCloneRuleButtonClick(categoryIndex: number, ruleIndex: number): void {
    const originalRule: Rule = JSON.parse(
      JSON.stringify(this.rulesList[categoryIndex]?.rules[ruleIndex])
    );
    originalRule.id = `${new Date().valueOf()}`;
    originalRule.type =
      originalRule.type === 'template' ? 'template-copy' : 'custom';
    this.rulesList[categoryIndex]?.rules?.splice(
      ruleIndex + 1,
      0,
      originalRule
    );
  }

  handleRuleComponentInputChange(
    value: any,
    rule: Rule,
    ruleComponent: RuleComponent,
    idx: number
  ): void {
    rule.error = false;
    if (ruleComponent.key === 'event1' || ruleComponent.key === 'event2') {
      ruleComponent.metadata = [];
      ruleComponent.operator = '';
      ruleComponent.regimen_days = undefined;
      ruleComponent.data = value ? `${value}` : null;
      this.handleAddSubclassButtonClick(ruleComponent);
    } else if (
      ruleComponent.key === 'occurrence1' ||
      ruleComponent.key === 'occurrence2'
    ) {
      if (value === 'nth') {
        ruleComponent.data = null;
      } else {
        ruleComponent.data = `${ruleComponent.value}`;
      }
    } else if (ruleComponent.key === 'condition') {
      for (let i = idx - 1; i < rule.rule.length; ++i) {
        rule.rule[i].exists = !!value;
      }
      ruleComponent.data = value ? `${value}` : null;
    } else if (
      ruleComponent.key === 'gap_days' ||
      ruleComponent.key === 'occurrence_time'
    ) {
      ruleComponent.data = value ? `${value}` : null;
    }
  }

  handleRuleComponentMetadataChange(rule: Rule): void {
    rule.error = false;
  }

  handleStepChange(step: number): void {
    this.currentStep = step;
    this.loadStep(this.currentStep);
  }

  handleBackButtonClick(step: number): void {
    if (step === 0) {
      this.handleClose();
    }
    this.errorMessage = '';
    this.currentStep = step - 1;
    this.loadStep(this.currentStep);
  }

  handleNextButtonClick(step: number): void {
    switch (step + 1) {
      case 1: {
        break;
      }
      case 2: {
        if (this.getRuleCategoryValidation().some(Boolean)) {
          return;
        }
        this.priorityRulesList = this.stageIdentification.combineRulesList(this.existingRulesList);
        break;
      }
      case 3: {
        this.triggerSaveApi();
        return;
      }
      default: {
        throw Error(`unhandled "next" button click for step - ${step}`);
      }
    }
    this.currentStep = step + 1;
    this.loadStep(this.currentStep);
  }

  isNextButtonDisabled(step: number): boolean {
    if (this.ruleListFetchStatus !== 'available') {
      return true;
    }

    if (step === 2) {
      return !this.isPriorityRuleListNonEmpty();
    }

    return false;
  }

  handleTemplateRuleCheck(
    checked: boolean,
    categoryIndex: number,
    rule: Rule
  ): void {
    if (checked) {
      this.rulesList[categoryIndex]?.rules.push(
        JSON.parse(JSON.stringify(rule))
      );
    } else {
      const ruleIndex = this.rulesList[categoryIndex]?.rules.findIndex(
        (r) => r.id === rule.id
      );
      this.rulesList[categoryIndex]?.rules.splice(ruleIndex, 1);
    }
  }

  handleAddSubclassButtonClick(ruleComponent: RuleComponent): void {
    this.subclassDrawerData.studyId = this.studyId;
    this.subclassDrawerData.ruleType = this.titleCasePipe.transform(
      ruleComponent.value
    );
    this.subclassDrawerData.ruleComponent = ruleComponent;
    this.subclassDrawerData.selectedValues = ruleComponent.metadata?.length
      ? [...ruleComponent.metadata!]
      : [];
    this.subclassDrawerData.regimenDays = {
      operator: `${ruleComponent.operator || ''}`,
      value: ruleComponent.regimen_days
        ? Number(ruleComponent.regimen_days)
        : null,
    };
    this.subclassDrawerData.show = true;
  }

  handleSubclassDrawerClose(payload: any): void {
    this.subclassDrawerData.ruleType = '';
    this.subclassDrawerData.show = false;

    if (!payload) {
      return;
    }

    const { subclasses, operator, regimenDays } = payload;
    if (subclasses) {
      (this.subclassDrawerData.ruleComponent as RuleComponent).metadata = [
        ...subclasses,
      ];
    }
    if (operator && regimenDays) {
      (this.subclassDrawerData.ruleComponent as RuleComponent).operator =
        operator;
      (this.subclassDrawerData.ruleComponent as RuleComponent).regimen_days =
        regimenDays;
    }
  }

  drop($event: CdkDragDrop<any[]>, categoryIndex: number): void {
    moveItemInArray(
      this.priorityRulesList[categoryIndex].rules,
      $event.previousIndex,
      $event.currentIndex
    );
  }

  private loadStep(step: number): void {
    this.stepsOption.forEach((stepOption, idx) => {
      stepOption.disabled = idx > step;
    });
  }

  private fetchTemplateRules(): void {
    this.ruleListFetchStatus = 'loading';
    this.stageIdentification.getStageIdentificationTemplateRules().subscribe(
      (data) => {
        this.templateRulesList = Object.entries(ruleCategories()).map(
          ([category, name]) => ({
            name,
            key: category,
            rules: data[category]?.map((r: Rule) => ({
              ...r,
              type: 'template',
            })),
          })
        );
        this.ruleListFetchStatus = 'available';
      },
      () => {
        this.ruleListFetchStatus = 'error';
      }
    );
  }

  private getRuleCategoryValidation(): boolean[] {
    const isComponentValid = (
      component: RuleComponent,
      index: number,
      ruleComponents: RuleComponent[]
    ) => {
      if (component.exists) {
        if (component.required && !component.value) {
          this.errorMessage = STAGE_IDENTIFICATION_RULE_ERROR.INSUFFICIENT_DATA;
          return false;
        }
        if (component.value === 'nth' && !component.data) {
          this.errorMessage = STAGE_IDENTIFICATION_RULE_ERROR.INSUFFICIENT_DATA_OCCURRENCE
          return false;
        }
        if (
          (component.value === 'treatment' ||
            component.value === 'diagnosis') &&
          !component.metadata?.length
        ) {
          this.errorMessage = STAGE_IDENTIFICATION_RULE_ERROR.INSUFFICIENT_DATA_TREATMENT
          return false;
        }
        if (
          component.key === 'condition' &&
          !ruleComponents[index + 2]?.value
        ) {
          this.errorMessage = STAGE_IDENTIFICATION_RULE_ERROR.INSUFFICIENT_DATA_GAP_DAYS
          return false;
        }
        this.errorMessage = '';
      }
      return true;
    };
    return this.rulesList.reduce(
      (errorAccumulator, ruleCategory, idx) => {
        errorAccumulator[idx] = ruleCategory.rules.reduce((errorFlag, rule) => {
          rule.error = rule.rule.some(
            (c, i) => !isComponentValid(c, i, rule.rule)
          );
          return errorFlag || rule.error;
        }, false as boolean);
        return errorAccumulator;
      },
      [false, false, false] as boolean[]
    );
  }

  private triggerSaveApi(): void {
    const payload = this.stageIdentification.prepareSaveRulesPayload(this.studyId, this.priorityRulesList);
    this.errorMessage = '';
    this.ruleListFetchStatus = 'loading';
    this.stageIdentification.saveStageIdentificationRules(payload).subscribe(
      (saveSuccessful) => {
        this.ruleListFetchStatus = 'available';
        if (saveSuccessful) {
          this.handleClose(true);
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
        }
      },
      () => {
        this.ruleListFetchStatus = 'available';
        this.errorMessage = SOMETHING_WENT_WRONG;
      }
    );
  }

  handleClose(refreshData = false): void {
    this.modalClose.emit(refreshData);
  }
}
