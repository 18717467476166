export class CloneStudyDetails {
  studyId: number;
  title: string;
  cohortName: string;
  dataSourceName: string;
  cbDataSourceId: number;
  cbCohortId: number;
  cbExportId: string;
  therapyArea: string;
  type: string;
  s3Key: string;
  importedS3Location: string;
  importedCohortDescription: string;
  createdOn: string;
  updatedOn: string;
  createdBy: string;
  updatedBy: string;
  progress: number;
  isPublished: boolean;
  outcome: string;
  studyOutcome: string;
  studyTitle: string;
  viewers: string;
  collaborators: string;
  mappingsTriggered: boolean;

  constructor(data: any) {
    this.studyId = data.studyId;
    this.title = data.title;
    this.cohortName = data.cohortName;
    this.dataSourceName = data.dataSourceName;
    this.cbDataSourceId = data.cbDataSourceId;
    this.cbCohortId = data.cbCohortId;
    this.cbExportId = data.cbExportId;
    this.therapyArea = data.therapyArea;
    this.type = data.type;
    this.s3Key = data.s3Key;
    this.importedS3Location = data.importedS3Location;
    this.importedCohortDescription = data.importedCohortDescription;
    this.createdOn = data.createdOn;
    this.updatedOn = data.updatedOn;
    this.createdBy = data.createdBy;
    this.updatedBy = data.updatedBy;
    this.progress = data.progress;
    this.isPublished = data.isPublished;
    this.outcome = data.outcome;
    this.studyOutcome = data.studyOutcome;
    this.studyTitle = data.studyTitle;
    this.viewers = data.viewers;
    this.collaborators = data.collaborators;
    this.mappingsTriggered = data.mappingsTriggered;
  }
}

export class StudyDetailsResponse {
  status: string;
  data: CloneStudyDetails;

  constructor(data: any) {
    this.status = data.status;
    this.data = data.data;
  }
}

export class DataSource {
  dataSourceId: number;
  dataSourceName: string;

  constructor(dataSourceId: number, dataSourceName: string) {
    this.dataSourceId = dataSourceId;
    this.dataSourceName = dataSourceName;
  }
}

export class CohortDetails {
  cohortId: number;
  cohortName: string;
  cohortExport: CohortExport[];
  cohortDescription: string;
  constructor(
    chrt_id: number,
    chrt_nm: string,
    chrt_desc: string,
    chrt_export: CohortExport[]
  ) {
    (this.cohortId = chrt_id),
      (this.cohortName = chrt_nm),
      (this.cohortExport = chrt_export),
      (this.cohortDescription = chrt_desc);
  }
}

export class CohortExport {
  globalConceptSet: GlobalConceptSet[];
  dataSourceList: DataSourceList[];
  version: string;

  constructor(
    glbl_con_set: GlobalConceptSet[],
    data_src_list: DataSourceList[],
    version: string
  ) {
    this.globalConceptSet = glbl_con_set;
    this.dataSourceList = data_src_list;
    this.version = version;
  }
}

export class GlobalConceptSet {
  conceptSetId: number;
  conceptSetName: string;
  constructor(con_set_id: number, con_set_nm: string) {
    this.conceptSetId = con_set_id;
    this.conceptSetName = con_set_nm;
  }
}

export class DataSourceList {
  s3bucket: string;
  dataSourceId: number;
  dataSourceDisplayName: string;
  cohortExports3Path: string;
  constructor(
    s3bucket: string,
    data_src_id: number,
    data_src_displ_nm: string,
    cohort_export_s3_path: string
  ) {
    this.s3bucket = s3bucket;
    this.dataSourceId = data_src_id;
    this.dataSourceDisplayName = data_src_displ_nm;
    this.cohortExports3Path = cohort_export_s3_path;
  }
}

export class CloneResponseBody {
  status: string;
  message: string;
  constructor(status: string, message: string) {
    this.status = status;
    this.message = message;
  }
}

export class ValidateTableResponse {
  status: string;
  dataSufficiency?: boolean;
  tablesMissing?: string[];
  tablesPresent?: string[];
  message: string;
  constructor(
    status: string,
    message: string,
    dataSufficiency?: boolean,
    tablesMissing?: string[],
    tablesPresent?: string[]
  ) {
    this.status = status;
    this.dataSufficiency = dataSufficiency;
    this.tablesMissing = tablesMissing;
    this.tablesPresent = tablesPresent;
    this.message = message;
  }
}
